import { ScreenBusUtils } from '../hooks/useEventBus';

const time = 750;

const stopPermission = (stream) => {
    stream.getTracks().forEach((track) => {
        if (track.readyState === 'live' && track.kind === 'video') {
            track.stop();
        }
    });
};

export const checkPermisionCamera = () =>
    new Promise((resovle, reject) => {
        ScreenBusUtils.showLoading();

        if (!navigator.mediaDevices && !navigator.mediaDevices.getUserMedia) {
            navigator.userMedia = navigator.mozGetUserMedia || navigator.getUserMedia;
            if (!navigator.userMedia) {
                reject();
                return;
            }

            navigator.userMedia({
                video: true,
            },
                stream => {
                    stopPermission(stream);
                    setTimeout(() => resovle(true), time);
                },
                () => resovle());
            return;
        }

        navigator.mediaDevices.getUserMedia({
            video: true,
        })
            .then(stream => {
                stopPermission(stream);
                setTimeout(() => resovle(true), time);
            })
            .catch(() => resovle());
    }).finally(() =>
        setTimeout(() => ScreenBusUtils.hideLoading(), time),
    );
