import useEventListener from './useEventListener';
import { isHomePage } from '../utils/pageUtils';

export const useBeforePageUnload = (pathname) => {
    // eslint-disable-next-line consistent-return
    useEventListener('beforeunload', (e) => {
        // ignore home page
        if (isHomePage(pathname)) {
            return true;
        }

        e.preventDefault();
        e.returnValue = '';
        return undefined;
    });
};
