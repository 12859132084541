import React from 'react';

const HelpIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12 22.3125C17.6954 22.3125 22.3125 17.6954 22.3125 12C22.3125 6.30456 17.6954 1.6875 12 1.6875C6.30456 1.6875 1.6875 6.30456 1.6875 12C1.6875 17.6954 6.30456 22.3125 12 22.3125Z" stroke="#FFCC00" strokeWidth="1.125" />
    <path d="M11.5312 10.2188C11.5312 9.90809 11.7831 9.65625 12.0938 9.65625C12.4044 9.65625 12.6562 9.90809 12.6562 10.2188V16.6185C12.6562 16.9292 12.4044 17.181 12.0938 17.181C11.7831 17.181 11.5312 16.9292 11.5312 16.6185V10.2188Z" fill="#FFCC00" />
    <path d="M11.5312 7.59375C11.5312 7.28309 11.7831 7.03125 12.0938 7.03125C12.4044 7.03125 12.6562 7.28309 12.6562 7.59375V8.34375C12.6562 8.65441 12.4044 8.90625 12.0938 8.90625C11.7831 8.90625 11.5312 8.65441 11.5312 8.34375V7.59375Z" fill="#FFCC00" />
  </svg>
);

export default HelpIcon;
