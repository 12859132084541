import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import joinclass from 'joinclass';

import { fetchAffordabilityResults } from '../actions';
import { useCommonContext } from '../context/CommonContext';
import useEventListener from '../hooks/useEventListener';
import useTimeout from '../hooks/useTimeout';
import useEventBus, { EventBusName, ScreenBusUtils, TruIDBusUtils } from '../hooks/useEventBus';
import { execShowMissingFormWhenDeclineCode } from '../utils/commonUtils';
import { FlexRowCenter } from '../components/Common/FlexCenter';

const TruId = ({
    data,
    loanSubmission,
    verifyLoanApplicationData,
    // eslint-disable-next-line no-shadow
    fetchAffordabilityResults,
}) => {
    const { declineSpecialCode } = useCommonContext();
    const [cache, setCache] = useState(1);
    const [loaded, setLoaded] = useState(false);

    const loanApplicationNumber = _.get(loanSubmission, 'loanApplicationNumber') || _.get(verifyLoanApplicationData, 'loanApplicationNumber');
    const url = _.get(data, 'inviteUrl', '');

    useTimeout(() => ScreenBusUtils.showLoading(), 100);
    useTimeout(() => {
        ScreenBusUtils.hideLoading();
        !loaded && ScreenBusUtils.showErrorCode();
    }, 120 * 1000);

    useEffect(() => {
        execShowMissingFormWhenDeclineCode(declineSpecialCode);
    }, [declineSpecialCode]);

    // we need refresh truid screen
    useEventBus(EventBusName.TRUID_NORIFY_RELOAD_IFRAME, () => {
        ScreenBusUtils.clearDeclineStatus();
        ScreenBusUtils.showLoading();
        setCache(val => val + 1);
        setLoaded(false);
    });

    // eslint-disable-next-line no-shadow
    useEventListener('message', ({ data }) => {
        if (data.status === 0 || data.status === 1110) {
            TruIDBusUtils.showTruIDCancel();
            return;
        }

        if (data.status === 2000) {
            fetchAffordabilityResults({ loanApplicationNumber });
            return;
        }

        if (data.status) {
            TruIDBusUtils.showTruIDError();
        }
    }, window);

    return (
        <FlexRowCenter id="truid">
            <iframe
                key={cache}
                id="iframe"
                className={joinclass('iframe container', url && loaded && 'iframe-ready')}
                src={url}
                width="100%"
                height="100%"
                onLoad={() => {
                    ScreenBusUtils.hideLoadingLazy();
                    setLoaded(true);
                }}
                title="truid-view"
            />
        </FlexRowCenter>
    );
};

const mapStateToProps = state => ({
    data: state.offers,
    loanSubmission: state.loanSubmission,
    verifyLoanApplicationData: state.verifyLoanApplicationData,
});

export default connect(
    mapStateToProps,
    {
        fetchAffordabilityResults,
    },
)(TruId);
