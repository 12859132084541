import React from 'react';
import ActionSteps from '../Common/ActionSteps';
import LabelTitle from '../Common/LabelTitle';

import { introTruIDSprintHiveStepData } from '../../assets/data/truid-step-data';

function IntroSprintHiveSteps({
    title,
}) {
    return (
        <React.Fragment>
            <LabelTitle className="mt-sm-4 my-3 mx-4 text-center">
                {title}
            </LabelTitle>

            <ActionSteps
                className="intro-truid-steps mt-sm-4 mt-3"
                listSteps={introTruIDSprintHiveStepData} />
        </React.Fragment>
    );
}

export default IntroSprintHiveSteps;
