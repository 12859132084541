/* eslint-disable no-mixed-operators */
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';

import { ScreenBusUtils } from '../hooks/useEventBus';

import { XWrapLabelSmallSkeleton, XWrapMoneyLargeSkeleton } from '../components/Common/LoadingSkeletons';
import XWrapMoney from '../components/Common/XWrapMoney';
import LabelLoanApplication from '../components/Common/LabelLoanApplication';
import PLButton from '../components/Common/PLButton';
import HelpTip from '../components/Common/HelpTip';

import iconMoneyLimit from '../assets/images/iconMoneyLimit.svg';

const OffersLimit = ({ data }) => {
    const amount = (data.offerList[0] && data.offerList[0].finalApprovedAmount);
    const dateExpired = moment(new Date(data && data.incomeVerificationExpired)).format('DD/MM/YYYY');
    return (
        <div id="offers">
            <LabelLoanApplication />

            <img
                className="mt-2"
                src={iconMoneyLimit}
                alt=""
                width="64"
            />

            <h2 className="font-size-16 font-size-md-20 font-weight-medium mt-4">
                Based on the details you provided, this is the highest loan amount you qualify for right now:
            </h2>
            <XWrapMoneyLargeSkeleton>
                <XWrapMoney className="font-size-36 font-size-md-40">
                    {amount}
                </XWrapMoney>
            </XWrapMoneyLargeSkeleton>

            <p className="mt-5">
                It may be a little lower than what you requested but it should get you closer to your goal.
            </p>

            <HelpTip className="mt-0 mt-md-4 mb-4">
                <span className='mr-1'>This application is valid until</span>
                <XWrapLabelSmallSkeleton>
                    <span className="font-weight-500">{dateExpired}.</span>
                </XWrapLabelSmallSkeleton>
            </HelpTip>

            <div className="buttons my-4">
                <PLButton onClick={() => ScreenBusUtils.gotoScreenOffers()}>
                    Confirm
                </PLButton>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    data: state.offers || { offerList: [] },
});

export default connect(
    mapStateToProps,
)(OffersLimit);
