// =================================================================
/** For optimize later */
// eslint-disable-next-line no-unused-vars
import React from 'react';

import Said from '../../containers/Said';
import Phone from '../../containers/Phone';
import OTP from '../../containers/OTP';
import LoanInfo from '../../containers/LoanInfo';
import LoanPurposeMain from '../../containers/LoanPurpose';
import UserInfo from '../../containers/UserInfo';
import FinancialInformation from '../../containers/FinancialInformation';
import Confirmation from '../../containers/Confirmation';
import Offers from '../../containers/Offers';
import OffersLimit from '../../containers/OffersLimit';
import LowerOffers from '../../containers/LowerOffers';
import OffersUpsell from '../../containers/OffersUpsell';
import SalaryTymeOffers from '../../containers/OffersTymeSalary';

import IntroTruid from '../../containers/IntroTruid';
import TruId from '../../containers/Truid';
import Package from '../../containers/Package';
import LoanAgreement from '../../containers/LoanAgreement';
import DebiCheck from '../../containers/DebiCheck';
import Funded from '../../containers/Funded';
import ResumeLoan from '../../containers/ResumeLoan';
import OffersDeclined from '../../containers/OffersDeclined';
import IntroSprintHive from '../../containers/IntroSprintHive';
import IntroSelfie from '../../containers/IntroSelfie';

import X2Landing from '../../x2-layout/x2-page/X2Landing';

import { routeName } from '../../constants';

import bannerVerify from '../../assets/images/article-4-option-3-1785111.png';
import bannerOtp from '../../assets/images/bannerPhone.png';
import bannerLoanInfo from '../../assets/images/376897.png';
import bannerFinanceInformation from '../../assets/images/article-11-option-5-1015184.png';
import bannerAdditionalInfo from '../../assets/images/competition-image.png';
import bannerConfirmation from '../../assets/images/article-11-option-4-1017776.png';
import bannerOffers from '../../assets/images/shutterstock-383617564.png';
import bannerFee from '../../assets/images/fee.png';
import bannerDeclaration from '../../assets/images/declaration.png';
import bannerDebiCheck from '../../assets/images/debicheck.png';
import bannerDeline from '../../assets/images/delineBanner.png';

export const mappingFullSizePageRouter = [
  { path: routeName.HONE, component: X2Landing },
  { path: routeName.X2LANDING, component: X2Landing },
  { path: routeName.TRUID_INTRO, component: IntroTruid },
  { path: routeName.TRUID, component: TruId },
  { path: routeName.DEBICHECK, component: DebiCheck },
  { path: routeName.FUNDED, component: Funded },
  { path: routeName.INTRO_SPRINTHIDE, component: IntroSprintHive },
  { path: routeName.TYME_BANK_OFFERS, component: SalaryTymeOffers },
  { path: routeName.TYME_BANK_OFFERS_APPROVED, component: SalaryTymeOffers },
  { path: routeName.UPSELL_OFFERS, component: OffersUpsell },
  { path: routeName.FACE_INTRO_SELFIE, component: IntroSelfie },

  // <pl-domain>/products/:productId: use landing page and forward route
  { path: routeName.SEGMENT_PRODUCT_ID, component: X2Landing },
  { path: routeName.NEW_SEGMENT_PRODUCT_ID, component: X2Landing },
];

export const mappingXPageRouter = [
  { path: routeName.START, component: Said },
  { path: routeName.PHONE, component: Phone },
  { path: routeName.OTP, component: OTP },
  { path: routeName.OTP_RESUME, component: OTP },
  { path: routeName.LOAN_AMOUT, component: LoanInfo },
  { path: routeName.LOAN_AMOUT_MODE, component: LoanInfo },
  { path: routeName.LOAN_PURPOSE, component: LoanPurposeMain },
  { path: routeName.LOAN_PURPOSE_MODE, component: LoanPurposeMain },
  { path: routeName.USER_INFO, component: UserInfo },
  { path: routeName.USER_INFO_MODE, component: UserInfo },
  { path: routeName.FINANCIAL_INFO, component: FinancialInformation },
  { path: routeName.FINANCIAL_INFO_MODE, component: FinancialInformation },
  { path: routeName.CONFIRMATION, component: Confirmation },
  { path: routeName.OFFERS, component: Offers },
  { path: routeName.LIMIT_OFFERS, component: OffersLimit },
  { path: routeName.LOWER_OFFERS, component: LowerOffers },
  { path: routeName.PACKAGE, component: Package },
  { path: routeName.AGREEMENT, component: LoanAgreement },
  { path: routeName.RESUME, component: ResumeLoan },
  { path: routeName.OFFER_DECLINED_LAN, component: OffersDeclined },
];

export const headerBannerData = {
  [routeName.HONE]: {
    pageName: 'Landing',
    exact: true,
  },
  [routeName.START]: {
    banner: bannerVerify,
    keyName: 'saidPage',
    pageName: 'IDENTITY - SAID',
  },
  [routeName.PHONE]: {
    banner: bannerOtp,
    keyName: 'phonePage',
    pageName: 'IDENTITY - CellPhone',
  },
  [routeName.OTP]: {
    banner: bannerOtp,
    keyName: 'otpPage',
    pageName: 'IDENTITY - OTP Verify',
  },
  [routeName.OTP_RESUME]: {
    banner: bannerOtp,
    keyName: 'otpPage',
  },
  [routeName.LOAN_AMOUT]: {
    banner: bannerLoanInfo,
    keyName: 'loanInfoPage',
    pageName: 'BORROW MONEY - Loan Amount',
  },
  [routeName.LOAN_PURPOSE]: {
    banner: bannerLoanInfo,
    keyName: 'loanPurposePage',
    pageName: 'BORROW MONEY - Loan Purpose Information',
  },
  [routeName.USER_INFO]: {
    banner: bannerFinanceInformation,
    keyName: 'userInfoPage',
    pageName: 'ABOUT YOURSELF - User Information',
  },
  [routeName.FINANCIAL_INFO]: {
    banner: bannerAdditionalInfo,
    keyName: 'financeInformationPage',
    pageName: 'ABOUT YOURSELF - Financial Information',
  },
  [routeName.ADDITIONAL_INFO]: {
    banner: bannerAdditionalInfo,
    keyName: 'additionalInfoPage',
    pageName: 'ABOUT YOURSELF - More Information',
  },
  [routeName.CONFIRMATION]: {
    banner: bannerAdditionalInfo,
    keyName: 'confirmationPage',
    pageName: 'CONFIRMATION YOURSELF - Information',
  },
  [routeName.OFFERS]: {
    banner: bannerOffers,
    keyName: 'offersPage',
    pageName: 'DECISION ENGINE - CONDITIONAL APPROVED Offers',
    exact: true,
  },
  // confirm use salary tymebank
  [routeName.TYME_BANK_OFFERS]: {
    banner: bannerOffers,
    keyName: 'offersPage',
    pageName: 'DECISION ENGINE - TYMEBANK SALARY CONDITIONAL Offers',
    exact: true,
  },
  [routeName.TYME_BANK_OFFERS_APPROVED]: {
    banner: bannerFee,
    keyName: 'packagePage',
    pageName: 'DECISION ENGINE - TYMEBANK SALARY APPROVED Offers',
    exact: true,
  },
  [routeName.UPSELL_OFFERS]: {
    banner: bannerOffers,
    keyName: 'offersPage',
    pageName: 'DECISION ENGINE - UPSELL Offers',
  },
  // confirm use salary tymebank
  [routeName.LIMIT_OFFERS]: {
    banner: bannerFinanceInformation,
    keyName: 'limitOffersPage',
    pageName: 'DECISION ENGINE - LIMIT Offers',
  },
  [routeName.LOWER_OFFERS]: {
    banner: bannerFinanceInformation,
    keyName: 'lowerOffersPage',
    pageName: 'DECISION ENGINE - LOWER Offers',
  },
  [routeName.TRUID_INTRO]: {
    banner: bannerDebiCheck,
    keyName: 'introTruIDPage',
    pageName: 'SCRAPPING - Intro truID',
  },
  [routeName.INTRO_SPRINTHIDE]: {
    banner: bannerDebiCheck,
    keyName: 'introSprintHivePage',
    pageName: 'SCRAPPING - Intro SprintHive',
  },
  [routeName.TRUID]: {
    banner: bannerDebiCheck,
    keyName: 'truidPage',
    pageName: 'SCRAPPING - truID',
  },
  [routeName.PACKAGE]: {
    banner: bannerFee,
    keyName: 'packagePage',
    pageName: 'PACKAGE AND CONTRACT - Select money package',
  },
  [routeName.AGREEMENT]: {
    banner: bannerDeclaration,
    keyName: 'agreementPage',
    pageName: 'PACKAGE AND CONTRACT - Sign the contract',
  },
  [routeName.FACE_INTRO_SELFIE]: {
    banner: bannerFee,
    keyName: 'introSelfiePage',
    pageName: 'FACIAL SELFIE - Intro facial',
  },
  [routeName.DEBICHECK]: {
    banner: bannerDebiCheck,
    keyName: 'debicheckPage',
    pageName: 'DEBI-CHECK - Debit check order',
  },
  [routeName.FUNDED]: {
    banner: bannerDebiCheck,
    keyName: 'fundedPage',
    pageName: 'LOAN APPLICATION - Funded',
  },
  [routeName.OFFER_DECLINED]: {
    banner: bannerDeline,
    keyName: 'offersDeclinedPage',
    pageName: 'DECISION ENGINE - Deny',
  },
  [routeName.RESUME]: {
    banner: bannerConfirmation,
    keyName: 'resumePage',
    pageName: 'LOAN APPLICATION - Expired',
  },
};

export const headerBannerKeys = Object.keys(headerBannerData);
