import React from 'react';
import joinclass from 'joinclass';
import styled from 'styled-components';

export const WrapA = styled.a`
  cursor: pointer;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &.base {
    color: #FDC600;
  }

  &.normal-text {
    color: inherit;
    &:hover {
      color: #3eb5e5;
    }
  }
  &.show-link {
    text-decoration: underline;
  }
`;

const PLLinkTab = ({
  url,
  className,
  base,
  ...props
}) => {
  const linkHref = (e) => {
    if (typeof window.open === 'function') {
      window.open(url, '_blank', 'noopener,noreferrer');
      e.preventDefault();
      e.stopPropagation();
      return false;
    }
    return true;
  };

  return (
    <WrapA
      {...props}
      href={url}
      className={joinclass(className, base && 'base')}
      onClick={linkHref}
      onTouchEndCapture={linkHref}
    >
      {props.children || url}
    </WrapA>
  );
};

export default PLLinkTab;
