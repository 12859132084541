/**
 * We suggest some city in south africa
 */
const southAfricaCity = [
  {
    city: 'Adelaide',
  },
  {
    city: 'Albertina',
  },
  {
    city: 'Alberton',
  },
  {
    city: 'Alice',
  },
  {
    city: 'Aliwal North',
  },
  {
    city: 'Allanridge',
  },
  {
    city: 'Arniston',
  },
  {
    city: 'Atlantis',
  },
  {
    city: 'Balfour',
  },
  {
    city: 'Ballito',
  },
  {
    city: 'Barberton',
  },
  {
    city: 'Barkly West',
  },
  {
    city: 'Beaufort West',
  },
  {
    city: 'Belfast',
  },
  {
    city: 'Benoni',
  },
  {
    city: 'Berea',
  },
  {
    city: 'Bergvliet',
  },
  {
    city: 'Bethal',
  },
  {
    city: 'Bethlehem',
  },
  {
    city: 'Bhisho',
  },
  {
    city: 'Bloemfontein',
  },
  {
    city: 'Bloemhof',
  },
  {
    city: 'Bochum',
  },
  {
    city: 'Boksburg',
  },
  {
    city: 'Bothaville',
  },
  {
    city: 'Botshabelo',
  },
  {
    city: 'Brakpan',
  },
  {
    city: 'Brandfort',
  },
  {
    city: 'Brandvlei',
  },
  {
    city: 'Bredasdorp',
  },
  {
    city: 'Breyten',
  },
  {
    city: 'Brits',
  },
  {
    city: 'Bronkhorstspruit',
  },
  {
    city: 'Burgersdorp',
  },
  {
    city: 'Butterworth',
  },
  {
    city: 'Caledon',
  },
  {
    city: 'Calitzdorp',
  },
  {
    city: 'Calvinia',
  },
  {
    city: 'Cape Town',
  },
  {
    city: 'Carletonville',
  },
  {
    city: 'Carnarvon',
  },
  {
    city: 'Carolina',
  },
  {
    city: 'Centurion',
  },
  {
    city: 'Ceres',
  },
  {
    city: 'Christiana',
  },
  {
    city: 'Claremont',
  },
  {
    city: 'Clocolan',
  },
  {
    city: 'Colesberg',
  },
  {
    city: 'Constantia',
  },
  {
    city: 'Cradock',
  },
  {
    city: 'Cullinan',
  },
  {
    city: 'Danielskuil',
  },
  {
    city: 'De Aar',
  },
  {
    city: 'De Rust',
  },
  {
    city: 'Delmas',
  },
  {
    city: 'Deneysville',
  },
  {
    city: 'Diepsloot',
  },
  {
    city: 'Dordrecht',
  },
  {
    city: 'Driefontein',
  },
  {
    city: 'Duiwelskloof',
  },
  {
    city: 'Dundee',
  },
  {
    city: 'Durban',
  },
  {
    city: 'East London',
  },
  {
    city: 'Eastleigh',
  },
  {
    city: 'Eden Glen',
  },
  {
    city: 'Eden Glen Ext 60',
  },
  {
    city: 'Edenvale',
  },
  {
    city: 'Ekangala',
  },
  {
    city: 'Ekuvukeni',
  },
  {
    city: 'Elliot',
  },
  {
    city: 'Empangeni',
  },
  {
    city: 'Ermelo',
  },
  {
    city: 'Eshowe',
  },
  {
    city: 'Fochville',
  },
  {
    city: 'Fort Beaufort',
  },
  {
    city: 'Frankfort',
  },
  {
    city: 'Fraserburg',
  },
  {
    city: 'Ga-Kgapane',
  },
  {
    city: 'Ga-Rankuwa',
  },
  {
    city: 'George',
  },
  {
    city: 'Germiston',
  },
  {
    city: 'Giyani',
  },
  {
    city: 'Glencoe',
  },
  {
    city: 'Graaff-Reinet',
  },
  {
    city: 'Grabouw',
  },
  {
    city: 'Grahamstown',
  },
  {
    city: 'Greytown',
  },
  {
    city: 'Hardys Memories of Africa',
  },
  {
    city: 'Harrismith',
  },
  {
    city: 'Heidelberg',
  },
  {
    city: 'Heilbron',
  },
  {
    city: 'Hendrina',
  },
  {
    city: 'Hennenman',
  },
  {
    city: 'Hermanus',
  },
  {
    city: 'Hluhluwe',
  },
  {
    city: 'Hoopstad',
  },
  {
    city: 'Howick',
  },
  {
    city: 'Ilinge',
  },
  {
    city: 'Jan Kempdorp',
  },
  {
    city: 'Johannesburg',
  },
  {
    city: 'Kathu',
  },
  {
    city: 'Kenhardt',
  },
  {
    city: 'Khaya Mnandi',
  },
  {
    city: 'Kimberley',
  },
  {
    city: 'Kirkwood',
  },
  {
    city: 'Klerksdorp',
  },
  {
    city: 'Knysna',
  },
  {
    city: 'Kokstad',
  },
  {
    city: 'Komatipoort',
  },
  {
    city: 'Koppies',
  },
  {
    city: 'Koster',
  },
  {
    city: 'Kraaifontein',
  },
  {
    city: 'Kriel',
  },
  {
    city: 'Kroonstad',
  },
  {
    city: 'Krugersdorp',
  },
  {
    city: 'Kruisfontein',
  },
  {
    city: 'Kuruman',
  },
  {
    city: 'Kutloanong',
  },
  {
    city: 'KwaDukuza',
  },
  {
    city: 'Ladismith',
  },
  {
    city: 'Lady Frere',
  },
  {
    city: 'Ladybrand',
  },
  {
    city: 'Lansdowne',
  },
  {
    city: 'Lebowakgomo',
  },
  {
    city: 'Lichtenburg',
  },
  {
    city: 'Lindley',
  },
  {
    city: 'Louis Trichardt',
  },
  {
    city: 'Lydenburg',
  },
  {
    city: 'Mabopane',
  },
  {
    city: 'Mahikeng',
  },
  {
    city: 'Maile',
  },
  {
    city: 'Malmesbury',
  },
  {
    city: 'Mankoeng',
  },
  {
    city: 'Margate',
  },
  {
    city: 'Marquard',
  },
  {
    city: 'Middelburg',
  },
  {
    city: 'Middelburg',
  },
  {
    city: 'Midrand',
  },
  {
    city: 'Midstream',
  },
  {
    city: 'Mmabatho',
  },
  {
    city: 'Modderfontein',
  },
  {
    city: 'Modimolle',
  },
  {
    city: 'Mokopane',
  },
  {
    city: 'Molteno',
  },
  {
    city: 'Mondlo',
  },
  {
    city: 'Montagu',
  },
  {
    city: 'Mooirivier',
  },
  {
    city: 'Mossel Bay',
  },
  {
    city: 'Mpophomeni',
  },
  {
    city: 'Mpumalanga',
  },
  {
    city: 'Mthatha',
  },
  {
    city: 'Muldersdriseloop',
  },
  {
    city: 'Nelspruit',
  },
  {
    city: 'Newcastle',
  },
  {
    city: 'Newlands',
  },
  {
    city: 'Nigel',
  },
  {
    city: 'Nkowakowa',
  },
  {
    city: 'Noupoort',
  },
  {
    city: 'Orange Farm',
  },
  {
    city: 'Orania',
  },
  {
    city: 'Orkney',
  },
  {
    city: 'Oudtshoorn',
  },
  {
    city: 'Paarl',
  },
  {
    city: 'Pampierstad',
  },
  {
    city: 'Parys',
  },
  {
    city: 'Phalaborwa',
  },
  {
    city: 'Phuthaditjhaba',
  },
  {
    city: 'Piet Retief',
  },
  {
    city: 'Pietermaritzburg',
  },
  {
    city: 'Pinetown',
  },
  {
    city: 'Plettenberg Bay',
  },
  {
    city: 'Pofadder',
  },
  {
    city: 'Polokwane',
  },
  {
    city: 'Port Alfred',
  },
  {
    city: 'Port Elizabeth',
  },
  {
    city: "Port Saint John's",
  },
  {
    city: 'Port Shepstone',
  },
  {
    city: 'Postmasburg',
  },
  {
    city: 'Potchefstroom',
  },
  {
    city: 'Pretoria',
  },
  {
    city: 'Prieska',
  },
  {
    city: 'Prince Albert',
  },
  {
    city: 'Queensdale',
  },
  {
    city: 'Queenstown',
  },
  {
    city: 'Randburg',
  },
  {
    city: 'Randfontein',
  },
  {
    city: 'Reitz',
  },
  {
    city: 'Retreat',
  },
  {
    city: 'Richards Bay',
  },
  {
    city: 'Richmond',
  },
  {
    city: 'Ritchie',
  },
  {
    city: 'Riversdale',
  },
  {
    city: 'Robertson',
  },
  {
    city: 'Rondebosch',
  },
  {
    city: 'Roodepoort',
  },
  {
    city: 'Rosebank',
  },
  {
    city: 'Rustenburg',
  },
  {
    city: 'Saint Lucia Estuary',
  },
  {
    city: 'Saldanha',
  },
  {
    city: 'Sasolburg',
  },
  {
    city: 'Schweizer-Reneke',
  },
  {
    city: 'Scottburgh',
  },
  {
    city: 'Secunda',
  },
  {
    city: 'Senekal',
  },
  {
    city: "Simon's Town",
  },
  {
    city: 'Siyabuswa',
  },
  {
    city: 'Somerset East',
  },
  {
    city: 'Soweto',
  },
  {
    city: 'Springbok',
  },
  {
    city: 'Springs',
  },
  {
    city: 'Standerton',
  },
  {
    city: 'Stanford',
  },
  {
    city: 'Stellenbosch',
  },
  {
    city: 'Stilfontein',
  },
  {
    city: 'Stutterheim',
  },
  {
    city: 'Sundumbili',
  },
  {
    city: 'Sunset Beach',
  },
  {
    city: 'Swellendam',
  },
  {
    city: 'Tembisa',
  },
  {
    city: 'Thaba Nchu',
  },
  {
    city: 'Thabazimbi',
  },
  {
    city: 'Theunissen',
  },
  {
    city: 'Thohoyandou',
  },
  {
    city: 'Thornhill',
  },
  {
    city: 'Thulamahashi',
  },
  {
    city: 'Tzaneen',
  },
  {
    city: 'Uitenhage',
  },
  {
    city: 'Ulundi',
  },
  {
    city: 'Upington',
  },
  {
    city: 'Utrecht',
  },
  {
    city: 'Van Wyksvlei',
  },
  {
    city: 'Vanderbijlpark',
  },
  {
    city: 'Ventersburg',
  },
  {
    city: 'Vereeniging',
  },
  {
    city: 'Viljoenskroon',
  },
  {
    city: 'Villiers',
  },
  {
    city: 'Virginia',
  },
  {
    city: 'Volksrust',
  },
  {
    city: 'Vrede',
  },
  {
    city: 'Vredefort',
  },
  {
    city: 'Vredenburg',
  },
  {
    city: 'Vredendal',
  },
  {
    city: 'Vryburg',
  },
  {
    city: 'Vryheid',
  },
  {
    city: 'Warmbaths',
  },
  {
    city: 'Warrenton',
  },
  {
    city: 'Welkom',
  },
  {
    city: 'Wellington',
  },
  {
    city: 'Wesselsbron',
  },
  {
    city: 'Westonaria',
  },
  {
    city: 'White River',
  },
  {
    city: 'Whittlesea',
  },
  {
    city: 'Willowmore',
  },
  {
    city: 'Winburg',
  },
  {
    city: 'Witbank',
  },
  {
    city: 'Wolmaransstad',
  },
  {
    city: 'Worcester',
  },
  {
    city: 'Zastron',
  },
  {
    city: 'Zeerust',
  },
  {
    city: 'Zoar',
  },
  {
    city: 'eMbalenhle',
  },
  {
    city: 'eMkhomazi',
  },
  {
    city: 'eSikhaleni',
  },
];

export default southAfricaCity;
