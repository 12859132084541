import React, { useState } from 'react';
import joinclass from 'joinclass';
import { scrollElementSmooth } from '../../../utils/commonUtils';
import { WrapSegmentBusUtils } from '../../../hooks/useSegmentEventBus';

function XLandingFAQItem({
    label,
    icon,
    className,
    trackingName,
    children,
}) {
    const [open, setOpen] = useState(false);
    const refs = React.useRef(null);

    const handleToogle = () => {
        const toggle = !open;
        setOpen(toggle);
        toggle && setTimeout(() => scrollElementSmooth(refs.current, 'center'), 100);

        if (toggle && trackingName) {
            trackingName && WrapSegmentBusUtils.sendSegmentEventTrackingFromClient(trackingName);
        }
    };

    return (
        <div className="answer-wrap">
            <div className={joinclass(className, 'd-flex flex-row')}>
                <div className="arrow-question" />

                <div className="background font-size-16 font-size-md-20">
                    <div ref={refs} className="d-flex answer-item flex-row w-100 gap-12">
                        <div
                            className="cursor-pointer"
                            onClick={() => {
                                handleToogle();
                            }}
                            aria-hidden="true"
                        >
                            {label}
                        </div>

                        <div
                            className={joinclass('arrow-left pl-accordion-arrow', open && 'arrow-active')}
                            onClick={() => {
                                handleToogle();
                            }}
                            aria-hidden="true"
                        >
                            <img src={icon} width={24} height={24} alt=" " />
                        </div>
                    </div>

                    {open &&
                        <div className="pl-accordion-content font-size-16 font-weight-300">
                            {children}
                        </div>
                    }
                </div>
            </div>
        </div>

    );
}

export default XLandingFAQItem;
