import {
    FETCH_CONFIGURATION_SUCCESS,
    FETCH_CONFIGURATION_FAILED,
    FETCH_CONFIGURATION,
    SET_MODE_IFRAME_KIOSK,
} from '../store/actionTypes';
import { Configs } from '../constants';
import config from '../config';

const initialState = {
    configs: {},
    error: null,
    isLoading: null,
    isModeIframeKiosk: false,
};

export default (state = initialState, action) => {
    let configs = {};
    const percent = Configs.INTEREST_RATE_PERCENT;
    const serviceFee = Configs.LOAN_SERVICE_FEE;
    const establishFee = Configs.ESTABLISHMENT_FEE;
    const otpTimeoutSecond = Configs.OTP_COUNTDOWN_TIMEOUT_SECOND;
    const retryTime = Configs.OTP_RETRY_TIME;
    const validateTime = Configs.OTP_VALIDATE_TIME;
    const validateAgeMinimum = Configs.VALIDATION_AGE_MINIMUM;

    const borrowMin = Configs.BORROW_MIN_AMOUNT;
    const borrowMax = Configs.BORROW_MAX_AMOUNT;

    switch (action.type) {
        case FETCH_CONFIGURATION_SUCCESS:
            configs = action.payload;
            if (configs) {
                const object = configs.reduce((obj, item) => Object.assign(obj, { [item.key]: item.value }), {});
                const INTEREST_RATE_PERCENT = object[percent] ? object[percent] : 0;
                const LOAN_SERVICE_FEE = object[serviceFee] ? object[serviceFee] : 0;
                const ESTABLISHMENT_FEE = object[establishFee] ? object[establishFee] : 0;
                const OTP_COUNTDOWN_TIMEOUT_SECOND = object[otpTimeoutSecond] ? object[otpTimeoutSecond] : 0;
                const OTP_RETRY_TIME = object[retryTime] ? object[retryTime] : 0;
                const OTP_VALIDATE_TIME = object[validateTime] ? object[validateTime] : 0;
                const VALIDATION_AGE_MINIMUM =
                    object[validateAgeMinimum] || Number(object[validateAgeMinimum]) === 0
                        ? object[validateAgeMinimum]
                        : config.validation.age.minimum;
                const START_WORKING_YEAR_GAP = object[Configs.START_WORKING_YEAR_GAP]
                    ? Number(object[Configs.START_WORKING_YEAR_GAP])
                    : 0;
                const BORROW_MIN_AMOUNT = object[borrowMin] ? object[borrowMin] : 0;
                const BORROW_MAX_AMOUNT = object[borrowMax] ? object[borrowMax] : 0;
                return {
                    ...state,
                    INTEREST_RATE_PERCENT,
                    LOAN_SERVICE_FEE,
                    ESTABLISHMENT_FEE,
                    OTP_COUNTDOWN_TIMEOUT_SECOND,
                    OTP_RETRY_TIME,
                    OTP_VALIDATE_TIME,
                    VALIDATION_AGE_MINIMUM,
                    START_WORKING_YEAR_GAP,
                    BORROW_MIN_AMOUNT,
                    BORROW_MAX_AMOUNT,
                    error: null,
                    isLoading: false,
                };
            }
            configs = {};
            return {
                ...state,
                configs,
                error: null,
                isLoading: false,
            };

        case FETCH_CONFIGURATION_FAILED:
            return {
                ...state,
                error: action.payload,
                isLoading: false,
            };
        case FETCH_CONFIGURATION:
            return {
                ...state,
                error: null,
                isLoading: true,
            };
        case SET_MODE_IFRAME_KIOSK:
            return {
                ...state,
                isModeIframeKiosk: true,
            };
        default:
            return state;
    }
};
