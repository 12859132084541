import { API_HOST_AND_LDS_CONTEXT, usePost } from '../../services/useService';

export const postUpsell = ({
    url,
    said,
    stepUpToken,
    loanApplicationNumber,
    expectedLoanAmount,
}) => usePost({
    url,
    headers: {
        'Step-Up-Token': stepUpToken,
        said,
    },
    bodyData: {
        loanApplicationNumber,
        expectedLoanAmount,
    },
});

export const useChangeUpsell = ({
    said,
    stepUpToken,
    loanApplicationNumber,
    expectedLoanAmount,
}) => postUpsell({
    url: `${API_HOST_AND_LDS_CONTEXT}/upsell-offers`,
    said,
    stepUpToken,
    loanApplicationNumber,
    expectedLoanAmount,
});

export const useConfirmUpsell = ({
    said,
    stepUpToken,
    loanApplicationNumber,
    expectedLoanAmount,
}) => postUpsell({
    url: `${API_HOST_AND_LDS_CONTEXT}/confirmations`,
    said,
    stepUpToken,
    loanApplicationNumber,
    expectedLoanAmount,
});
