/* eslint-disable no-useless-escape */
import imgCar from './assets/images/buttons-has-icons/car.svg';
import imgUnexpected from './assets/images/buttons-has-icons/unable-to-make-contributions-02.svg';
import imgHoliday from './assets/images/buttons-has-icons/holiday.svg';
import imgHomeLoan from './assets/images/buttons-has-icons/home.svg';
import imgOthers from './assets/images/buttons-has-icons/others.svg';
import imgEducation from './assets/images/buttons-has-icons/apply.svg';
import imgElectronics from './assets/images/buttons-has-icons/camera.svg';
import imgBusiness from './assets/images/buttons-has-icons/medium-fees.svg';

export const ContentType = {
    FORM_URL_ENCODED: 'application/x-www-form-urlencoded',
    JSON_TEXT: 'application/json',
};

export const BUTTON = {
    OK: 'OK',
    GOT_IT: 'Got it',
    EXIT: 'Exit',
    CONTINUE: 'Continue',
};

export const VALIDATE_PHONE_PREFIX = ['06', '07', '08'];
export const PHONE_LENGTH = 10;
export const LANGUAGE = {
    ENGLISH: 'ENGLISH',
    ZULU: 'ZULU',
};

export const INITIATION_FEE = {
    ADD: 'ALLOCATE_TO_INSTALLMENT',
    NO: 'UPFRONT',
};

export const INSURANCE = {
    ADD: 'ADD',
    NO: 'NO',
};

export const INCONME_TYPE_VERIFIER = {
    SPRINT_HIVE: 'SPRINT_HIVE',
    TRUID: 'TRUID',
};

export const ErrorCode = {
    GENERIC_ERROR: '0440001',
    INVALID_INVITATION: '0440014',
    EXISTING_INVITATION: '0440010',
    EXISTING_TYMEBANK_PROFILE: '0440021',
    EXISTING_TYMEBANK_PROFILE_BY_PASS_SALARY: '0439036',
    AGE_MUST_BE_18: '0440020',
    CANNOT_ELIGIBILITY_CHECK: '0440022',
    SAFPS_FAILED: '0440023',
    IDV_FAILED: '0440024',
    IDN_BLOCK: '0440025',
    BLACKLIST_BLOCK: '0440026',
    RISKSECURE_HIT: '0440027',
    FRAUD_TRACK: '0440038',
    CELLPHONE_EXIST: '0440028',
    APPLY_REACH_LIMIT: '0440005',
    EXPIRED_INVITATION: '0440006',
    UNDER18: 'AE005',
    OTP_LIMIT_REACHED: '0407015',
    OTP_CANNOT_VERIFY: '0407005',
    OTP_EXPIRED: '0407003',
    TOKEN_EXPIRED: '0440016',
    DE_TIMEDOUT: 'DE0001',
    CONNECTIVITY_ERROR: '0440012',
    LOAN_DELETED: '0440015',
    LOAN_DUPLICATED: '0440030',
    CELLPHONE_DONT_MATCH: '0440033',
    CELLPHONE_RESUME_DONT_MATCH: '0440034',
    NONE_ACTIVATED_GPROFILE: '0440035',
    UPLOAD_ERROR: '0440777',
    LINK_EXPIRED: '0439015', // customer hasn’t sent their bank statement within 7 days since the provisionally approval, loan status = income_verification
    LINK_RETRIES_EXCEED: '0439016', // customer only have 30 times to retry uploading
    LINK_INVALID_STATE: '0439017', // loan status = offer_retracted, loan_expired, approved, contract_signed, funded
    LINK_IVALID_UPLOAD: '0439004', // wrong UploadRefId or LRN, General error
    G_PROFILE_INCOME_TYPE: '0400002',
    // Address error
    ADDRESS_ERROR_CODE: '0439003',
    ADDRESS_ERROR_CODE_LIST: ['0439004', '0439003', '0439017'],

    // error mandate
    MANDATE_TECHNICAL_ERROR: '0432001',
    MANDATE_BUSSINES_ERROR: '0432002',
    MANDATE_LOAN_EXPRIRED: '0432054',
    MANDATE_AFTER_CUTOFF: '0432055',
    MANDATE_EXIST_ACCEPTED: '0432056',
    MANDATE_SEND_FAILED: '0432057',
    MANDATE_STATUS_INVALID: '0432058',
    // facial error
    FACIAL_NO_FOTO_ERROR: '0440037',
    FACIAL_RETRTY_TOKEN: '0162001',
    FACIAL_PROVIDER_DOWN: '0418012',
    FACIAL_NO_PHOTO: '0418011',
    // Selfie failed or Unknow error
    FACIAL_SELFIE_FAILED: '0418004',
    FACIAL_UNKNOWN_ERROR: '0418013',
    FACIAL_LIVENESS_CHECK_FAILED_NO_SELFIE: '0418016',
    FACIAL_COMPARISON_FAILED_NO_SELFIE: '0418017',
    FACIAL_GENERIC: 'FACIAL_GENERIC',
    FACIAL_UPLOAD_S3: 'FACIAL_UPLOAD_S3',
    FACIAL_ERROR_CONFIG: 'FACIAL_ERROR_CONFIG',
    // LivenessCheck failed or Comparison failed
    FACIAL_LIVENESS_CHECK_FAILED: '0418006',
    FACIAL_COMPARISON_FAILED: '0418007',
    // Limit
    FACIAL_COMPARISON_LIMIT: '0418014',
    // FACIAL_OFFER
    FACIAL_OFFER_RETRACTED: '0418015',
    // FACIAL_SUCCESS
    FACIAL_SUCCESS: '0418009',
};

export const Configs = {
    INTEREST_RATE_PERCENT: 'interest.rate.percent',
    LOAN_SERVICE_FEE: 'loan.service.fee',
    ESTABLISHMENT_FEE: 'establishment.fee',
    OTP_RETRY_TIME: 'otp.retry.time',
    OTP_VALIDATE_TIME: 'otp.validate.time',
    OTP_COUNTDOWN_TIMEOUT_SECOND: 'otp.count_down.time_out.second',
    VALIDATION_AGE_MINIMUM: 'validation.age.minimum',
    START_WORKING_YEAR_GAP: 'start.working.year.gap',
    BORROW_MIN_AMOUNT: 'borrow.min.amount',
    BORROW_MAX_AMOUNT: 'borrow.max.amount',
};

export const API_HEADER_INPUTS = {
    CHANNEL_WEB: 'Channel.Web',
    CHANNEL_IFRAME: 'Channel.Iframe',
    CONTENT_TYPE: 'application/json;charset=UTF-8',
    DOCUMENT_ON_LINK: true,
};

export const OTPError = {
    INVALID_OTP: '0407002',
    TOKEN_NOT_MET: '0407012',
    OTP_LIMIT_REACHED: '0407005',
};

export const apiSIT = 'https://pl-ingress.testtymebank.co.za';
export const apiUAT = 'https://pl-ingress.stgtymebank.co.za';
export const apiPROD = 'https://pl-ingress.tymebank.co.za';

export const policyLink =
    'https://www.tymebank.co.za/inchannel/privacy-policy.html/';

export const CALL_CENTER_NUMBER = '0860 999 119';

export const EMAIL_LOAN_DOC_SIT = 'loandoc-sit@tymebank.co.za';
export const EMAIL_LOAN_DOC_UAT = 'loandoc-uat@tymebank.co.za';
export const EMAIL_LOAN_DOC = 'loandoc@tymebank.co.za';

export const Said = {
    LENGTH: 13,
};

export const MapButtonPurposeIcon = {
    HOME: imgHomeLoan,
    UNEXPECTED: imgUnexpected,
    CAR: imgCar,
    EDUCATION: imgEducation,
    ELECTRONICS: imgElectronics,
    SOMETHING_FOR_ME: imgHoliday,
    BUSINESS: imgBusiness,
    LOAN_PURPOSE_OTHER: imgOthers,
};

export const ScrollStepsStructure = {
    STEPS_CLASS_NAMES: 'step',
    WRAPPER_MIN_HEIGHT: { id: 'wrapperMinHeight' },
    WRAPPER_REAL_HEIGHT: { id: 'wrapperRealHeight' },
    PAGE_NAMES: {
        USER_INFO: 'USER_INFO',
        ABOUT_USER: 'ABOUT_USER',
        FINANCIAL_INFORMATION: 'FINANCIAL_INFORMATION',
    },
    ORDERED_STEPS: {
        EMPLOYMENT: 'stepEmploymentStatus',
        COMPANY: 'stepCompany',
        RACE: 'stepRaceValue',
        MARITAL_STATUS: 'stepMaritalStatus',
        MARITAL_TYPE: 'stepMaritalType',
        SPOUSE_CONSENT: 'stepSpouseConsent',
        AVAILABLE_BALANCE: 'stepAvailableBalance',
    },
    ORDERED_STEPS_ABOUT_USER: {
        PURPOSE_NORMAL: 'stepPurposeNormal',
        PURPOSE_OTHER: 'stepPurposeOther',
        AMOUNT: 'stepAmount',
        EMAIL: 'stepEmail',
        BOTTOM_ACTION: 'stepBottomAction',
    },
    ORDERED_STEPS_ADDITIONAL_INFO: {
        COMPANY: 'stepCompany',
        FREQUENCY: 'stepFrequency',
        NEXT_PAY_DAY: 'stepNextPayDay',
        EMERGENCY_CONTACT: 'stepEmergencyContact',
    },
    // revamp ux
    USER_INFO: {
        EMAIL: 'stepEmail',
        EMPLOYMENT: 'stepEmploymentStatus',
        CURRENT_COMPANY: 'stepCurrentCompany',
        TERMINATION_MESSAGE: 'stepTerminationMessage',
        PREVIOUS_COMPANY: 'stepPreviousCompany',
        RACE: 'stepRaceValue',
        BOTTOM_ACTION: 'stepBottomAction',
    },
};

export const ListOfValues = {
    EMPLOYMENT_STATUS: {
        FULL_TIME: 'FULL_TIME',
        PART_TIME: 'PART_TIME',
        CONTRACTOR: 'CONTRACTOR',
        UNEMPLOYED: 'UNEMPLOYED',
        SELF: 'SELF',
        PENSIONER: 'PENSIONER',
    },
    LOAN_PURPOSE: {
        LOAN_PURPOSE_OTHER: 'LOAN_PURPOSE_OTHER',
    },
    MARITAL_STATUS: {
        code: 'MARITAL_STATUS',
        SINGLE: 'SINGLE',
        MARRIED: 'MARRIED',
        SEPARATED: 'SEPARATED',
        DIVORCED: 'DIVORCED',
        WIDOWED: 'WIDOWED',
    },

    MARITAL_TYPE: {
        code: 'MARITAL_TYPE',
        COMMUNITY_OF_PROPERTY: 'COMMUNITY_OF_PROPERTY',
        ANC_WITH_ACCRUAL: 'ANC_WITH_ACCRUAL',
        ANC_WITHOUT_ACCRUAL: 'ANC_WITHOUT_ACCRUAL',
        CUSTOMARY: 'CUSTOMARY',
    },

    RACE: {},
    CONSENT: {
        YES: {
            code: 'YES',
            name: 'Yes',
        },
        NO: {
            code: 'NO',
            name: 'No',
        },
    },
    IS_DEBT_COMMITMENT: {
        YES: {
            code: 'YES',
            name: 'Yes',
        },
        NO: {
            code: 'NO',
            name: 'No',
        },
    },
};
export const DecisioningResult = {
    loanApplicationStatus: {
        SUBMITTED: 'SUBMITTED',
        CONDITIONALLY_APPROVED: 'CONDITIONALLY_APPROVED',
        OFFER_RETRACTED: 'OFFER_RETRACTED',
        DECLINED: 'DECLINED',
        APPROVED: 'APPROVED',
        CONTRACT_SIGNED: 'CONTRACT_SIGNED',
        FUNDED: 'FUNDED',
        DEBICHECK_ACCEPTED: 'DEBICHECK_ACCEPTED',
        CONDITIONALLY_APPROVED_COLLECTION_RECEIVED: 'CONDITIONALLY_APPROVED_COLLECTION_RECEIVED',
    },
    decisioningStatus: {
        INIT: 'INIT',
        COMPLETED: 'COMPLETED',
    },
};

export const UpsellingResultStatus = {
    // show offer
    NO: {
        NOT_ALLOWED: 'NOT_ALLOWED',
        CONFIRMED: 'CONFIRMED',
    },
    // show upsell
    YES: {
        ALLOWED: 'ALLOWED',
        INPROGESS: 'INPROGESS',
        IN_PROGRESS: 'IN_PROGRESS',
        ACCEPTED: 'ACCEPTED',
    },
    // error api
    ERROR: {
        ERROR: 'ERROR',
    },

    POOLING: {
        INPROGESS: 'INPROGESS',
        IN_PROGRESS: 'IN_PROGRESS',
    },
};

export const LoanStatusApproved = [
    DecisioningResult.loanApplicationStatus.APPROVED,
    DecisioningResult.loanApplicationStatus.CONDITIONALLY_APPROVED,
    DecisioningResult.loanApplicationStatus.CONDITIONALLY_APPROVED_COLLECTION_RECEIVED,
];

export const LoanOffersSelection = {
    customerSelection: {
        ACCEPTED: 'ACCEPTED',
        MAYBE_LATER: 'MAYBE_LATER',
    },
};

export const ConsentType = {
    ELIGIBILITY: 'ELIGIBILITY',
    CONTRACT_AGREEMENT_QUOTATION: 'CONTRACT_AGREEMENT_QUOTATION',
};

export const SignContractMethod = {
    FACIAL: 'FACIAL',
};

export const FacialStatusResults = {
    COMPLETED: 'COMPLETED',
    PROCESSING: 'PROCESSING',
};

// we will create task and refactor later
export const routeName = {
    HONE: '/', // Hone source
    X2LANDING: '/x2-landing',
    START: '/start',
    PHONE: '/phone',
    OTP: '/otp',
    OTP_RESUME: '/otp-resume',
    LOAN_AMOUT: '/loan-info',
    LOAN_AMOUT_MODE: '/loan-info/:mode', // edit
    LOAN_PURPOSE: '/loan-purpose',
    LOAN_PURPOSE_MODE: '/loan-purpose/:mode', // edit
    USER_INFO: '/user-info',
    USER_INFO_MODE: '/user-info/:mode', // edit
    FINANCIAL_INFO: '/financial-info',
    FINANCIAL_INFO_MODE: '/financial-info/:mode', // edit
    ADDITIONAL_INFO: '/additional-info',
    ADDITIONAL_INFO_MODE: '/additional-info/:mode', // edit
    CONFIRMATION: '/confirmation',
    OFFERS: '/offers',
    LIMIT_OFFERS: '/limit',
    LOWER_OFFERS: '/lower-de-offers',
    UPSELL_OFFERS: '/upsell-de-offers',
    TYME_BANK_OFFERS: '/tymebank-salary-offers-condition',
    TYME_BANK_OFFERS_APPROVED: '/tymebank-salary-offers-approved',
    TRUID_INTRO: '/introtruid',
    INTRO_SPRINTHIDE: '/intro-sprinthive',
    TRUID: '/truid',
    PACKAGE: '/package',
    TRUID_ADDRESS_SUPPLEMENT: '/address-supplement',
    AGREEMENT: '/agreement',
    DEBICHECK: '/debicheck',
    FUNDED: '/funded',
    OFFER_DECLINED: '/offers-declined',
    OFFER_DECLINED_LAN: '/offers-declined/:ref',
    RESUME: '/resume',

    SEGMENT_PRODUCT: '/products', // key
    SEGMENT_PRODUCT_ID: '/products/:productId',

    NEW_SEGMENT_PRODUCT: '/p/',
    NEW_SEGMENT_PRODUCT_ID: '/p/:productId',
    FACE_INTRO_SELFIE: '/intro-facial-selfie',
    FACE_SELFIE: '/facial-selfie',
    FACE_SELFIE_RESULTS: '/facial-selfie-results',
};

export const routeFullScreen = [
    routeName.HONE,
    routeName.TRUID_INTRO,
    routeName.INTRO_SPRINTHIDE,
    routeName.TRUID,
    routeName.DEBICHECK,
    routeName.FUNDED,
    routeName.X2LANDING,
    routeName.UPSELL_OFFERS,
    routeName.TYME_BANK_OFFERS,
    routeName.TYME_BANK_OFFERS_APPROVED,
    routeName.FACE_INTRO_SELFIE,
    routeName.FACE_SELFIE,
    routeName.FACE_SELFIE_RESULTS,
];

// special event
export const beforeOTPScreen = [routeName.HONE, routeName.START, routeName.HONE, undefined];

export const PL_PAGE = 'pl-page-info';

export const EMAIL_REGEX = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const listMonthNames = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];

export const profileEFType = ['E', 'F'];

