import _ from 'lodash';

class GoogleAnalytics {
    sendPageView(pathname = '') {
        if (!this.isGaExist()) {
            return;
        }

        const obj = {
            hitType: 'pageview',
            page: pathname || window.location.pathname,
        };
        window.ga(this.sendAction, obj);
    }

    sendEvent(eventAction = '', eventLabel = '', eventCategory = null) {
        if (!this.isGaExist()) return;
        const obj = {
            hitType: 'event',
            eventCategory: eventCategory || window.location.pathname,
            eventAction,
            eventLabel,
        };
        if (typeof eventLabel === 'object') {
            obj.eventLabel = JSON.stringify(eventLabel);
        }
        window.ga(this.sendAction, obj);
    }

    isGaExist = () => {
        if (window && window.ga) {
            // Need to get the tracker name from GA
            // because we use react-gtm-module to init GA
            // so this lib has automated init GA and
            // set the random name for the tracker.
            // Ex: gtm1, gtm2....
            const { getAll } = window.ga;
            if (typeof getAll !== 'function') return false;
            this.trackerName = _.get(getAll(), '[0].b.data.values[:name]');
            this.sendAction = `${this.trackerName || 'gtm2'}.send`;
            return true;
        }
        return false;
    };
}

const GA = new GoogleAnalytics();

export { GA };
