import { combineEpics } from 'redux-observable';
import configurationEpic from './configurationEpic';
import saidEpic from './saidEpic';
import otpEpics from './otpEpics';
import loanSubmissionEpic from './loanSubmissionEpic';
import decisionEpic from './decisionEpic';
import offersEpic from './offersEpic';
import affordabilityEpic from './affordabilityEpic';
import loanDetailEpic from './loanDetailEpic';
import loanContractInfoEpic from './loanContractInfoEpic';
import signContractEpic from './signContractEpic';
import fulfillmentEpic from './fulfillmentEpic';
import verifyLoanApplicationEpic from './verifyLoanApplicationEpic';

const epics = [
    configurationEpic,
    saidEpic,
    otpEpics,
    loanSubmissionEpic,
    decisionEpic,
    offersEpic,
    affordabilityEpic,
    loanDetailEpic,
    loanContractInfoEpic,
    signContractEpic,
    fulfillmentEpic,
    verifyLoanApplicationEpic,
];

const rootEpic = combineEpics(...epics);

export default rootEpic;
