// 1. loan-success-screen
export const segment2200LoanSuccessScreenData = {
    type: 'track',
    event: 'lndPln loan success scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/loan-success/path-happy/loan-success-screen/',
        ui_screen_state: 'informational',
        flow_conversion: 'yes-positive',
        dev_screen_class_description: 'string',
    },
};

// 2. loan-funded-displayed
export const segment2200LoanFundedDisplayedData = {
    type: 'track',
    event: 'lndPln loan funded scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/loan-success/path-happy/loan-funded-displayed/',
        ui_screen_state: 'informational',
        flow_conversion: 'yes-positive',
        dev_screen_class_description: 'string',
    },
};
