import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { XWrapLabelMediumSkeleton } from './LoadingSkeletons';
import LabelTitle from './LabelTitle';


export function LabelLoanApplication({
    refNumber,
    verifyLoanApplicationData,
    loanSubmission,
}) {
    const resumeNumber = _.get(verifyLoanApplicationData, 'loanApplicationNumber');
    const loanApplicationNumber = _.get(loanSubmission, 'loanApplicationNumber') || resumeNumber;

    return (
        <React.Fragment>
            <LabelTitle small>
                Reference number
            </LabelTitle>

            <XWrapLabelMediumSkeleton>
                <p className="mt-1 mb-2">{refNumber || loanApplicationNumber}</p>
            </XWrapLabelMediumSkeleton>
        </React.Fragment>
    );
}

const mapStateToProps = state => ({
    verifyLoanApplicationData: state.verifyLoanApplicationData,
    loanSubmission: state.loanSubmission,
});

export default connect(mapStateToProps)(LabelLoanApplication);
