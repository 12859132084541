import React from 'react';
import XWrapLabelMoney from '../../../components/Common/XWrapLabelMoney';

import { x2LandingMonthCalculator } from '../../../assets/x2-data/x2-landing-data';

function XLandingTermsCalculator({
    loanInfo,
    loanConfig,
}) {
    return (
        <div className="d-flex flex-column mt-4 gap-16">
            {x2LandingMonthCalculator.map((item, index) => (
                <XWrapLabelMoney
                    key={item.key}
                    {...item}
                    duration={`${(index + 1) * 500}ms`}
                    tooltipInfoData={{
                        ...loanInfo,
                        ...loanConfig,
                    }}
                    borderBottom={index < x2LandingMonthCalculator.length - 1}
                >
                    {loanInfo[item.key]}
                </XWrapLabelMoney>
            ))}
        </div>
    );
}

export default XLandingTermsCalculator;
