import React from 'react';
import { connect } from 'react-redux';

import { XNavLogo } from './Common/XNavLogo';
import PLLinkTab from './Common/PLLinkTab';
import LinkTel from './Common/LinkTel';
import { FlexColumnCenter, FlexRowCenter } from './Common/FlexCenter';
import { scrollTopSmooth } from '../utils/commonUtils';

import { socialInfoDatas, tymePolicyDatas } from '../assets/data/footer-data';
import { ArrowUpIcon } from '../assets/images/elements/ArrowIcon';

function Footer() {
    return (
        <React.Fragment>
            <footer className="page-footer">
                <div className="copyright">
                    <div className="container">
                        <p>Follow us</p>

                        <div className="d-flex flex-row social-contact gap-24 gap-md-32">
                            {socialInfoDatas.map((item) => (
                                <FlexRowCenter key={item.url}>
                                    <div className="d-flex align-items-center social-icon">
                                        <PLLinkTab url={item.url}>
                                            {item.icon}
                                        </PLLinkTab>
                                    </div>
                                    <div>
                                        <PLLinkTab url={item.url}>{item.label}</PLLinkTab>
                                    </div>
                                </FlexRowCenter>
                            ))}
                        </div>

                        <div className="line">
                            <div className="container">
                                <FlexColumnCenter className="anchor-top gap-1 gap-md-4" onClick={() => scrollTopSmooth()}>
                                    <ArrowUpIcon />
                                    <div className="font-size-14 font-size-md-16">TOP</div>
                                </FlexColumnCenter>
                            </div>
                        </div>

                        <div className="solid-line mb-3" />

                        <div className="policy-info social-contact description-info gap-24 gap-md-32">
                            {tymePolicyDatas.map((item) => (
                                <div key={item.url}>
                                    <PLLinkTab url={item.url}>
                                        {item.label}
                                    </PLLinkTab>
                                </div>
                            ))}
                        </div>

                        <div className="social-contact description-info">TymeBank is an authorised Financial Services (FSP 49140) and Registered Credit Provider (NCRCP 10774).  TymeBank Limited Reg no: 2015/231510/06</div>

                        <div className="social-contact description-info">
                            If you have any questions, feel free to ask our in-store Ambassadors or contact our Customer Service Centre on <LinkTel tel="0860999119">0860 999 119</LinkTel>
                        </div>
                    </div>
                </div>
            </footer>
            <aside className="py-2">
                <XNavLogo center />
            </aside>
        </React.Fragment>

    );
}

const mapStateToProps = state => ({
    router: state.router,
});

export default connect(mapStateToProps, {})(Footer);
