import { handleActions } from 'redux-actions';

import {
    FETCH_LOAN_DETAIL,
    FETCH_LOAN_DETAIL_FAILED,
    FETCH_LOAN_DETAIL_SUCCESS,
    PUT_SELECTED_OFFER,
    PUT_SELECTED_OFFER_FAILED,
    PUT_SELECTED_OFFER_SUCCESS,
    RESET_LOAN_SUBMISSION,
} from '../store/actionTypes';

const initialState = {
    isLoading: null,
    loanDetail: null,
};

export const actions = {
    [FETCH_LOAN_DETAIL]: (state = initialState) => ({
        ...state,
        isLoading: true,
        retryCount: 0,
    }),
    [FETCH_LOAN_DETAIL_SUCCESS]: (state, { payload }) => ({
        ...state,
        isLoading: false,
        loanDetail: payload,
        retryCount: 0,
    }),
    [FETCH_LOAN_DETAIL_FAILED]: (state = initialState, action) => ({
        ...state,
        error: action.payload,
        retryCount: state.retryCount + 1,
        // do we have any errors here?
    }),
    [PUT_SELECTED_OFFER]: (state = initialState) => ({
        ...state,
        isLoading: true,
    }),
    [PUT_SELECTED_OFFER_SUCCESS]: (state, { payload }) => ({
        ...state,
        isLoading: false,
        loanDetail: payload,
    }),
    [PUT_SELECTED_OFFER_FAILED]: (state = initialState, action) => ({
        ...state,
        error: action.payload,
        // do we have any errors here?
    }),
    [RESET_LOAN_SUBMISSION]: () => ({
        ...initialState,
    }),
};

export default handleActions(actions, initialState);
