import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { WrapAction } from '../Common/ActionButtons';
import PLButton from '../Common/PLButton';
import WrapperIconModal from '../Common/WrapperIconModal';

import TruidSelectSalaryDate from './TruidSelectSalaryDate';
import useTruIDConfirmChange from './useTruIDConfirmChange';

import paymentCalandar from '../../assets/images/payments-calendar.png';
import { useSaveSalaryDate } from './useHandleTruidAddress';

const TruidFormEditSalaryDate = ({
    dateExpired,
    stepUpToken,
    loanApplicationNumber,
    handleKeepOk = () => { },
}) => {
    const { showConfirmLeaveChange } = useTruIDConfirmChange(true);
    const [formData, setFormData] = useState({
        salaryDate: '01',
    });

    const { postChange, isFetch } = useSaveSalaryDate({
        salaryDate: Number(formData.salaryDate),
        loanApplicationNumber,
    }, loanApplicationNumber, stepUpToken);

    return (
        <WrapperIconModal
            icon={<img src={paymentCalandar} alt=" " />}
            title="We weren’t able to work out when you receive your salary"
        >
            <p className="text-center">
                Please select which day of the month it’s normally paid on.
            </p>

            <TruidSelectSalaryDate formData={formData} setFormData={setFormData} />

            <p className="text-center mt-4 mb-3">
                If you choose to do it later, we’ll save your progress until <span className="d-inline-block">{dateExpired}</span>.
            </p>

            <WrapAction className="form-group d-md-flex flex-md-row justify-content-center flex-column-reverse d-flex d-md-block">
                <PLButton
                    primary={false}
                    onClick={() => {
                        showConfirmLeaveChange({
                            dateExpired,
                            isSalaryDate: true,
                            handleOK: () => handleKeepOk(),
                        });
                    }}
                >
                    I'll do it later
                </PLButton>

                <PLButton
                    disabled={isFetch}
                    onClick={() => postChange()}
                >
                    Continue
                </PLButton>
            </WrapAction>
        </WrapperIconModal>
    );
};

const mapStateToProps = state => ({
    stepUpToken: state.otp.stepUpToken,
    loanApplicationNumber: _.get(state.loanSubmission, 'loanApplicationNumber') || _.get(state.verifyLoanApplicationData, 'loanApplicationNumber'),
});

export default connect(
    mapStateToProps,
    null,
)(TruidFormEditSalaryDate);
