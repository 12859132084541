import _ from 'lodash';
import { combineEpics, ofType } from 'redux-observable';
import { of, timer } from 'rxjs';
import { catchError, exhaustMap, filter, switchMap, takeUntil } from 'rxjs/operators';
import 'rxjs/add/operator/exhaustMap';
import 'rxjs/add/operator/takeUntil';

import Config from '../config';
import apiService from '../services/apiService';
import { updateOfferList } from '../actions';
import {
    AFFORDABILITY_RESULTS_ELIGIBLE,
    AFFORDABILITY_RESULTS_IN_PROGRESS,
    AFFORDABILITY_RESULTS_INELIGIBLE,
    FETCH_AFFORDABILITY_RESULTS,
    FETCH_SPRINT_HIVE_AFFORDABILITY_RESULTS,
} from '../store/actionTypes';

import { ScreenBusUtils, TruIDBusUtils } from '../hooks/useEventBus';
import ErrorUtils from '../utils/errorUtils';
import { handleDeclineSpecialCode, isMissingAddressTruID, isMissingSalaryDate } from '../utils/commonUtils';
import { DecisioningResult, INCONME_TYPE_VERIFIER } from '../constants';

export const handleSpecialStatus = (isMissing, callback) => {
    // when db updates are slow
    if (isMissing) {
        // make IN_PROGRESS
        return [{ type: AFFORDABILITY_RESULTS_IN_PROGRESS }];
    }
    callback();
    return [{ type: AFFORDABILITY_RESULTS_INELIGIBLE }];
};

export function checkingMissingStatus(res, loanApplicationStatus, missingStatus) {
    const declineReasonDetails = res.declineReasonDetails || {};

    const funcHandle = handleDeclineSpecialCode({
        declinceCode: declineReasonDetails.code,
        loanApplicationStatus,
        handleMissingAddress: () =>
            handleSpecialStatus(
                isMissingAddressTruID(missingStatus),
                ScreenBusUtils.showTruIDFormAddress,
            ),
        handleMissingSalary: () =>
            handleSpecialStatus(
                isMissingSalaryDate(missingStatus),
                ScreenBusUtils.showTruIDFormSalaryDate,
            ),
        handleGProfileType: () => {
            ScreenBusUtils.showModalTruIDGProfileType({ ...declineReasonDetails, isInTruID: true });
            return [
                { type: AFFORDABILITY_RESULTS_INELIGIBLE },
                updateOfferList({ ...res, inviteUrl: '', isApprovedTruid: false }),
            ];
        },
    });

    if (funcHandle) {
        return funcHandle;
    }
    return false;
}

/** For cover UT */
export const getAffordabilityResultsInEpic = (payload, state$) => {
    const {
        loanApplicationNumber,
        missingStatus,
        retryCount = Config.decisioning.retryCount,
    } = payload;

    return apiService
        .getAffordabilityResults(loanApplicationNumber)
        .pipe(
            switchMap(res => {
                const { loanApplicationStatus } = res;
                const { APPROVED, CONDITIONALLY_APPROVED_COLLECTION_RECEIVED } = DecisioningResult.loanApplicationStatus;

                // LIMIT REQUEST
                if (state$.value.affordabilityResults.retryCount >= retryCount) {
                    TruIDBusUtils.showTruIDTimeout();
                    return of({ type: AFFORDABILITY_RESULTS_ELIGIBLE }, updateOfferList({ isApprovedTruid: false }));
                }

                // APPOVED
                if (loanApplicationStatus === APPROVED) {
                    return of({ type: AFFORDABILITY_RESULTS_ELIGIBLE }, updateOfferList({ ...res, isApprovedTruid: true }));
                }

                // TRUID DELINE
                const declineReasonDetails = res.declineReasonDetails || {};
                const funcHandle = checkingMissingStatus(res, loanApplicationStatus, missingStatus);
                if (funcHandle) {
                    return of(...funcHandle());
                }

                // IN_PROGRESS
                if (loanApplicationStatus !== CONDITIONALLY_APPROVED_COLLECTION_RECEIVED && declineReasonDetails.code) {
                    ScreenBusUtils.saveDeclineStatus(declineReasonDetails.code, _.get(res, 'declineReasonDetails'));

                    return of(
                        { type: AFFORDABILITY_RESULTS_INELIGIBLE, payload: res },
                        ScreenBusUtils.gotoScreenDeclinedOffers(loanApplicationNumber),
                    );
                }

                // IN_PROGRESS
                return of({ type: AFFORDABILITY_RESULTS_IN_PROGRESS });
            }),
            catchError(error => ErrorUtils.getApiAction(error)),
        );
};
export const affordabilityEpic = (action$, state$) =>
    action$.pipe(
        ofType(FETCH_AFFORDABILITY_RESULTS),
        switchMap(action => {
            const { missingStatus } = action.payload;

            const requestTime = missingStatus ?
                Config.decisioning.requestTimeWhenUpdateMissing :
                Config.decisioning.requestTimeTruid;

            return timer(0, requestTime)
                .pipe(
                    takeUntil(action$.pipe(filter(a => a.type !== AFFORDABILITY_RESULTS_IN_PROGRESS))),
                    exhaustMap(() => getAffordabilityResultsInEpic(action.payload, state$)),
                );
        }));

/** For cover UT */
export const getAffordabilityResultsSprintHiveInEpic = (loanApplicationNumber) =>
    apiService
        .getAffordabilityResults(loanApplicationNumber)
        .pipe(
            switchMap(res => {
                const { loanApplicationStatus } = res;
                const { APPROVED, OFFER_RETRACTED } = DecisioningResult.loanApplicationStatus;

                const declineReasonDetails = res.declineReasonDetails || {};
                const isSprintHive = res.incomeVerifier === INCONME_TYPE_VERIFIER.SPRINT_HIVE;

                // APPOVED
                if (loanApplicationStatus === APPROVED) {
                    const isApprovedTruid = isSprintHive;
                    return of({ type: AFFORDABILITY_RESULTS_ELIGIBLE }, updateOfferList({ ...res, isApprovedTruid, isIntroChecking: true }));
                }

                if (loanApplicationStatus === OFFER_RETRACTED) {
                    ScreenBusUtils.saveDeclineStatus(declineReasonDetails.code, declineReasonDetails);

                    return of(
                        { type: AFFORDABILITY_RESULTS_INELIGIBLE, payload: res },
                        ScreenBusUtils.gotoScreenDeclinedOffers(loanApplicationNumber),
                    );
                }

                // SPRINTHIVE
                if (isSprintHive) {
                    const func = checkingMissingStatus(res, loanApplicationStatus);
                    if (func) {
                        return func();
                    }
                }

                return of({ type: AFFORDABILITY_RESULTS_ELIGIBLE }, updateOfferList({ ...res, isIntroChecking: true }));
            }),
            catchError(error => ErrorUtils.getApiAction(error)),
        );

export const affordabilityIfSprintHiveEpic = (action$) =>
    action$.pipe(
        ofType(FETCH_SPRINT_HIVE_AFFORDABILITY_RESULTS),
        switchMap(action => {
            const { loanApplicationNumber } = action.payload;
            const requestTime = Config.decisioning.requestTimeTruid;

            // we only fetching once
            return timer(0, requestTime)
                .pipe(
                    takeUntil(action$.pipe(filter(a => a.type !== AFFORDABILITY_RESULTS_IN_PROGRESS))),
                    exhaustMap(() => getAffordabilityResultsSprintHiveInEpic(loanApplicationNumber)),
                );
        }));

export default combineEpics(affordabilityEpic, affordabilityIfSprintHiveEpic);
