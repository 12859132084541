/* eslint-disable no-mixed-operators */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { INITIATION_FEE } from '../constants';
import { fetchLoanDetail, putSelectedOffer, saveStepsInput } from '../actions';
import { ScreenBusUtils } from '../hooks/useEventBus';

import { removeComma } from '../utils/format';

import { useModalPopupContext } from '../context/ModalPopupContext';

import { XWrapMoneyLargeSkeleton } from '../components/Common/LoadingSkeletons';
import LabelLoanApplication from '../components/Common/LabelLoanApplication';
import PLButton from '../components/Common/PLButton';
import PlanDetails from '../components/Package/PlanDetails';
import SelectionOfferItem from '../components/Package/SelectionOfferItem';
import XWrapMoney from '../components/Common/XWrapMoney';

import { SegmentBusTrackPackage } from '../segment-bus/SegmentBusTrackPackage';

const Package = ({ t, loanApplicationNumber, ...props }) => {
    const { showModal, hideModal } = useModalPopupContext();
    const [offers, setOffers] = useState([]);
    const [selectedOffer, setSelectedOffer] = useState({});
    const [isActived, setActived] = useState(false);

    useEffect(() => {
        if (loanApplicationNumber) {
            const info = {
                stepUpToken: _.get(props.otp, 'stepUpToken'),
                loanApplicationNumber,
                initiationFeePaymentMethod: INITIATION_FEE.ADD,
                retryIfError: true,
            };
            setTimeout(() => props.fetchLoanDetail(info));
        }
        SegmentBusTrackPackage.segment1700LoanPackageSelectionDisplayedData();
    }, []);

    useEffect(() => {
        setOffers(_.get(props.loanDetail, 'loan.offerOptions') || []);
    }, [props.loanDetail]);

    useEffect(() => {
        if (isActived && !selectedOffer.id) {
            SegmentBusTrackPackage.segment1700LoanPackageNotSelectedData();
        }
    }, [isActived, !selectedOffer.id]);

    const handleNext = (id) => {
        const info = {
            stepUpToken: _.get(props.otp, 'stepUpToken'),
            loanApplicationNumber,
            selectedOfferOptionId: id || selectedOffer.id,
        };

        props.saveStepsInput({
            trackingTerm: {
                terms: selectedOffer.tenor,
                amount: Number(removeComma(selectedOffer.finalApprovedAmount)),
            },
        });

        props.putSelectedOffer(info);
        ScreenBusUtils.gotoScreenLoanAgreement();
        SegmentBusTrackPackage.segment1700LoanPackageConfirmData();
    };

    const handleShowDetail = (offer) => {
        showModal({
            centerAction: true,
            showXClose: false,
            className: 'modal-plan-details',
            cancelTitle: 'Change repayment plan',
            okTitle: 'Select repayment plan',
            modalContent: <PlanDetails offer={offer} />,
            handleOK: () => {
                setSelectedOffer(offer);
                handleNext(offer.id);
                hideModal();
            },
        });
        SegmentBusTrackPackage.segment1700LoanPackageDetailsData();
    };

    return (
        <div id="offers">
            <LabelLoanApplication />

            <h2 className="font-size-16 font-size-md-20 font-weight-medium mt-4">
                Your loan amount
            </h2>
            <XWrapMoneyLargeSkeleton>
                <XWrapMoney className="font-size-36 font-size-md-40">{_.get(offers[0], 'finalApprovedAmount')}</XWrapMoney>
            </XWrapMoneyLargeSkeleton>

            <p className="font-size-14 font-size-md-20 font-weight-medium mt-5 mb-4">
                Select your loan repayment plan below:
            </p>

            <div className="choices row justify-content-center mb-0 mb-md-2">
                {offers.map((offer) => (
                    <SelectionOfferItem
                        key={offer.tenor}
                        offer={offer}
                        isPackage
                        isSelected={selectedOffer.id === offer.id}
                        handleSelected={item => {
                            setSelectedOffer(item);
                            setActived(false);
                        }}
                        handleShowDetails={handleShowDetail}
                    />
                ))}
            </div>

            {isActived && !selectedOffer.id && <div className="d-block with-errors">Select an option to continue.</div>}

            <div className="buttons my-4">
                <PLButton
                    onClick={() => {
                        setActived(true);
                        if (selectedOffer.id) {
                            handleNext();
                        }
                    }}
                >
                    Next
                </PLButton>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    otp: state.otp,
    loanDetail: state.loanDetail.loanDetail,
    loanApplicationNumber: _.get(state.loanSubmission, 'loanApplicationNumber') ||
        _.get(state.verifyLoanApplicationData, 'loanApplicationNumber'),
});

export default
    connect(
        mapStateToProps,
        {
            putSelectedOffer,
            fetchLoanDetail,
            saveStepsInput,
        },
    )(Package);
