/** Load product and product consent */
import { useEffect, useState } from 'react';
import ApiService from './apiService';

import { PL_PAGE } from '../constants';
import useEventBus, { EventBusName, ScreenBusUtils } from '../hooks/useEventBus';
import { defaultConsent } from '../assets/data/consent-data';

const groupConsent = (data) => {
  const resposne = {};
  // eslint-disable-next-line no-return-assign
  data.forEach(consent => (resposne[consent.type] = consent));
  return resposne;
};

const matchingProducts = (listProducts = [], id) => {
  const segmentProduct = listProducts.filter(item => `${item.id}` === `${id}`)[0];
  const data = segmentProduct || listProducts[0];
  return data;
};

const useProductService = (loanApplicationNumber, { productId, isMounted }) => {
  const [product, setProduct] = useState({});
  const [listProduct, setListProduct] = useState([]);
  const [productConsent, setProductConsent] = useState(groupConsent(defaultConsent));

  // when resume or load segment product
  useEventBus(EventBusName.UPDATE_EXACT_SEGMENT_PRODUCT_BY_SAID, id => {
    if (product.id === id) {
      return;
    }

    const p = matchingProducts(listProduct, id);
    setProduct(p);
    ScreenBusUtils.sendABSegmentPage(p.id);
  });

  useEffect(() => {
    if (isMounted) {
      ScreenBusUtils.showLoading();
      ApiService.getProduct()
        .then(({ data = [] }) => {
          const p = matchingProducts(data, productId);
          setProduct(p);
          setListProduct(data);

          if (productId) {
            ScreenBusUtils.sendABSegmentPage(p.id);
            sessionStorage.setItem(PL_PAGE, p.id);
          }
        })
        .catch(() => {
          setTimeout(() => ScreenBusUtils.showLoading(), 100);
        });
    }

    return () => {
      sessionStorage.clear();
    };
  }, [isMounted]);

  useEffect(() => {
    if (!product.id) {
      return;
    }
    ApiService.getConsents(product.id, loanApplicationNumber)
      .then(result => setProductConsent(groupConsent(result.data)))
      .catch(() => setProductConsent(groupConsent(defaultConsent)))
      .finally(() => ScreenBusUtils.hideLoading());
  }, [product.id, loanApplicationNumber]);

  return {
    product,
    productConsent,
  };
};

export default useProductService;
