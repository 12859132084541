// 1. upsell-offer-displayed (other tracking data)
export const segment1800UpsellOfferDisplayedData = {
    type: 'track',
    event: 'lndPln upsell scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/upsell/path-happy/upsell-offer-displayed/',
        ui_screen_state: 'functional',
        flow_conversion: 'no',
        // "dev_screen_class_name": "string",
        dev_screen_class_description: 'string',
    },
};

// 2. upsell-confirm-upsell-offer
export const segment1800UpsellConfirmUpsellOfferData = {
    type: 'track',
    event: 'lndPln upsell scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/upsell/path-happy/upsell-confirm-upsell-offer/',
        ui_screen_state: 'functional',
        flow_conversion: 'no',
        // "dev_screen_class_name": "string",
        dev_screen_class_description: 'string',
    },
};

// 3. upsell-choose-other-offer
export const segment1800UpsellChooseOtherOfferData = {
    type: 'track',
    event: 'lndPln upsell scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/upsell/path-happy/upsell-choose-other-offer/',
        ui_screen_state: 'functional',
        flow_conversion: 'no',
        // "dev_screen_class_name": "string",
        dev_screen_class_description: 'string',
    },
};

// 4. upsell-exit
export const segment1800UpsellExitData = {
    type: 'track',
    event: 'lndPln upsell scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/upsell/path-error-exited/upsell-exit/',
        ui_screen_state: 'modal-alert',
        flow_conversion: 'no',
        // "dev_screen_class_name": "string",
        dev_screen_class_description: 'string',
    },
};

// 5. upsell-error-load-fail
export const segment1800UpsellErrorLoadFailData = {
    type: 'track',
    event: 'lndPln upsell scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/upsell/path-error-exited/upsell-error-load-fail/',
        ui_screen_state: 'modal-alert',
        flow_conversion: 'no',
        // "dev_screen_class_name": "string",
        dev_screen_class_description: 'string',
    },
};

// 6. upsell-try-again
export const segment1800UpsellTryAgainData = {
    type: 'track',
    event: 'lndPln upsell scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/upsell/path-error-conditional/upsell-retry/',
        ui_screen_state: 'modal-alert',
        flow_conversion: 'no',
        // "dev_screen_class_name": "string",
        dev_screen_class_description: 'string',
    },
};

// 7. upsell-error (other tracking data)
export const segment1800UpsellErrorData = {
    type: 'track',
    event: 'lndPln upsell scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/upsell/path-error-exited/upsell-error-load-fail/',
        ui_screen_state: 'modal-alert',
        flow_conversion: 'no',
        // "dev_screen_class_name": "string",
        dev_screen_class_description: 'string',
    },
};
