import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import Config from '../config';
import { detectmob } from '../utils/detectmob';
import { scrollElementSmooth } from '../utils/commonUtils';
import { ScreenBusUtils } from '../hooks/useEventBus';
import { SegmentBusTrackTruID } from '../segment-bus/SegmentBusTrackTruID';

import PLButton from '../components/Common/PLButton';
import ActionSteps from '../components/Common/ActionSteps';
import AccordionSimple from '../components/Common/AccordionSimple';
import LabelTitle from '../components/Common/LabelTitle';

import IntroSprintHiveSteps from '../components/TruId/IntroSprintHiveSteps';
import TruidLinkTab from '../components/TruId/TruidLinkTab';
import IntroWhoTruID, { IntroTruIDListCollections } from '../components/TruId/IntroWhoTruID';
import LabelLoanApplication from '../components/Common/LabelLoanApplication';

import { introTruIDStepData } from '../assets/data/truid-step-data';

const IntroTruid = () => {
  const refsAccordion = React.useRef();
  const refsAction = React.useRef();
  const isMobile = detectmob();

  useEffect(() => {
    SegmentBusTrackTruID.segment1500BankVerificationGetStartedData();
  }, []);

  function handleMoveTruID() {
    ScreenBusUtils.fetchAffordabilityIfSprintHive();
  }

  function toggleTruID(open) {
    if (isMobile || open) {
      setTimeout(() => scrollElementSmooth(refsAccordion.current), 100);
      return;
    }
    scrollElementSmooth(refsAccordion.current);
  }

  return (
    <div id="introtruid">
      <div className="pl-container-highlight px-1">
        <div className="container d-flex flex-column mb-4">
          <LabelLoanApplication />

          <LabelTitle className="mt-sm-4 mt-3 mb-3">
            The quickest way to confirm your affordability is to use <TruidLinkTab /> to log in to your internet banking. We’ll briefly transfer you to <TruidLinkTab />’s* website for a three-step affordability check.
          </LabelTitle>

          <ActionSteps className="intro-truid-steps mt-sm-4 mt-3" listSteps={introTruIDStepData} />
        </div>

        <IntroWhoTruID />
      </div>


      <div className="pl-container-highlight px-1 py-0">
        <div ref={refsAccordion} className="container py-2">
          <AccordionSimple
            title={
              <React.Fragment>This is the information that <TruidLinkTab /> will be accessing</React.Fragment>
            }
            subTitleWhenOpen={
              <p>
                <TruidLinkTab /> gives you complete control over your data. No one can ever access it without your consent or change it at all. Plus, the connection between you and your bank is completely safe and secure.
              </p>}
            handleOpen={(isOpen) => toggleTruID(isOpen)}
          >
            <hr className="pb-3 mt-0" />
            <div className="d-flex flex-column justify-content-center">
              <IntroTruIDListCollections />
            </div>
          </AccordionSimple>

          <div ref={refsAction} className="mt-2 pb-3 md-mb-5 mb-4">
            <PLButton onClick={() => handleMoveTruID()}>
              Confirm affordability with truID
            </PLButton>
          </div>
        </div>
      </div>

      <div className="pl-container-page px-1">
        <div className="container intro-sprint-hive md-pd-0 pb-4">
          <IntroSprintHiveSteps
            title={
              <React.Fragment>
                Or you can choose to email 3-month’s worth of your most recent bank-generated PDF statements to: <a className="font-weight-500" href={`mailto:${Config.emailLoanDoc}`} target="_blank" rel="noopener noreferrer">{Config.emailLoanDoc}</a>
              </React.Fragment>
            }
          />
        </div>
      </div>
    </div>
  );
};


export default connect()(IntroTruid);
