// 1. facial-verification-intro
export const segment2100FacialVerificationIntroData = {
    type: 'track',
    event: 'lndPln facial verification scrn viewed',
    properties: {
        ui_screen_path:
            '/027-personal-loan/facial-verification/path-happy/facial-verification-intro/',
        ui_screen_state: 'informational',
        flow_conversion: 'no',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};

// 2. facial-verification-request-camera-access
export const segment2100FacialVerificationRequestCameraData = {
    type: 'track',
    event: 'lndPln facial verification scrn viewed',
    properties: {
        ui_screen_path:
            '/027-personal-loan/facial-verification/path-happy/facial-verification-request-camera-access/',
        ui_screen_state: 'system-dialog',
        flow_conversion: 'no',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};

// 3. facial-verification-prepare-capture-selfie
export const segment2100FacialVerificationPrepareCaptureSelfieData = {
    type: 'track',
    event: 'lndPln facial verification scrn viewed',
    properties: {
        ui_screen_path:
            '/027-personal-loan/facial-verification/path-happy/facial-verification-prepare-capture-selfie/',
        ui_screen_state: 'informational',
        flow_conversion: 'no',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};

// 4. facial-verification-capture-selfie
export const segment2100FacialVerificationCaptureSelfieData = {
    type: 'track',
    event: 'lndPln facial verification scrn viewed',
    properties: {
        ui_screen_path:
            '/027-personal-loan/facial-verification/path-happy/facial-verification-capture-selfie/',
        ui_screen_state: 'modal-alert',
        flow_conversion: 'no',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};

// 5. facial-verification-waiting-result
export const segment2100FacialVerificationWaitingResultData = {
    type: 'track',
    event: 'lndPln facial verification scrn viewed',
    properties: {
        ui_screen_path:
            '/027-personal-loan/facial-verification/path-happy/facial-verification-waiting-result/',
        ui_screen_state: 'subjective-timing-load-skeleton',
        flow_conversion: 'no',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};

// 6. facial-verification-success
export const segment2100FacialVerificationSuccessData = {
    type: 'track',
    event: 'lndPln facial verification scrn viewed',
    properties: {
        ui_screen_path:
            '/027-personal-loan/facial-verification/path-happy/facial-verification-success/',
        ui_screen_state: 'modal-alert',
        flow_conversion: 'yes-positive',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};

// 7. facial-verification-no-camera-access
export const segment2100FacialVerificationNoCameraAccessData = {
    type: 'track',
    event: 'lndPln facial verification scrn viewed',
    properties: {
        ui_screen_path:
            '/027-personal-loan/facial-verification/path-unhappy/facial-verification-no-camera-access/',
        ui_screen_state: 'informational',
        flow_conversion: 'no',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};

// 8. facial-verification-general-error
export const segment2100FacialVerificationGeneralErrorData = {
    type: 'track',
    event: 'lndPln facial verification scrn viewed',
    properties: {
        ui_screen_path:
            '/027-personal-loan/facial-verification/path-unhappy/facial-verification-general-error/',
        ui_screen_state: 'modal-alert',
        flow_conversion: 'no',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};

// 9. facial-verification-offer-retracted
export const segment2100FacialVerificationOfferRetractedData = {
    type: 'track',
    event: 'lndPln facial verification scrn viewed',
    properties: {
        ui_screen_path:
            '/027-personal-loan/facial-verification/path-unhappy/facial-verification-offer-retracted/',
        ui_screen_state: 'informational',
        flow_conversion: 'yes-negative',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};

// 10. facial-verification-bad-lighting-error
export const segment2100FacialVerificationBadLightingErrorData = {
    type: 'track',
    event: 'lndPln facial verification scrn viewed',
    properties: {
        ui_screen_path:
            '/027-personal-loan/facial-verification/path-unhappy/facial-verification-bad-lighting-error/',
        ui_screen_state: 'modal-alert',
        flow_conversion: 'no',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};

// 11. facial-verification-smiling-face-error
export const segment2100FacialVerificationSmillingFaceErrorData = {
    type: 'track',
    event: 'lndPln facial verification scrn viewed',
    properties: {
        ui_screen_path:
            '/027-personal-loan/facial-verification/path-unhappy/facial-verification-smiling-face-error/',
        ui_screen_state: 'modal-alert',
        flow_conversion: 'no',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};

// 12. facial-verification-blur-camera-error
export const segment2100FacialVerificationBlurCameraErrorData = {
    type: 'track',
    event: 'lndPln facial verification scrn viewed',
    properties: {
        ui_screen_path:
            '/027-personal-loan/facial-verification/path-unhappy/facial-verification-blur-camera-error/',
        ui_screen_state: 'modal-alert',
        flow_conversion: 'no',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};

// 13. facial-verification-timeout-error
export const segment2100FacialVerificationTimeoutErrorData = {
    type: 'track',
    event: 'lndPln facial verification scrn viewed',
    properties: {
        ui_screen_path:
            '/027-personal-loan/facial-verification/path-unhappy/facial-verification-timeout-error/',
        ui_screen_state: 'modal-alert',
        flow_conversion: 'no',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};

// 14. facial-verification-home-affair-down-error
export const segment2100FacialVerificationHomeAffairDownErrorData = {
    type: 'track',
    event: 'lndPln facial verification scrn viewed',
    properties: {
        ui_screen_path:
            '/027-personal-loan/facial-verification/path-unhappy/facial-verification-home-affair-down-error/',
        ui_screen_state: 'modal-alert',
        flow_conversion: 'no',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};

// 15. facial-verification-no-camera-error
export const segment2100FacialVerificationNoCameraErrorData = {
    type: 'track',
    event: 'lndPln facial verification scrn viewed',
    properties: {
        ui_screen_path:
            '/027-personal-loan/facial-verification/path-unhappy/facial-verification-no-camera-error/',
        ui_screen_state: 'modal-alert',
        flow_conversion: 'no',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};
// 16. facial-verification-exit
export const segment2100FacialVerificationExitData = {
    type: 'track',
    event: 'lndPln facial verification scrn viewed',
    properties: {
        ui_screen_path:
            '/027-personal-loan/facial-verification/path-happy/facial-verification-exit/',
        ui_screen_state: 'modal-alert',
        flow_conversion: 'no',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};

// 17. said-error-no-hanis-photo-popup
export const segment2100FacialVerificationSaIDErrorNoHanisPhotoData = {
    type: 'track',
    event: 'lndPln said scrn viewed',
    properties: {
        ui_screen_path:
            '/027-personal-loan/said/path-error-conditional/said-error-no-hanis-photo-popup/',
        ui_screen_state: 'modal-alert',
        flow_conversion: 'yes-negative',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};

// 18. said-error-offer-retracted-fail-facial-verification
export const segment2100FacialVerificationSaIDErrorOfferRetractedFailData = {
    type: 'track',
    event: 'lndPln said scrn viewed',
    properties: {
        ui_screen_path:
            '/027-personal-loan/said/path-error-conditional/said-error-offer-retracted-fail-facial-verification/',
        ui_screen_state: 'informational',
        flow_conversion: 'yes-negative',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};
