import React from 'react';

import { FlexColumnCenter } from './FlexCenter';
import LabelTitle from './LabelTitle';

const WrapperIconModal = ({
    icon,
    title,
    children,
}) => (
    <React.Fragment>
        <FlexColumnCenter className="text-center">
            <div className="step-point">
                {icon}
            </div>
        </FlexColumnCenter>

        <LabelTitle className="py-3 wrapper-modal-title">{title}</LabelTitle>

        {children}
    </React.Fragment>
);

export default WrapperIconModal;
