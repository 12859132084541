import React from 'react';
import XWrapMoney from '../../../components/Common/XWrapMoney';
import PLSlider from '../../../components/Common/PLSlider';
import { MovingFadeIn } from '../../../components/Common/AnimateText';

import XLandingLabel from './XLandingLabel';

function XLandingSlider({
    loanConfig,
    product,
    handleSliderChange,
}) {
    return (
        <div className="landing-slider mb-4">
            <XLandingLabel>Slide to choose a loan amount</XLandingLabel>
            <MovingFadeIn key={loanConfig.value} duration='0.25s'>
                <XWrapMoney className="font-size-40" primary>
                    {loanConfig.value}
                </XWrapMoney>
            </MovingFadeIn>

            <PLSlider
                min={product.minAmount || 0}
                max={product.maxAmount || 1000}
                value={loanConfig.value || 0}
                onChange={handleSliderChange}
                step={100} />
        </div>
    );
}

export default XLandingSlider;
