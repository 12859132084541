export const faceTecSessionStatusError = {
    // MissingGuidanceImages = 1
    1: 'The Session was cancelled because not all guidance images were configured',

    // Timeout = 2
    2: 'The Session was cancelled because the user was unable to complete a Session in the default allotted time or the timeout set by the developer',

    // ContextSwitch = 3,
    3: 'The Session was cancelled due to the app being terminated, put to sleep, an OS notification, or the app was placed in the background',

    // ProgrammaticallyCancelled = 4,
    4: 'The developer programmatically called the Session cancel API',

    // OrientationChangeDuringSession = 5,
    5: 'The Session was cancelled due to a device orientation change during the Session',

    // LandscapeModeNotAllowed = 6,
    6: 'The Session was cancelled because device is in landscape mode',

    // UserCancelled = 7,
    7: 'The user pressed the cancel button and did not complete the Session',

    // UserCancelledFromNewUserGuidance = 8,
    8: 'The user pressed the cancel button during New User Guidance',

    // UserCancelledFromRetryGuidance = 9,
    9: 'The user pressed the cancel button during Retry Guidance',

    // UserCancelledWhenAttemptingToGetCameraPermissions = 10,
    10: 'The user cancelled out of the the FaceTec Browser SDK experience while attempting to get camera permissions',

    // LockedOut = 11,
    11: 'The Session was cancelled because the user was in a locked out state',

    // CameraNotEnabled = 12,
    12: 'The Session was cancelled because camera was not enabled',

    // NonProductionModeDeviceKeyIdentifierInvalid = 13,
    13: 'This status will never be returned in a properly configured or production app',

    // DocumentNotReady = 14,
    14: 'The Session was cancelled because the FaceTec Browser SDK cannot be rendered when the document is not ready',

    // SessionInProgress = 15,
    15: 'The Session was cancelled because there was another Session in progress',

    // CameraNotRunning = 16,
    16: 'The Session was cancelled because the selected camera is not active',

    // InitializationNotCompleted = 17,
    17: 'The Session was cancelled because initialization has not been completed yet',

    // UnknownInternalError = 18,
    18: 'The Session was cancelled because of an unknown and unexpected error.  The FaceTec Browser SDK leverages a variety of platform APIs including camera, storage, security, networking, and more',

    // UserCancelledViaClickableReadyScreenSubtext = 19,
    19: 'The Session cancelled because user pressed the Get Ready screen subtext message',

    // NotAllowedUseIframeConstructor = 20,
    20: 'The Session was cancelled, the FaceTec Browser SDK was opened in an Iframe without an Iframe constructor',

    // NotAllowedUseNonIframeConstructor = 21,
    21: 'The Session was cancelled, the FaceTec Browser SDK was not opened in an Iframe with an Iframe constructor',

    // IFrameNotAllowedWithoutPermission = 22,
    22: 'The Session was cancelled, the FaceTec Browser SDK was not opened in an Iframe without permission',

    // StillLoadingResources = 23,
    23: 'FaceTec SDK is still loading resources',

    // ResourcesCouldNotBeLoadedOnLastInit = 24,
    24: 'FaceTec SDK could not load resources',

    // UserCancelledFullScreenMode = 25
    25: 'The Session was cancelled because a full screen mode change was detected in an IFrame',
};

export const faceTecSDKStatus = {
    //  NeverInitialized = 0,
    0: 'Initialize was never attempted',

    // Initialized = 1,
    1: 'The Key provided was verified',

    // NetworkIssues = 2,
    2: 'The Key could not be verified due to connectivity issues on the user\'s device',

    // InvalidDeviceKeyIdentifier = 3,
    3: 'The Key provided was invalid',

    // VersionDeprecated = 4,
    4: 'DEPRECATED',

    // DeviceNotSupported = 5,
    5: 'This device/platform/browser/version combination is not supported by the FaceTec Browser SDK',

    // DeviceInLandscapeMode = 6
    6: 'Device is in landscape display orientation. The FaceTec Browser SDK can only be used in portrait display orientation',

    // DeviceLockedOut = 7,
    7: 'Device is in reverse portrait mode. The FaceTec Browser SDK can only be used in portrait display orientation',

    // KeyExpiredOrInvalid = 8
    8: 'The Key was expired, contained invalid text, or you are attempting to initialize on a domain that is not specified in your Key',

    // IFrameNotAllowedWithoutPermission = 9,
    9: 'The Session was cancelled, the FaceTec Browser SDK was opened in an IFrame without permission',

    // StillLoadingResources = 10,
    10: 'FaceTec SDK is still loading resources',

    // ResourcesCouldNotBeLoadedOnLastInit = 11,
    11: 'FaceTec SDK could not load resources',
};
