import React, { useState } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { Button } from 'reactstrap';
import joinclass from 'joinclass';

const PLButton = ({
  primary = true,
  ui,
  className,
  label,
  light,
  isAsync = false,
  onClick = () => { },
  refElement = {},
  ...props
}) => {
  const [lock, setLock] = useState(false);
  const disabled = isAsync ? (lock || ui.overlayLoading) : props.disabled;

  const handleClick = (evt) => {
    if (!isAsync) {
      onClick(evt);
      return;
    }

    setLock(true);
    setTimeout(() => {
      setLock(false);
      onClick(evt);
    }, 250);
  };

  return (
    <Button
      type="button"
      ref={evt => _.set(refElement, 'current', evt)}
      className={joinclass('btn btn-common', primary ? 'btn-yellow' : 'btn-default', light && 'btn-light', className)}
      disabled={disabled}
      onClick={handleClick}
      {...props}
    >
      {label || props.children}
    </Button>
  );
};

const mapStateToProps = state => ({
  ui: state.ui,
});

export default connect(
  mapStateToProps,
  {},
)(PLButton);
