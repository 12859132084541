import { createAction } from 'redux-actions';
import {
    FETCH_CONFIGURATION,
    FETCH_CONFIGURATION_SUCCESS,
    FETCH_CONFIGURATION_FAILED,
    HANDLE_ERROR_API,
    HIDE_MODAL_HANDLE_ERROR_API,
    VALIDATION_SAID,
    VALIDATION_SAID_SUCCESS,
    VALIDATION_SAID_FAILED,
    VALIDATE_OTP,
    VALIDATE_OTP_SUCCESS,
    VALIDATE_OTP_FAILED,
    SHOW_OVERLAY_LOADING,
    HIDE_OVERLAY_LOADING,
    VERIFY_OTP,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAILED,
    GENERATE_OTP_SUCCESS,
    GENERATE_OTP_FAILED,
    GENERATE_OTP,
    FETCH_LIST_VALUES_SUCCESS,
    FETCH_LIST_VALUES,
    FETCH_LIST_VALUES_FAILED,
    SAVE_STEPS_INPUT,
    PRELOAD_MEDIA_PROCESS,
    POST_LOAN_SUBMISSION,
    POST_LOAN_SUBMISSION_SUCCESS,
    POST_LOAN_SUBMISSION_FAILED,
    FETCH_DECISION_RESULTS,
    FETCH_DECISION_UPSELL_RESULTS,
    HIDE_CONFIRM_OFFER_MODAL,
    SHOW_CONFIRM_OFFER_MODAL,
    SEND_LOAN_OFFERS_SELECTION,
    UPDATE_OFFER_LIST,
    // TOAST_LOAD,
    RESET_LOAN_SUBMISSION,
    SET_MODE_IFRAME_KIOSK,
    GENERATE_PRESIGNED_URL,
    GENERATE_PRESIGNED_URL_SUCCESS,
    GENERATE_PRESIGNED_URL_FAILED,
    ASSIGN_LOAN_DOCUMENTS,
    ASSIGN_LOAN_DOCUMENTS_SUCCESS,
    ASSIGN_LOAN_DOCUMENTS_FAILED,
    FETCH_LIST_PRODUCTS,
    FETCH_LIST_PRODUCTS_SUCCESS,
    FETCH_LIST_PRODUCTS_FAILED,
    FETCH_AFFORDABILITY_RESULTS,
    FETCH_LOAN_DETAIL,
    FETCH_LOAN_DETAIL_FAILED,
    FETCH_LOAN_DETAIL_SUCCESS,
    PUT_SELECTED_OFFER,
    PUT_SELECTED_OFFER_FAILED,
    PUT_SELECTED_OFFER_SUCCESS,
    FETCH_CONTRACT_INFO,
    FETCH_CONTRACT_INFO_FAILED,
    FETCH_CONTRACT_INFO_SUCCESS,
    SIGN_CONTRACT_LOAN,
    SIGN_CONTRACT_LOAN_FAILED,
    SIGN_CONTRACT_LOAN_FIRST_TIME_SUCCESS,
    SIGN_CONTRACT_LOAN_SUCCESS,
    FETCH_FULFILLMENT_RESULTS,
    FULFILLMENT_RESULTS_SUCCESS,
    EVALUATE_ELIBILITY_CHECK_RESULT,
    SAVE_STEP_UP_TOKEN, SAVE_CUSTOMER_INFO,
    EVALUATE_ELIBILITY_CHECK_RESULT_WITH_RETRY,
    VERIFY_LOAN_APPLICATION,
    VERIFY_LOAN_APPLICATION_FAILED,
    VERIFY_LOAN_APPLICATION_SUCCESS,
    VERIFY_LOAN_APPLICATION_IN_ELIBILITY,
    FETCH_SPRINT_HIVE_AFFORDABILITY_RESULTS,
    FETCH_USER_TYPE,
    FETCH_USER_TYPE_SUCCESS,
    FETCH_USER_TYPE_FAILED,
} from '../store/actionTypes';

export const fetchConfiguration = createAction(FETCH_CONFIGURATION);
export const fetchConfigurationSuccess = createAction(FETCH_CONFIGURATION_SUCCESS);
export const fetchConfigurationFailed = createAction(FETCH_CONFIGURATION_FAILED);

export const handleErrorApi = createAction(HANDLE_ERROR_API);
export const hideModalHandleErrorApi = createAction(HIDE_MODAL_HANDLE_ERROR_API);

export const validationSaid = createAction(VALIDATION_SAID);
export const validationSaidSuccess = createAction(VALIDATION_SAID_SUCCESS);
export const validationSaidFailed = createAction(VALIDATION_SAID_FAILED);

export const validateOtp = createAction(VALIDATE_OTP);
export const validateOtpSuccess = createAction(VALIDATE_OTP_SUCCESS);
export const validateOtpFailed = createAction(VALIDATE_OTP_FAILED);

export const showOverlayLoading = createAction(SHOW_OVERLAY_LOADING);
export const hideOverlayLoading = createAction(HIDE_OVERLAY_LOADING);

export const verifyOtp = createAction(VERIFY_OTP);
export const verifyOtpSuccess = createAction(VERIFY_OTP_SUCCESS);
export const verifyOtpFailed = createAction(VERIFY_OTP_FAILED);

export const generateOtp = createAction(GENERATE_OTP);
export const generateOtpSucess = createAction(GENERATE_OTP_SUCCESS);
export const generateOtpFailed = createAction(GENERATE_OTP_FAILED);

export const fetchListProducts = createAction(FETCH_LIST_PRODUCTS);
export const fetchListProductsSuccess = createAction(FETCH_LIST_PRODUCTS_SUCCESS);
export const fetchListProductsFailed = createAction(FETCH_LIST_PRODUCTS_FAILED);

export const fetchListValues = createAction(FETCH_LIST_VALUES);
export const fetchListValuesSuccess = createAction(FETCH_LIST_VALUES_SUCCESS);
export const fetchListValuesFailed = createAction(FETCH_LIST_VALUES_FAILED);

export const saveStepsInput = createAction(SAVE_STEPS_INPUT);
export const processPreloadMedia = createAction(PRELOAD_MEDIA_PROCESS);

export const postLoanSubmission = createAction(POST_LOAN_SUBMISSION);
export const postLoanSubmissionSuccess = createAction(POST_LOAN_SUBMISSION_SUCCESS);
export const postLoanSubmissionFailed = createAction(POST_LOAN_SUBMISSION_FAILED);
export const resetLoanSubmission = createAction(RESET_LOAN_SUBMISSION);

export const showConfirmOfferModal = createAction(SHOW_CONFIRM_OFFER_MODAL);
export const hideConfirmOfferModal = createAction(HIDE_CONFIRM_OFFER_MODAL);

export const fetchDecisionResults = createAction(FETCH_DECISION_RESULTS);
export const fetchDecisionUpsellResults = createAction(FETCH_DECISION_UPSELL_RESULTS);

export const sendLoanOffersSelection = createAction(SEND_LOAN_OFFERS_SELECTION);

export const updateOfferList = createAction(UPDATE_OFFER_LIST);

export const setModeIframeKiosk = createAction(SET_MODE_IFRAME_KIOSK);
export const generatePresignedURL = createAction(GENERATE_PRESIGNED_URL);
export const generatePresignedURLSuccess = createAction(GENERATE_PRESIGNED_URL_SUCCESS);
export const generatePresignedURLFailed = createAction(GENERATE_PRESIGNED_URL_FAILED);

export const assignLoanDocument = createAction(ASSIGN_LOAN_DOCUMENTS);
export const assignLoanDocumentSuccess = createAction(ASSIGN_LOAN_DOCUMENTS_SUCCESS);
export const assignLoanDocumentFailed = createAction(ASSIGN_LOAN_DOCUMENTS_FAILED);

export const fetchAffordabilityResults = createAction(FETCH_AFFORDABILITY_RESULTS);
export const fetchSprintHiveAffordabilityResults = createAction(FETCH_SPRINT_HIVE_AFFORDABILITY_RESULTS);

export const fetchLoanDetail = createAction(FETCH_LOAN_DETAIL);
export const fetchLoanDetailSuccess = createAction(FETCH_LOAN_DETAIL_SUCCESS);
export const fetchLoanDetailFailed = createAction(FETCH_LOAN_DETAIL_FAILED);

export const putSelectedOffer = createAction(PUT_SELECTED_OFFER);
export const putSelectedOfferSuccess = createAction(PUT_SELECTED_OFFER_SUCCESS);
export const putSelectedOfferFailed = createAction(PUT_SELECTED_OFFER_FAILED);

export const fetchContractInfo = createAction(FETCH_CONTRACT_INFO);
export const fetchContractInfoSuccess = createAction(FETCH_CONTRACT_INFO_SUCCESS);
export const fetchContractInfoFailed = createAction(FETCH_CONTRACT_INFO_FAILED);

export const evaluateEligibilityCheckResult = createAction(EVALUATE_ELIBILITY_CHECK_RESULT);
export const evaluateEligibilityCheckResultWithRetry = createAction(EVALUATE_ELIBILITY_CHECK_RESULT_WITH_RETRY);

export const verifyLoanApplicationInElibility = createAction(VERIFY_LOAN_APPLICATION_IN_ELIBILITY);

export const saveStepUpToken = createAction(SAVE_STEP_UP_TOKEN);
export const saveCustomerInfo = createAction(SAVE_CUSTOMER_INFO);

export const signContractLoan = createAction(SIGN_CONTRACT_LOAN);
export const signContractLoanSuccess = createAction(SIGN_CONTRACT_LOAN_SUCCESS);
export const signContractLoanFirstTimeSuccess = createAction(SIGN_CONTRACT_LOAN_FIRST_TIME_SUCCESS);
export const signContractLoanFailed = createAction(SIGN_CONTRACT_LOAN_FAILED);

export const fetchFulfillmentResults = createAction(FETCH_FULFILLMENT_RESULTS);
export const fetchFulfillmentSuccess = createAction(FULFILLMENT_RESULTS_SUCCESS);

export const verifyLoanApplication = createAction(VERIFY_LOAN_APPLICATION);
export const verifyLoanApplicationSuccess = createAction(VERIFY_LOAN_APPLICATION_SUCCESS);
export const verifyLoanApplicationFailed = createAction(VERIFY_LOAN_APPLICATION_FAILED);

export const fetchUserProfileType = createAction(FETCH_USER_TYPE);
export const fetchUserProfileTypeSuccess = createAction(FETCH_USER_TYPE_SUCCESS);
export const fetchUserProfileTypeFailed = createAction(FETCH_USER_TYPE_FAILED);

