import _ from 'lodash';

import {
    VERIFY_LOAN_APPLICATION,
    VERIFY_LOAN_APPLICATION_SUCCESS,
    VERIFY_LOAN_APPLICATION_FAILED,
    RESET_LOAN_SUBMISSION,
} from '../store/actionTypes';

const initialState = {
    isVerifyLoanSuccess: null,
    errors: null,
    requestToken: null,
    loanApplicationNumber: null,
    loanApplicationStatus: null,
    phoneNumber: null,
};

export default (state = initialState, action) => {
    const hashedSAID = _.get(action, 'payload.hashedSAID') || state.hashedSAID;
    switch (action.type) {
        case VERIFY_LOAN_APPLICATION:
            return {
                ...state,
                isVerifyLoanSuccess: false,
                loanApplicationNumber: action.payload.loanApplicationNumber,
            };
        case VERIFY_LOAN_APPLICATION_SUCCESS:
            return {
                ...state,
                hashedSAID,
                requestToken: action.payload.requestToken,
                phoneNumber: action.payload.phoneNumber,
                loanApplicationStatus: action.payload.loanApplicationStatus,
                loanApplicationNumber: action.payload.loanApplicationNumber,
                isVerifyLoanSuccess: true,
                errors: null,
            };
        case VERIFY_LOAN_APPLICATION_FAILED:
            return {
                ...state,
                isVerifyLoanSuccess: false,
                errors: action.payload,
            };
        case RESET_LOAN_SUBMISSION: {
            return {
                ...initialState,
            };
        }
        default:
            return state;
    }
};
