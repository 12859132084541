import React from 'react';

const InfomationIcon = ({
  id,
  getInputRef,
}) => (
  <svg id={id} ref={getInputRef} className="cursor-pointer" width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M12.9648 2C7.46484 2 2.96484 6.5 2.96484 12C2.96484 17.5 7.46484 22 12.9648 22C18.4648 22 22.9648 17.5 22.9648 12C22.9648 6.5 18.4648 2 12.9648 2ZM11.9647 16.9998H13.9647V10.9998H11.9647V16.9998ZM13.9647 9H11.9647V7H13.9647V9Z" fill="#4782CA" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.9649 9H11.9649V7H13.9649V9ZM13.9648 16.9998H11.9648V10.9998H13.9648V16.9998Z" fill="white" />
  </svg>
);

export default InfomationIcon;
