import React from 'react';
import { FlexColumnCenter } from '../Common/FlexCenter';
import successIcon from '../../assets/images/success-icon.svg';

const SelfieResults = () => (
    <FlexColumnCenter className="mt-4 my-md-3 text-center">
        <div>
            <img alt="" src={successIcon} />
        </div>
        <p className="mt-4 font-weight-500 font-size-16 font-size-md-24">
            You’ve successfully signed the agreement
        </p>

        <p className="my-md-3 font-size-14 font-size-md-18">
            Next and last step: Confirm your DebiCheck debit order.
        </p>
    </FlexColumnCenter>
);

export default SelfieResults;
