import React from 'react';

export const DocumentTimeIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z" fill="#E8E8E8" />
    <rect x="20.6885" y="16.4629" width="21.9767" height="28.4846" fill="white" stroke="#4F4D4C" />
    <rect x="18.103" y="13.873" width="21.9767" height="28.4846" fill="white" stroke="#4F4D4C" />
    <path d="M22.6276 20.3464H32.9696" stroke="#4F4D4C" />
    <path d="M22.6276 22.9362H32.9696" stroke="#4F4D4C" />
    <path d="M38.1405 50.1269C42.4243 50.1269 45.897 46.6488 45.897 42.3584C45.897 38.0679 42.4243 34.5898 38.1405 34.5898C33.8568 34.5898 30.3841 38.0679 30.3841 42.3584C30.3841 46.6488 33.8568 50.1269 38.1405 50.1269Z" fill="#FFCC00" stroke="#4F4C4D" />
    <path d="M35.5551 44.9473L38.1405 42.3578V37.8262" stroke="#4F4C4D" strokeLinecap="square" />
  </svg>
);

const DocumentIcon = () => (
  <svg width="101" height="100" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50.5" cy="50" r="50" fill="#E3E3E3" />
    <path d="M30.1392 70.9749H67.3703V22.0332H30.1392V70.9749Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M30.1392 70.9749H67.3703V22.0332H30.1392V70.9749Z" stroke="#4F4E4D" />
    <path d="M25.4854 66.3148H62.7165V17.373H25.4854V66.3148Z" fill="#FEFEFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M25.4854 66.3148H62.7165V17.373H25.4854V66.3148Z" stroke="#4F4E4D" />
    <path d="M30.1392 31.3559H53.4086" stroke="#4F4E4D" />
    <path d="M55.7358 31.3559H58.0628" stroke="#4F4E4D" />
    <path d="M55.7358 36.018H58.0628" stroke="#4F4E4D" />
    <path d="M30.1392 36.018H53.4086" stroke="#4F4E4D" />
    <path d="M30.1392 59.3227H39.4469" stroke="#4F4E4D" />
    <path d="M61.553 82.6288C69.2638 82.6288 75.5146 76.3682 75.5146 68.6455C75.5146 60.9227 69.2638 54.6621 61.553 54.6621C53.8422 54.6621 47.5913 60.9227 47.5913 68.6455C47.5913 76.3682 53.8422 82.6288 61.553 82.6288Z" fill="#FDC600" stroke="#4F4C4D" />
    <path d="M62.3687 72.1418H60.7373V61.6543H62.3687V72.1418Z" fill="#4F4C4D" />
    <path d="M62.3687 76.2206H60.7373V74.4727H62.3687V76.2206Z" fill="#4F4C4D" />
  </svg>
);

export default DocumentIcon;

export const DocumentMailIcon = () => (
  <svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.74805 19.7097L20.4996 11.0371L39.2512 19.7097V38.5003H1.74805V19.7097Z" fill="#FFCC00" stroke="#4F4C4D" strokeLinejoin="round" />
    <rect x="8.96094" y="3.81055" width="23.0788" height="31.7995" fill="white" stroke="#4F4C4D" />
    <path d="M12.5664 8.86915H18.3361H12.5664Z" fill="white" />
    <path d="M12.5664 8.86915H18.3361" stroke="#4F4C4D" />
    <path d="M12.5664 11.7598H18.3361H12.5664Z" fill="white" />
    <path d="M12.5664 11.7598H18.3361" stroke="#4F4C4D" />
    <path d="M12.5664 14.8848H28.4932H12.5664Z" fill="white" />
    <path d="M12.5664 14.8848H28.4932" stroke="#4F4C4D" />
    <path d="M12.5664 18.0098H28.4932H12.5664Z" fill="white" />
    <path d="M12.5664 18.0098H28.4932" stroke="#4F4C4D" />
    <path d="M12.5664 21.1367H28.4932H12.5664Z" fill="white" />
    <path d="M12.5664 21.1367H28.4932" stroke="#4F4C4D" />
    <path d="M1.74805 19.7109L20.4996 29.829L39.2512 19.7109V38.5016H1.74805V19.7109Z" fill="white" stroke="#4F4C4D" strokeLinejoin="round" />
  </svg>
);

export const DocumentQuestionIcon = () => (
  <svg width="72" height="72" viewBox="0 0 72 72" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.6392 56.973H52.8703V8.03125H15.6392V56.973Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M15.6392 56.973H52.8703V8.03125H15.6392V56.973Z" stroke="#4F4E4D" strokeWidth="1.8" />
    <path d="M10.9854 52.3128H48.2165V3.37109H10.9854V52.3128Z" fill="#FEFEFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M10.9854 52.3128H48.2165V3.37109H10.9854V52.3128Z" stroke="#4F4E4D" strokeWidth="1.8" />
    <path d="M15.6392 17.3539H38.9086" stroke="#4F4E4D" strokeWidth="1.8" />
    <path d="M41.2354 17.3539H43.5623" stroke="#4F4E4D" strokeWidth="1.8" />
    <path d="M41.2354 22.016H43.5623" stroke="#4F4E4D" strokeWidth="1.8" />
    <path d="M15.6392 22.016H38.9086" stroke="#4F4E4D" strokeWidth="1.8" />
    <path d="M15.6392 45.3207H24.9469" stroke="#4F4E4D" strokeWidth="1.8" />
    <path d="M47.053 68.6269C54.7638 68.6269 61.0146 62.3663 61.0146 54.6435C61.0146 46.9207 54.7638 40.6602 47.053 40.6602C39.3422 40.6602 33.0913 46.9207 33.0913 54.6435C33.0913 62.3663 39.3422 68.6269 47.053 68.6269Z" fill="#FFCC00" stroke="#4F4C4D" strokeWidth="1.8" />
    <path d="M43.563 51.7433C43.5954 49.4162 45.0217 47.6504 47.5384 47.6504C50.0552 47.6504 51.3859 49.8198 51.3506 51.7536C51.3153 53.6874 49.839 55.4536 47.3961 55.4468C47.3961 55.9949 47.3961 56.8018 47.3961 57.8676" stroke="#4F4C4D" strokeWidth="1.8" strokeLinecap="square" />
    <path d="M47.3964 61.2461C47.3964 62.2699 47.3964 62.6038 47.3964 62.2479" stroke="#4F4C4D" strokeWidth="1.8" strokeLinecap="square" />
  </svg>
);

export const DocumentResidenceIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="128" height="128" viewBox="0 0 128 128">
    <g fill="none" fillRule="evenodd">
      <path fill="#FECC05" d="M27.444 124H111V14.435H27.444z" />
      <path stroke="#4F4E4D" strokeWidth="3" d="M27.444 124H111V14.435H27.444z" />
      <path fill="#FEFEFE" d="M17 113.565h83.556V4H17z" />
      <path stroke="#4F4E4D" strokeWidth="3" d="M17 113.565h83.556V4H17zM27.444 35.304h52.223M84.889 35.304h5.222M84.889 45.74h5.222M27.444 45.74h52.223M27.444 97.913h20.89" />
      <path d="M0 0h128v128H0z" />
    </g>
  </svg>
);
