import { handleActions } from 'redux-actions';
import {
    SHOW_OVERLAY_LOADING,
    HIDE_OVERLAY_LOADING,
    FETCH_CONFIGURATION,
    FETCH_CONFIGURATION_FAILED,
    FETCH_CONFIGURATION_SUCCESS,
    VALIDATION_SAID_SUCCESS,
    VALIDATION_SAID_FAILED,
    VALIDATION_SAID,
    HANDLE_ERROR_API,
    VERIFY_OTP,
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAILED,
    PRELOAD_MEDIA_PROCESS,
    SHOW_CONFIRM_OFFER_MODAL,
    HIDE_CONFIRM_OFFER_MODAL,
    POST_LOAN_SUBMISSION,
    POST_LOAN_SUBMISSION_FAILED,
    DECISION_RESULTS_IN_PROGRESS,
    FETCH_DECISION_RESULTS,
    SEND_LOAN_OFFERS_SELECTION,
    SEND_LOAN_OFFERS_SELECTION_SUCCESS,
    SEND_LOAN_OFFERS_SELECTION_FAILED,
    DECISION_RESULTS_ELIGIBLE,
    DECISION_RESULTS_INELIGIBLE,
    FETCH_LIST_PRODUCTS,
    FETCH_LIST_PRODUCTS_SUCCESS,
    FETCH_LIST_PRODUCTS_FAILED,
    FETCH_AFFORDABILITY_RESULTS,
    AFFORDABILITY_RESULTS_ELIGIBLE,
    AFFORDABILITY_RESULTS_INELIGIBLE,
    AFFORDABILITY_RESULTS_IN_PROGRESS,
    FETCH_LOAN_DETAIL,
    FETCH_LOAN_DETAIL_FAILED,
    FETCH_LOAN_DETAIL_SUCCESS,
    PUT_SELECTED_OFFER,
    PUT_SELECTED_OFFER_FAILED,
    PUT_SELECTED_OFFER_SUCCESS,
    FETCH_CONTRACT_INFO,
    FETCH_CONTRACT_INFO_FAILED,
    FETCH_CONTRACT_INFO_SUCCESS,
    SIGN_CONTRACT_LOAN,
    SIGN_CONTRACT_LOAN_SUCCESS,
    SIGN_CONTRACT_LOAN_FIRST_TIME_SUCCESS,
    SIGN_CONTRACT_LOAN_FAILED,
    FETCH_FULFILLMENT_RESULTS,
    FULFILLMENT_RESULTS_IN_PROGRESS,
    FULFILLMENT_RESULTS_SUCCESS,
    FULFILLMENT_RESULTS_FAILED,
    EVALUATE_ELIBILITY_CHECK_RESULT,
    SAVE_CUSTOMER_INFO,
    EVALUATE_ELIBILITY_CHECK_RESULT_WITH_RETRY,
    FETCH_SPRINT_HIVE_AFFORDABILITY_RESULTS,
    VERIFY_LOAN_APPLICATION_IN_ELIBILITY,
    FETCH_USER_TYPE,
    FETCH_USER_TYPE_SUCCESS,
    VERIFY_LOAN_APPLICATION_SUCCESS,
} from '../store/actionTypes';

const initialState = {
    overlayLoading: false,
};

export const actions = {
    [VERIFY_OTP]: state => ({
        ...state,
        overlayLoading: true,
    }),
    [VERIFY_OTP_SUCCESS]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [VERIFY_OTP_FAILED]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [SHOW_OVERLAY_LOADING]: state => ({
        ...state,
        overlayLoading: true,
    }),
    [HIDE_OVERLAY_LOADING]: state => ({
        ...state,
        overlayLoading: false,
    }),

    [FETCH_CONFIGURATION]: state => ({
        ...state,
        overlayLoading: true,
    }),
    [FETCH_CONFIGURATION_FAILED]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [FETCH_CONFIGURATION_SUCCESS]: state => ({
        ...state,
        overlayLoading: false,
    }),

    [FETCH_LIST_PRODUCTS]: state => ({
        ...state,
        overlayLoading: true,
    }),
    [FETCH_LIST_PRODUCTS_FAILED]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [FETCH_LIST_PRODUCTS_SUCCESS]: state => ({
        ...state,
        overlayLoading: false,
    }),

    [VALIDATION_SAID]: state => ({
        ...state,
        overlayLoading: true,
    }),
    [VALIDATION_SAID_SUCCESS]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [VALIDATION_SAID_FAILED]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [HANDLE_ERROR_API]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [PRELOAD_MEDIA_PROCESS]: (state, action) => ({
        ...state,
        preloadMediaLoading: action.payload,
    }),
    [SHOW_CONFIRM_OFFER_MODAL]: state => ({
        ...state,
        showConfirmOfferModal: true,
    }),
    [HIDE_CONFIRM_OFFER_MODAL]: state => ({
        ...state,
        showConfirmOfferModal: false,
    }),
    [POST_LOAN_SUBMISSION]: state => ({
        ...state,
        overlayLoading: true,
    }),
    [POST_LOAN_SUBMISSION_FAILED]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [FETCH_DECISION_RESULTS]: state => ({
        ...state,
        overlayLoading: true,
    }),
    [DECISION_RESULTS_IN_PROGRESS]: state => ({
        ...state,
        // overlayLoading: true,
    }),
    [DECISION_RESULTS_ELIGIBLE]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [DECISION_RESULTS_INELIGIBLE]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [SEND_LOAN_OFFERS_SELECTION]: state => ({
        ...state,
        overlayLoading: true,
    }),
    [SEND_LOAN_OFFERS_SELECTION_SUCCESS]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [SEND_LOAN_OFFERS_SELECTION_FAILED]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [FETCH_AFFORDABILITY_RESULTS]: state => ({
        ...state,
        overlayLoading: true,
    }),
    [FETCH_SPRINT_HIVE_AFFORDABILITY_RESULTS]: state => ({
        ...state,
        overlayLoading: true,
    }),
    [AFFORDABILITY_RESULTS_IN_PROGRESS]: state => ({
        ...state,
        overlayLoading: true,
    }),
    [AFFORDABILITY_RESULTS_ELIGIBLE]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [AFFORDABILITY_RESULTS_INELIGIBLE]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [PUT_SELECTED_OFFER]: state => ({
        ...state,
        overlayLoading: true,
    }),
    [PUT_SELECTED_OFFER_SUCCESS]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [PUT_SELECTED_OFFER_FAILED]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [FETCH_LOAN_DETAIL]: state => ({
        ...state,
        overlayLoading: true,
    }),
    [FETCH_LOAN_DETAIL_SUCCESS]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [FETCH_LOAN_DETAIL_FAILED]: (state, action) => ({
        ...state,
        overlayLoading: (action.payload || {}).keepLoading || false,
    }),
    [FETCH_CONTRACT_INFO]: state => ({
        ...state,
        overlayLoading: true,
    }),
    [FETCH_CONTRACT_INFO_SUCCESS]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [FETCH_CONTRACT_INFO_FAILED]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [SIGN_CONTRACT_LOAN]: state => ({
        ...state,
        overlayLoading: true,
    }),
    [SIGN_CONTRACT_LOAN_FIRST_TIME_SUCCESS]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [SIGN_CONTRACT_LOAN_SUCCESS]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [SIGN_CONTRACT_LOAN_FAILED]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [SIGN_CONTRACT_LOAN_FIRST_TIME_SUCCESS]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [FETCH_FULFILLMENT_RESULTS]: state => ({
        ...state,
        overlayLoading: true,
    }),
    [FULFILLMENT_RESULTS_IN_PROGRESS]: state => ({
        ...state,
        overlayLoading: true,
    }),
    [FULFILLMENT_RESULTS_SUCCESS]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [FULFILLMENT_RESULTS_FAILED]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [EVALUATE_ELIBILITY_CHECK_RESULT]: state => ({
        ...state,
        overlayLoading: true,
    }),
    [EVALUATE_ELIBILITY_CHECK_RESULT_WITH_RETRY]: state => ({
        ...state,
        overlayLoading: true,
    }),
    [VERIFY_LOAN_APPLICATION_IN_ELIBILITY]: state => ({
        ...state,
        overlayLoading: true,
    }),
    [FETCH_USER_TYPE]: state => ({
        ...state,
        overlayLoading: true,
    }),
    [FETCH_USER_TYPE_SUCCESS]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [VERIFY_LOAN_APPLICATION_SUCCESS]: state => ({
        ...state,
        overlayLoading: false,
    }),
    [SAVE_CUSTOMER_INFO]: state => ({
        ...state,
        overlayLoading: false,
    }),
};

export default handleActions(actions, initialState);
