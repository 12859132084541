import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import { push } from 'react-router-redux';
import _ from 'lodash';

import { handleErrorApi, resetLoanSubmission } from '../actions';
import useEventBus, { CommonBusUtils, EventBusName } from '../hooks/useEventBus';
import { useModalPopupContext } from './ModalPopupContext';

import useProductService from '../services/useProductService';
import { ErrorCode, routeName } from '../constants';

const Context = React.createContext({});

// we shouldn't use context
const CommonContext = ({
  // eslint-disable-next-line no-shadow
  push,
  // eslint-disable-next-line no-shadow
  handleErrorApi,
  router,
  isLANResuming,
  ...props
}) => {
  const { hideModal } = useModalPopupContext();
  const [shareLoanConfig, setShareLoanConfig] = useState({});
  const [declineSpecialCode, setDeclineSpecialCode] = useState();
  const [declineReasonDetails, setDeclineReasonDetails] = useState({});

  const { product, productConsent } = useProductService(_.get(props, 'loanApplicationNumber'), { ...shareLoanConfig });

  // save code from another truid screen
  useEventBus(EventBusName.TRUID_SAVE_DELINCE_CODE, (code, details) => {
    setDeclineReasonDetails(details || {});
    setDeclineSpecialCode(code);
  });
  useEventBus(EventBusName.HIDE_MODAL_COMMON, () => hideModal());

  // defined clear and goto home
  useEventBus(EventBusName.NOTIFY_CLEAR_GO_HOME, () => {
    setDeclineSpecialCode('');
    setDeclineReasonDetails({});
    hideModal();
    push(routeName.HONE);
    props.resetLoanSubmission();
    // clear ready
    CommonBusUtils.setAcceptConsents(false);
  });

  // quick showError
  useEventBus(EventBusName.NOTIFY_ERROR, errors => handleErrorApi(errors || [{ errorCode: ErrorCode.GENERIC_ERROR }])); // default error

  useEventBus(EventBusName.MOVE_TO_SCREEN, (page, params) => push(page, params));

  return (
    <Context.Provider
      value={{
        product,
        productConsent,
        shareLoanConfig,
        setShareLoanConfig,
        declineSpecialCode,
        declineReasonDetails,
        isLANResuming,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};

export const useCommonContext = () => useContext(Context);
export { Context as ShareCommonContext };

const mapStateToProps = state => ({
  isLANResuming: !!_.get(state.verifyLoanApplicationData, 'loanApplicationNumber'),
  loanApplicationNumber: _.get(state.loanSubmission, 'loanApplicationNumber') || _.get(state.verifyLoanApplicationData, 'loanApplicationNumber'),
  router: state.router,
});

export default connect(
  mapStateToProps,
  { push, handleErrorApi, resetLoanSubmission },
)(CommonContext);
