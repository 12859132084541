import React from 'react';

import WrapperIconModal from '../Common/WrapperIconModal';

export const TruidWrapDialogContent = ({
  icon,
  title,
  listContent = [],
  children, // single content
}) => (
  <WrapperIconModal icon={icon} title={title}>
    <div className="text-center">{
      children ?
        <p className="label-guilde mb-2">{children}</p> :
        listContent.map((item) => <p className="label-guilde mb-2" key={item}>{item}</p>)
    }
    </div>
  </WrapperIconModal>
);

