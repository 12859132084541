// for testing, update later
import phoneVibration from '../assets/sound/sound-phone-vibration.mp3';
import success from '../assets/sound/sound-success.mp3';
import click from '../assets/sound/sound-click.mp3';
import selection from '../assets/sound/sound-selection.mp3';

const playSound = (sound) => {
    try {
        const audio = new Audio(sound);
        audio.play();
        // eslint-disable-next-line no-empty
    } catch (e) { }
};

export const playSoundPhoneVibration = () => playSound(phoneVibration);
export const playSoundSuccess = () => playSound(success);
export const playSoundClick = () => playSound(click);
export const playSoundSelection = () => playSound(selection);

