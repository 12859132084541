export default {
  common: {
    BUTTON_NEXT: 'common:BUTTON_NEXT',
    BUTTON_CANCEL: 'common:BUTTON_CANCEL',
    BUTTON_OK: 'common:BUTTON_OK',
    BUTTON_REVIEW: 'common:BUTTON_REVIEW',
    BUTTON_BACK: 'common:BUTTON_BACK',
    MONTHS: 'common:MONTHS',
    FREQUENCY: 'common:FREQUENCY',
    SELECT_OPTION: 'common:SELECT_OPTION',
  },
  handleErrors: {
    HEADER_MESSAGE_CANNOT_PROCEED: 'handleErrors:HEADER_MESSAGE_CANNOT_PROCEED',
    HEADER_MESSAGE_SOMETHING_WRONG: 'handleErrors:HEADER_MESSAGE_SOMETHING_WRONG',
    CONTENT_MESSAGE_NO_INVITATION: 'handleErrors:CONTENT_MESSAGE_NO_INVITATION',
    CONTENT_MESSAGE_TECHNICAL_DIFFICULTY: 'handleErrors:CONTENT_MESSAGE_TECHNICAL_DIFFICULTY',
    CONTENT_MESSAGE_CONTACT_CENTRE: 'handleErrors:CONTENT_MESSAGE_CONTACT_CENTRE',
    CONTENT_MESSAGE_UNDER18: 'handleErrors:CONTENT_MESSAGE_UNDER18',
    EXPRESS_BUTTON: 'handleErrors:EXPRESS_BUTTON',
    CHANGE_PHONE_NUMBER_BUTTON: 'handleErrors:CHANGE_PHONE_NUMBER_BUTTON',
    OTP_LIMIT_HEADER: 'handleErrors:OTP_LIMIT_HEADER',
    OTP_LIMIT_TOP: 'handleErrors:OTP_LIMIT_TOP',
    OTP_LIMIT_BOTTOM: 'handleErrors:OTP_LIMIT_BOTTOM',
    OTP_CANNOT_VERIFY_HEADER: 'handleErrors:OTP_CANNOT_VERIFY_HEADER',
    OTP_CANNOT_VERIFY_TOP: 'handleErrors:OTP_CANNOT_VERIFY_TOP',
    OTP_EXPIRED_HEADER: 'handleErrors:OTP_EXPIRED_HEADER',
    OTP_EXPIRED_TOP: 'handleErrors:OTP_EXPIRED_TOP',
    OTP_EXPIRED_BOTTOM: 'handleErrors:OTP_EXPIRED_BOTTOM',
    CONNECTIVITY_ERROR_BOTTOM: 'handleErrors:CONNECTIVITY_ERROR_BOTTOM',
    HEADER_EXISTING_TYMEBANK_PROFILE: 'handleErrors:HEADER_EXISTING_TYMEBANK_PROFILE',
    EXISTING_TYMEBANK_PROFILE_BOTTOM: 'handleErrors:EXISTING_TYMEBANK_PROFILE_BOTTOM',
    EXISTING_TYMEBANK_PROFILE: 'handleErrors:EXISTING_TYMEBANK_PROFILE',
    HEADER_AGE_MUST_BE_18: 'handleErrors:HEADER_AGE_MUST_BE_18',
    AGE_MUST_BE_18: 'handleErrors:AGE_MUST_BE_18',
    SAFPS_FAILED: 'handleErrors:SAFPS_FAILED',
    IDN_BLOCK: 'handleErrors:IDN_BLOCK',
    ASSISTANCE_MESSAGE: 'handleErrors:ASSISTANCE_MESSAGE',
    HEADER_CELLPHONE_EXIST: 'handleErrors:HEADER_CELLPHONE_EXIST',
    CELLPHONE_EXIST: 'handleErrors:CELLPHONE_EXIST',
    CELLPHONE_EXIST_SUPPORT: 'handleErrors:CELLPHONE_EXIST_SUPPORT',
    LOAN_IN_PROGRESS: 'handleErrors:LOAN_IN_PROGRESS',
    HEADER_HAS_PROBLEM: 'handleErrors:HEADER_HAS_PROBLEM',
    HAS_PROBLEM: 'handleErrors:HAS_PROBLEM',
    HEADER_APPLY_REACH_LIMIT: 'handleErrors:HEADER_APPLY_REACH_LIMIT',
    APPLY_REACH_LIMIT: 'handleErrors:APPLY_REACH_LIMIT',
    EXPIRED_INVITATION_HEADER: 'handleErrors:EXPIRED_INVITATION_HEADER',
    EXPIRED_INVITATION_CONTENT: 'handleErrors:EXPIRED_INVITATION_CONTENT',
    TRUID_ADDRESS_CONTENT: 'handleErrors:TRUID_ADDRESS_CONTENT',
  },
  landingPage: {
    APPLY_LOAN_HEADER: 'landingPage:APPLY_LOAN_HEADER',
    WHAT_IS_PERSONAL_LOAN: 'landingPage:WHAT_IS_PERSONAL_LOAN',
    LOAN_HEADER_SUB: 'landingPage:LOAN_HEADER_SUB',
    PERSONAL_LOAN_DESCRIPTION: 'landingPage:PERSONAL_LOAN_DESCRIPTION',
    COST_AND_FEES: 'landingPage:COST_AND_FEES',
    INTEREST_RATE: 'landingPage:INTEREST_RATE',
    ESTABLISHMENT_FEE: 'landingPage:ESTABLISHMENT_FEE',
    LOAN_SERVICE_FEE: 'landingPage:LOAN_SERVICE_FEE',
    BEFORE_STARTED: 'landingPage:BEFORE_STARTED',
    PLEASE_HAVE_DOCUMENT: 'landingPage:PLEASE_HAVE_DOCUMENT',
    BANK_STATEMENT_OR_PAYSLIP: 'landingPage:BANK_STATEMENT_OR_PAYSLIP',
    MARRIAGE_CERTIFICATE: 'landingPage:MARRIAGE_CERTIFICATE',
    BUTTON_START_NOW: 'landingPage:BUTTON_START_NOW',
    BUTTON_BACK: 'landingPage:BUTTON_BACK',
  },
  saidPage: {
    PAGE_TITLE: 'saidPage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'saidPage:PAGE_SUB_HEADING',
    SAID_LABEL: 'saidPage:SAID_LABEL',
    SAID_EMPTY: 'saidPage:SAID_EMPTY',
    SAID_INVALID: 'saidPage:SAID_INVALID',
    SAID_INVALID_18_AGE: 'saidPage:SAID_INVALID_18_AGE',
    SAID_POLICY: 'saidPage:SAID_POLICY',
  },
  phonePage: {
    PAGE_TITLE: 'phonePage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'phonePage:PAGE_SUB_HEADING',
    PHONE_LABEL: 'phonePage:PHONE_LABEL',
    PHONE_EMPTY: 'phonePage:PHONE_EMPTY',
    PHONE_INVALID: 'phonePage:PHONE_INVALID',
  },
  otpPage: {
    PAGE_TITLE: 'otpPage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'otpPage:PAGE_SUB_HEADING',

    ENTER_OTP: 'otpPage:ENTER_OTP',
    DID_NOT_RECEIVE: 'otpPage:DID_NOT_RECEIVE',
    RESEND_IN_SECOND: 'otpPage:RESEND_IN_SECOND',
    RESEND: 'otpPage:RESEND',
  },
  loanInfoPage: {
    PAGE_TITLE: 'loanInfoPage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'loanInfoPage:PAGE_SUB_HEADING',

    LOAN_LABEL: 'loanInfoPage:LOAN_LABEL',
    LOAN_TIP: 'loanInfoPage:LOAN_TIP',
    LOAN_EMPTY: 'loanInfoPage:LOAN_EMPTY',
    LOAN_OVER_RANGE: 'loanInfoPage:LOAN_OVER_RANGE',
  },
  loanPurposePage: {
    PAGE_TITLE: 'loanPurposePage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'loanPurposePage:PAGE_SUB_HEADING',
    LOAN_PURPOSE_OPTIONS: 'loanPurposePage:LOAN_PURPOSE_OPTIONS',
  },
  // revamp ux
  userInfoPage: {
    PAGE_TITLE: 'userInfoPage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'userInfoPage:PAGE_SUB_HEADING',
  },

  // financial page
  financeInformationPage: {
    PAGE_TITLE: 'financeInformationPage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'financeInformationPage:PAGE_SUB_HEADING',
  },

  financeInformation: {
    PAGE_TITLE: 'financeInformation:PAGE_TITLE',
    PAGE_SUB_HEADING: 'financeInformation:PAGE_SUB_HEADING',

    WHAT_IS_TOTAL: 'financeInformation:WHAT_IS_TOTAL',
    THIS_INCLUDES_ALL: 'financeInformation:THIS_INCLUDES_ALL',
    DEDUCTION: 'financeInformation:DEDUCTION',
    INCLUDES_ALL_STATUTORY: 'financeInformation:INCLUDES_ALL_STATUTORY',
    DEBT_COMMITMENTS: 'financeInformation:DEBT_COMMITMENTS',
    SUCH_AS_REPAYMENTS: 'financeInformation:SUCH_AS_REPAYMENTS',
    ARE_YOU_RECEIVE: 'financeInformation:ARE_YOU_RECEIVE',
    LIVING_EXPENSES: 'financeInformation:LIVING_EXPENSES',
    SUCH_AS_FOOD: 'financeInformation:SUCH_AS_FOOD',
    YOUR_AVAILABLE_BALANCE: 'financeInformation:YOUR_AVAILABLE_BALANCE',
    MARITAL_TYPE_TITLE: 'financeInformation:MARITAL_TYPE_TITLE',
    SPOUSE_CONSENT_TITLE: 'financeInformation:SPOUSE_CONSENT_TITLE',
    ERROR_INCOME: 'financeInformation:ERROR_INCOME',
    ERROR_BALANCE: 'financeInformation:ERROR_BALANCE',
    HOW_LONG_DID_YOU_WORK: 'financeInformation:HOW_LONG_DID_YOU_WORK',
  },

  // additional-page
  additionalInfoPage: {
    PAGE_TITLE: 'additionalInfoPage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'additionalInfoPage:PAGE_SUB_HEADING',
  },
  additionalInfo: {
    PAGE_TITLE: 'additionalInfo:PAGE_TITLE',
    PAGE_SUB_HEADING: 'additionalInfo:PAGE_SUB_HEADING',

    FREQUENCY_TITLE: 'additionalInfo:FREQUENCY_TITLE',
    NEXTPAYDATE_TITLE: 'additionalInfo:NEXTPAYDATE_TITLE',
    EMERGENCY_TITLE: 'additionalInfo:EMERGENCY_TITLE',
    EMERGENCY_INFO: 'additionalInfo:EMERGENCY_INFO',
    EMERGENCY_NAME: 'additionalInfo:EMERGENCY_NAME',
    EMERGENCY_CELLPHONE: 'additionalInfo:EMERGENCY_CELLPHONE',
    EMERGENCY_CELLPHONE_ERROR: 'additionalInfo:EMERGENCY_CELLPHONE_ERROR',
  },

  confirmationPage: {
    PAGE_TITLE: 'confirmationPage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'confirmationPage:PAGE_SUB_HEADING',
    BUTTON_EDIT: 'confirmationPage:BUTTON_EDIT',
    WHAT_YOU_LIKE_TO_BORROW: 'confirmationPage:WHAT_YOU_LIKE_TO_BORROW',
    ABOUT_YOUR_SELF: 'confirmationPage:ABOUT_YOUR_SELF',
    ABOUT_FINANCE: 'confirmationPage:ABOUT_FINANCE',
    ABOUT_MORE: 'confirmationPage:ABOUT_MORE',
    AGREE_PERMISSION: 'confirmationPage:AGREE_PERMISSION',
    WILLING_PAY_EXTRA: 'confirmationPage:WILLING_PAY_EXTRA',
    THIRD_PARTY_PERMISSION: 'confirmationPage:THIRD_PARTY_PERMISSION',

    LOAN_PURPOSE_TITLE: 'confirmationPage:LOAN_PURPOSE_TITLE',
    LOAN_AMOUNT_TITLE: 'confirmationPage:LOAN_AMOUNT_TITLE',
    EMAIL_TITLE: 'confirmationPage:EMAIL_TITLE',

    EMPLOYMENT_STATUS: 'confirmationPage:EMPLOYMENT_STATUS',
    RACE: 'confirmationPage:RACE',
    MARITAL_STATUS: 'confirmationPage:MARITAL_STATUS',
    MARITAL_TYPE: 'confirmationPage:MARITAL_TYPE',
    SPOUSE_CONSENT: 'confirmationPage:SPOUSE_CONSENT',

    INCOME: 'confirmationPage:INCOME',
    DEDUCTION: 'confirmationPage:DEDUCTION',
    DEBT: 'confirmationPage:DEBT',
    IS_DEBT_COMMITMENT: 'confirmationPage:IS_DEBT_COMMITMENT',
    LIVING_EXPENSES: 'confirmationPage:LIVING_EXPENSES',

    WORK_FOR: 'confirmationPage:WORK_FOR',
    WORK_START: 'confirmationPage:WORK_START',
    HOW_PAID: 'confirmationPage:HOW_PAID',
    DAY_PAID: 'confirmationPage:DAY_PAID',
    CONTACT: 'confirmationPage:CONTACT',
  },

  offersPage: {
    PAGE_TITLE: 'offersPage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'offersPage:PAGE_SUB_HEADING',
  },

  limitOffersPage: {
    PAGE_TITLE: 'limitOffersPage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'limitOffersPage:PAGE_SUB_HEADING',
  },

  lowerOffersPage: {
    PAGE_TITLE: 'lowerOffersPage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'lowerOffersPage:PAGE_SUB_HEADING',
  },

  introTruIDPage: {
    PAGE_TITLE: 'introTruIDPage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'introTruIDPage:PAGE_SUB_HEADING',
    PAGE_NOTE: 'introTruIDPage:PAGE_NOTE',
  },

  introSprintHivePage: {
    PAGE_TITLE: 'introSprintHivePage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'introSprintHivePage:PAGE_SUB_HEADING',
  },

  truidPage: {
    PAGE_TITLE: 'truidPage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'truidPage:PAGE_SUB_HEADING',
  },

  packagePage: {
    PAGE_TITLE: 'packagePage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'packagePage:PAGE_SUB_HEADING',
  },

  agreementPage: {
    PAGE_TITLE: 'agreementPage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'agreementPage:PAGE_SUB_HEADING',
  },

  introSelfiePage: {
    PAGE_TITLE: 'introSelfiePage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'introSelfiePage:PAGE_SUB_HEADING',
  },

  selfiePage: {
    PAGE_TITLE: 'selfiePage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'selfiePage:PAGE_SUB_HEADING',
  },

  debicheckPage: {
    PAGE_TITLE: 'debicheckPage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'debicheckPage:PAGE_SUB_HEADING',
  },

  fundedPage: {
    PAGE_TITLE: 'fundedPage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'fundedPage:PAGE_SUB_HEADING',
  },

  offersDeclinedPage: {
    PAGE_TITLE: 'offersDeclinedPage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'offersDeclinedPage:PAGE_SUB_HEADING',
    REF_NUMBER_LABEL: 'offersDeclinedPage:REF_NUMBER_LABEL',
    ANY_QUESTION_LABEL: 'offersDeclinedPage:ANY_QUESTION_LABEL',
    WHAT_CAN_DO_LABEL: 'offersDeclinedPage:WHAT_CAN_DO_LABEL',
    LEARN_MORE_TEXT: 'offersDeclinedPage:LEARN_MORE_TEXT',
    VISIT: 'offersDeclinedPage:VISIT',
    TODAY: 'offersDeclinedPage:TODAY',
    OFFERS_DESCRIPTION: 'offersDeclinedPage:OFFERS_DESCRIPTION',
    LEVEL_USER_DESCRIPTION: 'offersDeclinedPage:LEVEL_USER_DESCRIPTION',
    LOAN_AMOUNT: 'offersDeclinedPage:LOAN_AMOUNT',
    WHAT_NEXT: 'offersDeclinedPage:WHAT_NEXT',
    NEXT: 'offersDeclinedPage:NEXT',
    SEND_STATEMENTS: 'offersDeclinedPage:SEND_STATEMENTS',
    SEND_STATEMENTS_DESCRIPTION: 'offersDeclinedPage:SEND_STATEMENTS_DESCRIPTION',
    WAITTING: 'offersDeclinedPage:WAITTING',
    WAITTING_DESCRIPTION: 'offersDeclinedPage:WAITTING_DESCRIPTION',
    GO_TO_KIOSK: 'offersDeclinedPage:GO_TO_KIOSK',
    GO_TO_KIOSK_DESCRIPTION: 'offersDeclinedPage:GO_TO_KIOSK_DESCRIPTION',
  },

  resumePage: {
    PAGE_TITLE: 'resumePage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'resumePage:PAGE_SUB_HEADING',
  },

  uploadPage: {
    PAGE_TITLE: 'uploadPage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'uploadPage:PAGE_SUB_HEADING',
  },
  finalPage: {
    PAGE_TITLE_SUCCESS: 'finalPage:PAGE_TITLE_SUCCESS',
    PAGE_SUB_HEADING_SUCCESS: 'finalPage:PAGE_SUB_HEADING_SUCCESS',
    PAGE_TITLE_FAILED: 'finalPage:PAGE_TITLE_FAILED',
    PAGE_SUB_HEADING_FAILED: 'finalPage:PAGE_SUB_HEADING_FAILED',
  },
  fullyApprovedPage: {
    PAGE_TITLE: 'fullyApprovedPage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'fullyApprovedPage:PAGE_SUB_HEADING',
  },
  methodsPage: {
    PAGE_TITLE: 'methodsPage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'methodsPage:PAGE_SUB_HEADING',
    DESCRIPTION_TIP: 'methodsPage:DESCRIPTION_TIP',
  },
  guidePage: {
    PAGE_TITLE: 'guidePage:PAGE_TITLE',
    PAGE_SUB_HEADING: 'guidePage:PAGE_SUB_HEADING',
    BANK_USING: 'guidePage:BANK_USING',
    BANK_LABEL: 'guidePage:BANK_LABEL',
    HOW_TO_TEXT: 'guidePage:HOW_TO_TEXT',
  },
  inputEmail: {
    FORM_TITLE: 'inputEmail:FORM_TITLE',
    FORM_LABEL_EMAIL: 'inputEmail:FORM_LABEL_EMAIL',
    FORM_LABEL_EMAIL_AGAIN: 'inputEmail:FORM_LABEL_EMAIL_AGAIN',
    FORM_ERROR: 'inputEmail:FORM_ERROR',
    FORM_RETRY_ERROR: 'inputEmail:FORM_RETRY_ERROR',
  },
  employmentStatus: {
    ARE_YOU_TITLE: 'employmentStatus:ARE_YOU_TITLE',
  },
  raceSelection: {
    ARE_YOU_TITLE: 'raceSelection:ARE_YOU_TITLE',
  },
  currentCompany: {
    COMPANY_FORM_TITLE: 'currentCompany:COMPANY_FORM_TITLE',
    COMPANY_FORM_LABEL: 'currentCompany:COMPANY_FORM_LABEL',
    WORKING_TIME_LABEL: 'currentCompany:WORKING_TIME_LABEL',
  },
};
