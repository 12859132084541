import React from 'react';

const CellPhoneIcon = () => (
  <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="16.0664" y="2.99805" width="33.3529" height="58.005" rx="4" fill="white" stroke="#231F20" />
    <path d="M16.0664 50.6445H49.4193V56.8519C49.4193 59.1443 47.561 61.0026 45.2687 61.0026H20.217C17.9247 61.0026 16.0664 59.1443 16.0664 56.8519V50.6445V50.6445Z" fill="#FFCC00" stroke="#231F20" />
    <path d="M32.7428 57.8952C33.894 57.8952 34.8273 56.9677 34.8273 55.8236C34.8273 54.6794 33.894 53.752 32.7428 53.752C31.5915 53.752 30.6582 54.6794 30.6582 55.8236C30.6582 56.9677 31.5915 57.8952 32.7428 57.8952Z" fill="white" stroke="#231F20" />
    <path d="M30.6582 8.17723H34.8273" stroke="#231F20" />
    <path d="M33.7462 20.437C38.9052 20.437 43.0879 24.6491 43.0879 29.8445C43.0879 35.0398 38.9052 39.252 33.7462 39.252C28.5871 39.252 24.4045 35.0398 24.4045 29.8445C24.4045 24.6491 28.5871 20.437 33.7462 20.437Z" fill="#FFCC00" stroke="#231F20" />
    <path d="M34.8262 30.9648H32.7416V25.7858H34.8262V30.9648Z" fill="#231F20" />
    <path d="M33.7324 34.0508L32.2584 32.5768L35.9205 28.9147L37.3945 30.3887L33.7324 34.0508Z" fill="#231F20" />
    <path d="M35.3105 32.5762L33.8365 34.0502L30.1744 30.3881L31.6484 28.9141L35.3105 32.5762Z" fill="#231F20" />
  </svg>

);

export default CellPhoneIcon;

export const CellPhoneMobileIcon = () => (
  <svg width="18" height="30" viewBox="0 0 18 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.78125" y="0.5" width="16.6764" height="29.0025" rx="2" fill="white" stroke="#231F20" strokeWidth="0.5" />
    <path d="M0.78125 24.3242H17.4577V27.4279C17.4577 28.5741 16.5285 29.5032 15.3824 29.5032H2.85656C1.7104 29.5032 0.78125 28.5741 0.78125 27.4279V24.3242Z" fill="#FFCC00" stroke="#231F20" strokeWidth="0.5" />
    <path d="M9.1204 27.9486C9.69604 27.9486 10.1627 27.4848 10.1627 26.9128C10.1627 26.3407 9.69604 25.877 9.1204 25.877C8.54477 25.877 8.07812 26.3407 8.07812 26.9128C8.07812 27.4848 8.54477 27.9486 9.1204 27.9486Z" fill="white" stroke="#231F20" strokeWidth="0.5" />
    <path d="M8.07812 3.08959H10.1627" stroke="#231F20" strokeWidth="0.5" />
    <path d="M9.62016 9.21752C12.1997 9.21752 14.291 11.3236 14.291 13.9213C14.291 16.5189 12.1997 18.625 9.62016 18.625C7.04064 18.625 4.9493 16.5189 4.9493 13.9213C4.9493 11.3236 7.04064 9.21752 9.62016 9.21752Z" fill="#FFCC00" stroke="#231F20" strokeWidth="0.5" />
    <path d="M10.1621 14.4824H9.11983V11.8929H10.1621V14.4824Z" fill="#231F20" />
    <path d="M9.61328 16.0254L8.87628 15.2884L10.7073 13.4573L11.4443 14.1943L9.61328 16.0254Z" fill="#231F20" />
    <path d="M10.4023 15.2891L9.66534 16.0261L7.83428 14.195L8.57128 13.458L10.4023 15.2891Z" fill="#231F20" />
  </svg>
);
