import React from 'react';
import HtmlParser from 'react-html-parser';

import { FlexRowCenter } from '../../components/Common/FlexCenter';
import { DocumentMailIcon } from '../../assets/images/elements/DocumentIcon';
import { PaymentsCheckIcon, PaymentsSuccessIcon } from '../../assets/images/elements/PaymentsIcon';

import AccountKeyIcon, { AccountKeyMobileIcon } from '../../assets/images/elements/AccountKeyIcon';
import CellPhoneIcon, { CellPhoneMobileIcon } from '../../assets/images/elements/CellPhoneIcon';
import SavingGoalIcon, { SavingGoalMobileIcon } from '../../assets/images/elements/SavingGoalIcon';

const confirmIcon = [<DocumentMailIcon />, <PaymentsCheckIcon />, <PaymentsSuccessIcon />];

export const confirmStepData = (listData = []) => listData.map((content, index) => ({
  icon: <FlexRowCenter width="64px" height="64px">{confirmIcon[index]}</FlexRowCenter>,
  iconMobile: <FlexRowCenter width="60px" height="60px">{confirmIcon[index]}</FlexRowCenter>,
  content: HtmlParser(content),
}));

export const debicheckStepData = [
  {
    icon: <AccountKeyIcon />,
    iconMobile: <AccountKeyMobileIcon />,
    title: 'Activate your TymeBank account',
    content: 'We’ll send you an email with instructions on how to activate your account and manage your loan.',
  },
  {
    icon: <CellPhoneIcon />,
    iconMobile: <CellPhoneMobileIcon />,
    title: 'Download the TymeBank app',
    content: 'Get the app from Google Play Store, The App Store, or HUAWEI AppGallery.',
  },
  {
    icon: <SavingGoalIcon />,
    iconMobile: <SavingGoalMobileIcon />,
    title: 'Manage your loan on the app',
    content: 'Keep track of your repayments on the TymeBank app so you always know what to expect.',
  },
];
