import { WrapSegmentBusUtils } from '../hooks/useSegmentEventBus';

export class SegmentBusTrackTruID {
    // intro-truid
    static segment1500BankVerificationGetStartedData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment1500BankVerificationGetStartedData');
    }

    static segment1500IntroSprinthiveDisplayedData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment1500IntroSprinthiveDisplayedData');
    }

    static segment1500TruidWebviewDisplayedData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment1500TruidWebviewDisplayedData');
    }

    static segment1500MissingAddressPopupData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment1500MissingAddressPopupData');
    }

    static segment1500MissingAddressInputData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment1500MissingAddressInputData');
    }

    static segment1500MissingAddressConfirmData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment1500MissingAddressConfirmData');
    }

    static segment1500MissingSalaryPopupData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment1500MissingSalaryPopupData');
    }
}
