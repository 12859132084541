// 0. bank-verification-get-started
export const segment1500BankVerificationGetStartedData = {
    type: 'track',
    event: 'lndPln bank verification scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/bank-verification/path-happy/bank-verification-get-started/',
        ui_screen_state: 'informational',
        flow_conversion: 'no',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};

// 1. intro-sprinthive-displayed
export const segment1500IntroSprinthiveDisplayedData = {
    type: 'track',
    event: 'lndPln verification process scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/verification-process/path-happy/intro-sprinthive-displayed/',
        ui_screen_state: 'functional',
        flow_conversion: 'no',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};

// 2. truid-webview-displayed
export const segment1500TruidWebviewDisplayedData = {
    type: 'track',
    event: 'lndPln verification process scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/verification-process/path-happy/truid-webview-displayed/',
        ui_screen_state: 'functional',
        flow_conversion: 'no',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};

// more
// 5. missing-address-popup
export const segment1500MissingAddressPopupData = {
    type: 'track',
    event: 'lndPln verification process scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/verification-result/path-error-conditional/missing-address-popup/',
        ui_screen_state: 'informational',
        flow_conversion: 'no',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};

// 5.1 missing-address-input
export const segment1500MissingAddressInputData = {
    type: 'track',
    event: 'lndPln verification process scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/verification-result/path-happy/missing-address-input/',
        ui_screen_state: 'functional',
        flow_conversion: 'no',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};

// 5.2 missing-address-confirm
export const segment1500MissingAddressConfirmData = {
    type: 'track',
    event: 'lndPln verification process scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/verification-result/path-happy/missing-address-confirm/',
        ui_screen_state: 'modal-alert',
        flow_conversion: 'no',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};

// 6. missing-salary-popup
export const segment1500MissingSalaryPopupData = {
    type: 'track',
    event: 'lndPln verification process scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/verification-result/path-error-conditional/missing-salary-popup/',
        ui_screen_state: 'informational',
        flow_conversion: 'no',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};
