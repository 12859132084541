import React from 'react';
import joinclass from 'joinclass';

import { MovingFadeIn } from './AnimateText';
import XWrapMoney from './XWrapMoney';

function XWrapLabelMoneyTooltip({
    title,
    children,
    subFunMoney,
    classNameMoney,
    duration = '0.25s',
    disabledFormat = false,
    borderBottom = true,
}) {
    return (
        <div className={joinclass('label-money-item d-flex flex-row justify-content-between', borderBottom && 'border-bottom')}>
            <div className="d-flex flex-column font-size-14 font-size-md-16 item-label col-8 p-0 font-weight-300">
                {title}
            </div>

            <MovingFadeIn className="d-flex flex-column gap-4 item-value text-right" duration={duration} key={children}>
                <XWrapMoney disabledFormat={disabledFormat} light className={classNameMoney}>
                    {children}
                </XWrapMoney>
                {subFunMoney && <div className="font-size-12 font-size-md-14 sub-label">{subFunMoney()}</div>}
            </MovingFadeIn>
        </div>
    );
}

export default XWrapLabelMoneyTooltip;
