import React, { useRef, useState } from 'react';
import { Tooltip } from 'reactstrap';
import { detectmob } from '../../utils/detectmob';

import { scrollElementSmooth } from '../../utils/commonUtils';
import useEventListener from '../../hooks/useEventListener';

import InfomationIcon from '../../assets/images/elements/InfomationIcon';

const PLTooltip = ({
  target,
  placement = 'bottom',
  children,
  className,
  handleClick = () => { },
  scrollWhenOpen,
  ...props
}) => {
  const [open, setOpen] = useState(false);
  const refs = useRef();
  const isMobile = detectmob();
  useEventListener('keydown', e => {
    if (e.keyCode === 9 || ['Esc', 'Escape', 27, 'Enter'].includes(e.key || e.keyCode)) {
      setOpen(false);
    }
  });

  return (
    <Tooltip
      placement={placement}
      target={target}
      className={className}
      autohide
      trigger="click"
      isOpen={open}
      toggle={e => {
        if (Boolean(refs.current) && refs.current.contains(e.target)) {
          return;
        }

        setOpen(!open);
        handleClick(!open);
        if (scrollWhenOpen && !open) {
          setTimeout(() => scrollElementSmooth(refs.current, 'center'), 1);
        }
      }}
      {...props}
    >
      <div ref={refs} onMouseLeave={() => !isMobile && setOpen(false)}> {children}</div>
    </Tooltip >
  );
};
export default PLTooltip;

export const PLTooltipInfomationIcon = ({
  id,
  children,
  placement = 'bottom',
  handleClick,
  scrollWhenOpen,
}) => {
  const tooltip = useRef();
  return (
    <React.Fragment>
      <span className="ml-2 d-line user-select-none">
        <InfomationIcon
          id={id}
          getInputRef={e => {
            tooltip.current = e;
          }}
        />
      </span>
      <PLTooltip
        target={tooltip}
        className="landing-tooltip"
        scrollWhenOpen={scrollWhenOpen}
        placement={placement}
        handleClick={handleClick}
      >
        {children}
      </PLTooltip>
    </React.Fragment>
  );
};
