/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import { DecisioningResult, UpsellingResultStatus } from '../constants';
import { useCommonContext } from '../context/CommonContext';
import { useModalPopupContext } from '../context/ModalPopupContext';

import { fetchDecisionResults, fetchDecisionUpsellResults, hideOverlayLoading, saveStepsInput, updateOfferList } from '../actions';
import { calculatorSlider, execShowMissingFormWhenDeclineCode, matchingSlider } from '../utils/commonUtils';
import { removeComma } from '../utils/format';

import useEventBus, { ScreenBusUtils, UpsellBusUtils } from '../hooks/useEventBus';
import { isOverlayLoading, isScreenBackgroundLoading } from '../hooks/useIsLoading';
import { useConfirmBeforeBackBrowser } from '../hooks/useBackBrowser';

// components
import { XWrapLabelSmallSkeleton, XWrapMoneyLargeSkeleton } from '../components/Common/LoadingSkeletons';
import AsyncButton from '../components/Common/AsyncButton';
import { FlexColumnCenter, FlexRowBetween } from '../components/Common/FlexCenter';
import PLSlider from '../components/Common/PLSlider';
import XWrapMoney from '../components/Common/XWrapMoney';
import { LabelLoanApplication } from '../components/Common/LabelLoanApplication';
import { MovingFadeIn } from '../components/Common/AnimateText';
import HelpTip from '../components/Common/HelpTip';

import SelectionOfferItem from '../components/Package/SelectionOfferItem';
import MinMaxUpsell from '../components/Package/MinMaxUpsell';
import useHandleModalOffer, { UPSELL_ERROR_CODE, UPSELL_TRACKING_MESSAGE } from '../components/Package/useHandleModalOffer';

// upsell change
import { useChangeUpsell, useConfirmUpsell } from '../components/Package/useChangeUpsell';
import { SegmentBusTrackUpsell } from '../segment-bus/SegmentBusTrackUpsell';

// we need to optimize later
function OffersUpsell({
    data,
    ui,
    loanSubmission,
    verifyLoanApplicationData,
    stepsInputData,
    stepUpToken,
    // eslint-disable-next-line no-shadow
    saveStepsInput,
    ...props
}) {
    const { declineSpecialCode } = useCommonContext();
    const { hideModal } = useModalPopupContext();

    const screenLoading = isOverlayLoading(ui);
    const backgroundLoading = isScreenBackgroundLoading();

    const [offers, setOffers] = useState([]);
    const [stepConfig, setStepConfig] = useState({});
    const [listCacheUpsell, setListCacheUpsell] = useState([]);

    const { upsellValues = {}, userInfo = {} } = stepsInputData;
    const resumeNumber = _.get(verifyLoanApplicationData, 'loanApplicationNumber');
    const isEFProfile = _.get(verifyLoanApplicationData, 'isEFProfile') || userInfo.isEFProfile;

    const loanApplicationNumber = _.get(loanSubmission, 'loanApplicationNumber') || resumeNumber;
    const loanStatus = _.get(verifyLoanApplicationData, 'loanApplicationStatus');

    const expectedAmount = _.get(data, 'expectedAmount');

    const realOfferData = data || _.get(data, 'state');
    const incomeVerificationExpired = _.get(realOfferData, 'incomeVerificationExpired');
    const dateExpired = moment(new Date(incomeVerificationExpired)).format('DD/MM/YYYY');

    const {
        onPost: reCalculator, data: calculatorStatus, errors: calculatorErrors, loading: calculatorLoading,
    } = useChangeUpsell({
        said: stepsInputData.said,
        stepUpToken,
        loanApplicationNumber: resumeNumber || loanApplicationNumber,
        expectedLoanAmount: stepConfig.upsellSelectionVal,
    });

    const {
        onPost: confirmUpsell, data: confimStatus, errors: confirmErrors, loading: confirmLoading,
    } = useConfirmUpsell({
        said: stepsInputData.said,
        stepUpToken,
        loanApplicationNumber: resumeNumber || loanApplicationNumber,
        expectedLoanAmount: stepConfig.defaultVal,
    });

    const isPoolingError = data.errorUpsell || data.timeoutUpsell;

    // optimize later
    const isLoading = calculatorLoading || confirmLoading || isPoolingError || backgroundLoading;
    const displayOffers = (screenLoading || isLoading) ? [{ tenor: 1 }, { tenor: 2 }, { tenor: 3 }] : offers;

    useEventBus(UpsellBusUtils.EventBusName.HANDLE_OK, () => {
        hideModal();

        if (listCacheUpsell.length > 0) {
            props.updateOfferList({ ...listCacheUpsell.slice(-1) });
            setStepConfig({
                ...stepConfig,
                ...stepConfig.previousValues,
            });
            props.hideOverlayLoading();
            ScreenBusUtils.hideLazyLoadingInBackground(200);
            ScreenBusUtils.hideLoadingLazy();
            return;
        }

        if (isLoading) {
            refreshUpsell();
        }
    });

    useConfirmBeforeBackBrowser({
        dateExpired,
        handleOK: UpsellBusUtils.handleOKBackBrowser,
        handlCancel: () => {
            SegmentBusTrackUpsell.segment1800UpsellExitData({
                dev_input_data_captured: 'Upsell: Stop and go home',
            });
            ScreenBusUtils.goHome(300);
        },
    });

    useHandleModalOffer({
        offer: data,
        calculatorErrors,
        confirmError: confirmErrors,
        handleRetry: () => {
            hideModal();

            if (calculatorErrors) {
                SegmentBusTrackUpsell.segment1800UpsellTryAgainData({
                    dev_input_data_captured: 'Upsell: Calculator try again',
                });

                handleCalculator();
                return;
            }

            if (confirmErrors) {
                SegmentBusTrackUpsell.segment1800UpsellTryAgainData({
                    dev_input_data_captured: 'Upsell: Confirm try again',
                });

                handleConfirm();
                return;
            }

            refreshUpsell();
        },
        handleBack: () => {
            hideModal();
            ScreenBusUtils.hideLazyLoadingInBackground(200);
            props.hideOverlayLoading();
            props.updateOfferList({ ...listCacheUpsell.slice(-1) });
            setStepConfig({
                ...stepConfig,
                ...stepConfig.previousValues,
            });

            if (calculatorErrors) {
                SegmentBusTrackUpsell.segment1800UpsellErrorData({
                    dev_error_code: UPSELL_ERROR_CODE.ERROR_CALCULATION,
                    dev_error_description: UPSELL_TRACKING_MESSAGE.ERROR_PROCCESS,
                });
                return;
            }

            if (confirmErrors) {
                SegmentBusTrackUpsell.segment1800UpsellErrorData({
                    dev_error_code: UPSELL_ERROR_CODE.ERROR_CONFIRM,
                    dev_error_description: UPSELL_TRACKING_MESSAGE.ERROR_PROCCESS,
                });
                return;
            }

            // default
            SegmentBusTrackUpsell.segment1800UpsellErrorLoadFailData();
        },
    });

    useEffect(() => {
        const offerList = _.get(data, 'offerList') || _.get(data, 'state.offerList') || [];
        if (resumeNumber || offerList.length === 0) {
            setTimeout(() => props.fetchDecisionResults({ loanApplicationNumber, offerUpsell: true }));
        }

        SegmentBusTrackUpsell.segment1800UpsellOfferDisplayedData();
    }, []);

    useEffect(() => {
        if (_.isNil(stepConfig.upMax) && upsellValues.maxValueSldier) {
            // eslint-disable-next-line no-shadow
            const expectedAmount = Number(removeComma(upsellValues.minValueSlider));
            const finalApprovedAmount = Number(removeComma(upsellValues.maxValueSldier));
            setSliderValues({
                expectedAmount,
                finalApprovedAmount,
                defaultVal: finalApprovedAmount,
            });
        }
    }, [upsellValues]);

    useEffect(() => {
        if (resumeNumber && loanStatus === DecisioningResult.loanApplicationStatus.CONDITIONALLY_APPROVED_COLLECTION_RECEIVED) {
            ScreenBusUtils.setMessageLoading('Please wait while we resume your application. This might take a couple of minutes.');
        }
    }, [loanStatus]);

    useEffect(() => {
        if (calculatorStatus === 202) {
            ScreenBusUtils.showLazyLoadingInBackground(0);
            props.fetchDecisionUpsellResults({
                loanApplicationNumber: resumeNumber || loanApplicationNumber,
                isUpselling: true,
            });
        }
    }, [calculatorStatus]);

    useEffect(() => {
        if (confimStatus === 204) {
            props.updateOfferList({ ...data, upsellStatus: UpsellingResultStatus.NO.CONFIRMED });
            execShowMissingFormWhenDeclineCode(declineSpecialCode)
                .then(result => {
                    ScreenBusUtils.hideLoadingInBackground();
                    if (result) {
                        ScreenBusUtils.clearDeclineStatus();
                        return;
                    }

                    isEFProfile ?
                        ScreenBusUtils.gotoScreenConditionSalaryOffer() :
                        ScreenBusUtils.gotoScreenIntroTruID();
                });
        }
    }, [confimStatus]);

    useEffect(() => {
        if (!data.loanApplicationStatus) {
            return;
        }

        // optimize source later
        ScreenBusUtils.hideLazyLoadingInBackground(500);

        if (!UpsellingResultStatus.YES[data.upsellStatus]) {
            setTimeout(() => ScreenBusUtils.gotoScreenOffers());
            return;
        }

        const offerList = _.get(data, 'offerList') || _.get(data, 'state.offerList');
        setOffers(offerList);

        const finalApprovedAmount = Number(removeComma(_.get(offerList[0], 'finalApprovedAmount')));
        if (finalApprovedAmount) {
            storedUpsell(data, finalApprovedAmount);
        }

        const expNumber = Number(removeComma(expectedAmount));
        if (expNumber) {
            if (_.isNil(stepConfig.maxValueSldier)) {
                setSliderValues({
                    expectedAmount: expNumber,
                    finalApprovedAmount,
                    defaultVal: finalApprovedAmount,
                });
                return;
            }

            setStepConfig({
                ...stepConfig,
                defaultVal: finalApprovedAmount,
                previousValues: {
                    currentValueSlider: stepConfig.currentValueSlider,
                    upsellSelectionVal: stepConfig.upsellSelectionVal,
                },
            });
        }
    }, [_.get(data, 'offerList'), _.get(data, 'state.offerList')]);

    function handleCalculator(tracking) {
        if (!calculatorErrors && (screenLoading || isLoading)) {
            return;
        }

        saveStepsInput({
            trackingTerm: {
                terms: undefined,
                amount: stepConfig.upsellSelectionVal,
            },
        });

        const backupUpsell = listCacheUpsell.find(item => item.expNumber === stepConfig.upsellSelectionVal);
        if (
            isPoolingError ||
            !backupUpsell
        ) {
            reCalculator(true);
        } else {
            props.updateOfferList({ ...backupUpsell });
            storedUpsell(backupUpsell, stepConfig.upsellSelectionVal);
            ScreenBusUtils.hideLazyLoadingInBackground(2000);
        }

        ScreenBusUtils.showLazyLoadingInBackground(0);
        if (tracking) {
            SegmentBusTrackUpsell.segment1800UpsellChooseOtherOfferData();
        }
    }

    function handleConfirm(tracking) {
        if (!confirmErrors && (screenLoading || isLoading)) {
            return;
        }

        saveStepsInput({
            trackingTerm: {
                terms: undefined,
                amount: stepConfig.defaultVal,
            },
        });

        confirmUpsell(true);
        ScreenBusUtils.showLazyLoadingInBackground();
        if (tracking) {
            SegmentBusTrackUpsell.segment1800UpsellConfirmUpsellOfferData({
                loan_amount_expected: stepConfig.upMin,
                loan_amount_final: stepConfig.defaultVal,
            });
        }
    }

    function setSliderValues({
        // eslint-disable-next-line no-shadow
        expectedAmount,
        finalApprovedAmount,
        defaultVal,
    }) {
        const step = calculatorSlider(expectedAmount, finalApprovedAmount);
        setStepConfig({
            minValueSlider: 1,
            maxValueSldier: step,
            currentValueSlider: step,

            upMin: expectedAmount, // loanAmountExpected
            upMax: finalApprovedAmount,
            upsellSelectionVal: finalApprovedAmount,

            defaultVal, // default value
            previousValues: {
                currentValueSlider: step,
                upsellSelectionVal: finalApprovedAmount,
            },
        });
    }

    function handleChangeUpsell(value) {
        if (!stepConfig.maxValueSldier) {
            return;
        }

        const matchValue = matchingSlider({
            ...stepConfig,
            sliderStep: value,
            sliderSize: stepConfig.maxValueSldier,
        });

        setStepConfig({
            ...stepConfig,
            upsellSelectionVal: matchValue,
            currentValueSlider: value,
        });
    }

    function storedUpsell(dataOffer, expNumber) {
        const now = new Date().getTime();
        const ignoreCached = listCacheUpsell.filter(item => ((now - item.cacheTime) / 60000) <= 30);
        const newCached = ignoreCached.filter(item => item.expNumber !== expNumber);
        setListCacheUpsell([...newCached, { ...dataOffer, expNumber, cacheTime: new Date().getTime() }]);
    }

    function refreshUpsell() {
        // we need optimize src.
        const offerUpsell = !data.loanApplicationStatus; // first loading
        const isUpselling = data.timeoutUpsell || data.errorUpsell; // upsell try gain
        props.fetchDecisionUpsellResults({ loanApplicationNumber, offerUpsell, isUpselling });
        ScreenBusUtils.showLazyLoadingInBackground();
        SegmentBusTrackUpsell.segment1800UpsellTryAgainData({
            dev_input_data_captured: 'Upsell: Pooling-data try again',
        });
    }

    return (
        <div id="offers">
            <div className="pl-container-highlight px-1">
                <div className="container d-flex flex-column">
                    <LabelLoanApplication refNumber={loanApplicationNumber} />

                    <FlexColumnCenter className="mb-3 gap-4 gap-md-12 text-center">
                        <h2 className="font-size-20 font-size-md-24 font-weight-medium mt-4">
                            Congratulations! You’re qualified to borrow more than your desired amount
                        </h2>

                        <p className="font-size-14 font-size-md-16 font-weight-medium mb-2">
                            Slide to choose a loan amount of your choice
                        </p>

                        <MovingFadeIn key={stepConfig.upsellSelectionVal}>
                            <XWrapMoney className="font-size-36 font-size-md-40 mb-0">
                                {stepConfig.upsellSelectionVal}
                            </XWrapMoney>
                        </MovingFadeIn>

                        <div className="col-sm-6 col-xs-12 px-0 mb-2">
                            <PLSlider
                                className="w-100"
                                min={stepConfig.minValueSlider}
                                max={stepConfig.maxValueSldier}
                                value={stepConfig.currentValueSlider}
                                step={1}
                                disabled={isLoading}
                                onChange={handleChangeUpsell}
                            />

                            <FlexRowBetween className="font-size-14 font-weight-500 sub-title mt-3 mt-md-4">
                                <MinMaxUpsell
                                    className="text-left"
                                    label="Min"
                                >
                                    {stepConfig.upMin}
                                </MinMaxUpsell>

                                <MinMaxUpsell
                                    className="text-right"
                                    label="Max"
                                >
                                    {stepConfig.upMax}
                                </MinMaxUpsell>
                            </FlexRowBetween>
                        </div>

                        <AsyncButton
                            primary={false}
                            className="mt-0"
                            light
                            onClick={() => handleCalculator(true)}
                        >
                            Calculate the repayment plan
                        </AsyncButton>
                    </FlexColumnCenter>
                </div>
            </div>

            <div className="pl-container-page container px-1 mb-4 mb-md-0">
                <FlexColumnCenter className="mb-3 gap-4 gap-md-12 text-center">
                    <h2 className="font-size-14 font-size-md-16 font-weight-medium mt-4">
                        Loan amount of your choice
                    </h2>

                    <XWrapMoneyLargeSkeleton>
                        <MovingFadeIn key={stepConfig.defaultVal} duration="0.1s">
                            <XWrapMoney className="font-size-36 font-size-md-40 mb-2">
                                {stepConfig.defaultVal}
                            </XWrapMoney>
                        </MovingFadeIn>
                    </XWrapMoneyLargeSkeleton>

                    <p className="col-sm-7 col-xs-12 font-size-14 font-size-md-16 font-weight-medium mb-4">
                        We’ll verify your affordability in the next step.
                        After that, you’ll be able to choose from the following repayment plans:
                    </p>
                </FlexColumnCenter>

                <div className="choices row px-3 px-md-0 justify-content-center mb-0 mb-md-2">
                    {displayOffers.map((offer) => (
                        <SelectionOfferItem
                            key={offer.id || offer.tenor}
                            offer={offer}
                            isLoading={isLoading}
                        />
                    ))}
                </div>

                <FlexColumnCenter className="container my-4">
                    <HelpTip className="mt-0 mt-md-3 mb-3">
                        <span className="mr-1">This conditional approval is valid until</span>
                        <XWrapLabelSmallSkeleton>
                            <span className="font-weight-500">{dateExpired}.</span>
                        </XWrapLabelSmallSkeleton>
                    </HelpTip>

                    <AsyncButton onClick={() => handleConfirm(true)}>
                        Confirm and continue
                    </AsyncButton>
                </FlexColumnCenter>
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    ui: state.ui,
    data: state.offers,
    loanSubmission: state.loanSubmission,
    verifyLoanApplicationData: state.verifyLoanApplicationData,
    stepsInputData: state.stepsInputData,
    stepUpToken: state.otp.stepUpToken,
});

export default connect(
    mapStateToProps,
    {
        fetchDecisionResults,
        fetchDecisionUpsellResults,
        hideOverlayLoading,
        saveStepsInput,
        updateOfferList,
    },
)(OffersUpsell);
