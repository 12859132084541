import React, { useEffect } from 'react';
import useEventBus, { EventBusName, ScreenBusUtils } from './useEventBus';
import { useModalPopupContext } from '../context/ModalPopupContext';

const useBackBrowser = (callback = () => { }, params) => {
    const onBackButtonEvent = (e) => {
        e.preventDefault();
        window.history.pushState(null, null, window.location.pathname);
        callback();
    };

    useEffect(() => {
        window.history.pushState(null, null, window.location.pathname);
        window.addEventListener('popstate', onBackButtonEvent);
        return () => {
            window.removeEventListener('popstate', onBackButtonEvent);
        };
    }, [params]);

    return onBackButtonEvent;
};

export default useBackBrowser;

export const useConfirmBeforeBackBrowser = ({
    dateExpired,
    handleOK,
    handlCancel,
    handleTracking = () => { },
}) => {
    const { showErrorModal, hideModal } = useModalPopupContext();

    const confirmScreen = (evtOk = handleOK, evtCancel = handlCancel) => {
        handleTracking();
        showErrorModal({
            showXClose: false,
            escOnClose: false,
            modalTitle: 'Sure you want to cancel?',
            modalContent: (
                <React.Fragment>
                    <p>Your progress will be saved but you only have until {dateExpired} to complete your application. After that, you’ll need to start the application process again. </p>
                    <p>Please check your email, we’ve sent you a link so you can continue from where you left off. </p>
                </React.Fragment>
            ),
            handleOK: evtOk || hideModal,
            handleCancel: evtCancel || ScreenBusUtils.goHome,
            okTitle: 'Stay here',
            cancelTitle: 'Yes, cancel',
        });
        ScreenBusUtils.hideLoading();
    };

    useEventBus(EventBusName.NOTIFY_CONFIRM_BEFORE_LEAVE, (evtOk, evtCancel) => {
        confirmScreen(evtOk, evtCancel);
    });

    useBackBrowser(confirmScreen, dateExpired);
};

