/* eslint-disable no-mixed-operators */
import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import AccordionSimple from '../components/Common/AccordionSimple';
import ActionSteps from '../components/Common/ActionSteps';
import LabelTitle from '../components/Common/LabelTitle';
import { FlexColumnCenter } from '../components/Common/FlexCenter';
import { MovingFadeIn } from '../components/Common/AnimateText';
import LabelLoanApplication from '../components/Common/LabelLoanApplication';
import { SegmentBusTrackFinal } from '../segment-bus/SegmentBusTrackFinal';

import { debicheckStepData } from '../assets/data/debicheck-step-data';
import TickSuccessIcon from '../assets/images/tick-success-basic.svg';

function Funded() {
    useEffect(() => {
        SegmentBusTrackFinal.segment2200LoanFundedDisplayedData();
    }, []);
    return (
        <div id="debicheck">
            <div className="container pl-container-page">
                <LabelLoanApplication />

                <MovingFadeIn>
                    <FlexColumnCenter className="container py-3 text-center">
                        <img className="py-2" src={TickSuccessIcon} alt="funded" width={60} height={60} />
                        <LabelTitle>
                            Congrats, we’ve disbursed your loan funds!
                        </LabelTitle>
                        <p className="pt-2">It may take up to 3 business days for the funds to reflect in your account.</p>
                    </FlexColumnCenter>
                </MovingFadeIn>
            </div>

            <div className="section">
                <FlexColumnCenter className="container pl-container-page">
                    <AccordionSimple
                        title="How to easily manage your loan on the TymeBank app"
                        scrollMeIfOpen
                    >
                        <ActionSteps className="debicheck-steps" listSteps={debicheckStepData} />
                    </AccordionSimple>
                </FlexColumnCenter>
            </div>
        </div>
    );
}

const mapStateToProps = () => ({});

export default connect(
    mapStateToProps,
)(Funded);
