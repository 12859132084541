import React, { useEffect } from 'react';
import { useModalPopupContext } from '../../context/ModalPopupContext';

import { ScreenBusUtils } from '../../hooks/useEventBus';
import { SegmentBusTrackUpsell } from '../../segment-bus/SegmentBusTrackUpsell';

export const UPSELL_ERROR_CODE = {
    ERROR_POOLING: 'UPSELL_ERROR_POOLING',
    ERROR_CALCULATION: 'UPSELL_ERROR_CALCULATION',
    ERROR_CONFIRM: 'UPSELL_ERROR_CONFIRM',
};

export const UPSELL_TRACKING_MESSAGE = {
    TIMEOUT: 'the request has timed out',
    ERROR_PROCCESS: 'we have some issues while processing request',
};

const useHandleModalOffer = ({
    offer,
    handleRetry,
    handleBack,
    calculatorErrors = false,
    confirmError = false,
}) => {
    const {
        showErrorModal,
    } = useModalPopupContext();

    const options = {
        modalTitle: 'Unable to load',
        modalContent: (
            <React.Fragment>
                <p>It may take a little longer for us to load your information. Please try again.</p>
                <p>If you need assistance, call us on 0860 999 119.</p>
            </React.Fragment>
        ),
        showXClose: false,
        escOnClose: false,
        handleCancel: !offer.loanApplicationStatus ? ScreenBusUtils.goHome : handleBack,
        handleOK: handleRetry,
        cancelTitle: 'Close',
        okTitle: 'Try again',
    };

    useEffect(() => {
        if (offer.errorUpsell || offer.timeoutUpsell) {
            // fake screen
            ScreenBusUtils.showLazyLoadingInBackground(111);
            showErrorModal(options);

            SegmentBusTrackUpsell.segment1800UpsellErrorData({
                dev_error_code: UPSELL_ERROR_CODE.ERROR_POOLING,
                dev_error_description: offer.timeoutUpsell ?
                    UPSELL_TRACKING_MESSAGE.TIMEOUT :
                    UPSELL_TRACKING_MESSAGE.ERROR_PROCCESS,
            });
        }
    }, [offer]);

    useEffect(() => {
        if (confirmError || calculatorErrors) {
            // fake screen
            ScreenBusUtils.showLazyLoadingInBackground(111);
            showErrorModal(options);
        }
    }, [confirmError, calculatorErrors]);
};

export default useHandleModalOffer;
