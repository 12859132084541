import { FaceTecSDK } from 'react-facetec-sdk/build/core/FaceTecSDK.js/FaceTecSDK';
import { LivenessCheckProcessor } from './LivenessCheckProcessor';
import { formatPublicKey } from './FacialEncryption';
import { SegmentBusTrackFacials } from '../../segment-bus/SegmentBusTrackFacials';

import { FaceTecStrings } from '../data/facetec-strings-data';

export class FaceApp {
    static setup() {
        // Set the directory path for FaceTec Browser SDK Resources.
        FaceTecSDK.setResourceDirectory('../../facetec/FaceTecSDK.js/resources');
        // Set the directory path for FaceTec Browser SDK images.
        FaceTecSDK.setImagesDirectory('../../facetec/FaceTec_images');
    }

    static isReady() {
        return FaceTecSDK.getStatus() === FaceTecSDK.FaceTecSDKStatus.Initialized;
    }

    static init({
        deviceKey = '',
        encryptionPublicKey = '',
        sdkLicense,
        isFirst,
    } = {}) {
        return new Promise((resolve, reject) => {
            if (FaceApp.isReady()) {
                resolve();
                return;
            }

            const license = JSON.parse(atob(sdkLicense));
            FaceTecSDK.initializeInProductionMode(
                license,
                deviceKey,
                formatPublicKey(encryptionPublicKey),
                initializedSuccessfully =>
                    FaceApp.onInitializationComplete({
                        initializedSuccessfully,
                        isFirst,
                        resolve,
                        reject,
                    }),
            );
        });
    }

    static onLivenessCheckPressed = ({
        url,
        encryptionPublicKey,
        session,
        sessionToken,
        onComplete = () => { },
    } = {}) => {
        setTimeout(() => {
            new LivenessCheckProcessor(url, encryptionPublicKey, onComplete, sessionToken || session);
        }, 500);
    };

    static onInitializationComplete({
        initializedSuccessfully,
        isFirst,
        resolve,
        reject,
    }) {
        const status = FaceTecSDK.getStatus();

        if (!initializedSuccessfully || !FaceApp.isReady()) {
            const inputData = isFirst ? 'sdk-first-initial' : 'sdk-retry-initial';
            SegmentBusTrackFacials.trackingFacetecSDK(status, inputData);
            reject();
            return;
        }

        FaceTecSDK.configureLocalization({
            ...FaceTecStrings,
            FaceTec_initializing_camera: '',
            FaceTec_instructions_header_ready_desktop: 'Get ready for your selfie',
            FaceTec_instructions_header_ready_1_mobile: 'Get ready for',
            FaceTec_instructions_header_ready_2_mobile: 'your selfie',

            FaceTec_instructions_message_ready_desktop: 'Position your face within the frame. Then tap “Start” when you’re ready.',
            FaceTec_instructions_message_ready_1_mobile: 'Position your face within the frame',
            FaceTec_instructions_message_ready_2_mobile: 'Then tap “Start” when you’re ready',

            FaceTec_action_im_ready: 'Start',
            FaceTec_action_try_again: 'Try again',

            FaceTec_retry_header: 'Let\'s try that again',
            FaceTec_retry_subheader_message: 'Keep a neutral face, no smiling',
            FaceTec_retry_your_image_label: 'Your selfie',
            FaceTec_retry_ideal_image_label: 'Good example',
        });
        resolve();
    }
}
