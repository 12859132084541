/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import axios from 'axios';
import { ajax } from 'rxjs/observable/dom/ajax';
import { map } from 'rxjs/operators';

import { ContentType, API_HEADER_INPUTS } from '../constants';
import config from '../config';
import { getProvider } from '../utils/commonUtils';

const timeout = 60 * 1000;
/**
 * A Http Client utility as a wrapper of RxJS's AJAX
 */

const deviceInfo = {
    device_name: '',
    device_os: '',
    device_id: '',
    sim_info: [
        {
            imei: '',
            imsi: '',
            network: '',
            sim_id: '',
        },
    ],
    mac: ' ',
    geolocation: '',
};

export class HttpClient {
    /**
     * GET an existing resource
     * @param {string} endpoint web service endpoint
     * @param {object} headers request headers as a object
     */
    get(endpoint, headers) {
        const _headers = {
            ...headers,
            Provider: config.provider,
            'Caller-Id': 'Channel.Web',
            'Is-Launching-Automation': config.isLaunchingAutomation,
        };

        return ajax({
            url: endpoint,
            headers: _headers,
            timeout,
        }).pipe(
            map(resp => resp.response),
        );
    }

    async axiosGet({
        endPoint, headers, params = {}, cancelToken,
    }) {
        return axios({
            method: 'GET',
            url: endPoint,
            timeout,
            headers: {
                'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE,
                Channel: config.channel,
                'Caller-Id': 'Channel.Web',
                'Correlation-Id': '123',
                Timestamp: Date.now(),
                'Session-Id': '1111',
                'Device-Info': window.btoa(JSON.stringify(deviceInfo)),
                Provider: getProvider(),
                ...headers,
                'Is-Launching-Automation': config.isLaunchingAutomation,
            },
            params,
            cancelToken,
        });
    }

    async axiosPost({
        endPoint, headers = {}, data = {}, method = 'POST',
    }) {
        const _headers = {
            'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE,
            Channel: config.channel,
            'Caller-Id': 'Channel.Web',
            'Correlation-Id': '123',
            ...headers,
            Timestamp: Date.now(),
            'Device-Info': window.btoa(JSON.stringify(deviceInfo)),
            Provider: config.provider,
            'Is-Launching-Automation': config.isLaunchingAutomation,
        };
        return axios({
            method,
            url: endPoint,
            headers: _headers,
            data,
            timeout,
        });
    }

    /**
     * POST to web service with body as a object
     * @param {string} endpoint web service endpoint
     * @param {object} headers request headers as a object
     * @param {object} body request body as a object
     */
    postAsJson(endpoint, headers, body) {
        const _headers = {
            ...headers,
            Channel: config.channel,
            'Caller-Id': 'Channel.Web',
            Timestamp: Date.now(),
            'Correlation-Id': '123',
            'Device-Info': window.btoa(JSON.stringify(deviceInfo)),
            'Session-Id': '1111',
            'Delivery-Method': 'sms',
            Provider: config.provider,
            'Is-Launching-Automation': config.isLaunchingAutomation,
        };
        const _body = JSON.stringify(body);

        return ajax({
            method: 'POST',
            url: endpoint,
            headers: _headers,
            body: _body,
            timeout,
        }).pipe(
            map(resp => resp.response),
        );
    }

    /**
     * POST to web service with body as Form-Url-Encoded
     * @param {string} endpoint web service endpoint
     * @param {object} headers request headers as a object
     * @param {object} body request body as a object
     */
    postAsFormUrlEncoded(endpoint, headers, body) {
        const _headers = {
            ...headers,
            Provider: config.provider,
            'Content-Type': ContentType.FORM_URL_ENCODED,
            'Is-Launching-Automation': config.isLaunchingAutomation,
        };
        const _body = this.serializeAsFormUrlEncode(body);

        return ajax({
            method: 'POST',
            url: endpoint,
            headers: _headers,
            body: _body,
        }).pipe(
            map(resp => resp.response),
        );
    }

    /**
     * PUT to web service with body as a object
     * @param {string} endpoint web service endpoint
     * @param {object} headers request headers as a object
     * @param {object} body request body as a object
     */
    putAsJson(endpoint, headers, body) {
        const _headers = {
            ...headers,
            'Content-Type': ContentType.JSON_TEXT,
            Provider: config.provider,
            'Is-Launching-Automation': config.isLaunchingAutomation,
        };
        const _body = JSON.stringify(body);

        return ajax({
            method: 'PUT',
            url: endpoint,
            headers: _headers,
            body: _body,
        }).pipe(
            map(resp => resp.response),
        );
    }

    /**
     * PUT to web service with body as a object
     * @param {string} endpoint web service endpoint
     * @param {object} headers request headers as a object
     * @param {object} body request body as a object
     */
    putAsFormUrlEncoded(endpoint, headers, body) {
        const _headers = {
            ...headers,
            Provider: config.provider,
            'Content-Type': ContentType.FORM_URL_ENCODED,
            'Is-Launching-Automation': config.isLaunchingAutomation,
        };
        const _body = JSON.stringify(body);

        return ajax({
            method: 'PUT',
            url: endpoint,
            headers: _headers,
            body: _body,
        }).pipe(
            map(resp => resp.response),
        );
    }

    /**
     * DELETE an existing resource
     * @param {string} endpoint web service endpoint
     * @param { object} headers request headers as a object
     */
    delete(endpoint, headers) {
        const _headers = {
            ...headers,
            Provider: config.provider,
            'Is-Launching-Automation': config.isLaunchingAutomation,
        };
        return ajax({
            method: 'DELETE',
            url: endpoint,
            headers: _headers,
        }).pipe(
            map(resp => resp.response),
        );
    }

    /**
     * Uri encode a object
     * @param {object} data object to serialize
     */
    serializeAsFormUrlEncode(data) {
        return Object.keys(data)
            .map((keyName) => (
                `${encodeURIComponent(keyName)
                }=${encodeURIComponent(data[keyName])}`
            ))
            .join('&');
    }
}

export default new HttpClient();
