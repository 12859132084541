import React from 'react';
import ActionSteps from '../Common/ActionSteps';
import LabelTitle from '../Common/LabelTitle';

import { offerStepAppTymeBank } from '../../assets/data/offers-data';

function OfferSteps({
    title,
}) {
    return (
        <React.Fragment>
            <LabelTitle className="mt-sm-4 my-3 mx-4 text-center">
                {title}
            </LabelTitle>

            <ActionSteps
                className="offers-steps mt-sm-4 mt-3 justify-content-center"
                listSteps={offerStepAppTymeBank} />
        </React.Fragment>
    );
}

export default OfferSteps;
