/* eslint-disable no-mixed-operators */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import { ScreenBusUtils } from '../hooks/useEventBus';
import { DecisioningResult, INITIATION_FEE, UpsellingResultStatus } from '../constants';
import { fetchDecisionResults, fetchLoanDetail } from '../actions';

// components
import OfferSteps from '../components/Package/OfferSteps';
import OfferViewChoices from '../components/Package/OfferViewChoices';
import { useConfirmBeforeBackBrowser } from '../hooks/useBackBrowser';

function OffersTymeSalary({
    data,
    loanSubmission,
    verifyLoanApplicationData,
    ...props
}) {
    const [offers, setOffers] = useState(() => _.get(data, 'offerList', _.get(data, 'state.offerList') || []));

    const resumeNumber = _.get(verifyLoanApplicationData, 'loanApplicationNumber');
    const loanApplicationNumber = _.get(loanSubmission, 'loanApplicationNumber') || resumeNumber;

    const loanStatus = _.get(verifyLoanApplicationData, 'loanApplicationStatus');
    const loanApplicationStatus = _.get(data, 'loanApplicationStatus');
    const isApproved = [loanApplicationStatus, loanStatus].includes(DecisioningResult.loanApplicationStatus.APPROVED);

    const amount = _.get(offers[0], 'finalApprovedAmount');
    const realOfferData = data || _.get(data, 'state');
    const incomeVerificationExpired = _.get(realOfferData, 'incomeVerificationExpired') ||
        _.get(props.loanDetail, 'loan.info.incomeVerificationExpired');
    const dateExpired = moment(new Date(incomeVerificationExpired)).format('DD/MM/YYYY');

    useEffect(() => {
        if (loanApplicationNumber && !UpsellingResultStatus.YES[data.upsellStatus]) {
            if (!isApproved) {
                setTimeout(() => props.fetchDecisionResults({ loanApplicationNumber }));
                return;
            }

            const info = {
                stepUpToken: _.get(props.otp, 'stepUpToken'),
                loanApplicationNumber,
                initiationFeePaymentMethod: INITIATION_FEE.ADD,
                retryIfError: true,
            };
            setTimeout(() => props.fetchLoanDetail(info));
        }
    }, []);

    useEffect(() => {
        if (resumeNumber && loanStatus === DecisioningResult.loanApplicationStatus.CONDITIONALLY_APPROVED_COLLECTION_RECEIVED) {
            ScreenBusUtils.setMessageLoading('Please wait while we resume your application. This might take a couple of minutes.');
        }
    }, [loanStatus]);

    useEffect(() => {
        if (isApproved) {
            return;
        }

        if (UpsellingResultStatus.YES[data.upsellStatus]) {
            ScreenBusUtils.gotoScreenUpsellOffers();
        }
    }, [data]);

    useEffect(() => {
        if (isApproved) {
            setOffers(_.get(props.loanDetail, 'loan.offerOptions') || []);
            return;
        }

        setOffers(_.get(data, 'offerList', _.get(data, 'state.offerList') || []));
    }, [_.get(data, 'offerList'), _.get(data, 'state.offerList'), props.loanDetail]);

    useConfirmBeforeBackBrowser({
        dateExpired,
    });

    return (
        <div id="offers">
            <div className="pl-container-highlight px-1">
                <div className="container d-flex flex-column mb-md-2 mb-4">
                    <OfferViewChoices
                        amount={amount}
                        offers={offers}
                        isTymeBankSalary
                        loanApplicationNumber={loanApplicationNumber}
                        loanApplicationStatus={loanStatus}
                        dateExpired={dateExpired}
                    />
                </div>
            </div>

            <div className="pl-container-page container px-1">
                <OfferSteps title="Continue your loan application on the TymeBank app" />
            </div>
        </div>
    );
}

const mapStateToProps = state => ({
    data: state.offers,
    otp: state.otp,
    loanDetail: state.loanDetail.loanDetail,
    loanSubmission: state.loanSubmission,
    verifyLoanApplicationData: state.verifyLoanApplicationData,
});

export default connect(
    mapStateToProps,
    {
        fetchLoanDetail,
        fetchDecisionResults,
    },
)(OffersTymeSalary);
