/* eslint radix: ["error", "as-needed"] */
import moment from 'moment';

// validation rule https://www.westerncape.gov.za/general-publication/decoding-your-south-african-id-number-0
export function isValidatedSaid(idVal) {
    if (!idVal || idVal.length !== 13 || !moment(idVal.substring(0, 6), 'YYMMDD', true).isValid()) {
        return false;
    }
    if (
        parseInt(idVal.substring(10, 11)) !== 0 &&
        parseInt(idVal.substring(10, 11)) !== 1
    ) {
        return false;
    }
    const checkDigit = parseInt(idVal.charAt(idVal.length - 1));
    let odd = 0;
    let even = '';
    let evenResult = 0;
    let result;
    for (let c = 1; c <= idVal.length; c += 1) {
        if (c % 2 === 0) {
            even += idVal.charAt(c - 1);
        } else if (c !== idVal.length) {
            odd = parseInt(odd.toString()) + parseInt(idVal.charAt(c - 1));
        }
    }
    even = Number(even) * 2;
    even = even.toString();
    for (let r = 1; r <= even.length; r += 1) {
        evenResult =
            parseInt(evenResult.toString()) + parseInt(even.charAt(r - 1));
    }
    result = parseInt(odd.toString()) + parseInt(evenResult.toString());
    result = result.toString();
    result = 10 - parseInt(result.charAt(result.length - 1));
    result = result.toString();
    if (result.length > 1) {
        result = result.charAt(result.length - 1);
    }
    return parseInt(result) === checkDigit;
}

export const isSAIDEnoughAge = (said, minAge = 18) => {
    const strSaidTime = said.toString().substring(0, 6);
    const strLast2digitsYearSaid = strSaidTime.substring(0, 2);
    const strYearNow = moment().year().toString();
    const strSaidYearPrefix =
        Number(strLast2digitsYearSaid) < Number(strYearNow.slice(-2))
            ? `${strYearNow.substring(0, 2)}`
            : `${Number(strYearNow.substring(0, 2)) - 1}`;
    const momentBirth = moment(`${strSaidYearPrefix}${strSaidTime} 00:00 +02:00`, 'YYYYMMDD HH:mm Z');
    const age = moment().diff(momentBirth, 'years');
    return age >= minAge;
};
