import React, { useRef } from 'react';
import useAnimationFrame from 'use-animation-frame';

import useEventBus, { EventBusName } from '../../hooks/useEventBus';
import { SegmentBusTrackFacials } from '../../segment-bus/SegmentBusTrackFacials';

const useFacialStepsSegmentTracking = () => {
    const [ready, setReady] = React.useState(false);

    const trackingSteps = useRef({
        badLightingError: false,
        smillingFaceError: false,
        blurCameraError: false,
    });

    useEventBus(EventBusName.FACTECT_SDK_START, () => {
        setReady(true);
        trackingSteps.current = {
            badLightingError: false,
            smillingFaceError: false,
            blurCameraError: false,
        };
    });

    useEventBus(EventBusName.FACETEC_REFRESH_API_SESSION, () => {
        setReady(false);
    });

    useEventBus(EventBusName.FACTECT_REFRESH_API_FETCHING, () => {
        setReady(false);
    });

    useAnimationFrame((e) => {
        const time = Math.ceil(e.time.toFixed(1) * 1000);
        if (!ready || time % 200 !== 0) {
            return;
        }

        // clear all
        const readyScreen = document.querySelector('#DOM_FT_getReadyScreen');
        if (readyScreen && readyScreen.style.opacity === '1') {
            trackingSteps.current = {
                badLightingError: false,
                smillingFaceError: false,
                blurCameraError: false,
            };
            return;
        }

        if (!trackingSteps.current.badLightingError &&
            !trackingSteps.current.smillingFaceError &&
            !trackingSteps.current.blurCameraError
        ) {
            const retryScreen = document.querySelector('#DOM_FT_retryScreen');
            if (!retryScreen || retryScreen.style.opacity !== '1') {
                return;
            }

            const content1 = retryScreen.querySelector('#DOM_FT_retryInstructionParagraphElement1');
            const content2 = retryScreen.querySelector('#DOM_FT_retryInstructionParagraphElement2');
            const compareContent = content1.style.display !== 'none' ? content1 : content2;

            // FaceTecStrings.FaceTec_retry_instruction_message_1
            if (compareContent.textContent.includes('Find better lighting')) {
                SegmentBusTrackFacials.segment2100FacialVerificationBadLightingErrorData();
                trackingSteps.current.badLightingError = true;
                return;
            }

            // FaceTecStrings.FaceTec_retry_instruction_message_2
            if (compareContent.textContent.includes('Neutral Expression')) {
                SegmentBusTrackFacials.segment2100FacialVerificationSmillingFaceErrorData();
                trackingSteps.current.smillingFaceError = true;
                return;
            }

            // FaceTecStrings.FaceTec_retry_instruction_message_3
            if (compareContent.textContent.includes('to clean your camera')) {
                SegmentBusTrackFacials.segment2100FacialVerificationBlurCameraErrorData();
                trackingSteps.current.blurCameraError = true;
            }
        }
    });
};

export default useFacialStepsSegmentTracking;
