import React from 'react';
import joinclass from 'joinclass';
import { formatMoney } from '../../utils/format';

function XWrapMoney({
    primary,
    light,
    disabledFormat,
    children,
    className,
}) {
    return (
        <span
            className={joinclass('money-label font-weight-500', primary && 'primary-color', light && 'light-color', className)}
        >
            {disabledFormat ? children : formatMoney(children || 0)}
        </span>
    );
}

export default XWrapMoney;
