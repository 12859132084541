/* eslint-disable no-global-assign */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { I18nextProvider } from 'react-i18next';
import TagManager from 'react-gtm-module';
import { Route } from 'react-router-dom'; // initialized i18next instance
import App from './App';
import store, { history } from './store';
import i18n from './i18n/i18n';
import Config from './config';
import { plAnalytics } from './hooks/useSegmentAnalytics';

require('es6-shim');

const tagManagerArgs = {
    gtmId: Config.GaDefaultConfig.trackingId,
};
TagManager.initialize(tagManagerArgs);

plAnalytics.ready();

export default ReactDOM.render(
    <Provider store={store}>
        <ConnectedRouter history={history}>
            <I18nextProvider i18n={i18n}>
                <Route component={App} />
            </I18nextProvider>
        </ConnectedRouter>
    </Provider>,
    document.getElementById('root') || document.createElement('div'),
);
