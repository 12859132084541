import { WrapSegmentBusUtils } from '../hooks/useSegmentEventBus';

export class SegmentBusTrackUpsell {
    static segment1800UpsellOfferDisplayedData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment1800UpsellOfferDisplayedData');
    }

    static segment1800UpsellConfirmUpsellOfferData(params) {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment1800UpsellConfirmUpsellOfferData', params);
    }

    static segment1800UpsellChooseOtherOfferData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment1800UpsellChooseOtherOfferData');
    }

    static segment1800UpsellExitData(params) {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment1800UpsellExitData', params);
    }

    static segment1800UpsellErrorData(params) {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment1800UpsellErrorData', params);
    }

    static segment1800UpsellErrorLoadFailData(params) {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment1800UpsellErrorLoadFailData', params);
    }

    static segment1800UpsellTryAgainData(params) {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment1800UpsellTryAgainData', params);
    }
}
