import { handleActions } from 'redux-actions';
import {
    SEND_LOAN_OFFERS_SELECTION,
    SEND_LOAN_OFFERS_SELECTION_FAILED,
    SEND_LOAN_OFFERS_SELECTION_SUCCESS,
    SHOW_CONFIRM_OFFER_MODAL,
    UPDATE_OFFER_LIST,
    RESET_LOAN_SUBMISSION,
} from '../store/actionTypes';

// API res can return an unsorted array, we should sort it by tenor to have a correct display
export const offersSortByTenorAsc = arr => arr.sort((a, b) => a.tenor - b.tenor);

const initialState = {
    offerList: [],
    isSending: null,
    errors: null,
    inviteUrl: null,
    affordabilityProvider: null,
    incomeVerificationExpired: null,
    isApprovedTruid: false,
};

export const actions = {
    [UPDATE_OFFER_LIST]: (state, action) => {
        const {
            loanOffers,
            inviteUrl,
            affordabilityProvider,
            incomeVerificationExpired,
            isApprovedTruid,
            isIntroChecking,
            qualifiedLessMoney,
            loanApplicationStatus,
        } = action.payload;
        return {
            ...initialState,
            ...action.payload,
            offerList: offersSortByTenorAsc(loanOffers || []),
            inviteUrl,
            affordabilityProvider,
            incomeVerificationExpired,
            isApprovedTruid,
            isIntroChecking,
            qualifiedLessMoney,
            loanApplicationStatus,
        };
    },
    [SHOW_CONFIRM_OFFER_MODAL]: (state, action) => ({
        ...state,
        selectedOffer: action.payload,
    }),

    [SEND_LOAN_OFFERS_SELECTION]: state => ({
        ...state,
        isSending: true,
        errors: null,
    }),
    [SEND_LOAN_OFFERS_SELECTION_SUCCESS]: state => ({
        ...state,
        isSending: false,
        errors: null,
    }),
    [SEND_LOAN_OFFERS_SELECTION_FAILED]: (state, action) => ({
        ...state,
        isSending: false,
        errors: action.payload,
        isApprovedTruid: false,
    }),
    [RESET_LOAN_SUBMISSION]: () => ({
        ...initialState,
    }),
};

export default handleActions(actions, initialState);
