import React from 'react';

const AccountKeyIcon = () => (
  <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M62.0142 21.4636L58.3883 25.1717L56.7159 23.6325L54.4925 25.1717L52.4872 23.6325L50.4251 25.1717L48.336 23.6325L46.041 25.446H44.1552C42.6531 27.708 40.6501 28.839 38.1461 28.839C35.7894 28.839 31.0957 26.4683 31.0957 21.7874C31.0957 16.8762 35.7065 14.7852 38.1461 14.7852C40.6198 14.7852 42.6228 15.8994 44.1552 18.128H59.0694L62.0142 21.4636Z" fill="#FFCC00" stroke="#4F4D4C" strokeLinecap="square" />
    <path d="M43.75 21.1256H60.5987" stroke="#4F4D4C" strokeLinecap="square" />
    <path d="M36.7155 23.9193C37.8798 23.9193 38.8236 22.9755 38.8236 21.8112C38.8236 20.6469 37.8798 19.7031 36.7155 19.7031C35.5512 19.7031 34.6074 20.6469 34.6074 21.8112C34.6074 22.9755 35.5512 23.9193 36.7155 23.9193Z" fill="white" stroke="#4F4D4C" />
    <path d="M2.98828 44.6945H13.2716V25.1055H2.98828V44.6945Z" fill="#FFCC00" stroke="#4F4D4C" />
    <path d="M13.2715 41.0451C39.4128 49.4636 30.699 49.4636 60.5747 35.0318C57.9282 32.475 55.8394 31.8713 53.1058 32.6265L42.3605 35.9146L27.025 35.0318L20.2367 28.3613H13.2715V41.0451Z" fill="white" stroke="#4F4D4C" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M13.2715 28.1992H20.623C26.3865 28.1992 30.4249 33.3542 31.6502 33.3542H40.2269C42.6774 33.3542 42.6774 38.5092 40.2269 38.5092H26.7492" fill="white" />
    <path d="M13.2715 28.1992H20.623C26.3865 28.1992 30.4249 33.3542 31.6502 33.3542H40.2269C42.6774 33.3542 42.6774 38.5092 40.2269 38.5092H26.7492" stroke="#4F4D4C" strokeLinecap="round" strokeLinejoin="round" />
    <path d="M6.50135 28.8379V33.3495" stroke="#4F4C4D" strokeLinecap="square" />
  </svg>

);

export default AccountKeyIcon;

export const AccountKeyMobileIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="2.10547" y="2.91797" width="22.8727" height="16.4782" rx="3" fill="white" stroke="#231F20" strokeWidth="0.5" />
    <path d="M2.10547 4.88477V21.7318C2.10547 22.3431 2.60097 22.8386 3.21221 22.8386H24.8552C25.4664 22.8386 25.9619 22.3431 25.9619 21.7318V5.99151C25.9619 5.38027 25.4664 4.88477 24.8552 4.88477H2.10547Z" fill="white" stroke="#231F20" strokeWidth="0.5" />
    <rect x="18.9531" y="11.4023" width="6.88639" height="4.91885" fill="white" stroke="#231F20" strokeWidth="0.5" />
    <path d="M20.9202 11.4023V16.3212" stroke="#231F20" strokeWidth="0.5" />
    <path d="M30.3566 18.7302V16.0633C30.3566 14.5905 29.1794 13.3965 27.7271 13.3965C26.2749 13.3965 25.0977 14.5905 25.0977 16.0633V20.6977" fill="#E8E8E8" />
    <path d="M30.3566 18.7302V16.0633C30.3566 14.5905 29.1794 13.3965 27.7271 13.3965C26.2749 13.3965 25.0977 14.5905 25.0977 16.0633V20.6977" stroke="#231F20" strokeWidth="0.5" />
    <rect x="18.2285" y="20.5801" width="9.56179" height="8.24298" fill="#FFCC00" stroke="#231F20" strokeWidth="0.5" />
    <path d="M18.2285 27.3694H27.7903" stroke="#231F20" strokeWidth="0.5" />
    <path fillRule="evenodd" clipRule="evenodd" d="M23.0101 23.9742C23.4062 23.9742 23.7272 23.6485 23.7272 23.2469C23.7272 22.8452 23.4062 22.5195 23.0101 22.5195C22.614 22.5195 22.293 22.8452 22.293 23.2469C22.293 23.6485 22.614 23.9742 23.0101 23.9742Z" stroke="#231F20" strokeWidth="0.5" />
    <path d="M23.0101 24.2168V26.1563" stroke="#231F20" strokeWidth="0.5" />
  </svg>
);
