import React, { useEffect, useRef, useState } from 'react';
import joinclass from 'joinclass';
import { scrollElementSmooth } from '../../utils/commonUtils';
import { useElementShowOnce } from '../../hooks/useOnScreen';
import { MovingFadeIn, MovingPopIn } from './AnimateText';

import { CollapseArrowIcon } from '../../assets/images/elements/ArrowIcon';

const AccordionSimple = ({
  className,
  isOpen, // default value
  handleOpen = () => { },
  title,
  alwaysShowSubTitle,
  subTitleWhenOpen,
  children,
  scrollMeIfOpen = false,
}) => {
  const refsAccordion = useRef();
  const refsTitle = useRef();
  const [open, setOpen] = useState(isOpen);
  const isViewOnSreen = useElementShowOnce(refsTitle);

  useEffect(() => {
    setOpen(isOpen);
  }, [isOpen]);

  function handleLocalEvent() {
    setOpen(!open);
    handleOpen(!open);

    if (scrollMeIfOpen && !open) {
      setTimeout(() => {
        scrollElementSmooth(refsAccordion.current, 'center');
      }, 100);
    }
  }

  return (
    <div ref={refsAccordion} className={joinclass('pl-accordion d-flex flex-column px-sm-5 px-3', className)}>
      <div className="pl-accordion-title">
        <aside ref={refsTitle}>
          <MovingPopIn key={isViewOnSreen}>
            <div className="title font-size-20 mb-2" aria-hidden="true" onClick={handleLocalEvent}>
              {title}
            </div>
          </MovingPopIn>
          {(alwaysShowSubTitle || open) && subTitleWhenOpen}
        </aside>
        <div
          className={joinclass('pl-accordion-arrow', open && 'arrow-active')}
          onClick={handleLocalEvent}
          aria-hidden="true"
        >
          <CollapseArrowIcon />
        </div>
      </div>

      {open &&
        <MovingFadeIn duration='1s' className="pl-accordion-content">
          {children}
        </MovingFadeIn>
      }
    </div>
  );
};

export default AccordionSimple;
