export const truidResumeStatus = {
  DENIED_CONSENT: {
    buttonOK: 'Try again',
    buttonCancel: 'I’ll do it later', // can be again
  },
  INSUFFICIENT_INCOME_DATA: {
    buttonOK: 'Try again',
    buttonCancel: 'I’ll do it later', // can be again
  },
  INSUFFICIENT_DATA_FROM_TRUID: {
    buttonOK: 'Try again',
    buttonCancel: 'I’ll do it later', // can be again
  },
  INCOME_DATA_BELOW_STATED: {
    buttonOK: 'Try again',
    buttonCancel: 'I’ll do it later', // can be again
  },
  G_PROFILE_INCOME_TYPE: {
    buttonOK: 'Select a different bank',
  },
};

// code special => we need update
export const TRUID_ADDRESS_MISSING = 'ADDRESS_MISSING';
export const TRUID_SALARY_DATE_MISSING = 'SALARY_DATE_MISSING';
export const G_PROFILE_INCOME_TYPE = 'G_PROFILE_INCOME_TYPE';
