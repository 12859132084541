import joinclass from 'joinclass';
import React from 'react';
import { connect } from 'react-redux';

import { isHomePage } from '../../utils/pageUtils';
import { ScreenBusUtils } from '../../hooks/useEventBus';
import { scrollTopSmooth } from '../../utils/commonUtils';

export function NavLogo({
    center,
    pathname,
    transparent,
}) {
    const isHome = isHomePage(pathname);

    const handleClick = () => {
        if (!isHome) {
            window.location.reload();
            return;
        }

        scrollTopSmooth();
        ScreenBusUtils.goHome();
    };

    return (
        <div
            className={joinclass('d-flex flex-row align-items-center gap-16 font-size-20 font-weight-500 cursor-pointer', center && 'justify-content-center')}
            onClick={handleClick}
            aria-hidden
        >
            <div className={joinclass('nav-logo', !transparent ? 'logo-tymebank-white' : 'logo-tymebank-black')} />
            {(!transparent || !isHome) ? <div className="nav-split" /> : '&'}
            <div className={joinclass('nav-logo', !transparent ? 'logo-tfg-white' : 'logo-tfg-black')} />
        </div>
    );
}


const mapStateToProps = state => ({
    pathname: state.router.location.pathname,
});

export const XNavLogo = connect(mapStateToProps)(NavLogo);
