import React from 'react';
import { FlexRowCenter } from '../../../components/Common/FlexCenter';
import AccountInfomationIcon from './AccountInfomationIcon';

import DocumentIcon, { DocumentTimeIcon, DocumentResidenceIcon } from './DocumentIcon';
import HomeAddressIcon from './HomeAddressIcon';
import LocationIcon, { LocationSmallIcon } from './LocationIcon';
import MailIcon, { MailNotificationTimeIcon, MailStatementIcon } from './MailIcon';
import RepaymentIcon from './RepaymentIcon';

import { ResidenceTickIcon, ResidenceTickMobileIcon } from './ProofOfResidenceIcon';
import { UnLockIcon, UnLockMobileIcon } from './LockIcon';
import { AppBankTfgTymeIcon, AppBankTymeIcon } from './AppBankTymeIcon';

export const wrapElement = (element, small, className) => ({
  viewMobile: () => <FlexRowCenter className={className} width="60px" height="60px">{small || element}</FlexRowCenter>,
  viewBasic: () => <FlexRowCenter className={className} width="64px" height="64px">{element}</FlexRowCenter>,
  viewLarge: () => <FlexRowCenter className={className} width="100px" height="100px">{element}</FlexRowCenter>,
});

export const wrapDocumentIcon = wrapElement(<DocumentIcon />);
export const wrapDocumentTimeIcon = wrapElement(<DocumentTimeIcon />);
export const wrapDocumentResidenceIcon = wrapElement(<DocumentResidenceIcon />);

export const wrapLocationIcon = wrapElement(<LocationIcon />);
export const wrapLocationSmallIcon = wrapElement(<LocationSmallIcon />);
export const wrapAddressIcon = wrapElement(<HomeAddressIcon />);
export const wrapEmailIcon = wrapElement(<MailIcon />);
export const wrapEmailStatementIcon = wrapElement(<MailStatementIcon />, undefined, 'wrap-svg-icon');
export const wrapEmailNotificationIcon = wrapElement(<MailNotificationTimeIcon />, undefined, 'wrap-svg-icon');
export const wrapRepaymentIcon = wrapElement(<RepaymentIcon />);
export const wrapAccountInformationIcon = wrapElement(<AccountInfomationIcon />);

export const wrapPaymentIntroTruidIcon = wrapElement(<ResidenceTickIcon />, <ResidenceTickMobileIcon />);
export const wrapUnlockIcon = wrapElement(<UnLockIcon />, <UnLockMobileIcon />);

export const wrapAppBankTymeIcon = wrapElement(<AppBankTymeIcon />);
export const wrapAppBankTfgTymeIcon = wrapElement(<AppBankTfgTymeIcon />, undefined, 'wrap-svg-icon');
