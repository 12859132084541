export const Config = (function () {
    // -------------------------------------
    // REQUIRED
    // Available at https://dev.facetec.com/account
    // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
    const DeviceKeyIdentifier = '';

    // -------------------------------------
    // REQUIRED
    // The URL to call to process FaceTec SDK Sessions.
    // In Production, you likely will handle network requests elsewhere and without the use of this variable.
    // See https://dev.facetec.com/security-best-practices?link=facetec-server-rest-endpoint-security for more information.
    // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
    const BaseURL = '';

    // -------------------------------------
    // REQUIRED
    // The FaceMap Encryption Key you define for your application.
    // Please see https://dev.facetec.com/keys?link=keys for more information.
    // NOTE: This field is auto-populated by the FaceTec SDK Configuration Wizard.
    const PublicFaceScanEncryptionKey = '';

    return {
        DeviceKeyIdentifier,
        BaseURL,
        PublicFaceScanEncryptionKey,
    };
}());
