import { handleActions } from 'redux-actions';
import {
    SIGN_CONTRACT_LOAN,
    SIGN_CONTRACT_LOAN_SUCCESS,
    SIGN_CONTRACT_LOAN_FIRST_TIME_SUCCESS,
    SIGN_CONTRACT_LOAN_FAILED,
} from '../store/actionTypes';

const initialState = {
    loanCreationSessionId: null,
    loanFundedAmount: null,
    loanStatus: null,
    requestToken: null,
    isSignContract: false,
    errors: null,
};

export const actions = {
    [SIGN_CONTRACT_LOAN]: (state = initialState) => ({
        ...state,
        isSignContract: true,
    }),
    [SIGN_CONTRACT_LOAN_FIRST_TIME_SUCCESS]: (state, action) => ({
        ...state,
        isSignContract: true,
        requestToken: action.payload && action.payload.requestToken,
    }),
    [SIGN_CONTRACT_LOAN_SUCCESS]: (state, action) => ({
        ...state,
        loanCreationSessionId: action.payload && action.payload.loanCreationSessionId,
        loanFundedAmount: action.payload && action.payload.loanFundedAmount,
        loanStatus: action.payload && action.payload.loanStatus,
    }),
    [SIGN_CONTRACT_LOAN_FAILED]: (state, action) => ({
        ...state,
        errors: action.payload,
    }),

};

export default handleActions(actions, initialState);
