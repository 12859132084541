import React from 'react';

import { FlexRowCenter } from '../../components/Common/FlexCenter';
import { detectmob } from '../../utils/detectmob';

import selfieStandard from '../../assets/images/selfie-face-standard.svg';
import selfieNoLight from '../../assets/images/selfie-face-no-light.svg';
import selfieBlur from '../../assets/images/selfie-face-blur.svg';
import selfieMiss from '../../assets/images/selfie-face-miss.svg';


export const stepConfigCamera = {
    default: {
        title: 'Allow TymeBank to access your camera',
        content: 'This lets you sign the agreement with a selfie, a step you need to complete so we can disburse your loan. Open your browser settings and allow camera access then click “Try again” below.',
    },
    browser: {
        title: 'Allow TymeBank to access your camera',
        content: 'This lets you sign the agreement with a selfie, a step you need to complete so we can disburse your loan.',
        chrome: [
            <React.Fragment>
                On the Chrome app, tap the <b className="font-weight-500">More icon</b>
            </React.Fragment>,
            <React.Fragment>
                Go to <b className="font-weight-500">Settings &gt;</b> <b className="font-weight-500">Site Settings</b>
            </React.Fragment>,
            <React.Fragment>
                Select <b className="font-weight-500">Microphone or Camera</b>
            </React.Fragment>,
            <React.Fragment>
                Tap to turn the <b className="font-weight-500">camera on</b>
            </React.Fragment>,
            <React.Fragment>
                Go back to this page then tap <b className="font-weight-500">Try again</b> below
            </React.Fragment>,
        ],
        samsungMoble: [
            <React.Fragment>
                On the Samsung Internet app, tap the <b className="font-weight-500">Menu icon</b>
            </React.Fragment>,
            <React.Fragment>
                Go to <b className="font-weight-500">Settings &gt; Sites and downloads &gt; Site permissions</b>
            </React.Fragment>,
            <React.Fragment>
                Tap <b className="font-weight-500">Camera</b>
            </React.Fragment>,
            <React.Fragment>
                <b className="font-weight-500">Toggle the button next</b> next to the <b className="font-weight-500">TymeBank website</b>
            </React.Fragment>,
            <React.Fragment>
                Go back to this page then tap <b className="font-weight-500">Try again </b>below
            </React.Fragment>,
        ],
        safari: [
            <React.Fragment>
                <div className="point-item">On Safari’s address/search bar, tap the <b className="font-weight-500">A</b><b className="font-weight-500 font-size-17">A</b> symbol</div>
            </React.Fragment>,
            <React.Fragment>
                Go to <b className="font-weight-500">Website Settings</b>
            </React.Fragment>,
            <React.Fragment>
                Select <b className="font-weight-500">Camera &gt; Allow</b>, then tap <b className="font-weight-500">Done</b>
            </React.Fragment>,
            <React.Fragment>
                Tap <b className="font-weight-500">Try again </b>below
            </React.Fragment>,
        ],
    },
};

export const introSelfieStepData = [
    {
        title: 'Find some good lighting',
        content: 'Make sure your face is visible with good lighting. Not too dark. Not too bright.',
        icon:
            <FlexRowCenter className="gap-16">
                <img alt="" src={selfieStandard} width={140} height={200} />
                <img alt="" src={selfieNoLight} width={140} height={200} />
            </FlexRowCenter>,
        iconMobile:
            <FlexRowCenter className="gap-8 mt-2 face-mobile-lighting">
                <img alt="" src={selfieStandard} width={56} height={80} />
                <img alt="" src={selfieNoLight} width={56} height={80} />
            </FlexRowCenter>
        ,
    },
    {
        title: 'Don’t cover your face',
        content: 'Remove anything that covers your face, like sunglasses, a hat or a mask.',
        icon:
            <FlexRowCenter className="gap-16">
                <img alt="" src={selfieMiss} width={140} height={200} />
            </FlexRowCenter>,
        iconMobile:
            <FlexRowCenter className="gap-8 mt-2 face-mobile-lighting">
                <img alt="" src={selfieMiss} width={56} height={80} />
                <img alt="" className="opacity-dot-0" src={selfieMiss} width={56} height={80} />
            </FlexRowCenter>
        ,
    },
    {
        title: 'Stay still, no need to smile',
        content: 'Try not to smile, position your face in the oval, and follow the instructions.',
        icon:
            <FlexRowCenter className="gap-16">
                <img alt="" src={selfieStandard} width={140} height={200} />
                <img alt="" src={selfieBlur} width={140} height={200} />
            </FlexRowCenter>,
        iconMobile:
            <FlexRowCenter className="gap-8 mt-2 face-mobile-lighting">
                <img alt="" src={selfieStandard} width={56} height={80} />
                <img alt="" src={selfieBlur} width={56} height={80} />
            </FlexRowCenter>
        ,
    },
];

export const selfieStepData = [
    {
        title: 'Find some good lighting',
        icon: <img alt="" src={selfieNoLight} width={64} height={96} />,
        iconMobile: <img alt="" src={selfieNoLight} width={48} height={72} />,
    },
    {
        title: 'Don’t cover your face',
        icon: <img alt="" src={selfieMiss} width={64} height={96} />,
        iconMobile: <img alt="" src={selfieMiss} width={48} height={72} />,
    },
    {
        title: 'Stay still, no need to smile',
        icon: <img alt="" src={selfieBlur} width={64} height={96} />,
        iconMobile: <img alt="" src={selfieBlur} width={48} height={72} />,
    },
];

export function getConfigPermissions() {
    if (!detectmob()) {
        return stepConfigCamera.default;
    }

    // Get the user-agent string
    const userAgentString =
        navigator.userAgent;

    // Detect Chrome
    let chromeAgent =
        userAgentString.indexOf('Chrome') > -1;

    // // Detect Internet Explorer
    // const IExplorerAgent =
    //     userAgentString.indexOf('MSIE') > -1 ||
    //     userAgentString.indexOf('rv:') > -1;

    // Detect Safari
    let safariAgent =
        userAgentString.indexOf('Safari') > -1;

    const samsungBrowserAgent = navigator.userAgent.match(/SamsungBrowser/i);

    // Discard Safari since it also matches Chrome
    if ((chromeAgent) && (safariAgent)) { safariAgent = false; }

    // Detect Opera
    const operaAgent =
        userAgentString.indexOf('OP') > -1;

    // Discard Chrome since it also matches Opera
    if ((chromeAgent) && (operaAgent)) { chromeAgent = false; }

    if (safariAgent) {
        return {
            ...stepConfigCamera.browser,
            step: stepConfigCamera.browser.safari,
        };
    }

    if (chromeAgent) {
        return {
            ...stepConfigCamera.browser,
            step: stepConfigCamera.browser.chrome,
        };
    }

    if (samsungBrowserAgent) {
        return {
            ...stepConfigCamera.browser,
            step: stepConfigCamera.browser.samsungMoble,
        };
    }

    return {
        ...stepConfigCamera.default,
    };
}

export function getBrowserInformation() {
    // Get the user-agent string
    const userAgentString =
        navigator.userAgent;

    // Detect Chrome
    let chromeAgent =
        userAgentString.indexOf('Chrome') > -1;

    // Detect Internet Explorer
    const IExplorerAgent =
        userAgentString.indexOf('MSIE') > -1 ||
        userAgentString.indexOf('rv:') > -1;

    // Detect Safari
    let safariAgent =
        userAgentString.indexOf('Safari') > -1;

    const samsungBrowserAgent = navigator.userAgent.match(/SamsungBrowser/i);

    // Discard Safari since it also matches Chrome
    if ((chromeAgent) && (safariAgent)) { safariAgent = false; }

    // Detect Opera
    const operaAgent =
        userAgentString.indexOf('OP') > -1;

    // Discard Chrome since it also matches Opera
    if ((chromeAgent) && (operaAgent)) { chromeAgent = false; }

    return {
        chromeAgent,
        operaAgent,
        safariAgent,
        IExplorerAgent,
        samsungBrowserAgent,
    };
}
