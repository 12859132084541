import React from 'react';

const LockIcon = () => (
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="7.875" y="18.9121" width="32.625" height="27.5883" fill="white" stroke="#FFCC00" />
    <path d="M7.875 41.6317H40.5" stroke="#4F4D4C" />
    <path d="M33.1586 19.3512V10.4256C33.1586 5.49614 29.1417 1.5 24.1867 1.5C19.2317 1.5 15.2148 5.49614 15.2148 10.4256L15.2148 19.3512" stroke="#FFCC00" />
    <path d="M24.1871 30.7709C25.8122 30.7709 27.134 29.4596 27.134 27.8366C27.134 26.2136 25.8122 24.9023 24.1871 24.9023C22.562 24.9023 21.2402 26.2136 21.2402 27.8366C21.2402 29.4596 22.562 30.7709 24.1871 30.7709Z" fill="#FFCC00" stroke="#4F4D4C" />
    <path d="M24.1867 31.082V37.5734" stroke="#4F4D4C" />
  </svg>
);

export default LockIcon;

export const UnLockIcon = () => (
  <svg width="62" height="62" viewBox="0 0 62 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="41.5885" height="35.415" transform="matrix(-1 0 0 1 45.0361 24.5879)" fill="white" stroke="#4F4D4C" />
    <path d="M45.0361 53.7534H3.44764" stroke="#4F4D4C" />
    <path d="M35.6788 24.9136V13.4558C35.6788 7.12787 40.7992 1.99805 47.1156 1.99805C53.432 1.99805 58.5524 7.12787 58.5524 13.4558V24.9136" stroke="#4F4D4C" />
    <path d="M24.2419 39.8951C22.1188 39.8951 20.3977 38.1715 20.3977 36.0452C20.3977 33.919 22.1188 32.1953 24.2419 32.1953C26.365 32.1953 28.0861 33.919 28.0861 36.0452C28.0861 38.1715 26.365 39.8951 24.2419 39.8951Z" fill="#FFCC00" stroke="#4F4D4C" />
    <path d="M24.2419 40.2109V48.5439" stroke="#4F4D4C" />
  </svg>

);

export const UnLockMobileIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="21.4691" height="18.2822" transform="matrix(-1 0 0 1 23.2759 12.6602)" fill="white" stroke="#4F4D4C" strokeWidth="0.5" />
    <path d="M23.2759 27.7146H1.80678" stroke="#4F4D4C" strokeWidth="0.5" />
    <path d="M18.4454 12.8277V6.91286C18.4454 3.6462 21.0887 0.998047 24.3494 0.998047C27.6101 0.998047 30.2534 3.6462 30.2534 6.91286V12.8277" stroke="#4F4D4C" strokeWidth="0.5" />
    <path d="M12.5414 20.5627C11.4454 20.5627 10.5569 19.6729 10.5569 18.5753C10.5569 17.4777 11.4454 16.5879 12.5414 16.5879C13.6374 16.5879 14.5259 17.4777 14.5259 18.5753C14.5259 19.6729 13.6374 20.5627 12.5414 20.5627Z" fill="#FFCC00" stroke="#4F4D4C" strokeWidth="0.5" />
    <path d="M12.5414 20.7266V25.0282" stroke="#4F4D4C" strokeWidth="0.5" />
  </svg>
);
