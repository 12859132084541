import React, { useState } from 'react';
import joinclass from 'joinclass';
import { v4 } from 'uuid';

const InputCheck = ({
  checked,
  value,
  className,
  labelClassName,
  hidden,
  type = 'radio',
  name,
  id,
  label,
  handleChange = () => { },
  handleEnterSpace = () => { },
  getInputRef = () => { },
}) => {
  // eslint-disable-next-line no-unused-vars
  const [localId, setLocalId] = useState(id || v4());
  return (
    <React.Fragment>
      <input
        ref={getInputRef}
        className={joinclass('form-check-input', hidden && 'hidden-input d-block', className)}
        type={type}
        name={name}
        id={localId}
        checked={checked}
        value={value}
        onChange={() =>
          handleChange(value)
        }
        onKeyDown={e => {
          if (e.key === 'Enter') {
            handleEnterSpace(true);
            return;
          }

          if (e.key === ' ' ||
            e.code === 'Space' ||
            e.keyCode === 32
          ) {
            handleEnterSpace();
          }
        }}
      />
      {label &&
        <label
          className={joinclass('form-check-label', labelClassName)}
          htmlFor={localId}
        >
          {label}
        </label>
      }
    </React.Fragment>
  );
};

export default InputCheck;
