/* eslint-disable no-tabs */
import React from 'react';
import styled from 'styled-components';
import BackIcon from '../../assets/images/elements/BackIcon';

export const WrapBack = styled.div`
	display: inline-flex;
	flex-direction: row;
	margin-left: -4px;
	gap: 4px;
	font-size: 16px;

	.btn-icon-back {
		cursor: pointer;
		margin-top: -2px;
		user-select: none;
		&:hover {
			opacity: 0.65;
		}
	}
`;

const BackButton = ({ children, onClick = () => { }, className }) => (
	<WrapBack className={className} >
		<div
			className="btn-icon-back"
			aria-hidden="true"
			onClick={(evt) => onClick(evt)}
		>
			<BackIcon />
		</div>
		<div className="btn-back-info">{children}</div>
	</WrapBack>
);

export default BackButton;
