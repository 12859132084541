import { of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, switchMap } from 'rxjs/operators';

import apiService from '../services/apiService';
import ErrorUtils from '../utils/errorUtils';
import { SIGN_CONTRACT_LOAN } from '../store/actionTypes';
import {
    signContractLoanSuccess,
    fetchFulfillmentResults,
} from '../actions';

export function signContractEpic(action$) {
    return action$.pipe(
        ofType(SIGN_CONTRACT_LOAN),
        switchMap(({ payload }) => {
            const {
                stepUpToken, loanApplicationNumber, initiationFeePaymentMethod, isSelectInsurance,
            } = payload;

            return apiService
                .signContract(
                    stepUpToken,
                    loanApplicationNumber,
                    initiationFeePaymentMethod,
                    isSelectInsurance,
                )
                .pipe(
                    switchMap(res => of(
                        signContractLoanSuccess(res),
                        fetchFulfillmentResults({
                            stepUpToken,
                            loanReferenceNumber: loanApplicationNumber,
                        }),
                    )),
                    catchError(error => ErrorUtils.getApiAction(error)),
                );
        }));
}

export default combineEpics(signContractEpic);
