import React, { Component } from 'react';
import { connect } from 'react-redux';
import ReactOtp from 'react-otp-input-z';
import joinclass from 'joinclass';
import _ from 'lodash';
import { $on } from 'event-bus-e2z';

import { hidePhoneNumber } from '../utils/format';
import { playSoundPhoneVibration } from '../utils/soundUtils';

import {
    generateOtp,
    verifyOtp,
    verifyOtpFailed,
    verifyLoanApplication,
    verifyLoanApplicationInElibility,
    resetLoanSubmission,
    saveStepsInput,
} from '../actions';

import { ErrorCode, OTPError } from '../constants';
import { EventBusName, ScreenBusUtils } from '../hooks/useEventBus';
import { SegmentBusTrackOtp } from '../segment-bus/SegmentBusTrackOtp';

import { ShareCommonContext } from '../context/CommonContext';
import BackButton from '../components/Common/BackButton';
import TimeCounterOtp from '../components/Common/TimeCounterOtp';

const MAX_OTP_TIME = 3;

export class OTP extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
            // hasError: true,
            otpAttempt: 0,
            resendAttempt: 1,
        };
        this.refsOtp = React.createRef();
    }

    componentDidMount() {
        // user need verify otp again
        if (this.props.stepsInputData.refreshToken) {
            this.props.verifyOtpFailed({});
            ScreenBusUtils.goHome();
            return;
        }

        // This code for case resumse loan application
        const loanApplicationNumber = _.get(this.props, 'router.location.state.loanApplicationNumber');
        if (loanApplicationNumber) {
            this.props.verifyLoanApplication({ loanApplicationNumber, isFetchStatus: !this.context.declineSpecialCode });
            SegmentBusTrackOtp.segment500OtpResumeCheckScreenDisplayedData();
        }

        // verify loan application number if exist (by said and phone)
        $on(EventBusName.VERIFY_LOAN_APPLICATION_SAID, () => {
            this.props.verifyLoanApplicationInElibility({ ...this.props.verifyLoanApplicationData });
        });

        SegmentBusTrackOtp.segment500OtpCheckScreenDisplayedData();
    }

    componentWillReceiveProps(nextProps) {
        const { otp } = nextProps;
        const maxTimes = MAX_OTP_TIME - 1;
        if (this.state.otpAttempt > maxTimes) {
            return;
        }

        const errorCode = _.get(otp, 'error.errorCode');
        if (errorCode) {
            playSoundPhoneVibration();

            // clear code for new
            this.props.verifyOtpFailed({});

            // focus ready
            const inputs = this.refsOtp.current.querySelectorAll('input');
            inputs[0].focus();

            // max time
            if (this.state.otpAttempt === maxTimes) {
                this.setState({
                    hasError: true,
                    otpAttempt: this.state.otpAttempt + 1,
                    value: '',
                });
                ScreenBusUtils.showErrorCode(ErrorCode.OTP_CANNOT_VERIFY);
                return;
            }

            // invalid otp or token
            if (errorCode === OTPError.INVALID_OTP || errorCode === OTPError.TOKEN_NOT_MET) {
                this.setState({
                    hasError: true,
                    otpAttempt: this.state.otpAttempt + 1,
                    value: '',
                });
                return;
            }

            // something error
            ScreenBusUtils.showErrorCode(errorCode);
        }
    }

    otpChange = (otpCode, fulfilled) => {
        // clear error when change otp
        this.setState({
            hasError: false,
            value: otpCode,
        });

        if (!fulfilled) {
            return;
        }

        // clear focused
        if (this.refsOtp.current) {
            const inputs = this.refsOtp.current.querySelectorAll('input');
            setTimeout(() => [...inputs].forEach(e => e.blur()), 200);
        }

        // eslint-disable-next-line no-shadow
        const { verifyOtp, customer, verifyLoanApplicationData } = this.props;
        const requestToken = verifyLoanApplicationData.requestToken || customer.requestToken;
        verifyOtp({ otpCode, requestToken });

        this.props.saveStepsInput({
            refreshToken: requestToken,
        });
    };

    resendOtp = () => {
        if (this.state.resendAttempt === MAX_OTP_TIME) {
            ScreenBusUtils.showErrorCode(ErrorCode.OTP_LIMIT_REACHED);
            return;
        }

        if (this.refsOtp.current) {
            // focus empty
            const inputs = this.refsOtp.current.querySelectorAll('input');
            const item = [...inputs].find(e => e.value === '') || inputs[0];
            item.focus();
        }

        const {
            // eslint-disable-next-line no-shadow
            generateOtp, customer, verifyLoanApplicationData,
        } = this.props;
        const requestToken = verifyLoanApplicationData.requestToken || customer.requestToken;
        generateOtp({ requestToken });

        this.setState({
            resendAttempt: this.state.resendAttempt + 1,
            otpAttempt: 0,
            hasError: false,
        });
    };

    render() {
        const { customerInfo, verifyLoanApplicationData } = this.props;
        const phoneNumber = _.get(customerInfo, 'phoneNumber') || _.get(verifyLoanApplicationData, 'phoneNumber');

        return (
            <React.Fragment>
                <BackButton
                    onClick={() => {
                        this.props.saveStepsInput({
                            refreshToken: undefined,
                        });
                        ScreenBusUtils.backBrowserScreenResumeOtp(this.props.stepsInputData || {});
                    }}
                >
                    Cellphone number: {hidePhoneNumber(phoneNumber)}
                </BackButton>

                <div ref={this.refsOtp} className={joinclass('form-group', this.state.hasError && 'has-error')}>
                    <h2 className="font-size-14 font-size-md-16 my-4">
                        Enter the OTP to authorise TymeBank to start your Personal Loan application process
                    </h2>

                    <div className="mb-2">OTP</div>
                    <ReactOtp
                        className="gap-16 otp-control"
                        otpClassName="form-control otp-number"
                        hasError={this.state.hasError}
                        value={this.state.value}
                        onCompleted={this.otpChange}
                    />

                    <div className="with-errors">
                        Incorrect OTP. Please try again {this.state.otpAttempt} of 3 attempts
                    </div>
                </div>

                <TimeCounterOtp handleResendOtp={this.resendOtp} />
            </React.Fragment>
        );
    }
}

OTP.contextType = ShareCommonContext;

const mapStateToProps = state => ({
    otp: state.otp,
    customer: state.said.customer,
    verifyLoanApplicationData: state.verifyLoanApplicationData,
    customerInfo: state.said.customerInfo,
    router: state.router,
    stepsInputData: state.stepsInputData,
});

const mapDispatchToProps = {
    verifyOtp,
    verifyOtpFailed,
    generateOtp,
    resetLoanSubmission,
    saveStepsInput,
    verifyLoanApplication,
    verifyLoanApplicationInElibility,
};

export { OTP as OtpRaw };

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(OTP);

