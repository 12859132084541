import React from 'react';
import styled from 'styled-components';

const WrapTooltip = styled.div`
  font-size: 16px;
  font-weight: 400;
`;

const GrossTooltipItem = styled.div``;

const GrossTooltipItemDetail = styled.div`
  opacity: 0.6;
  font-size: 14px;
  padding-bottom: 8px;
`;

export const FinancialGrossNetContent = () => (
  <WrapTooltip>
    <GrossTooltipItem>
      Gross income
    </GrossTooltipItem>
    <GrossTooltipItemDetail>
      is your total income before tax and deductions.
    </GrossTooltipItemDetail>

    <GrossTooltipItem>
      Net income
    </GrossTooltipItem>
    <GrossTooltipItemDetail>
      is your income after tax, benefits and other deductions.
    </GrossTooltipItemDetail>
    <GrossTooltipItem>
      Your gross income is always bigger than your net income.
    </GrossTooltipItem>
  </WrapTooltip>
);

export const FinancialTotalContent = () => (
  <WrapTooltip>
    <GrossTooltipItem>
      Total monthly credit repayments:
    </GrossTooltipItem>
    <GrossTooltipItemDetail>
      Add up your account payments like store cards, credit cards and other loans.
    </GrossTooltipItemDetail>

    <GrossTooltipItem>
      Total monthly living expenses:
    </GrossTooltipItem>
    <GrossTooltipItemDetail>
      Add up expenses like groceries, rent, school fees, transport, entertainment, etc.
    </GrossTooltipItemDetail>

  </WrapTooltip>
);
