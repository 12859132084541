import React from 'react';

import { FlexColumnCenter } from '../../../components/Common/FlexCenter';
import PLLinkTab from '../../../components/Common/PLLinkTab';
import XWrapMoney from '../../../components/Common/XWrapMoney';

import XLandingFAQItem from './XLandingFAQItem';

import arrrowLeftWhite from '../../../assets/images/arrow-left-white.png';
import arrrowLeftBlack from '../../../assets/images/arrow-left-black.png';

function XLandingR4Question() {
    return (
        <FlexColumnCenter
            className="x-landing-question container gap-16 gap-md-24 font-size-16 font-size-md-20"
        >
            <h1 className="font-size-32 font-size-md-40 text-center">Common questions</h1>

            <XLandingFAQItem
                className="message-right"
                icon={arrrowLeftWhite}
                label="Why are TymeBank and TFG Money offering loans?"
                trackingName="segment01QuestionWhyTymeAndTFG"
            >
                TymeBank and TFG Money have established a strategic partnership. The loan is powered by TFG Money and accessed via TymeBank’s digital channels.
            </XLandingFAQItem>

            <XLandingFAQItem
                className="message-left"
                label="What are fees and interest of the loan?"
                icon={arrrowLeftBlack}
                trackingName="segment01QuestionWhatFeeAndInterest"
            >
                <div>The final fees and interest will always be displayed for you before you accept the loan. In general, below are what we charge:</div>
                <ul className="ml-3">
                    <li className="alpha">Initiation fee (once-off): This is the fee paid when you take out the loan. It can be added to your loan amount and paid off monthly, or you have the option of paying it upfront. It’s calculated based on the loan amount you are getting but will never be more than <XWrapMoney className="font-size-14 font-size-md-16 d-inline-block">1050</XWrapMoney> (VAT exclusive).</li>
                    <li className="alpha">Monthly service fee: <XWrapMoney className="font-size-14 font-size-md-16 d-inline-block">60</XWrapMoney> (VAT inclusive).</li>
                    <li className="alpha">Interest rate: Interest rate is dependent on your credit risk profile at the point of applying, the loan term and size that you want to borrow but it will <span className="font-weight-500">always below the regulatory maximum</span> as set out in Chapter 5 of the National Credit Act 34 of 2005. Our interest rate range is from 1.7% to a maximum of 4.5% per month.</li>
                    <li className="alpha">TFG Money Personal Loan Protection: <XWrapMoney className="font-size-14 font-size-md-16 d-inline-block">3.96</XWrapMoney> per <XWrapMoney className="font-size-14 font-size-md-16 d-inline-block">1000</XWrapMoney> of outstanding balance (View TFG Money Personal Loan Protection Ts&Cs here: (<PLLinkTab url="https://mytfginsure.co.za/t-cs/tfg-money-personal-loan-protection-terms-and-conditions/">TFG Money Personal Loan Protection Terms and Conditions | TFG Insurance</PLLinkTab>).</li>
                </ul>
            </XLandingFAQItem>

            <XLandingFAQItem
                className="message-right"
                label="What do I need to apply?"
                icon={arrrowLeftWhite}
                trackingName="segment01QuestionWhyApply"
            >
                <div>All you need to apply are:</div>
                <ul className="ml-3">
                    <li>Valid SAID number ( must be 18 years or older)</li>
                    <li>Valid mobile number and email address</li>
                    <li>Internet banking details of where your salary is paid in.</li>
                </ul>
            </XLandingFAQItem>

            <XLandingFAQItem
                className="message-left"
                label="How do I make my monthly repayments?"
                icon={arrrowLeftBlack}
                trackingName="segment01QuestionHowRepay"
            >
                Your monthly repayment will be automatically debited from the bank account you provided and consented us to collect through DebiCheck. You can find those details in the loan contract before signing or on the loan management session of the app.
            </XLandingFAQItem>
        </FlexColumnCenter>
    );
}

export default XLandingR4Question;
