import React from 'react';

import WrapperIconModal from '../Common/WrapperIconModal';
import { FlexRowCenter } from '../Common/FlexCenter';
import { getConfigPermissions } from '../../assets/data/selfie-step-data';

import selfieCamera from '../../assets/images/selfie-warning.svg';

const SelfiePermissions = () => {
    const settings = getConfigPermissions();
    return (
        <WrapperIconModal
            icon={<img alt="" src={selfieCamera} width={72} height={72} />}
            title={settings.title}
        >
            <p className="text-center my-2 md-my-3 md-mx-5 mx-2">
                {settings.content}
            </p>

            {settings.step &&
                <div className="my-3 my-md-4 d-flex flex-column gap-16">
                    {settings.step.map((item, index) => (
                        <div key={item} className="d-inline-flex gap-16">
                            <FlexRowCenter className="point">{index + 1}</FlexRowCenter>
                            <div>{item}</div>
                        </div>
                    ))}
                </div>
            }
        </WrapperIconModal>
    );
};

export default SelfiePermissions;
