import React from 'react';
import { FlexColumnCenter } from '../../../components/Common/FlexCenter';
import PLButton from '../../../components/Common/PLButton';
import HelpTip from '../../../components/Common/HelpTip';

import { XNavLogo } from '../../../components/Common/XNavLogo';

function XLandingR3Ready({ applyNow }) {
    return (
        <FlexColumnCenter className="x-landing-ready gap-16 gap-md-24">
            <h1><span className="hight-light">Ready</span> to start?</h1>

            <FlexColumnCenter className="w-100">
                <PLButton onClick={applyNow}>Apply now</PLButton>
            </FlexColumnCenter>

            <FlexColumnCenter>
                <div className="solid-line mb-4 col-3" />
                <p>A TymeBank and TFG Money partnership</p>
            </FlexColumnCenter>

            <div><XNavLogo transparent /></div>

            <HelpTip>
                <small>
                    TymeBank TFG Money Personal Loan to be provided by TymeBank, a registered credit provider (NCRCP 10774).
                </small>
            </HelpTip>
        </FlexColumnCenter>
    );
}

export default XLandingR3Ready;
