/* eslint-disable no-mixed-operators */
import React, { useState } from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import moment from 'moment';
import styled from 'styled-components';
import htmlParser, { convertNodeToElement } from 'react-html-parser';
import joinclass from 'joinclass';

import { ConsentType } from '../constants';
import { formatMoney } from '../utils/format';
import { ScreenBusUtils } from '../hooks/useEventBus';
import { useModalPopupContext } from '../context/ModalPopupContext';
import { useCommonContext } from '../context/CommonContext';

import LabelTitle from '../components/Common/LabelTitle';
import BackButton from '../components/Common/BackButton';
import { WrapAction } from '../components/Common/ActionButtons';
import { PLTooltipInfomationIcon } from '../components/Common/PLTooltip';
import PLButton from '../components/Common/PLButton';
import PLLinkTab from '../components/Common/PLLinkTab';

const WrapBlock = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 8px;
    padding-bottom: 8px;

    &.loan-general {
        font-size: 16px;
        font-weight: 300;
    }

    .col-label, .col-value {
        width: 50%;
        padding: 0 16px;

        small {
            font-size: 14px;
            opacity: 0.65;
            &.percent-rate {
                opacity: 1;
            }
        }
    }

    .col-label2 {
        width: 100%;
        padding: 0 16px;

        small {
            font-size: 14px;
            opacity: 0.65;
        }
    }

    .col-value {
        font-weight: 500;
    }

    @media (max-width: 767px) {
        .col-value {
            text-align: right;
        }
    }

    .loan-tooltip {
        #loanding-agreee-tooltip {
            display: flex;
            align-items: center;
        }
    }
`;

const WrapConfirmError = styled.div`
    color: #4F4C4D;
    font-size: 14px;
`;

const WrapLineDash = styled.hr`
    border-top: 1px dashed #C7C7C7;
`;

const WrapLine = styled.hr`
    border-top: 2px solid #C7C7C7;
`;

// eslint-disable-next-line consistent-return
export const reactOptionLink = (node, index) => {
    const {
        type, name, attribs, children,
    } = node;
    if (type === 'tag') {
        if (name === 'a') {
            return <PLLinkTab key={index} url={attribs.href}>{children[0].data}</PLLinkTab>;
        }

        // append hr line
        if (name === 'section') {
            return (
                <React.Fragment key={index}>
                    {convertNodeToElement(node, index, reactOptionLink)}
                    <hr className="dash-line" />
                </React.Fragment>
            );
        }

        if (name === 'p' && attribs.class === 'item-title') {
            return (
                <React.Fragment key={index}>
                    <hr className="dash-line" />
                    {convertNodeToElement(node, index, reactOptionLink)}
                </React.Fragment>
            );
        }

        // replace tag
        if (name === 'html' || name === 'body' || name === 'head') {
            node.name = 'div';
            return convertNodeToElement(node, index, reactOptionLink);
        }
    }
};

const createProfiles = () => [
    {
        key: 'Name',
        value: 'creditProviderInfo.name',
    },
    {
        key: 'NCRCP number',
        value: 'creditProviderInfo.ncrcpNumber',
    },
    {
        key: 'Company registrattion',
        value: 'creditProviderInfo.companyRegistration',
    },
    {
        key: 'Physical address',
        value: 'creditProviderInfo.address',
    },
    {
        key: 'Contact details',
        value: 'creditProviderInfo.contactDetail',
    },
    {
        key: 'Application Reference number',
        value: 'loanInfo.loanReferenceNumber',
    },
    {
        key: 'Date',
        value: moment(new Date()).format('DD/MM/YYYY'),
        customize: true,
    },
];

const consumerBorrower = () => [
    {
        key: 'Name',
        value: 'customerInfo.fullName',
    },
    {
        key: (
            <React.Fragment>
                <div>Address</div>
                <PLTooltipInfomationIcon id="loanding-agreee-tooltip">
                    <div className="pb-3">To change any of your address details, contact us at 0860 999 119.</div>
                    <div><b>You are responsible for making sure that all of your information is correct before signing this agreement.</b></div>
                </PLTooltipInfomationIcon>
            </React.Fragment>
        ),
        classKey: 'd-flex loan-tooltip',
        value: 'customerInfo.address.fullAddress',
    },
    {
        key: 'Contact details',
        value: 'customerInfo.phone',
    },
    {
        key: 'ID number',
        value: 'customerInfo.said',
    },
];

const partAInfo = () => [
    {
        key: (
            <React.Fragment>
                Principle Debt
                <br />
                <small>Loan amount</small>
            </React.Fragment>
        ),
        value: 'loanInfo.approvalAmount',
    },
    {
        key: 'Initiation Fee*',
        value: 'loanInfo.initiationFee',
    },
    {
        key: (
            <React.Fragment>
                Monthly service fee
                <br />
                <small>Included in instalment</small>
            </React.Fragment>
        ),
        value: 'loanInfo.serviceFee',
    },
    {
        key: 'Annual Interest Rate',
        // value: 'loanInfo.tenor'
        customize: true,
    },
    {
        key: 'Rand value of the interest',
        value: 'loanInfo.totalInterestAmount',
    },
    {
        key: 'Credit cost multiple',
        value: 'loanInfo.creditCostMultiple',
        noFormat: true,
    },
    {
        key: (
            <React.Fragment>
                Instalment
                <br />
                <small>including interest, fees, and required insurance (credit life insurance)</small>
            </React.Fragment>
        ),
        value: 'loanInfo.installmentEachMonth',
    },
    {
        key: 'Number of instalments',
        value: 'loanInfo.tenor',
        noFormat: true,
    },

    {
        key: 'Frequency of instalment payments',
        value: 'loanInfo.frequencyInstallmentPayment',
        noFormat: true,
    },
    {
        key: (
            <React.Fragment>Total of all instalments
                <br />
                <small>Total of all instalments including interest, fees and credit life insurance</small>
            </React.Fragment>
        ),
        value: 'loanInfo.installmentAmount',
    },
    {
        key: (
            <React.Fragment>Cost of credit life insurance
                <br />
                <small>included in instalment (if TFG Money Personal Loan Protection Plan is accepted)</small>
            </React.Fragment>
        ),
        value: 'loanInfo.firstPremiumFeeAmount',
    },
];

const partBInfo = (installmentEachMonth, firstRepaymentDate) => [
    {
        key: 'Monthly instalment amount',
        value: installmentEachMonth,
        customize: true,
    },
    {
        key: 'Monthly instalment due date',
        value: 'loanInfo.monthlyRepaymentDay',
    },
    {
        key: 'Method of payment',
        value: 'loanInfo.methodPayment',
    },
    {
        key: 'Number of instalments',
        value: 'loanInfo.tenor',
    },
    {
        key: 'First instalment due date',
        value: firstRepaymentDate,
        customize: true,
    },
    {
        key: 'Debit Order deduction date',
        value: 'loanInfo.debitOrderDeductionDay',
    },
];

const BlockAItem = ({ item, loanContractInfo }) => {
    if (item.customize) {
        return (
            <React.Fragment>
                {`${_.get(loanContractInfo, 'loanInfo.interestRate', 0)}%`}
                <br />
                <small className="percent-rate">({_.get(loanContractInfo, 'loanInfo.monthlyInterestRatePresent', 0)})</small>
            </React.Fragment>
        );
    }

    if (item.noFormat) {
        return (
            <React.Fragment>
                {_.get(loanContractInfo, item.value)}
            </React.Fragment>
        );
    }

    return (
        <React.Fragment>
            {formatMoney(_.get(loanContractInfo, item.value))}
        </React.Fragment>
    );
};

const LoanAgreement = ({
    data,
    loanContractInfo,
}) => {
    const { showErrorModal, hideModal } = useModalPopupContext();
    const [condition1, setCondition1] = useState(false);
    const [condition2, setCondition2] = useState(false);
    const [active, setActive] = useState(false);

    const { productConsent } = useCommonContext();
    const consentList = _.get(productConsent[ConsentType.CONTRACT_AGREEMENT_QUOTATION], 'consentList', []);

    const firstRepaymentDate = moment(new Date(_.get(loanContractInfo, 'loanInfo.firstRepaymentDate'))).format('DD/MM/YYYY');
    const dateExpired = moment(new Date(_.get(data, 'info.incomeVerificationExpired'))).format('DD/MM/YYYY');

    // Details of Credit Provider
    const creditProfileData = createProfiles();
    const consumerBorrowerData = consumerBorrower();

    const blockAData = partAInfo();
    const blockBData = partBInfo(formatMoney(_.get(loanContractInfo, 'loanInfo.installmentEachMonth')), firstRepaymentDate);

    return (
        <React.Fragment>
            <BackButton className="pb-3" onClick={ScreenBusUtils.gotoScreenPackage}>Back</BackButton>
            <br />
            <LabelTitle small>In terms of section 92 of the National Credit Act 34 of 2005</LabelTitle>
            <WrapLine />

            <WrapBlock>
                <LabelTitle small>Details of Credit Provider</LabelTitle>
                {creditProfileData.map((item) => (
                    <div key={item.key} className="row">
                        <div className="col-label">
                            {item.key}
                        </div>
                        <div className="col-value">
                            <div className="font-weight-bold">
                                {item.customize ? item.value : _.get(loanContractInfo, item.value)}
                            </div>
                        </div>
                    </div>
                ))}
            </WrapBlock>
            <WrapLineDash />

            <WrapBlock>
                <LabelTitle small>Details of Consumer / Borrower</LabelTitle>
                {consumerBorrowerData.map((item) => (
                    <div key={item.key} className="row">
                        <div className={joinclass('col-label', item.classKey)}>
                            {item.key}
                        </div>
                        <div className="col-value">
                            <div className="font-weight-bold">
                                {_.get(loanContractInfo, item.value)}
                            </div>
                        </div>
                    </div>
                ))}
            </WrapBlock>
            <WrapLine />

            <LabelTitle>Quotation</LabelTitle>

            <WrapBlock>
                <LabelTitle small>Part A: Summary</LabelTitle>
                {blockAData.map((item) => (
                    <div key={item.key} className="row">
                        <div className="col-label">
                            {item.key}
                        </div>
                        <div className="col-value">
                            <div className="font-weight-bold">
                                <BlockAItem item={item} loanContractInfo={loanContractInfo} />
                            </div>
                        </div>
                    </div>
                ))}
                <div className="row">
                    <div className="col-label2">
                        *Initiation Fee, charged upfront
                    </div>
                </div>

            </WrapBlock>
            <WrapLineDash />

            <WrapBlock>
                <LabelTitle small>Part B: Repayment Arrangement</LabelTitle>
                {blockBData.map((item) => (
                    <div key={item.key} className="row">
                        <div className="col-label">
                            {item.key}
                        </div>
                        <div className="col-value">
                            <div className="font-weight-bold">
                                {item.customize ? item.value : _.get(loanContractInfo, item.value)}
                            </div>
                        </div>
                    </div>
                ))}
                <div className="row">
                    <div className="col-label2">
                        This Quotation shall be valid for a period of 5 (five) business days and must be read together with the Pre-Agreement Statement.
                    </div>
                </div>
            </WrapBlock>

            <WrapLine />

            {/* ======= */}
            <div className="loan-agreement">
                {htmlParser(consentList[0], { transform: reactOptionLink })}
            </div>
            <WrapLine />

            <div className="form-check">
                <input
                    className="form-check-input"
                    type="checkbox"
                    id="loan-condition1"
                    value={condition1}
                    onChange={() => setCondition1(!condition1)}
                />
                <label className="form-check-label" htmlFor="loan-condition1">
                    <div className="font-size-15 align-self-start align-self-md-center">
                        I have read and accept the terms of the Pre-Agreement Statement and Quotation above.
                    </div>
                </label>
            </div>

            <div className="form-check">
                <input
                    className="form-check-input"
                    type="checkbox"
                    id="loan-condition2"
                    value={condition2}
                    onChange={() => setCondition2(!condition2)}
                />
                <label className="form-check-label" htmlFor="loan-condition2">
                    <div className="font-size-15 align-self-start align-self-md-center">
                        I have read and agree to the TymeBank TFG Money Personal Loan terms & conditions.
                        <PLLinkTab url="https://www.tymebank.co.za/inchannel/terms-conditions-personal-loans.html">
                            View Personal Loan View Ts&Cs
                        </PLLinkTab>
                    </div>
                </label>
            </div>

            {
                active && (!condition1 || !condition2) && (
                    <div className="label-error">
                        Please tick all the checkboxes to continue.
                    </div>
                )
            }

            <WrapAction className="buttons flex-column-reverse d-flex d-md-block mt-4 mb-4">
                <PLButton
                    primary={false}
                    onClick={() => {
                        showErrorModal({
                            modalTitle: 'Sure you want to finalise later?',
                            modalContent: (
                                <WrapConfirmError>
                                    <p>If you prefer to finalise later, we’ll email you a link you can use to continue the application when you’re ready. The link will expire on {dateExpired}. </p>
                                </WrapConfirmError>
                            ),
                            cancelTitle: 'I need to think again',
                            showXClose: false,
                            okTitle: 'Stay',
                            handleOK: hideModal,
                            handleCancel: ScreenBusUtils.goHome,
                        });
                    }}
                >
                    Maybe later
                </PLButton>

                <PLButton
                    onClick={() => {
                        if (!condition1 || !condition2) {
                            setActive(true);
                            return;
                        }
                        ScreenBusUtils.gotoScreenIntroSelfie();
                    }}
                >
                    Accept and continue
                </PLButton>
            </WrapAction>
        </React.Fragment>
    );
};


const mapStateToProps = state => ({
    data: _.get(state, 'loanDetail.loanDetail.loan'),
    otp: state.otp,
    loanContractInfo: state.loanContractInfo.loanContractInfo,
    fulfillmentResults: state.fulfillmentResults.result,
});

export default connect(
    mapStateToProps,
    {},
)(LoanAgreement);
