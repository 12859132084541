import React from 'react';
import joinclass from 'joinclass';

import { scrollElementSmooth } from '../../utils/commonUtils';
import ButtonSelectionTick from './ButtonSelectionTick';

function ButtonListSelectionTick(props) {
    const refsContainer = React.useRef();

    const inputProps = {
        value: props.value,
        btnGroup: props.btnGroup,
        btnIcon: props.icon,
        handleNext: props.handleNext,
    };

    if (props.errorMessage) {
        scrollElementSmooth(refsContainer.current, 'center');
    }

    return (
        <React.Fragment>
            <div className="font-weight-bold mt-4 mb-3">
                {props.label}
            </div>

            <div
                ref={refsContainer}
                className={joinclass('d-flex flex-row flex-wrap gap-20', props.flexClassName ? props.flexClassName : 'justify-content-between')}
            >
                {(props.listData || []).map(item => (
                    <ButtonSelectionTick
                        {...inputProps}
                        key={item.code}
                        isSelected={props.selectedValue === item.code}
                        onClick={() => props.handleChangeSelection && props.handleChangeSelection(item.code, item.name)}
                    >
                        {item.name}
                    </ButtonSelectionTick>
                ))}
            </div>

            {props.errorMessage && <div className="with-errors d-block mt-2">{props.errorMessage}</div>}
        </React.Fragment>
    );
}

export default ButtonListSelectionTick;
