import { handleActions } from 'redux-actions';
import {
    FETCH_CONTRACT_INFO,
    FETCH_CONTRACT_INFO_FAILED,
    FETCH_CONTRACT_INFO_SUCCESS,
} from '../store/actionTypes';

const initialState = {
    isLoading: null,
    loanContractInfo: null,
};

export const actions = {
    [FETCH_CONTRACT_INFO]: (state = initialState) => ({
        ...state,
    }),
    [FETCH_CONTRACT_INFO_SUCCESS]: (state, { payload }) => ({
        ...state,
        loanContractInfo: payload,
    }),

    [FETCH_CONTRACT_INFO_FAILED]: (state = initialState, action) => ({
        ...state,
        error: action.payload,
        // do we have any errors here?
    }),
};

export default handleActions(actions, initialState);
