export const segment01LandingDisplayData = {
    event: 'lndPln loan information scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/loan-information/path-happy/apply-landing-displayed/',
        ui_screen_state: 'informational',
        // dev_screen_class_description: 'apply-landing-displayed',
    },
};

export const segment01ABTestingData = {
    event: 'lndPln loan information scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/loan-information/path-happy/apply-ab-testing-displayed/',
        ui_screen_state: 'informational',
    },
};

export const segment01LandingTermChangeData = {
    event: 'lndPln loan information scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/loan-information/path-happy/apply-landing-amount-terms-chosen/',
        ui_screen_state: 'functional',
        // dev_screen_class_description: 'apply-landing-amount-terms-chosen',
    },
};

export const segment01RefreshPageData = {
    event: 'lndPln loan information scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/loan-information/path-happy/apply-landing-refreshed/',
        ui_screen_state: 'informational',
        // dev_screen_class_description: 'apply-landing-refreshed',
    },
};

export const segment01QuestionWhyTymeAndTFG = {
    event: 'lndPln loan information scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/loan-information/path-happy/apply-landing-view-question-tymebank-and-tfg/',
        ui_screen_state: 'informational',
    },
};

export const segment01QuestionWhatFeeAndInterest = {
    event: 'lndPln loan information scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/loan-information/path-happy/apply-landing-view-question-fee-and-interest/',
        ui_screen_state: 'informational',
    },
};

export const segment01QuestionWhyApply = {
    event: 'lndPln loan information scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/loan-information/path-happy/apply-landing-view-question-why-apply/',
        ui_screen_state: 'informational',
    },
};

export const segment01QuestionHowRepay = {
    event: 'lndPln loan information scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/loan-information/path-happy/apply-landing-view-question-how-repay/',
        ui_screen_state: 'informational',
    },
};

export const segment02ConsentData = {
    event: 'lndPln consent scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/consent/path-happy/eligibility-declaration-displayed/',
        ui_screen_state: 'functional',
        // dev_screen_class_description: 'eligibility-declaration-displayed',
    },
};

