import { combineEpics, ofType } from 'redux-observable';
import { of, timer } from 'rxjs';
import { catchError, exhaustMap, filter, switchMap, takeUntil } from 'rxjs/operators';

import apiService from '../services/apiService';
import Config from '../config';

import { handleErrorApi, updateOfferList, verifyLoanApplicationSuccess } from '../actions';
import {
    DECISION_RESULTS_ELIGIBLE,
    DECISION_RESULTS_IN_PROGRESS,
    DECISION_RESULTS_INELIGIBLE,
    FETCH_DECISION_RESULTS,
    FETCH_DECISION_UPSELL_RESULTS,
} from '../store/actionTypes';
import { ScreenBusUtils } from '../hooks/useEventBus';
import { handleDeclineSpecialCode } from '../utils/commonUtils';
import ErrorUtils from '../utils/errorUtils';

import {
    DecisioningResult,
    ErrorCode,
    INCONME_TYPE_VERIFIER,
    LoanStatusApproved,
    profileEFType,
    UpsellingResultStatus,
} from '../constants';

const {
    CONDITIONALLY_APPROVED,
    CONDITIONALLY_APPROVED_COLLECTION_RECEIVED,
    DECLINED,
    APPROVED,
    OFFER_RETRACTED,
} = DecisioningResult.loanApplicationStatus;

/**
 * Fetches decision results for a loan application and processes the response based on various conditions.
 * @param {Object} payload - An object containing loan application details.
 * @param {Observable} state$ - An observable state stream.
 * @returns {Observable} - Emits Redux actions or observables based on decision results and conditions.
 */
export const getDecisionResultsInDecisionEpic = (payload, state$) => {
    const {
        loanApplicationNumber,
        isReceivedSalaryInTyme,
        isLoanSubmit,
        isSelectedTymeAffordability,
        isUpselling,
        offerUpsell,
    } = payload;

    return apiService
        .getDecisionResults(loanApplicationNumber, isUpselling)
        .pipe(
            switchMap(res => {
                // res = desicionData;
                const { decisioningStatus, loanApplicationStatus, upsellStatus } = res;
                const declineReasonDetails = res.declineReasonDetails || {};
                const { COMPLETED } = DecisioningResult.decisioningStatus;

                if (isUpselling || offerUpsell) {
                    if (UpsellingResultStatus.ERROR[upsellStatus]) {
                        ScreenBusUtils.hideLoadingLazy(0);
                        return of(
                            { type: DECISION_RESULTS_ELIGIBLE },
                            updateOfferList({ errorUpsell: true, timeNow: new Date() }),
                        );
                    }

                    if (state$.value.decisionResults.retryCount >= 8) {
                        ScreenBusUtils.hideLoadingLazy(0);
                        return of(
                            { type: DECISION_RESULTS_ELIGIBLE },
                            updateOfferList({ timeoutUpsell: true, timeNow: new Date() }),
                        );
                    }

                    if (UpsellingResultStatus.POOLING[upsellStatus]) {
                        return of({ type: DECISION_RESULTS_IN_PROGRESS });
                    }
                }

                // basic follow
                if (state$.value.decisionResults.retryCount >= 8) {
                    return of(handleErrorApi([{ errorCode: ErrorCode.DE_TIMEDOUT }]));
                }

                if (decisioningStatus === COMPLETED) {
                    const isProfileType = profileEFType.includes(res.profileType);

                    if ([DECLINED, OFFER_RETRACTED].includes(loanApplicationStatus)) {
                        if (declineReasonDetails.code) {
                            ScreenBusUtils.saveDeclineStatus(declineReasonDetails.code, declineReasonDetails);
                        }
                        return of({ type: DECISION_RESULTS_INELIGIBLE, payload: res });
                    }

                    const handleDesicionIfSubmit = handleDesicionIfSubmitProfile({
                        isLoanSubmit,
                        isProfileType,
                        isSelectedTymeAffordability,
                        loanApplicationStatus,
                        loanApplicationNumber,
                        upsellStatus,
                    });

                    if (handleDesicionIfSubmit) {
                        return of(...handleDesicionIfSubmit);
                    }

                    const handleBasic = handleDesicionBasic({
                        isProfileType,
                        isReceivedSalaryInTyme,
                        res,
                    });
                    if (handleBasic) {
                        return of(...handleBasic);
                    }
                }

                if (loanApplicationStatus !== CONDITIONALLY_APPROVED_COLLECTION_RECEIVED && declineReasonDetails.code) {
                    ScreenBusUtils.saveDeclineStatus(declineReasonDetails.code, declineReasonDetails);
                }

                const funcHandle = handleDeclineSpecialCode({
                    declinceCode: declineReasonDetails.code,
                    loanApplicationStatus,
                    handleMissing: true,
                });

                if (funcHandle) {
                    return of({ type: DECISION_RESULTS_ELIGIBLE }, updateOfferList(res));
                }

                return of({ type: DECISION_RESULTS_IN_PROGRESS });
            }),
            catchError(error => {
                if (isUpselling || offerUpsell) {
                    ScreenBusUtils.hideLoadingLazy();
                    return of(
                        { type: DECISION_RESULTS_ELIGIBLE },
                        updateOfferList({ errorUpsell: true, timeNow: new Date() }),
                    );
                }
                return ErrorUtils.getApiAction(error);
            }),
        );
};

export function decisionEpic(action$, state$) {
    return action$.pipe(
        ofType(FETCH_DECISION_RESULTS),
        switchMap(action =>
            timer(0, Config.decisioning.requestFrequentSpan)
                .pipe(
                    takeUntil(action$.pipe(filter(e => e.type !== DECISION_RESULTS_IN_PROGRESS))),
                    exhaustMap(() => getDecisionResultsInDecisionEpic(action.payload, state$)),
                )),
    );
}

export function decisionUpsellEpic(action$, state$) {
    return action$.pipe(
        ofType(FETCH_DECISION_UPSELL_RESULTS),
        switchMap(action =>
            timer(0, Config.decisioning.requestDecisionUpsellSpan)
                .pipe(
                    takeUntil(action$.pipe(filter(e => e.type !== DECISION_RESULTS_IN_PROGRESS))),
                    exhaustMap(() => getDecisionResultsInDecisionEpic(action.payload, state$)),
                )),
    );
}

export default combineEpics(decisionEpic, decisionUpsellEpic);

export function handleDesicionIfSubmitProfile({
    isLoanSubmit,
    isProfileType,
    isSelectedTymeAffordability,
    loanApplicationStatus,
    loanApplicationNumber,
    upsellStatus,
    declinceCode,
}) {
    if (isLoanSubmit && isProfileType) {
        if (LoanStatusApproved.includes(loanApplicationStatus)) {
            return [
                verifyLoanApplicationSuccess({ loanApplicationNumber, loanApplicationStatus, isEFProfile: true }),
                ScreenBusUtils.redirectScreenAfterResumeByLoanStatus({
                    loanApplicationStatus, isEFProfile: true, upsellStatus, declinceCode,
                }),
            ];
        }

        if (isSelectedTymeAffordability &&
            ![APPROVED, CONDITIONALLY_APPROVED_COLLECTION_RECEIVED].includes(loanApplicationStatus)) {
            return [{ type: DECISION_RESULTS_IN_PROGRESS }];
        }
    }
    return undefined;
}

export function handleDesicionBasic({
    isProfileType,
    isReceivedSalaryInTyme,
    res,
}) {
    const { loanApplicationStatus, inviteUrl, incomeVerifier } = res;
    const isSprintHive = incomeVerifier === INCONME_TYPE_VERIFIER.SPRINT_HIVE;

    if (isProfileType || isReceivedSalaryInTyme) {
        return [
            { type: DECISION_RESULTS_ELIGIBLE },
            updateOfferList(res),
        ];
    }

    if (loanApplicationStatus === CONDITIONALLY_APPROVED && (inviteUrl || isSprintHive)) {
        return [
            { type: DECISION_RESULTS_ELIGIBLE },
            updateOfferList(res),
        ];
    }

    return undefined;
}
