import React from 'react';
import joinclass from 'joinclass';

import { MovingFadeIn } from './AnimateText';
import { PLTooltipInfomationIcon } from './PLTooltip';
import TooltipFee from './TooltipFee';
import XWrapMoney from './XWrapMoney';

function XWrapLabelMoney({
    title,
    smallTitle,
    children,
    classNameLabel,
    classNameMoney,
    classNameSubTitle,
    duration = '0.25s',
    borderBottom = true,
    tooltipId,
    tooltipText,
    tooltipInfoData,
}) {
    return (
        <div className={joinclass('label-money-item d-flex flex-row justify-content-between', borderBottom && 'border-bottom')}>
            <div className="d-flex flex-column gap-4 font-size-14 font-size-md-16 item-label p-0">
                {
                    tooltipId ?
                        <div className="d-inline-flex">
                            <div className={classNameLabel}>{title}</div>
                            {
                                tooltipText ?
                                    <PLTooltipInfomationIcon id={tooltipId}>{tooltipText}</PLTooltipInfomationIcon> :
                                    <TooltipFee id={tooltipId} tooltipInfoData={tooltipInfoData} />
                            }
                        </div> :
                        <div className={classNameLabel}>{title}</div>
                }
                {smallTitle && <div className={joinclass('font-size-12 font-size-md-14 sub-label', classNameSubTitle)}>{smallTitle}</div>}
            </div>

            <MovingFadeIn key={children} className="d-flex flex-column item-value text-right" duration={duration}>
                <XWrapMoney light className={classNameMoney}>
                    {children}
                </XWrapMoney>
            </MovingFadeIn>
        </div>
    );
}

export default XWrapLabelMoney;
