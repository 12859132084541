import React from 'react';
import joinclass from 'joinclass';

const InputEmail = ({
    emailValue,
    errorMessage,
    handleChange = () => { },
    handleEnter = () => { },
    getInputRefs = () => { },
}) => {
    const MessageGuilde = () => {
        if (errorMessage) {
            return <div className="with-errors">{errorMessage}</div>;
        }
        return (
            <div className="tip-guilde">
                Make sure you provide the correct email address. All of your loan application documents and updates will be sent to this email address.
            </div>
        );
    };

    return (
        <div className={joinclass('mb-4', errorMessage && 'has-error')}>
            <label className="input-label">Email address</label>
            <input
                ref={getInputRefs}
                type="email"
                placeholder=""
                className="form-control"
                value={emailValue}
                onChange={({ target }) => {
                    handleChange(target.value);
                }}
                onKeyDown={e => {
                    if (e.key === 'Enter') {
                        handleChange(e.target.value);
                        handleEnter();
                    }
                }}
            />
            <MessageGuilde />
        </div>
    );
};

export default InputEmail;
