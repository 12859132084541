/**
 * If use more, please update it
 */
import React, { useEffect, useRef, useState } from 'react';
import { v4 } from 'uuid';
import joinclass from 'joinclass';
import useOnClickOutside from '../../hooks/useOnClickOutside';
import { FlexRowCenter } from '../Common/FlexCenter';

import SearchIcon from '../../assets/images/elements/SearchIcon';
import LoadingIcon from '../../assets/images/elements/LoadingIcon';
import CloseIcon from '../../assets/images/elements/CloseIcon';

const PLAutoComplete = ({
  nameText,
  nameValue,
  className,
  listSuggest = [], // list suggestion
  text = '',
  setText = () => { }, // set label
  isLoading = false,
  showing = false,
  hasAsync = false,
  placeholder = '',
  noDataMsg = 'There is no data',
  activeIconSearch = false,
  handleSelection = () => { }, // selection value
  handleOutSideClick = () => { }, // when click outside
  handleIconSearch = () => { },
}) => {
  const [id] = useState(`pl-sugggest-${v4()}`);
  const [completeValue, setCompleteValue] = useState({
    localText: text,
    localValue: '',
  });

  const refIndexFocus = useRef();
  const refsSearch = useRef();
  const refsContainer = useRef();

  const hasSearch = completeValue.localText.trim().length > 0;
  const showList = showing && !isLoading && hasSearch;
  const hasOptions = listSuggest.length > 0;

  useOnClickOutside(refsContainer, () => {
    if (showList) {
      handleOutSideClick();
      refIndexFocus.current = -1;
    }
  });

  const bindAutoComplete = () => {
    refIndexFocus.current = -1;
    refsSearch.current.addEventListener('keydown', (e) => {
      let x = document.getElementById(`${id}-autocomplete-list`);
      if (x) {
        x = x.getElementsByTagName('div');
      }

      if (e.keyCode === 40) { // DOWN
        // eslint-disable-next-line no-plusplus
        refIndexFocus.current++;
        addActive(x);
        return;
      }

      if (e.keyCode === 38) { // UP
        // eslint-disable-next-line no-plusplus
        refIndexFocus.current--;
        addActive(x);
        return;
      }

      if (e.keyCode === 13) { // ENTER
        e.preventDefault();
        if (refIndexFocus.current > -1) {
          if (x) {
            x[refIndexFocus.current].click();
          }
        }
      }
    });

    function addActive(x) {
      if (!x) {
        return false;
      }
      removeActive(x);

      if (refIndexFocus.current >= x.length) {
        refIndexFocus.current = 0;
      }

      if (refIndexFocus.current < 0) {
        refIndexFocus.current = (x.length - 1);
      }

      x[refIndexFocus.current].classList.add('autocomplete-active');
      return true;
    }

    function removeActive(x) {
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < x.length; i++) {
        x[i].classList.remove('autocomplete-active');
      }
    }
  };

  useEffect(() => {
    bindAutoComplete();
  }, []);

  return (
    <div ref={refsContainer} className={joinclass('autocomplete', className)}>
      <FlexRowCenter
        className={
          joinclass(
            'autocomplete_loading',
            activeIconSearch && 'autocomplete-search-icon__active',
            isLoading && 'search-icon__busy',
          )
        }
        onClick={() => !isLoading && handleIconSearch()}
      >
        {hasAsync && isLoading && hasSearch ? <LoadingIcon /> : <SearchIcon />}
      </FlexRowCenter>

      <input
        id={id}
        ref={refsSearch}
        value={completeValue.localText}
        autoComplete="off"
        type="text"
        placeholder={placeholder}
        onChange={evt => {
          setCompleteValue({
            ...completeValue,
            localText: evt.target.value,
          });
          setText(evt.target.value);
        }}
      />

      {hasSearch && (
        <FlexRowCenter
          className="autocomplete_clear"
          onClick={() => {
            refsSearch.current.focus();
            setCompleteValue({
              ...completeValue,
              localText: '',
            });
            setText('');
          }}><CloseIcon />
        </FlexRowCenter>
      )}

      {showList &&
        <div id={`${id}-autocomplete-list`} className="autocomplete-items">
          {hasOptions ?
            (listSuggest.map((item) => (
              <div
                key={item[nameValue]}
                onClick={() => {
                  setCompleteValue({
                    localText: item[nameText],
                    localValue: item[nameValue],
                  });
                  handleSelection(item);
                }}
                aria-hidden="true"
              >
                {item[nameText]}
              </div>
            ))) : noDataMsg && <div className="autocomplete-items-empty">{noDataMsg}</div>
          }
        </div>}
    </div>
  );
};

export default PLAutoComplete;
