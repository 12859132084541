import { handleActions } from 'redux-actions';
import {
    DECISION_RESULTS_IN_PROGRESS,
    DECISION_RESULTS_ELIGIBLE,
    FETCH_DECISION_RESULTS,
    FETCH_DECISION_UPSELL_RESULTS,
    DECISION_RESULTS_INELIGIBLE,
    RESET_LOAN_SUBMISSION,
} from '../store/actionTypes';

const initialState = {
    isFetching: null,
    isResultInProgress: null,
    isResultApproved: null,
    isResultDeclined: null,
};

export const actions = {
    [FETCH_DECISION_RESULTS]: () => ({
        ...initialState,
        isFetching: true,
        retryCount: 0,
    }),
    [FETCH_DECISION_UPSELL_RESULTS]: () => ({
        ...initialState,
        isFetching: true,
        retryCount: 0,
    }),
    [DECISION_RESULTS_IN_PROGRESS]: (state) => ({
        ...initialState,
        isResultInProgress: true,
        isFetching: false,
        retryCount: state.retryCount + 1,
    }),
    [DECISION_RESULTS_ELIGIBLE]: () => ({
        ...initialState,
        isResultApproved: true,
        isFetching: false,
    }),
    [DECISION_RESULTS_INELIGIBLE]: (state, { payload }) => ({
        ...initialState,
        isResultDeclined: true,
        isFetching: false,
        result: payload,
    }),
    [RESET_LOAN_SUBMISSION]: () => ({
        ...initialState,
    }),
};

export default handleActions(actions, initialState);
