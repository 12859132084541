import React from 'react';
import HtmlParser from 'react-html-parser';
import _ from 'lodash';

import {
    MovingFadeInFromBottom,
    MovingSlideInFromBottom,
    MovingFadeIn,
    MovingPopIn,
    MovingEffect3D,
    MovingSlideInFromLeft,
    MovingFadeInFromLeft,
    MovingFlipFromBottom,
} from './Common/AnimateText';
import HOCAnimation from '../hooks/HOCAnimation';

// we should make common share for another
const webTitles = [MovingEffect3D, MovingSlideInFromBottom, MovingFadeIn, MovingPopIn, MovingFlipFromBottom,
    MovingFlipFromBottom, MovingFlipFromBottom];
const webContents = [MovingEffect3D, MovingFadeInFromBottom, MovingSlideInFromBottom, MovingFadeIn, MovingFlipFromBottom,
    MovingFlipFromBottom, MovingFlipFromBottom];
const mobileElements = [MovingEffect3D, MovingFadeIn, MovingSlideInFromLeft, MovingFadeInFromLeft];

function LandingBannerLabel({
    title,
    content,
    isMobile,
    parse = true,
}) {
    const targetTitles = isMobile ? mobileElements : webTitles;
    const targetContent = isMobile ? mobileElements : webContents;

    const Title = HOCAnimation(
        _.sample(targetTitles),
        {
            key: title,
            children: <h1 className="banner-header-title">{!parse ? title : HtmlParser(title)}</h1>,
        },
    );

    const Content = HOCAnimation(
        _.sample(targetContent),
        {
            key: content,
            duration: '1.5s',
            children: <div className="banner-header-content">{!parse ? content : HtmlParser(content)}</div>,
        },
    );

    return (
        <React.Fragment>
            <Title />
            <Content />
        </React.Fragment>
    );
}

export default React.memo(LandingBannerLabel);
