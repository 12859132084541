/* eslint-disable camelcase */
import { segmentTemplateData } from './segment-base-template-data';

export const createSegmentProductData = ({
    name, id, terms, loanAmount,
}) => ({
    product_type: name, // string
    product_id: `${id || ''}`, // string
    loan_terms: terms, // int
    loan_amount: loanAmount ? Number(loanAmount) : undefined, // number
});

export const createSegmentEventDatas = ({
    event,
    ui_screen_state,
    ui_screen_path,
    dev_screen_class_name,
    dev_screen_class_description,
    ...properties
}) => {
    const descriptions = ui_screen_path.split('/');
    const description = dev_screen_class_description || descriptions[descriptions.length - 2];
    return {
        event,
        ...segmentTemplateData.properties,
        ui_screen_path,
        ui_screen_state,
        dev_screen_class_name,
        dev_screen_class_description: description,
        ...properties,
    };
};
