import React from 'react';
import HtmlParser from 'react-html-parser';

import Configs from '../../config';

import {
  wrapAccountInformationIcon,
  wrapDocumentTimeIcon,
  wrapEmailNotificationIcon,
  wrapEmailStatementIcon,
  wrapLocationSmallIcon,
  wrapPaymentIntroTruidIcon,
  wrapRepaymentIcon,
  wrapUnlockIcon,
} from '../images/elements/WrapSvgIcon';

import truIDTickIcon from '../../assets/images/truid-verify-icon.png';

export const introTruIDStepData = [
  {
    title: 'Consent',
    content: HtmlParser('Allow truID to securely process your data in compliance with all data protection laws.'),
    icon: <img src={truIDTickIcon} width={80} height={40} alt=" " />,
    iconMobile: <img src={truIDTickIcon} width={48} height={24} alt=" " />,
  },
  {
    title: 'Connect',
    content: HtmlParser('Select the bank that you receive your salary in.'),
    icon: wrapPaymentIntroTruidIcon.viewLarge(),
    iconMobile: wrapPaymentIntroTruidIcon.viewMobile(),
  },

  {
    title: 'Confirm',
    content: HtmlParser('Log in with your <b>internet banking credentials</b> to finalise.'),
    icon: wrapUnlockIcon.viewLarge(),
    iconMobile: wrapUnlockIcon.viewMobile(),
  },
];

export const introTruidCollectionData = [
  {
    title: 'We’ll use this information to confirm your affordability.',
    listItem: [
      {
        name: 'Transaction history',
        viewMobile: wrapDocumentTimeIcon.viewMobile(),
        viewBasic: wrapDocumentTimeIcon.viewBasic(),
      },
      {
        name: 'Bank statement',
        viewMobile: wrapRepaymentIcon.viewMobile(),
        viewBasic: wrapRepaymentIcon.viewBasic(),
      },
    ],
  },
  {
    title: 'This is for your security, it protects you from having someone else apply using your details and we can confirm your identity.',
    listItem: [
      {
        name: 'Address',
        viewMobile: wrapLocationSmallIcon.viewMobile(),
        viewBasic: wrapLocationSmallIcon.viewBasic(),
      },
    ],
  },
  {
    title: 'This information is for your repayment debit order. We’ll also disburse the loan funds into this bank account.',
    listItem: [
      {
        name: 'Account information',
        viewMobile: wrapAccountInformationIcon.viewMobile(),
        viewBasic: wrapAccountInformationIcon.viewBasic(),
      },
    ],
    // noHr
  },
];

// sprinthive
export const introTruIDSprintHiveStepData = [
  {
    title: 'Download the bank statements',
    content: '3-months’ worth of your most recent bank-generated PDF statements from the bank that you receive your salary in.',
    icon: wrapPaymentIntroTruidIcon.viewLarge(),
    iconMobile: wrapPaymentIntroTruidIcon.viewMobile(),
  },

  {
    title: 'Email us the statements',
    content: HtmlParser(`To this email address: <br /><a href="mailto:${Configs.emailLoanDoc}" target="_blank" rel="noopener noreferrer" class="font-weight-300">${Configs.emailLoanDoc}</a>`),
    icon: wrapEmailStatementIcon.viewLarge(),
    iconMobile: wrapEmailStatementIcon.viewMobile(),
  },

  {
    title: 'We’ll contact you',
    content: 'We’ll send you the next steps via email and SMS.',
    icon: wrapEmailNotificationIcon.viewLarge(),
    iconMobile: wrapEmailNotificationIcon.viewMobile(),
  },
];
