import { combineReducers } from 'redux';
import { routerReducer } from 'react-router-redux';
import configurationReducer from './configurationReducer';
import handleErrorApiReducer from './handleErrorApiReducer';
import saidReducer from './saidReducer';
import uiReducer from './uiReducer';
import otpReducer from './otpReducer';
import listValuesReducer from './listValuesReducer';
import stepsInputDataReducer from './confirmationReducer';
import loanSubmissionReducer from './loanSubmissionReducer';
import offersReducer from './offersReducer';
import decisionResults from './decisionResultsReducer';
import listProductsReducer from './listProductsReducer';
import affordabilityResultsReducer from './affordabilityResultsReducer';
import loanDetailReducer from './loanDetailReducer';
import loanContractInfoReducer from './loanContractInfoReducer';
import signContractReducer from './signContractReducer';
import fulfillmentResultsReducer from './fulfillmentResultsReducer';
import eligibilityCheckResultsReducer from './eligibilityCheckResultsReducer';
import verifyLoanApplicationReducer from './verifyLoanApplicationReducer';

export default combineReducers({
    router: routerReducer,
    configuration: configurationReducer,
    handleErrorApi: handleErrorApiReducer,
    said: saidReducer,
    ui: uiReducer,
    otp: otpReducer,
    listValues: listValuesReducer,
    listProducts: listProductsReducer,
    stepsInputData: stepsInputDataReducer,
    loanSubmission: loanSubmissionReducer,
    decisionResults,
    offers: offersReducer,
    affordabilityResults: affordabilityResultsReducer,
    loanDetail: loanDetailReducer,
    loanContractInfo: loanContractInfoReducer,
    signContract: signContractReducer,
    fulfillmentResults: fulfillmentResultsReducer,
    eligibilityCheckResults: eligibilityCheckResultsReducer,
    verifyLoanApplicationData: verifyLoanApplicationReducer,
});
