import React from 'react';
import { FaceApp } from '.';
import useEventBus, { EventBusName } from '../../hooks/useEventBus';

export const WrapFacialSDKContext = ({
    stepsInputData = {},
}) => {
    const { facialSdkData } = stepsInputData || {};

    useEventBus(EventBusName.FACTECT_SDK_SETUP, () => {
        FaceApp.setup();
    });

    useEventBus(EventBusName.FACTECT_SDK_INTITAL, ({ sdkData, resolve = () => { }, reject = () => { } }) => {
        if (sdkData || facialSdkData) {
            const {
                deviceKey, encryptionPublicKey, sdkLicense, isFirst,
            } = sdkData || facialSdkData;

            FaceApp
                .init({
                    deviceKey, encryptionPublicKey, sdkLicense, isFirst,
                })
                .then(resolve, reject);
        }
    });

    useEventBus(EventBusName.FACTECT_SDK_START, (onComplete = () => { }) => {
        FaceApp.onLivenessCheckPressed({
            ...(stepsInputData.facialInfoData || {}),
            ...(stepsInputData.facialSdkData || {}),
            onComplete,
        });
    });

    return <React.Fragment />;
};

export default WrapFacialSDKContext;
