import React from 'react';
import moment from 'moment';
import _ from 'lodash';
import useTask from 'react-task-z';

import { ErrorCode } from '../../constants';
import { useModalPopupContext } from '../../context/ModalPopupContext';
import useEventBus, {
    canUseEventBus,
    EventBusName,
    FacialBusUtils,
    ScreenBusUtils,
} from '../../hooks/useEventBus';
import { useConfirmBeforeBackBrowser } from '../../hooks/useBackBrowser';
import { checkPermisionCamera } from '../../utils/mediaUtils';
import { scrollTopSmooth } from '../../utils/commonUtils';

import SelfiePermissions from './SelfiePermissions';
import SelfieResults from './SelfieResults';
import useFacialStepsSegmentTracking from './useFacialStepsSegmentTracking';

import { SegmentBusTrackFacials } from '../../segment-bus/SegmentBusTrackFacials';
import { getBrowserInformation } from '../../assets/data/selfie-step-data';

export const facialSpecialMessage = {
    FACETEC_ERROR_SDK_2: {
        title: 'You’ve lost internet connection',
        content: 'You got disconnected. Please check your WiFi connection or mobile internet, then try again.',
    },

    FACETEC_ERROR_SDK_5: {
        title: 'Your device is not supported',
        content: 'Unfortunately, your device/browser/platform/version is not supported. You must update it to continue.',
    },

    FACETEC_ERROR_SDK_6: {
        title: 'Change landscape mode',
        content: 'To continue, you must change your viewing orientation from landscape to portrait mode.',
    },

    FACETEC_ERROR_SDK_7: {
        title: 'Change device orienatation',
        content: 'Your phone is upside down. Turn it the right way up in portrait mode to continue.',
    },

    FACETEC_ERROR_SESSION_3: {
        title: 'Facial recognition session ended',
        content: (
            <React.Fragment>
                <p>Your session has ended. Please focus on the selfie verification screen and follow the instructions.</p>
                <p>Then you can try again.</p>
            </React.Fragment>
        )
    },

    GENERIC_ERROR: {
        title: 'We’re having some problems with our selfie-checker',
        content: 'This could be due to a technical issue. You can try again now.',
    },
}

// optimize later
const mappingCode = {
    [ErrorCode.FACIAL_PROVIDER_DOWN]: {
        title: 'The Home Affairs system is currently unavailable',
        content: (
            <React.Fragment>
                Please try again later.
            </React.Fragment>
        ),
        handleOK: ScreenBusUtils.goHome,
        handleTracking: SegmentBusTrackFacials.segment2100FacialVerificationHomeAffairDownErrorData,
        contentTracking: 'Provider (WhoYou) is not available',
    },
    [ErrorCode.FACIAL_NO_PHOTO]: {
        title: 'There’s an issue with your photo at Home Affairs',
        content: (
            <React.Fragment>
                <p>We weren’t able to reference your photo from Home Affairs.</p>
                <p>You need to go to Home Affairs to update your photo.</p>
            </React.Fragment>
        ),
        handleOK: ScreenBusUtils.goHome,
        handleTracking: SegmentBusTrackFacials.segment2100FacialVerificationSaIDErrorNoHanisPhotoData,
        contentTracking: 'Has no Hanis Photo',
    },
    [`
        ${ErrorCode.FACIAL_LIVENESS_CHECK_FAILED},
        ${ErrorCode.FACIAL_COMPARISON_FAILED},
    `]: {
        title: 'We’re having some problems with our selfie-checker',
        content: (
            <React.Fragment>
                <p>This could be due to a technical issue. You can try again now.</p>
            </React.Fragment>
        ),
        button: 'Try again',
        handleOK: onComplete => refreshSesionScreen(false, onComplete),
        contentTracking: {
            [ErrorCode.FACIAL_LIVENESS_CHECK_FAILED]: 'Liveness check failed',
            [ErrorCode.FACIAL_COMPARISON_FAILED]: 'Facial comparison failed',
        },
    },
    [ErrorCode.FACIAL_COMPARISON_LIMIT]: {
        title: 'You’ve reached the limit for taking selfies for this session',
        content: (
            <React.Fragment>
                <p>For security reasons, we have to limit the number of times you can take a selfie to sign the agreement. Please try again later.</p>
            </React.Fragment>
        ),
        contentTracking: 'Liveness check failed reach its limit',
    },
    [ErrorCode.FACIAL_ERROR_CONFIG]: {
        title: 'We’re having some problems with our selfie-checker',
        content: (
            <React.Fragment>
                <p>This could be due to a technical issue. You can try again now.</p>
            </React.Fragment>
        ),
        button: 'Try again',
        codeTracking: ErrorCode.FACIAL_ERROR_CONFIG,
    },
    [ErrorCode.FACIAL_GENERIC]: {
        title: 'We’re having some problems with our selfie-checker',
        content: (
            <React.Fragment>
                <p>This could be due to a technical issue. You can try again now.</p>
            </React.Fragment>
        ),
        button: 'Try again',
        codeTracking: ErrorCode.GENERIC_ERROR,
        contentTracking: 'Technical/Unknown error/Internet error',
    },
    [ErrorCode.FACIAL_UPLOAD_S3]: {
        title: 'We’re having some problems with our selfie-checker',
        content: (
            <React.Fragment>
                <p>This could be due to a technical issue. You can try again now.</p>
            </React.Fragment>
        ),
        button: 'Try again',
        codeTracking: ErrorCode.GENERIC_ERROR,
        contentTracking: 'Technical/Unknown error/Upload S3 error',
    },
    [`
        ${ErrorCode.FACIAL_SELFIE_FAILED},
        ${ErrorCode.FACIAL_UNKNOWN_ERROR},
        ${ErrorCode.FACIAL_LIVENESS_CHECK_FAILED_NO_SELFIE},
        ${ErrorCode.FACIAL_COMPARISON_FAILED_NO_SELFIE},
        ${ErrorCode.FACIAL_GENERIC},
    `]: {
        title: 'We’re having some problems with our selfie-checker',
        content: (
            <React.Fragment>
                <p>This could be due to a technical issue. You can try again now.</p>
            </React.Fragment>
        ),
        button: 'Try again',
        contentTracking: 'Technical/Unknown error/Selfie failed',
    },
    [ErrorCode.FACIAL_RETRTY_TOKEN]: {
        title: 'Facial recognition session ended',
        content: (
            <React.Fragment>
                <p>Your session has ended. You can try again now.</p>
            </React.Fragment>
        ),
        button: 'Try again',
        contentTracking: 'Technical/Retry session/Session ended',
    },
    [ErrorCode.FACIAL_OFFER_RETRACTED]: {
        title: 'We weren’t able to verify your details',
        content: (
            <React.Fragment>
                Please restart the loan application and try again.
            </React.Fragment>
        ),
    },
    TIMEOUT: {
        title: 'The selfie-checker timed out',
        content: (
            <React.Fragment>
                <p>Try again and do not leave the selfie-checker idle for more than 30 seconds.</p>
            </React.Fragment>
        ),
    },
    NO_CAMERA: {
        title: 'Your device doesn’t support this feature',
        content: (
            <React.Fragment>
                Camera not found. Your device needs to have a camera so you can sign the agreement with a selfie.
            </React.Fragment>
        ),
    },
};

export const prepareCameraSelfie = async (onComplete, isRefresh) => {
    await scrollTopSmooth();
    FacialBusUtils.startSDKFacial(onComplete);
    SegmentBusTrackFacials.segment2100FacialVerificationPrepareCaptureSelfieData(isRefresh);
};

export function refreshSesionScreen(isRefresh, onComplete) {
    FacialBusUtils.refreshFacialSession(isRefresh, () => {
        prepareCameraSelfie(onComplete, isRefresh);
    });
}

const useFacialHandleModal = ({
    loanDetail = {},
    onComplete,
    readyFacetec,
    intialFacetec,
}) => {
    const {
        showModalAsyncEvent,
        showConfirmModal,
        showConfirmModalAsyncEvent,
        showErrorModalAsyncEvent,
        hideModal,
    } = useModalPopupContext();

    const dateExpired = moment(new Date(_.get(loanDetail, 'info.incomeVerificationExpired'))).format('DD/MM/YYYY');

    const task = useTask({
        delay: 3500,
        maxLoop: 1,
        callback: () => {
            hideModal();
            ScreenBusUtils.gotoScreenDebicheck();
            SegmentBusTrackFacials.segment2100FacialVerificationSuccessData();
        },
    });

    useEventBus(EventBusName.FACETEC_ASK_CAMERA, () => {
        hideModal();
        checkPermisionCamera()
            .then(
                async granted => {
                    // permission
                    if (!granted) {
                        ScreenBusUtils.hideLoadingLazy(0);
                        FacialBusUtils.showBlockCamera();
                        return;
                    }

                    // facetec was initialized
                    if (readyFacetec) {
                        prepareCameraSelfie(onComplete);
                        return;
                    }

                    // try again once
                    const results = await intialFacetec();
                    if (!results) {
                        FacialBusUtils.showGeneric(() => { });
                        return;
                    }

                    prepareCameraSelfie(onComplete);
                },
                () => FacialBusUtils.showNoSupportCamera());
    });

    useConfirmBeforeBackBrowser({
        dateExpired,
        handleOK: () => {
            hideModal();
            scrollTopSmooth();
            FacialBusUtils.refreshFacialSession(true);
        },
        handlCancel: () => {
            SegmentBusTrackFacials.segment2100FacialVerificationExitData();
            ScreenBusUtils.goHome(300);
        },
    });

    useEventBus(EventBusName.MEDIA_WEBCAM_BLOCK, () => {
        const { chromeAgent } = getBrowserInformation();
        showModalAsyncEvent({
            className: 'modal-permission-camera',
            modalContent: <SelfiePermissions />,
            centerAction: true,
            escOnClose: false,
            showXClose: false,
            showCancel: chromeAgent,
            handleCancel: hideModal,
            cancelTitle: 'Close',
            okTitle: chromeAgent ? 'Try again' : 'Close',
        }).then(() => {
            hideModal();
            if (!chromeAgent) {
                return;
            }

            ScreenBusUtils.showLoading();
            setTimeout(() => {
                ScreenBusUtils.hideLoading();
                FacialBusUtils.askCamera();
            }, 1500);
        });
    });

    useEventBus(EventBusName.MEDIA_WEBCAM_NO_EXIST, () => {
        const codeInfo = mappingCode.NO_CAMERA;
        showConfirmModal({
            escOnClose: false,
            modalTitle: codeInfo.title,
            modalContent: codeInfo.content,
            showCancel: false,
            showXClose: false,
            handleOK: ScreenBusUtils.goHome,
            okTitle: 'Exit',
        });
    });

    useEventBus(EventBusName.FACETEC_NOTIFY_TIMEOUT, () => {
        const codeInfo = mappingCode.TIMEOUT;
        ScreenBusUtils.hideLoading();
        showConfirmModalAsyncEvent({
            escOnClose: false,
            modalTitle: codeInfo.title,
            modalContent: codeInfo.content,
            showCancel: false,
            okTitle: 'Try again',
            handleXClose: ScreenBusUtils.goHome,
        }).then(() => {
            hideModal();
            refreshSesionScreen(true, onComplete);
        });
    });

    useEventBus(EventBusName.FACETEC_NOTIFY_ERROR, (code, callback, message) => {
        const key = Object.keys(mappingCode).find(k => k.includes(code));
        const codeInfo = mappingCode[key] || mappingCode[ErrorCode.FACIAL_GENERIC];

        // optimize later
        const { codeTracking, contentTracking, handleTracking } = codeInfo;
        const params = {
            dev_error_code: codeTracking || code,
            dev_error_description: typeof contentTracking === 'object' ? contentTracking[code] : contentTracking,
        };

        // optimize later
        if (canUseEventBus({
            uniqueId: EventBusName.FACETEC_NOTIFY_ERROR,
            seconds: 0.5,
        })) {
            if (handleTracking) {
                handleTracking(params);
            } else if (contentTracking) {
                if (message) {
                    params.dev_error_description = 'Config error: ' + message;
                }
                SegmentBusTrackFacials.segment2100FacialVerificationGeneralErrorData(params);
            }
        }

        ScreenBusUtils.hideLoading();
        showErrorModalAsyncEvent({
            escOnClose: false,
            modalTitle: codeInfo.title,
            modalContent: codeInfo.content,
            showCancel: false,
            okTitle: codeInfo.button || 'Exit',
            handleXClose: ScreenBusUtils.goHome,
        }).then(() => {
            hideModal();
            const func = callback || codeInfo.handleOK;
            if (func) {
                func(onComplete);
                return;
            }
            FacialBusUtils.refreshFacialSession(ErrorCode.FACIAL_RETRTY_TOKEN === code);
        });
    });

    useEventBus(EventBusName.FACTECT_SDK_ERROR, (code, isSDK) => {
        const title = isSDK ? 'FACETEC_ERROR_SDK' : 'FACETEC_ERROR_SESSION';
        const context = `${title}_${code}`;

        // get context
        const codeInfo = facialSpecialMessage[code] ||
            facialSpecialMessage[context] ||
            facialSpecialMessage.GENERIC_ERROR;

        if (canUseEventBus({
            uniqueId: EventBusName.FACTECT_SDK_ERROR,
            seconds: 0.5,
        })) {
            // sdk was tracking, we only tracking session
            if (facialSpecialMessage[context] && !isSDK) {
                SegmentBusTrackFacials.trackingFacetecSession(code);
            }
        }

        showErrorModalAsyncEvent({
            escOnClose: false,
            modalTitle: codeInfo.title,
            modalContent: codeInfo.content,
            showCancel: false,
            okTitle: 'Try again',
            handleXClose: ScreenBusUtils.goHome,
        }).then(async () => {
            hideModal();
            if (!isSDK) {
                refreshSesionScreen(true, onComplete);
                return;
            }

            const results = await intialFacetec();
            if (!results) {
                FacialBusUtils.showSDKError(undefined, true);
                return;
            }
            prepareCameraSelfie(onComplete);
        })
    });

    useEventBus(EventBusName.FACETEC_NOTIFY_SUCCESS, () => {
        showModalAsyncEvent({
            className: 'modal-selfie-retry',
            escOnClose: false,
            modalContent: <SelfieResults />,
            centerAction: true,
            disabledClose: true,
            showOK: false,
        });
        task.runAfter();
    });

    useFacialStepsSegmentTracking();
};

export default useFacialHandleModal;
