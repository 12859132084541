import React from 'react';
import WrapperIconModal from '../Common/WrapperIconModal';
import { wrapDocumentResidenceIcon } from '../../assets/images/elements/WrapSvgIcon';

const ConfirmFlowTruidOrSprintHive = () => (
    <WrapperIconModal
        icon={wrapDocumentResidenceIcon.viewBasic()}
        title="Sure you don’t want to use internet banking to confirm your affordability?"
    >
        <p className="text-center my-2 md-my-3 md-mx-5 mx-2">
            Using truID to log in to your internet banking is the quicker and easier confirmation method.
        </p>

        <p className="text-center my-2 md-my-3 md-mx-5 mx-2">
            Or you can email us your bank statements.
        </p>
    </WrapperIconModal>
);

export default ConfirmFlowTruidOrSprintHive;
