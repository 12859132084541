/* eslint-disable react/no-array-index-key */
/* eslint-disable no-shadow */
import React from 'react';
// import _ from 'lodash';
import TruidLinkTab from './TruidLinkTab';
import LabelTitle from '../Common/LabelTitle';
import PLDiv from '../Common/PLDiv';
import { MovingSlideInFromBottom } from '../Common/AnimateText';

import { introTruidCollectionData } from '../../assets/data/truid-step-data';

const IntroWhoTruID = () => (
  <div className="container mt-3">
    <LabelTitle>*Who is <TruidLinkTab />?</LabelTitle>
    <p className="mt-3 mb-0 font-size-16 intro-truid-who__content">
      <TruidLinkTab /> is a technology that lets you safely share information with banks and credit providers for the purpose of analysing profiles and affordability. The processing of your information strictly complies with all data protections laws. For more information about <TruidLinkTab />, visit <TruidLinkTab>truid.co.za</TruidLinkTab>.
    </p>
  </div>
);

export default IntroWhoTruID;

export const IntroTruIDItemCollection = ({
  title = '',
  listItem = [],
  noHr = false,
  index,
}) => (
  <React.Fragment>
    <MovingSlideInFromBottom duration={`${index * 500}ms`}>
      <div className="row m-0 mb-3 truid-intro-item">
        {listItem.map((item, index) => (
          <div key={index} className="item-icon d-flex">
            <PLDiv
              mobileContent={item.viewMobile}
              webContent={item.viewBasic}
            />
            <div className="font-weight-medium ml-3 font-size-16">
              {item.name}
            </div>
          </div>
        ))}
      </div>
      <p>{title}</p>
      {!noHr && <hr className="py-2" />}
    </MovingSlideInFromBottom>
  </React.Fragment>
);

export const IntroTruIDListCollections = () => {
  const size = introTruidCollectionData.length;
  return introTruidCollectionData.map((item, index) =>
  (
    <IntroTruIDItemCollection
      // eslint-disable-next-line react/no-array-index-key
      key={index}
      title={item.title}
      listItem={item.listItem}
      noHr={index === size - 1}
      index={index + 1}
    />
  ),
  );
};
