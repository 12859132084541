import React from 'react';
import { useModalPopupContext } from '../../context/ModalPopupContext';
import useEventBus, { EventBusName, ScreenBusUtils } from '../../hooks/useEventBus';
import { ErrorCode } from '../../constants';

// optimize later
const mappingCode = {
    [ErrorCode.MANDATE_LOAN_EXPRIRED]: {
        title: 'Your loan application has expired',
        content: (
            <React.Fragment>
                <p>Sorry, your loan application has expired.</p>
                <p>To request a new invitation or any further assistance, please call our Customer Contact Center on 0860 999 119.</p>
            </React.Fragment>
        ),
        goHome: true,
    },
    [ErrorCode.MANDATE_AFTER_CUTOFF]: {
        title: 'We\'ve received your request',
        content: (
            <React.Fragment>
                We'll send you the new debit order mandate at 7:00 tomorrow morning.
            </React.Fragment>
        ),
    },
    [ErrorCode.MANDATE_EXIST_ACCEPTED]: {
        title: 'Your loan application has been disbursed',
        content: (
            <React.Fragment>
                <p>Your loan application has been disbursed.</p>
                <p>If you need more information, contact us at 0860 999 119.</p>
            </React.Fragment>
        ),
    },
    [ErrorCode.MANDATE_SEND_FAILED]: {
        title: 'Debit Order was not sent',
        content: (
            <React.Fragment>
                <p>Please try again in a few minutes.</p>
                <p>If you need further assistance, call us on 0860 999 119.</p>
            </React.Fragment>
        ),
    },
    [ErrorCode.MANDATE_STATUS_INVALID]: {
        title: 'We can’t send you a new debit order mandate',
        content: (
            <React.Fragment>
                <p>Your personal details do not match with your bank account details.</p>
                <p>If you need further assistance, call us on 0860 999 119.</p>
            </React.Fragment>
        ),
        goHome: true,
    },
    DONE: {
        title: 'We\'ve sent you a new debit order mandate',
        content: (
            <React.Fragment>
                Once you accept the mandate, we'll disburse your funds.
            </React.Fragment>
        ),
    },
};

const useHandleResendMandes = () => {
    const {
        showConfirmModal,
        showErrorModal,
        hideModal,
    } = useModalPopupContext();

    useEventBus(EventBusName.MANDATE_NOTIFY_CONFIRM, () => {
        const codeInfo = mappingCode.DONE;
        showConfirmModal({
            modalTitle: codeInfo.title,
            modalContent: codeInfo.content,
            showCancel: false,
            handleOK: hideModal,
            okTitle: 'Close',
        });
    });

    useEventBus(EventBusName.MANDATE_NOTIFY_ERROR, (code) => {
        const codeInfo = mappingCode[code];
        if (!codeInfo) {
            ScreenBusUtils.showErrorCode(code);
            return;
        }

        const handleOK = codeInfo.goHome ? ScreenBusUtils.goHome : hideModal

        showErrorModal({
            modalTitle: codeInfo.title,
            modalContent: codeInfo.content,
            showCancel: false,
            handleOK,
            okTitle: 'Close',
        });
    });
};

export default useHandleResendMandes;
