import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import _ from 'lodash';

import { fetchConfiguration, fetchListValues, saveStepsInput } from '../actions';
import { ScreenBusUtils } from '../hooks/useEventBus';
import { scrollTopSmooth, isEditMode } from '../utils/commonUtils';

import UserInfoTabOne from '../components/UserInformation/UserInfoTabOne';
import UserInfoTabTwo from '../components/UserInformation/UserInfoTabTwo';

export class UserInfo extends Component {
    constructor(props) {
        super(props);

        this.t = this.props.t;
        this.mapPropsLovValuesToLists(true);

        this.isEditMode = isEditMode(this.props);
        this.state = {
            ...this.props.userInfo,
            tabIndex: 0,
        };
    }

    componentDidMount() {
        if (!this.props.values || !Object.keys(this.props.values).length) {
            this.props.fetchListValues();
        }

        if (!this.props.configuration.configs || !Object.keys(this.props.configuration.configs).length) {
            this.props.fetchConfiguration();
        }
    }

    componentDidUpdate(prevProps) {
        if (!Object.keys(prevProps.values).length && Object.keys(this.props.values).length) {
            this.mapPropsLovValuesToLists();
        }
    }

    mapPropsLovValuesToLists = (force) => {
        const {
            EMPLOYMENT_STATUS,
            MARITAL_STATUS,
            MARITAL_TYPE,
            RACE,
            PREVIOUS_EMPLOYMENT_DURATION,
        } = this.props.values;

        this.listEmployment = EMPLOYMENT_STATUS || null;
        this.listMaritalStatus = MARITAL_STATUS || null;
        this.listMaritalType = MARITAL_TYPE || null;
        this.listRace = RACE || null;
        this.listEmploymentDuration = PREVIOUS_EMPLOYMENT_DURATION || null;
        !force && this.forceUpdate();
    };

    handleNextTab = formData => {
        this.setState(
            {
                ...formData,
                tabIndex: 1,
            },
            () => scrollTopSmooth(),
        );
    }

    handleUpdate = (formData, noMove) => {
        const {
            emailValue,
            selectedRace,
            selectedRaceName,
            acceptTymeMarketingConsent,
        } = this.state;

        this.props.saveStepsInput({
            userInfo: {
                ...this.props.userInfo,
                emailValue,
                selectedRace,
                selectedRaceName,
                acceptTymeMarketingConsent,
                selectedPeriodEmployment: null,
                ...formData, // step 2 data
            },
        });
        !noMove && ScreenBusUtils.gotoScreenConfirmation();
    }

    handleNextScreen = (formData) => {
        this.handleUpdate(formData, true);
        ScreenBusUtils.gotoScreenFinancialInfomation();
    }

    render() {
        return (
            <div id="user-info">
                {
                    (this.state.tabIndex === 0) ?
                        (
                            <UserInfoTabOne
                                email={this.state.emailValue}
                                raceCode={this.state.selectedRace}
                                raceName={this.state.selectedRaceName}
                                acceptTymeMarketingConsent={this.state.acceptTymeMarketingConsent}
                                isEditMode={this.isEditMode}
                                listRace={this.listRace}
                                handleNextSection={this.handleNextTab}
                            />
                        )
                        :
                        (
                            <UserInfoTabTwo
                                userData={{ ...this.state }}
                                yearGap={this.props.configuration.START_WORKING_YEAR_GAP}
                                t={this.t}
                                isEditMode={this.isEditMode}
                                listEmployment={this.listEmployment}
                                handleBack={() => this.setState({ tabIndex: 0 }, () => scrollTopSmooth())}
                                handleNextSection={this.isEditMode ? this.handleUpdate : this.handleNextScreen}
                            />
                        )
                }
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    values: state.listValues && state.listValues.values ? state.listValues.values : {},
    configuration: state.configuration || {},
    userInfo: _.get(state, 'stepsInputData.userInfo') || {},
});

export default translate()(
    connect(
        mapStateToProps,
        {
            fetchListValues,
            saveStepsInput,
            fetchConfiguration,
        },
    )(UserInfo),
);
