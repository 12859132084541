import React from 'react';

export const ArrowUpIcon = () => (
  <svg width="18" height="11" viewBox="0 0 18 11" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M0.539063 8.83162L1.94995 10.2578L8.93452 3.11473L15.9191 10.2578L17.33 8.83162L8.93352 0.258306L0.539063 8.83162Z" fill="#231F20" />
  </svg>
);


export const CollapseArrowIcon = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.7185 13.437L6.28151 8L5 9.28151L11.7185 16L18.437 9.28151L17.1555 8L11.7185 13.437Z" fill="#979797" />
  </svg>
);

export const UpArrowIcon = ({
  color = '979797',
}) => (
  <svg width="14" height="8" viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.28151 2.56302L12.7185 8L14 6.71849L7.28151 -5.87349e-07L0.563018 6.71849L1.84453 8L7.28151 2.56302Z" fill={color} />
  </svg>
);

