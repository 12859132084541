import React from 'react';
import joinclass from 'joinclass';
import PLLinkTab from './PLLinkTab';

const tcUrl = 'https://mytfginsure.co.za/t-cs/tfg-money-personal-loan-protection-terms-and-conditions';

function PLTsAndC({
  className,
}) {
  return (
    <div className={joinclass('font-size-13 font-weight-300', className)}>*You must take up credit life insurance for the TymeBank TFG Money Personal Loan.
      The credit provider (TymeBank) brings you the TFG Money Personal Loan Protection credit life product [underwritten by Guardrisk Life Ltd, a licensed life insurer (FSP76) and administered by Foschini Retail Group (Pty) Ltd (FSP32719)].
      Premium guaranteed for 1 month. <PLLinkTab base url={tcUrl}>Ts&Cs apply</PLLinkTab>. You have the right to substitute with your own credit life policy.
    </div>
  );
}
export default PLTsAndC;
