import React from 'react';

import { ConsentType } from '../../constants';
import { useCommonContext } from '../../context/CommonContext';
import HelpTip from '../Common/HelpTip';
import WrapperIconModal from '../Common/WrapperIconModal';
import { MovingSlideInFromBottom } from '../Common/AnimateText';

import ProofOfResidenceIcon from '../../assets/images/elements/ProofOfResidenceIcon';

const LandingConsent = () => {
  const { productConsent } = useCommonContext();
  const consent = productConsent[ConsentType.ELIGIBILITY];

  return (
    <WrapperIconModal
      icon={<ProofOfResidenceIcon />}
      title={consent.title}
    >
      <p className="mb-3 text-center">{consent.description}</p>
      <div className="d-flex flex-column gap-12">
        {consent.consentList.map((item, index) => (
          <MovingSlideInFromBottom
            key={item}
            duration={`${(index + 1) * 500}ms`}><HelpTip key={item} tickIcon>{item}</HelpTip>
          </MovingSlideInFromBottom>
        ))}
      </div>
    </WrapperIconModal>
  );
};

export default LandingConsent;
