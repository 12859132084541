/* eslint-disable no-tabs */
import _ from 'lodash';
import { of } from 'rxjs';

import { ErrorCode } from '../constants';
import { handleErrorApi } from '../actions';
import { ScreenBusUtils } from '../hooks/useEventBus';

export class ErrorUtils {
	static parseError({
		error,
		noDefault = false,
		hideLoading = false,
	}) {
		hideLoading && ScreenBusUtils.hideLoading();
		const { errors = [], errorCode } = _.get(error, 'response') || {};

		if (!noDefault) {
			if (!error.response || Object.keys(error.response).length === 0) {
				return [{ errorCode: ErrorCode.CONNECTIVITY_ERROR }];
			}

			if (errors.length === 0 && !errorCode) {
				return ([{ errorCode: ErrorCode.GENERIC_ERROR }]);
			}
		}

		// eslint-disable-next-line no-restricted-globals
		if (typeof errorCode === 'number') {
			return ([{ errorCode: ErrorCode.GENERIC_ERROR }]);
		}

		const message = errorCode ? error.response : errors[0];
		return [message];
	}

	static getFuncApiAction(error, noDefault = false) {
		return handleErrorApi(ErrorUtils.parseError({
			error,
			noDefault,
			hideLoading: true,
		}));
	}

	static getApiAction(error, noDefault = false) {
		return of(ErrorUtils.getFuncApiAction(error, noDefault));
	}
}

export default ErrorUtils;
