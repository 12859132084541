import React from 'react';

const HomeAddressIcon = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="50" fill="#E3E3E3" />
    <path d="M39.6676 42.2637H26.752V19.0586H39.6676V42.2637Z" fill="#FFCC00" stroke="#4F4D4C" />
    <path d="M21.5859 41.5605L50.0003 19.0586L78.4147 41.5605V80.9388H21.5859V41.5605Z" fill="white" stroke="#4F4C4D" />
    <path d="M65.4992 80.9396H50.0005V55.1562H65.4992V80.9396Z" fill="#FFCC00" stroke="#4F4D4C" />
  </svg>
);

export default HomeAddressIcon;
