import { handleActions } from 'redux-actions';
import { HANDLE_ERROR_API, HIDE_MODAL_HANDLE_ERROR_API } from '../store/actionTypes';

const initialState = {
    showHandleErrorModal: false,
    errors: {},
    onAfterHide: null,
};

export const actions = {
    [HANDLE_ERROR_API]: (state = initialState, action) => ({
        ...state,
        showHandleErrorModal: true,
        errors: action.payload,
    }),
    [HIDE_MODAL_HANDLE_ERROR_API]: state => ({
        ...state,
        showHandleErrorModal: false,
        errors: null,
    }),
};

export default handleActions(actions, initialState);
