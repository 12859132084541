import React from 'react';
import joinclass from 'joinclass';
import PLLinkTab from '../Common/PLLinkTab';

export const truidUrl = 'https://www.truid.co.za';
const TruidLinkTab = ({
  className,
  children,
}) => (
  <PLLinkTab
    className={
      joinclass(className, 'normal-text', children && 'underline')
    }
    url={truidUrl}
  >
    {children || 'truID'}
  </PLLinkTab>
);
export default TruidLinkTab;
