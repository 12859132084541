// 1. loan-package-selection-displayed
export const segment1700LoanPackageSelectionDisplayedData = {
    type: 'track',
    event: 'lndPln loan package select scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/loan-package-select/path-happy/loan-package-selection-displayed/',
        ui_screen_state: 'functional',
        dev_screen_class_description: 'string',
    },
};

// 2. loan-package-not-selected
export const segment1700LoanPackageNotSelectedData = {
    type: 'track',
    event: 'lndPln loan package select scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/loan-package-select/path-error-conditional/loan-package-not-selected/',
        ui_screen_state: 'hint-error',
        dev_screen_class_description: 'string',
    },
};

// 3. loan-package-details
export const segment1700LoanPackageDetailsData = {
    type: 'track',
    event: 'lndPln loan package select scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/loan-package-select/path-happy/loan-package-details/',
        ui_screen_state: 'informational',
        dev_screen_class_description: 'string',
    },
};

// 4. loan-package-confirm
export const segment1700LoanPackageConfirmData = {
    type: 'track',
    event: 'lndPln loan package select scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/loan-package-select/path-happy/loan-package-confirm/',
        ui_screen_state: 'informational',
        dev_screen_class_description: 'string',
    },
};
