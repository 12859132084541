import { handleActions } from 'redux-actions';
import {
    FETCH_FULFILLMENT_RESULTS,
    FULFILLMENT_RESULTS_FAILED,
    FULFILLMENT_RESULTS_IN_PROGRESS,
    FULFILLMENT_RESULTS_SUCCESS,
} from '../store/actionTypes';

const initialState = {
    isFetching: null,
    isResultInProgress: null,
    isResultApproved: null,
    isResultDeclined: null,
    result: null,
};

export const actions = {
    [FETCH_FULFILLMENT_RESULTS]: () => ({
        ...initialState,
        isFetching: true,
        retryCount: 0,
    }),
    [FULFILLMENT_RESULTS_IN_PROGRESS]: (state) => ({
        ...initialState,
        isResultInProgress: true,
        isFetching: false,
        retryCount: state.retryCount + 1,
    }),
    [FULFILLMENT_RESULTS_SUCCESS]: (state, { payload }) => (
        {
            ...initialState,
            isResultApproved: true,
            isFetching: false,
            result: payload,
        }
    ),
    [FULFILLMENT_RESULTS_FAILED]: (state, { payload }) => ({
        ...initialState,
        isResultDeclined: true,
        isFetching: false,
        result: payload,
    }),
};

export default handleActions(actions, initialState);
