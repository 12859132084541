import { useEffect, useRef, useState } from 'react';

const useOnScreen = (ref) => {
    const [isOnScreen, setIsOnScreen] = useState(false);
    const observerRef = useRef(null);

    useEffect(() => {
        try {
            if ('IntersectionObserver' in window) {
                observerRef.current = new IntersectionObserver(([entry]) =>
                    setIsOnScreen(entry.isIntersecting),
                );
            } else {
                setIsOnScreen(false);
                observerRef.current = undefined;
            }
        } catch (ex) {
            setIsOnScreen(false);
            observerRef.current = undefined;
        }
    }, []);

    useEffect(() => {
        observerRef.current && observerRef.current.observe(ref.current);
        return () => {
            observerRef.current && observerRef.current.disconnect();
        };
    }, [ref]);

    return isOnScreen;
};
export default useOnScreen;

export const useElementShowOnce = (ref) => {
    const [isOnce, setOnce] = useState(false);
    const isActived = useOnScreen(ref);

    useEffect(() => {
        isActived && setOnce(true);
    }, [isActived]);

    return isOnce;
};
