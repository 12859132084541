import React from 'react';
import { connect } from 'react-redux';
import { Button } from 'reactstrap';
import joinclass from 'joinclass';

import { isOverlayLoading, isScreenBackgroundLoading } from '../../hooks/useIsLoading';

export const AsyncLoadingRing = () => (
    <div className="loading-ring-async">
        {new Array(12).fill(1).map((_item, index) => <div key={index} />)}
    </div>
);

const AsyncButton = ({
    ui,
    primary = true,
    className,
    light,
    ...props
}) => {
    const isLoading = isOverlayLoading(ui);
    const isBackground = isScreenBackgroundLoading();

    return (
        <Button
            className={joinclass({
                'btn btn-common btn-loading-async': true,
                'btn-yellow': primary,
                'btn-default': !primary,
                'btn-light': light,
                'd-flex justify-content-center align-items-center': true,
                'async-loading': isLoading || isBackground,
                className,
            })}
            {...props}
        >
            {(isLoading || isBackground) ? <AsyncLoadingRing /> : props.children}
        </Button>
    );
};

const mapStateToProps = state => ({
    ui: state.ui,
});

export default connect(mapStateToProps, {})(AsyncButton);
