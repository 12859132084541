import * as landingData from './segment-event-01-landing-data';
import * as decisionData from './segment-event-1300-decision-engine-data';
import * as bankData from './segment-event-1500-bank-data';
import * as packageData from './segment-event-1700-package-data';
import * as upsellData from './segment-event-1800-upsell-data';
import * as facialData from './segment-event-2100-facial-data';
import * as finalData from './segment-event-2200-final-data';

export const segmentTemplateData = {
    event: 'lndPln loan information scrn viewed',
    properties: {
        spec_track: '0.9.0',
        source: 'TFG_LENDING',
        product_area: 'lending',
        product_value_stream: 'personal-loan',
        ui_screen_path: '/027-personal-loan/loan-information/path-happy/apply-landing-displayed/',
        ui_screen_state: 'informational',
        flow_conversion: 'no',
        dev_workflow_id: '',
        dev_session_id: '',
        dev_screen_class_name: '',
        dev_screen_class_description: 'apply-landing-displayed',
        dev_error_code: '',
        dev_error_description: '',
        dev_input_data_captured: '',
        dev_metric: null,
        dev_metric_unit: '',
        dev_sdk_version: '',
        product_type: '',
        product_id: '',
        loan_terms: '', // int
        loan_amount: null, // number
        hash_SAID: '',
        loan_application_number: '',
    },
};

export const mappingConfigSegmentNames = {
    ...landingData,
    ...decisionData,
    ...bankData,
    ...packageData,
    ...upsellData,
    ...facialData,
    ...finalData,
};
