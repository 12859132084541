/**
 * Don't use reducer and Epic, because it don't share data
 */
import React, { useRef, useState } from 'react';
import joinclass from 'joinclass';
import NumberFormat from 'react-number-format';

import { ScreenBusUtils } from '../../hooks/useEventBus';
import useTimeout from '../../hooks/useTimeout';
import { scrollElementSmooth } from '../../utils/commonUtils';

import PLButton from '../Common/PLButton';
import { WrapAction } from '../Common/ActionButtons';

import TruidAutoCompleteCity from './TruidAutoCompleteCity';
import useTruIDConfirmChange from './useTruIDConfirmChange';

const validateAddress = ({
  address,
  city,
  zipCode,
}) => {
  const isErrorAddress = !address.trim();
  const isErrorCity = !city.trim();
  const isErrorZipCode = !zipCode;
  const isOverZipCode = !(zipCode > 0 && zipCode < 10000);

  return {
    isErrorAddress,
    isErrorCity,
    isErrorZipCode: isErrorZipCode || isOverZipCode,
    msgZipCode: isErrorZipCode ? 'Enter a valid postal code' : 'Postal code must be from 0001 to 9999',
  };
};

const TruidFormEditAddress = ({
  dateExpired,
  formData,
  setFormData,
  isFetch,
  onNext = () => { },
}) => {
  const refAddress = useRef();
  const refSubmit = useRef();
  const refForm = useRef();
  const { showConfirmLeaveChange } = useTruIDConfirmChange();

  const [active, setActive] = useState(false);
  const readyActive = active || formData.active;
  const resultValidate = validateAddress(formData);
  const disableSubmit = resultValidate.isErrorAddress || resultValidate.isErrorCity || resultValidate.isErrorZipCode;

  useTimeout(() => refAddress.current.focus(), 250);

  function handleChange(evt) {
    setFormData({
      ...formData,
      [evt.target.name]: evt.target.value,
    });
  }

  function handleKeyUp(evt) {
    let { id } = evt.target;

    if (id === 'truIdAddress' && !resultValidate.isErrorAddress) {
      id = 'truIdSuburd';
    } else if (id === 'truIdSuburd') {
      id = 'truIdCity';
    } else if (id === 'truIdCity' && !resultValidate.isErrorCity) {
      id = 'truidZIPCode';
    }

    if (id) {
      const element = refForm.current.querySelector(`#${id}`);
      setTimeout(() => {
        if (element) {
          element.focus();
        }
      });
    }

    evt.preventDefault();
    readyActive && refSubmit.current.onClick();
  }

  return (
    <section ref={refForm} >
      <div className={joinclass('form-group', readyActive && resultValidate.isErrorAddress && 'has-error')}>
        <div>
          <label htmlFor="truIdAddress">Street address</label>
          <input
            ref={refAddress}
            id="truIdAddress"
            className="form-control"
            value={formData.address}
            name="address"
            maxLength={255}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={handleChange}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                handleKeyUp(event);
              }
            }}
          />
        </div>
        <div className="help-block with-errors">Enter a valid street address</div>
      </div>

      <div className="form-group">
        <div>
          <label htmlFor="truIdSuburd">Suburb</label>
          <input
            id="truIdSuburd"
            className="form-control"
            value={formData.suburb}
            name="suburb"
            maxLength={50}
            // eslint-disable-next-line react/jsx-no-bind
            onChange={handleChange}
            onKeyUp={(event) => {
              if (event.key === 'Enter') {
                handleKeyUp(event);
              }
            }}
          />
        </div>
      </div>

      <div className={joinclass('form-group', readyActive && resultValidate.isErrorCity && 'has-error')}>
        <div>
          <label htmlFor="truIdCity">City</label>
          <TruidAutoCompleteCity
            city={formData.city}
            setCity={city =>
              setFormData({
                ...formData,
                city,
              })
            }
            // eslint-disable-next-line react/jsx-no-bind
            handleKeyUp={handleKeyUp}
          />
        </div>
        <div className="help-block with-errors">Enter a valid city</div>
      </div>

      <div className={joinclass('form-group', readyActive && resultValidate.isErrorZipCode && 'has-error')}>
        <div>
          <label htmlFor="truidZIPCode">Postal code</label>
          <NumberFormat
            id="truidZIPCode"
            name="zipCode"
            className="form-control"
            // maxLength={5}
            value={formData.zipCode}
            format="#####"
            type="tel"
            onChange={e => handleChange(e)}
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleKeyUp(e);
              }
            }}
          />
        </div>
        <div className="help-block with-errors">{resultValidate.msgZipCode}</div>
      </div>

      <WrapAction className="form-group d-md-flex flex-md-row justify-content-center flex-column-reverse d-flex d-md-block">
        <PLButton
          primary={false}
          onClick={() => {
            showConfirmLeaveChange({
              dateExpired,
              handleOK: () => {
                ScreenBusUtils.showTruIDFormAddress(formData);
              },
            });
          }}
        >
          I'll do it later
        </PLButton>

        <PLButton
          refElement={refSubmit}
          disabled={isFetch}
          onClick={() => {
            setActive(true);
            if (disableSubmit) {
              setTimeout(() => {
                const element = refForm.current.querySelector('.has-error input');
                if (element) {
                  scrollElementSmooth(element);
                  element.focus();
                }
              }, 250);
              return;
            }
            onNext(formData);
          }}
        >
          Next
        </PLButton>
      </WrapAction>
    </section>
  );
};

export default TruidFormEditAddress;
