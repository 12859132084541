import React, { useEffect } from 'react';
import WrapperIconModal from '../../components/Common/WrapperIconModal';
import CellPhonePaymentIcon from '../../assets/images/elements/CellPhonePaymentIcon';
import { useModalPopupContext } from '../../context/ModalPopupContext';

const ConfirmSalaryTymebank = ({
    isOpen = false,
    handleNext = () => { },
}) => {
    const { showModal, hideModal } = useModalPopupContext();

    useEffect(() => {
        if (!isOpen) {
            return;
        }

        showModal({
            modalContent: (
                <WrapperIconModal
                    icon={<CellPhonePaymentIcon />}
                    title="Do you receive your monthly salary through TymeBank?"
                >
                    <p className="mb-3 text-center">
                        We noticed that you’re a TymeBank customer. Confirming affordability using your TymeBank account details is the quickest and easiest method.
                    </p>
                </WrapperIconModal>
            ),
            cancelTitle: 'No',
            okTitle: 'Yes',
            showXClose: false,
            escOnClose: false,
            centerAction: true,
            handleOK: () => {
                hideModal();
                handleNext(true);
            },
            handleCancel: () => {
                hideModal();
                handleNext(false);
            },
        });
    }, [isOpen]);

    return <React.Fragment />;
};

export default ConfirmSalaryTymebank;
