import { handleActions } from 'redux-actions';
import {
    POST_LOAN_SUBMISSION,
    POST_LOAN_SUBMISSION_SUCCESS,
    POST_LOAN_SUBMISSION_FAILED,
    RESET_LOAN_SUBMISSION,
} from '../store/actionTypes';

const initialState = {
    captchaToken: null,
    loanSubmissionSuccess: false,
    loanApplicationNumber: null, // has value after submit success
};

export const actions = {
    [POST_LOAN_SUBMISSION]: (state = initialState, action) => ({
        ...state,
        ...action.payload,
    }),
    [POST_LOAN_SUBMISSION_SUCCESS]: (state = initialState, action) => ({
        ...state,
        ...action.payload,
        error: null,
        loanSubmissionSuccess: true,
    }),
    [POST_LOAN_SUBMISSION_FAILED]: (state = initialState, action) => ({
        ...state,
        error: action.payload,
        loanSubmissionSuccess: false,
    }),
    [RESET_LOAN_SUBMISSION]: () => ({
        ...initialState,
    }),
};

export default handleActions(actions, initialState);
