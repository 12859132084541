import React from 'react';
import { Doughnut } from 'react-chartjs-2';
import styled from 'styled-components';

import XWrapLabelMoney from '../Common/XWrapLabelMoney';
import PLDiv from '../Common/PLDiv';
import HelpTip from '../Common/HelpTip';
import PLTsAndC from '../Common/PLTsAndC';

import PlanDetailsRepayment from './PlanDetailsRerayment';

import { removeComma } from '../../utils/format';
import { planPackage } from '../../assets/data/offers-data';

const options = {
  elements: {
    arc: {
      borderWidth: 0,
    },
  },
  aspectRatio: 1,
  maintainAspectRatio: false,
  responsive: true,
  cutoutPercentage: 80,
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
  title: {
    display: false,
  },
};

const chartColors = Object.keys(planPackage).map(item => planPackage[item].color);

export const buildChartData = (data) => ({
  responsive: true,
  labels: data,
  datasets: [
    {
      data,
      backgroundColor: chartColors,
      hoverBackgroundColor: chartColors,
      borderWidth: 1,
    },
  ],
});

const WrapPoint = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  background-color: ${props => props.color};
`;

const PlanDetails = ({
  offer,
}) => {
  // const monthlyRepaymentAmount = removeComma(offer.monthlyRepaymentAmount);
  // const totalRepaymentAmount = removeComma(offer.totalRepaymentAmount);
  const finalApprovedAmount = removeComma(offer.finalApprovedAmount);
  const totalInsuranceFeeAmount = removeComma(offer.totalInsuranceFeeAmount);
  const interestAmount = removeComma(offer.interestAmount);
  const initiationFee = removeComma(offer.establishmentFeeAmount);
  const totalServiceFeeAmount = removeComma(offer.totalServiceFeeAmount);

  const data = [
    finalApprovedAmount,
    totalInsuranceFeeAmount,
    initiationFee,
    interestAmount,
    totalServiceFeeAmount,
  ];

  const chartData = buildChartData(data);

  return (
    <div className="plan-details d-flex flex-column gap-12">
      <h4 className="text-center pt-3">
        Let’s review your <span className="d-inline-block">{offer.tenor}-month</span> repayment plan
      </h4>

      <div className="row d-flex flex-row sm-flex-column p-md-0 m-0">
        <PLDiv
          className="box-boder p-3 col-12"
          mobileContent={
            <PlanDetailsRepayment
              label="Monthly repayment"
            >
              {offer.monthlyRepaymentAmount}
            </PlanDetailsRepayment>
          }
        />

        <div className="col-lg-6 mb-md-0 chartjs p-0">
          <Doughnut data={chartData} options={options} />

          <span className="plan-total-repayment col-12 text-center">
            <PlanDetailsRepayment
              label="Total repayment"
            >
              {offer.totalRepaymentAmount}
            </PlanDetailsRepayment>
          </span>
        </div>

        <div className="col-lg-6 p-0">
          <div className="d-flex flex-column px-0 pl-md-3 gap-8 gap-md-12">
            <PLDiv
              className="box-boder mb-2 p-3"
              webContent={
                <PlanDetailsRepayment
                  label="Monthly repayment"
                >
                  {offer.monthlyRepaymentAmount}
                </PlanDetailsRepayment>
              }
            />

            {Object.keys(planPackage).map((key, index) => {
              const { title, color, subKey } = planPackage[key];
              return (
                <XWrapLabelMoney
                  key={key}
                  duration="1.5s"
                  classNameLabel="d-flex flex-row gap-8 gap-md-12"
                  classNameMoney="money-value"
                  classNameSubTitle="money-text ml-2 ml-md-4"
                  title={
                    <React.Fragment>
                      <div>
                        <WrapPoint className="pl-0 mt-1" color={color} />
                      </div>
                      <div className="money-text col-11 p-0 pr-4">
                        {title}
                      </div>
                    </React.Fragment>
                  }
                  smallTitle={
                    subKey ?
                      `at ${offer.interestRatePresent} (${offer.monthlyInterestRatePresent})` :
                      undefined
                  }
                  borderBottom={index < data.length - 1}
                >
                  {data[index]}
                </XWrapLabelMoney>
              );
            })}
          </div>
        </div>
      </div>

      <PLTsAndC />

      <HelpTip>
        If you’d prefer to pay the initiation fee upfront or use your own credit life insurance, contact us at 0860 999 119.
      </HelpTip>
    </div>
  );
};

export default PlanDetails;
