import React from 'react';

const LinkTel = ({
  tel,
  children,
}) => {
  const content = children || tel;
  return (
    <a
      className="linkColor cursor-pointer"
      onClick={() => window.open(`tel:${tel}`)}
      aria-hidden
    >
      {content}
    </a>
  );
};

export default LinkTel;
