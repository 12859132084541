import React from 'react';
import { formatMoney } from '../../utils/format';

import XWrapLabelMoneyTooltip from './XWrapLabelMoneyTooltip';

function TooltipFeeList({
    listItems = {},
    tooltipInfoData = {},
    terms,
}) {
    return (
        <div className="list-interest-fee d-flex flex-column">
            {Object.keys(listItems).map((key) => {
                const item = listItems[key];
                return (
                    <XWrapLabelMoneyTooltip
                        key={item.title}
                        title={item.title}
                        disabledFormat={item.isPercent}
                        duration="0.5s"
                        subFunMoney={() => {
                            // tooltip offers
                            if (item.subKey) {
                                return tooltipInfoData[item.subKey];
                            }

                            // tooltip landing
                            if (typeof item.subLabel === 'function') {
                                const value = formatMoney(tooltipInfoData[item.key] / terms);
                                return `(${value}/month)`;
                            }
                            return item.subLabel;
                        }}
                    >
                        {item.isPercent ? `${tooltipInfoData[item.key] || 0}%` : tooltipInfoData[item.key]}
                    </XWrapLabelMoneyTooltip>
                );
            })}
        </div>
    );
}

export default TooltipFeeList;
