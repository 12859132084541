import { handleActions } from 'redux-actions';
import { FETCH_LIST_VALUES, FETCH_LIST_VALUES_SUCCESS, FETCH_LIST_VALUES_FAILED } from '../store/actionTypes';

const initialState = {
    isLoading: null,
    values: null, // name as 'values' to avoid 'listValues' of root state name
};

export const sortByOrderValue = array =>
    array && JSON.parse(JSON.stringify(array)).sort((a, b) => parseFloat(a.order) - parseFloat(b.order));

export const actions = {
    [FETCH_LIST_VALUES]: (state = initialState) => ({
        ...state,
        isLoading: true,
    }),
    [FETCH_LIST_VALUES_SUCCESS]: (state, { payload }) => ({
        ...state,
        isLoading: false,
        values: {
            ...payload,
            EMPLOYMENT_STATUS: sortByOrderValue(payload.EMPLOYMENT_STATUS),
            MARITAL_STATUS: sortByOrderValue(payload.MARITAL_STATUS),
            MARITAL_TYPE: sortByOrderValue(payload.MARITAL_TYPE),
            RACE: sortByOrderValue(payload.RACE),
        },
    }),

    [FETCH_LIST_VALUES_FAILED]: (state = initialState, action) => ({
        ...state,
        error: action.payload,
        // do we have any errors here?
    }),
};

export default handleActions(actions, initialState);
