import React from 'react';
import { Route } from 'react-router-dom';
import { connect } from 'react-redux';
import joinclass from 'joinclass';

import 'react-otp-input-z/build/styles.css';
import 'rc-slider/assets/index.css';
import 'react-loading-skeleton/dist/skeleton.css';
import './styles/index.css';

import { routeFullScreen } from './constants';

import CommonContext from './context/CommonContext';
import ModalPopupContext from './context/ModalPopupContext';
import TruIdContext from './context/TruIdContext';

import OverlayLoading from './containers/OverlayLoading';
import LandingBanner from './components/LandingBanner';
import Footer from './components/Footer';
import TapSteps from './components/TapSteps';
import ModalPopup from './components/Common/ModalPopup';
import HandleErrorModal from './components/HandleErrorModal';

import { mappingFullSizePageRouter, mappingXPageRouter } from './assets/data/route-config-data';
import WrapFacialSDK from './assets/face-sdk/WrapFacialSDKContext';

const isFullScreen = (pathname) => routeFullScreen.includes(pathname);

const App = ({ router, stepsInputData }) => {
  const { pathname } = router.location;

  /** We need migrate it */
  return (
    <ModalPopupContext>
      <CommonContext>
        {/* Hidden context */}
        <WrapFacialSDK stepsInputData={stepsInputData} />
        <TruIdContext />
        <LandingBanner />

        <TapSteps pathname={pathname} />

        {mappingFullSizePageRouter.map(({ path, component }) =>
          <Route key={path} exact path={path} component={component} />,
        )}

        {/* ORDER SCREEN  */}
        <div className={joinclass('container pl-container-page', isFullScreen(pathname) && 'page-empty')}>
          {mappingXPageRouter.map(({ path, component }) =>
            <Route key={path} exact path={path} component={component} />)
          }
        </div>

        <Footer />
        <HandleErrorModal />
        <ModalPopup />
        <OverlayLoading />
      </CommonContext>
    </ModalPopupContext>
  );
};

const mapStateToProps = state => ({
  router: state.router,
  stepsInputData: state.stepsInputData,
});

export default connect(mapStateToProps, null)(App);
