import React from 'react';

const RepaymentIcon = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M50 100C77.6142 100 100 77.6142 100 50C100 22.3858 77.6142 0 50 0C22.3858 0 0 22.3858 0 50C0 77.6142 22.3858 100 50 100Z" fill="#E8E8E8" />
    <path d="M32.8821 78.0962H72.0087V26.7901H32.8821V78.0962Z" fill="#FFCC00" />
    <path fillRule="evenodd" clipRule="evenodd" d="M32.8821 78.0962H72.0087V26.7901H32.8821V78.0962Z" stroke="#4F4E4D" />
    <path d="M27.9913 73.2099H67.1179V21.9038H27.9913V73.2099Z" fill="#FEFEFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M27.9913 73.2099H67.1179V21.9038H27.9913V73.2099Z" stroke="#4F4E4D" />
    <path d="M47.5546 36.5627H62.227" stroke="#4F4E4D" />
    <path d="M52.4454 41.449H62.227" stroke="#4F4E4D" />
    <path fillRule="evenodd" clipRule="evenodd" d="M43.8158 34.2518C43.826 31.05 41.0977 30.3327 39.8684 30.3327H32.4399V44.3808H35.4316V38.8447H38.6449C40.649 38.8726 40.7756 40.729 40.8865 42.3556C40.9426 43.1781 40.9947 43.9418 41.2835 44.3808H44.2413C43.7755 43.6874 43.7233 42.6942 43.67 41.6779C43.584 40.039 43.4948 38.3399 41.6624 37.7416C43.1294 37.1084 43.8101 36.0588 43.8158 34.2518ZM35.4335 36.7421L35.4435 32.7701L38.695 32.7844C38.695 32.7844 40.8095 32.6388 40.8095 34.7393C40.8095 36.797 38.685 36.7564 38.685 36.7564L35.4335 36.7421Z" fill="#231F20" />
  </svg>

);

export default RepaymentIcon;
