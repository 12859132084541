import React, { useRef, useState } from 'react';
import joinclass from 'joinclass';

import { FlexColumnCenter } from '../Common/FlexCenter';
import InputCheck from '../Common/InputCheck';
import { MovingSqueezeMix } from '../Common/AnimateText';
import { SegmentBusTrackLanding } from '../../segment-bus/SegmentBusTrackLanding';

import TickIcon from '../../assets/images/elements/TickIcon';

const LandingMonthvalue = ({
  value,
  loanConfig,
  setLoanConfig,
}) => {
  const refsElement = useRef(null);
  const [eventActived, setEventActived] = useState(0);

  const isChecked = value === loanConfig.terms;
  const handleChange = () => {
    setLoanConfig({ ...loanConfig, flgValueHasChange: true, terms: value });
    SegmentBusTrackLanding.segment01LandingTermChangeData();
  };

  return (
    <FlexColumnCenter
      className={
        joinclass(
          'month-terms position-relative',
          isChecked && 'active',
        )
      }
      onClick={() => {
        if (refsElement.current) {
          refsElement.current.focus();
        }
        handleChange();
        setEventActived(val => val + 1);
      }}
    >
      <div className="font-size-36">
        {eventActived ? <MovingSqueezeMix key={eventActived} duration="500ms">{value}</MovingSqueezeMix> : value}
      </div>
      <div>Months</div>

      {isChecked &&
        <div className="month-tick-icon position-absolute">
          <MovingSqueezeMix duration="500ms"><TickIcon /></MovingSqueezeMix>
        </div>
      }

      <InputCheck
        getInputRef={e => { refsElement.current = e; }}
        hidden
        name="month-terms"
        value={value}
        checked={isChecked}
        handleChange={handleChange}
      />
    </FlexColumnCenter>
  );
};

export default LandingMonthvalue;
