import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { translate } from 'react-i18next';

import keys from '../i18n/translationKeys';
import { useCommonContext } from '../context/CommonContext';
import { ScreenBusUtils } from '../hooks/useEventBus';
import { truidResumeStatus } from '../truidConstants';

import LabelLoanApplication from '../components/Common/LabelLoanApplication';
import PLButton from '../components/Common/PLButton';
import { WrapAction } from '../components/Common/ActionButtons';

const OffersDeclined = (props) => {
    const ref = _.get(props, 'match.params.ref');

    const { declineSpecialCode, declineReasonDetails } = useCommonContext();
    const title = _.get(declineReasonDetails, 'title');
    const messsage = _.get(declineReasonDetails, 'message') || props.t(keys.offersDeclinedPage.LEARN_MORE_TEXT);

    // can resume from truid
    const btnOk = _.get(truidResumeStatus[declineSpecialCode], 'buttonOK', 'Got it');
    const btnCancel = _.get(truidResumeStatus[declineSpecialCode], 'buttonCancel');

    const handleGotIt = () => {
        if (btnCancel) {
            ScreenBusUtils.gotoResume(ref);
            return;
        }
        ScreenBusUtils.goHome();
    };

    return (
        <React.Fragment>
            <LabelLoanApplication refNumber={ref} />

            <h2 className="font-size-16 font-size-md-20 font-weight-medium mt-4">{title}</h2>
            <p className="mt-1">{messsage}</p>

            <WrapAction className="buttons my-4">
                <PLButton onClick={() => handleGotIt()}>
                    {btnOk}
                </PLButton>

                {btnCancel &&
                    <PLButton primary={false} onClick={() => ScreenBusUtils.goHome()}>
                        {btnCancel}
                    </PLButton>
                }
            </WrapAction>
        </React.Fragment >
    );
};

const mapStateToProps = state => ({
    result: state.decisionResults.result,
});

export default translate()(
    connect(mapStateToProps)(OffersDeclined),
);
