import React from 'react';
import joinclass from 'joinclass';

import XWrapMoney from '../Common/XWrapMoney';
import InputCheck from '../Common/InputCheck';
import TooltipFee from '../Common/TooltipFee';
import PLButton from '../Common/PLButton';
import { XWrapLabelLargeSkeleton, XWrapLabelMediumSkeleton, XWrapMoneyLargeSkeleton, XWrapMoneyMediumSkeleton } from '../Common/LoadingSkeletons';

import { offerKey, packageKey } from '../../assets/data/offers-data';
import iconChecked from '../../assets/images/iconRadioButtonCheck.svg';
import iconUnChecked from '../../assets/images/iconRadioButtonUnCheck.svg';

function SelectionOfferItem({
    offer,
    isPackage = false,
    isApproved = false,
    isLoading = false,
    isSelected = false,
    handleSelected = () => { },
    handleShowDetails = () => { },
}) {
    const refs = React.useRef();
    const keys = (isPackage || isApproved) ? packageKey : offerKey;

    return (
        <div className="col-lg-4 mb-4 mb-md-0">
            <div
                className={
                    joinclass(
                        'choice px-4 pb-4',
                        isPackage && 'choice-package cursor-pointer',
                        isSelected && 'choice-package-border',
                    )
                }
                aria-hidden="true"
                onClick={(e) => {
                    if (!isPackage || e.target.type === 'button') {
                        return;
                    }
                    refs.current.focus();
                    handleSelected(offer);
                }}
            >
                <div className="my-4 mb-md-5 mt-md-4 font-size-20 font-size-md-24 font-weight-500 position-relative">
                    <XWrapLabelLargeSkeleton loading={isLoading}>
                        {offer.tenor} months

                        {isPackage &&
                            <img
                                className="position-absolute icon-radio"
                                src={isSelected ? iconChecked : iconUnChecked}
                                alt=" "
                            />
                        }
                    </XWrapLabelLargeSkeleton>
                </div>

                <div className="mb-2 mb-md-3">
                    <XWrapLabelMediumSkeleton loading={isLoading}>
                        <div className="money-text">Monthly repayment</div>
                    </XWrapLabelMediumSkeleton>

                    <XWrapMoneyLargeSkeleton loading={isLoading}>
                        <XWrapMoney className="font-size-36 font-size-md-40">
                            {offer[keys.monthRepay]}
                        </XWrapMoney>
                    </XWrapMoneyLargeSkeleton>
                </div>

                <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center m-0 p-0">
                        <XWrapLabelMediumSkeleton loading={isLoading}>
                            <div className="money-text">Total interest &amp; fees</div>

                            {!isPackage &&
                                <TooltipFee
                                    id={`tooltip-${offer.tenor}`}
                                    isOffer
                                    tooltipInfoData={{
                                        ...offer,
                                        value: offer.finalApprovedAmount,
                                        terms: offer.tenor,
                                        subKeyInterestRate: offer.monthlyInterestRatePresent,
                                    }}
                                />
                            }
                        </XWrapLabelMediumSkeleton>
                    </div>

                    <XWrapMoneyMediumSkeleton loading={isLoading}>
                        <XWrapMoney>{offer[keys.totalInterest]}</XWrapMoney>
                    </XWrapMoneyMediumSkeleton>
                </div>

                <div className="solid-line my-2" />

                <div className="d-flex justify-content-between mb-3">
                    <XWrapLabelMediumSkeleton loading={isLoading}>
                        <div className="money-text">Total repayment</div>
                    </XWrapLabelMediumSkeleton>

                    <XWrapMoneyMediumSkeleton loading={isLoading}>
                        <XWrapMoney>{offer[keys.totalRepay]}</XWrapMoney>
                    </XWrapMoneyMediumSkeleton>
                </div>

                {isPackage &&
                    <div className="mb-3 mb-md-4">
                        <PLButton
                            light
                            onClick={() => handleShowDetails(offer)}
                        >
                            View full plan details
                        </PLButton>
                    </div>
                }
            </div>

            {isPackage &&
                <InputCheck
                    getInputRef={e => { refs.current = e; }}
                    hidden
                    name="offter-item"
                    checked={isSelected}
                    handleChange={() => handleSelected(offer)}
                    handleEnterSpace={() => handleShowDetails(offer)}
                />
            }
        </div>
    );
}

export default SelectionOfferItem;
