import React from 'react';

function XLandingLabel({
    children,
}) {
    return (
        <h5 className="font-size-14 font-size-md-16 my-1">{children}</h5>
    );
}

export default XLandingLabel;
