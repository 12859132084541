import React from 'react';

import XWrapMoney from './XWrapMoney';
import PLTsAndC from './PLTsAndC';
import HelpTip from './HelpTip';
import { PLTooltipInfomationIcon } from './PLTooltip';

import TooltipFeeList from './TooltipFeeList';
import { x2LandingInterestFee, x2OfferInterestFee } from '../../assets/x2-data/x2-landing-data';

export const TOOLTIP_DATA = {
    LANDING: {
        header: (value, terms) => (
            <React.Fragment>
                Estimated fee breakdown for loan of
                <br /><XWrapMoney>{value}</XWrapMoney> with a {terms}-month term
            </React.Fragment>
        ),
        content: x2LandingInterestFee,
        footer: (
            <React.Fragment>
                This calculation is only for reference. Your personal interest rate will depend on your financial profile.
            </React.Fragment>
        ),
    },
    OFFERS: {
        header: (finalApprovedAmount, tenor) => (
            <React.Fragment>
                The fee breakdown for loan of<br />
                <XWrapMoney className="mr-1">{finalApprovedAmount}</XWrapMoney> with a {tenor}-month term
            </React.Fragment>
        ),
        content: x2OfferInterestFee,
        footer: (
            <React.Fragment>
                If you’d prefer to pay the initiation upfront or
                use a different credit life insurance provider,
                contact us at 0860 999 119.
            </React.Fragment>
        ),
    },
};

const TooltipFee = ({
    id,
    tooltipInfoData,
    isOffer = false,
}) => {
    if (!id) {
        return <React.Fragment />;
    }

    const tooltipInfo = TOOLTIP_DATA[isOffer ? 'OFFERS' : 'LANDING'];

    return (
        <PLTooltipInfomationIcon id={id} scrollWhenOpen>
            <div className="font-weight-bold mb-2">
                {tooltipInfo.header(tooltipInfoData.value, tooltipInfoData.terms)}
            </div>

            <TooltipFeeList
                listItems={tooltipInfo.content}
                tooltipInfoData={tooltipInfoData}
                terms={tooltipInfoData.terms}
            />

            <PLTsAndC className="mt-2 sub-label" />

            <HelpTip className="item-label mt-3" classNameContent="font-weight-300 font-size-13 sub-label">
                This calculation is only for reference. Your personal interest rate will depend on your financial profile.
            </HelpTip>
        </PLTooltipInfomationIcon>
    );
};

export default TooltipFee;
