import React from 'react';

const TickSuccessIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M16 31C24.2843 31 31 24.2843 31 16C31 7.71573 24.2843 1 16 1C7.71573 1 1 7.71573 1 16C1 24.2843 7.71573 31 16 31Z" fill="#60D154" stroke="#4F4C4D" />
    <path d="M9.625 18.1556L13.4957 22.0263L22.2885 9.875" stroke="white" />
  </svg>
);

export default TickSuccessIcon;
