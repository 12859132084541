import React from 'react';

const AccountInfomationIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z" fill="#E8E8E8" />
    <rect x="13.2484" y="20.123" width="37.5031" height="24.0645" rx="3" fill="white" stroke="#4F4D4C" />
    <path d="M15.5829 20.123H48.4171C49.7064 20.123 50.7516 21.1682 50.7516 22.4575V24.8023H13.2484V22.4575C13.2484 21.1682 14.2936 20.123 15.5829 20.123Z" fill="#FFCC00" stroke="#4F4D4C" />
    <path fillRule="evenodd" clipRule="evenodd" d="M41.3758 34.8301C42.8552 34.8301 44.0546 33.633 44.0546 32.1563C44.0546 30.6795 42.8552 29.4824 41.3758 29.4824C39.8963 29.4824 38.697 30.6795 38.697 32.1563C38.697 33.633 39.8963 34.8301 41.3758 34.8301Z" stroke="#4F4C4D" strokeLinecap="square" />
    <path d="M46.0637 39.5093C46.0637 36.925 43.9648 34.8301 41.3758 34.8301C38.7867 34.8301 36.6879 36.925 36.6879 39.5093" stroke="#4F4C4D" strokeLinecap="square" />
    <path d="M18.606 31.4883H25.303" stroke="#4F4D4C" strokeLinecap="square" strokeLinejoin="round" strokeDasharray="0.75" />
    <path d="M18.606 35.5H31.3303" stroke="#4F4D4C" strokeLinecap="square" strokeLinejoin="round" strokeDasharray="0.75" />
    <path d="M18.606 39.5098H31.3303" stroke="#4F4D4C" strokeLinecap="square" strokeLinejoin="round" strokeDasharray="0.75" />
  </svg>
);

export default AccountInfomationIcon;
