import React, { Component } from 'react';
import { connect } from 'react-redux';
import joinclass from 'joinclass';
import _ from 'lodash';
import NumberFormat from 'react-number-format';

import { translate } from 'react-i18next';
import translationKeys from '../i18n/translationKeys';
import { isSAIDEnoughAge, isValidatedSaid } from '../utils/saidUtil';
import { parseSAID } from '../utils/format';

import { Said as SaidConstant } from '../constants';
import { ScreenBusUtils } from '../hooks/useEventBus';
import { fetchConfiguration, saveStepsInput } from '../actions';

import HelpTip from '../components/Common/HelpTip';
import PLButton from '../components/Common/PLButton';
import PLLinkTab from '../components/Common/PLLinkTab';

export class Said extends Component {
    constructor(props) {
        super(props);

        this.state = {
            said: this.props.saID,
            errorsMsg: '',
        };

        this.refSaid = React.createRef();
    }

    componentDidMount() {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(this.props.VALIDATION_AGE_MINIMUM)) {
            this.props.fetchConfiguration();
        }

        this.refSaid.current.focus();
    }

    componentWillReceiveProps(nextProps) {
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(nextProps.VALIDATION_AGE_MINIMUM)) {
            ScreenBusUtils.goHome();
        }
    }

    getErrorsMsg = (value = '') => {
        const { t } = this.props;
        if (value.trim() === '') {
            return t(translationKeys.saidPage.SAID_EMPTY);
        }

        if (value.length < SaidConstant.LENGTH || !isValidatedSaid(value)) {
            return t(translationKeys.saidPage.SAID_INVALID);
        }

        if (!isSAIDEnoughAge(value, Number(this.props.VALIDATION_AGE_MINIMUM))) {
            return t(translationKeys.saidPage.SAID_INVALID_18_AGE);
        }
        return '';
    }

    handleSAIDChange = e => {
        const said = parseSAID(e.target.value);
        if (said === this.state.said) {
            return;
        }
        this.setState({ said, errorsMsg: '' });
    };

    handleBlur = e => {
        const said = parseSAID(e.target.value);
        if (said.length === SaidConstant.LENGTH) {
            this.setState({ errorsMsg: this.getErrorsMsg(said) });
        }
    };

    handleNext = () => {
        const { said } = this.state;
        const errorsMsg = this.getErrorsMsg(said);
        this.setState({ errorsMsg });

        if (errorsMsg) {
            this.refSaid.current.focus();
            return;
        }

        this.props.saveStepsInput({ said });
        ScreenBusUtils.gotoScreenPhone();
    };

    render() {
        const { t } = this.props;
        const { errorsMsg } = this.state;

        return (
            <React.Fragment>
                <div className={joinclass('form-group', errorsMsg && 'has-error')}>
                    <label>{t(translationKeys.saidPage.SAID_LABEL)}</label>

                    <NumberFormat
                        format="###### #### ## #"
                        className="form-control"
                        type="tel"
                        getInputRef={e => { this.refSaid.current = e; }}
                        value={this.state.said}
                        onChange={this.handleSAIDChange}
                        onKeyDown={e => {
                            if (e.key === 'Enter') {
                                this.handleNext();
                            }
                        }}
                        onBlur={this.handleBlur}
                    />
                    {errorsMsg && <div className="with-errors">{errorsMsg}</div>}
                </div>

                <div className="buttons mt-4 mb-5">
                    <PLButton
                        onClick={this.handleNext}>
                        {t(translationKeys.common.BUTTON_NEXT)}
                    </PLButton>
                </div>

                <HelpTip>
                    {t(translationKeys.saidPage.SAID_POLICY)}
                    <PLLinkTab className="ml-1" url="https://www.tymebank.co.za/inchannel/privacy-policy.html">
                        Privacy Policy
                    </PLLinkTab>
                </HelpTip>
            </React.Fragment>
        );
    }
}

const mapStateToProps = state => ({
    VALIDATION_AGE_MINIMUM: state.configuration.VALIDATION_AGE_MINIMUM,
    saID: _.get(state.stepsInputData, 'said', ''),
});

export default translate()(
    connect(
        mapStateToProps,
        {
            fetchConfiguration,
            saveStepsInput,
        },
    )(Said),
);
