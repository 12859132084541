import { ErrorCode } from '../constants';
import { WrapSegmentBusUtils } from '../hooks/useSegmentEventBus';

import { faceTecSDKStatus, faceTecSessionStatusError } from '../assets/segment/segment-event-2101-facetec-data';

export const createFacetectSessionError = ({ status }) => ({
        dev_workflow_id: 'third-party-facetec',
        dev_error_code: `FACETEC_ERROR_SESSION_${status}`,
        dev_error_description: faceTecSessionStatusError[status],
        dev_input_data_captured: status ? `session-status-code ${status}` : '',
    });

export const createFacetectSDKError = ({ status, inputData }) => ({
        dev_workflow_id: 'third-party-facetec',
        dev_error_code: `FACETEC_ERROR_SDK_${status}`,
        dev_error_description: faceTecSDKStatus[status],
        dev_input_data_captured: inputData || (status ? `sdk-status-code ${status}` : ''),
    });

export class SegmentBusTrackFacials {
    static trackingFacetecSession(status) {
        const params = createFacetectSessionError({ status });
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment2100FacialVerificationGeneralErrorData', params);
    }

    static trackingFacetecSDK(status, inputData) {
        const params = createFacetectSDKError({ status, inputData });
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment2100FacialVerificationGeneralErrorData', params);
    }

    static segment2100FacialVerificationIntroData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment2100FacialVerificationIntroData');
    }

    static segment2100FacialVerificationRequestCameraData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment2100FacialVerificationRequestCameraData');
    }

    static segment2100FacialVerificationPrepareCaptureSelfieData(isRefresh) {
        const params = { dev_input_data_captured: isRefresh ? 'renew-session-id' : '' };
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment2100FacialVerificationPrepareCaptureSelfieData', params);
    }

    static segment2100FacialVerificationCaptureSelfieData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment2100FacialVerificationCaptureSelfieData');
    }

    static segment2100FacialVerificationWaitingResultData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment2100FacialVerificationWaitingResultData');
    }

    static segment2100FacialVerificationSuccessData() {
        const params = {
            dev_error_code: ErrorCode.FACIAL_SUCCESS,
            dev_error_description: 'Facial success',
        };
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment2100FacialVerificationSuccessData', params);
    }

    static segment2100FacialVerificationNoCameraAccessData() {
        const params = {
            dev_error_code: 'FACIAL_NO_CAMERA_ACCESS',
            dev_error_description: 'Need to allow access to the camera',
        };
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment2100FacialVerificationNoCameraAccessData', params);
    }

    static segment2100FacialVerificationGeneralErrorData(params) {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment2100FacialVerificationGeneralErrorData', params);
    }

    static segment2100FacialVerificationOfferRetractedData() {
        const params = {
            dev_error_code: ErrorCode.FACIAL_OFFER_RETRACTED,
            dev_error_description: 'Offer retracted due to failed facial comparison (reach its limit)',
        };
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment2100FacialVerificationOfferRetractedData', params);
    }

    static segment2100FacialVerificationBadLightingErrorData() {
        const params = {
            dev_error_code: 'FACIAL_BAD_LIGHTING',
            dev_error_description: 'Find better lighting, with no glare or extreme lighting',
        };
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment2100FacialVerificationBadLightingErrorData', params);
    }

    static segment2100FacialVerificationSmillingFaceErrorData() {
        const params = {
            dev_error_code: 'FACIAL_NO_SMILING',
            dev_error_description: 'Neutral Expression, No Smiling',
        };
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment2100FacialVerificationSmillingFaceErrorData', params);
    }

    static segment2100FacialVerificationBlurCameraErrorData() {
        const params = {
            dev_error_code: 'FACIAL_NO_CLEAN',
            dev_error_description: 'You’ll need to clean your camera',
        };
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment2100FacialVerificationBlurCameraErrorData', params);
    }

    static segment2100FacialVerificationTimeoutErrorData() {
        const params = {
            dev_error_code: 'FACIAL_TIMEOUT',
            dev_error_description: 'The selfie-checker timed out',
        };
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment2100FacialVerificationTimeoutErrorData', params);
    }

    static segment2100FacialVerificationHomeAffairDownErrorData(params) {
        WrapSegmentBusUtils
            .sendSegmentEventTrackingFromClient('segment2100FacialVerificationHomeAffairDownErrorData', params);
    }

    static segment2100FacialVerificationNoCameraErrorData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment2100FacialVerificationNoCameraErrorData');
    }

    static segment2100FacialVerificationExitData() {
        const params = {
            dev_error_code: 'FACIAL_CLOSE',
            dev_error_description: 'Want to cancel?',
        };
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment2100FacialVerificationExitData', params);
    }

    static segment2100FacialVerificationSaIDErrorNoHanisPhotoData(params) {
        WrapSegmentBusUtils
            .sendSegmentEventTrackingFromClient('segment2100FacialVerificationSaIDErrorNoHanisPhotoData', params);
    }

    static segment2100FacialVerificationSaIDErrorOfferRetractedFailData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment2100FacialVerificationSaIDErrorOfferRetractedFailData');
    }
}
