/**
 * How to use
  const { modalInfo, showModal, showConfirmModal, showErrorModal } = useModalPopupContext()
  showErrorModal({ modalTitle: "abcd " })
 */
import React, { createContext, useContext, useState } from 'react';
import useEventListener from '../hooks/useEventListener';

const isEscape = (e = {}) => ['Esc', 'Escape', 27].includes(e.key || e.keyCode);

const Context = createContext({});
const defaultContext = () => ({
  isShow: false,
  modalTitle: '',
  modalContent: '',
  className: '',
  isConfirm: false,
  isError: false,
  cancelTitle: 'Cancel',
  okTitle: 'Accept',
  errorContent: '',
  errorCode: '',
  showOK: true,
  showCancel: true,
  showXClose: true,
  handleOK: () => { },
  handleCancel: null,
  handleXClose: null,
  handleEscClose: null,
  escOnClose: true,
  showHeader: true,
  disabledClose: false,
  centerAction: false,
  classNameOK: '',
  classNameCancel: '',
});

const ModalPopupContext = ({ children }) => {
  const [modalInfo, setModalInfo] = useState(defaultContext());

  const hideModal = () => {
    setModalInfo(defaultContext());
  };

  useEventListener('keydown', (e) => {
    // Do whatever when esc is pressed
    if (isEscape(e) && !modalInfo.disabledClose) {
      if (!modalInfo.escOnClose) {
        return;
      }
      if (modalInfo.handleEscClose) {
        modalInfo.handleEscClose();
        return;
      }
      if (modalInfo.handleXClose && modalInfo.showXClose) {
        modalInfo.handleXClose();
        return;
      }
      hideModal();
    }
  });

  const showModal = ({
    modalTitle = '',
    modalContent = '',
    className = '',
    cancelTitle = 'Cancel',
    okTitle = 'Accept',
    showOK = true,
    showCancel = true,
    showXClose = true,
    handleOK = () => { },
    handleCancel,
    handleXClose,
    escOnClose = true,
    showHeader = false,
    disabledClose = false,
    centerAction = false,
    classNameOK = '',
    classNameCancel = '',
  }) => {
    setModalInfo({
      ...modalInfo,
      isShow: true,
      modalTitle,
      modalContent,
      className,
      cancelTitle,
      okTitle,
      showCancel,
      showOK,
      showXClose,
      handleOK,
      handleCancel,
      handleXClose,
      escOnClose,
      showHeader,
      disabledClose,
      centerAction,
      classNameOK,
      classNameCancel,
    });
  };

  const showConfirmModal = ({
    modalTitle = '',
    modalContent = '',
    cancelTitle = 'Cancel',
    okTitle = 'OK',
    className,
    showOK = true,
    showCancel = true,
    showXClose = true,
    handleOK = () => { },
    handleCancel,
    handleXClose,
    escOnClose = true,
    showHeader = true,
    disabledClose = false,
    centerAction,
    classNameOK = '',
    classNameCancel = '',
  }) => {
    setModalInfo({
      ...modalInfo,
      isShow: true,
      modalTitle,
      modalContent,
      className,
      isConfirm: true,
      cancelTitle,
      okTitle,
      showCancel,
      showOK,
      showXClose,
      handleOK,
      handleCancel,
      handleXClose,
      escOnClose,
      showHeader,
      disabledClose,
      centerAction,
      classNameOK,
      classNameCancel,
    });
  };

  const showErrorModal = ({
    modalTitle,
    modalContent,
    cancelTitle = 'Cancel',
    okTitle = 'OK',
    className,
    errorCode = '',
    errorContent = '',
    showOK = true,
    showCancel = true,
    showXClose = true,
    handleOK = () => { },
    handleCancel,
    handleXClose,
    escOnClose = true,
    showHeader = true,
    disabledClose = false,
    centerAction,
    classNameOK = '',
    classNameCancel = '',
  }) => {
    setModalInfo({
      ...modalInfo,
      isShow: true,
      modalTitle,
      modalContent,
      className,
      isError: true,
      cancelTitle,
      okTitle,
      errorContent,
      errorCode,
      showCancel,
      showOK,
      showXClose,
      handleOK,
      handleCancel,
      handleXClose,
      escOnClose,
      showHeader,
      disabledClose,
      centerAction,
      classNameOK,
      classNameCancel,
    });
  };

  const showModalAsyncEvent = ({
    modalTitle = '',
    modalContent = '',
    className = '',
    cancelTitle = 'Cancel',
    okTitle = 'Accept',
    showOK = true,
    showCancel = true,
    showXClose = true,
    handleOK,
    handleCancel,
    handleXClose,
    escOnClose = true,
    showHeader = false,
    disabledClose = false,
    centerAction = false,
    classNameOK = '',
    classNameCancel = '',
  }) => new Promise((resolve, reject) => {
    const onCancel = () => {
      reject();
      hideModal();
    };

    setModalInfo({
      ...modalInfo,
      isShow: true,
      modalTitle,
      modalContent,
      className,
      cancelTitle,
      okTitle,
      showCancel,
      showOK,
      showXClose,
      handleOK: handleOK || resolve,
      handleCancel: handleCancel || onCancel,
      handleXClose,
      escOnClose,
      showHeader,
      disabledClose,
      centerAction,
      classNameOK,
      classNameCancel,
    });
  });

  const showConfirmModalAsyncEvent = ({
    modalTitle = '',
    modalContent = '',
    cancelTitle = 'Cancel',
    okTitle = 'OK',
    className,
    showOK = true,
    showCancel = true,
    showXClose = true,
    handleOK,
    handleCancel,
    handleXClose,
    escOnClose = true,
    showHeader = true,
    disabledClose = false,
    centerAction,
    classNameOK = '',
    classNameCancel = '',
  }) => new Promise((resolve, reject) => {
    const onCancel = () => {
      reject();
      hideModal();
    };

    setModalInfo({
      ...modalInfo,
      isShow: true,
      modalTitle,
      modalContent,
      className,
      isConfirm: true,
      cancelTitle,
      okTitle,
      showCancel,
      showOK,
      showXClose,
      handleOK: handleOK || resolve,
      handleCancel: handleCancel || onCancel,
      handleXClose,
      escOnClose,
      showHeader,
      disabledClose,
      centerAction,
      classNameOK,
      classNameCancel,
    });
  });

  const showErrorModalAsyncEvent = ({
    modalTitle,
    modalContent,
    cancelTitle = 'Cancel',
    okTitle = 'OK',
    className,
    errorCode = '',
    errorContent = '',
    showOK = true,
    showCancel = true,
    showXClose = true,
    handleOK,
    handleCancel,
    handleXClose,
    escOnClose = true,
    showHeader = true,
    disabledClose = false,
    centerAction,
    classNameOK = '',
    classNameCancel = '',
  }) => new Promise((resolve, reject) => {
    const onCancel = () => {
      reject();
      hideModal();
    };

    setModalInfo({
      ...modalInfo,
      isShow: true,
      modalTitle,
      modalContent,
      className,
      isError: true,
      cancelTitle,
      okTitle,
      errorContent,
      errorCode,
      showCancel,
      showOK,
      showXClose,
      handleOK: handleOK || resolve,
      handleCancel: handleCancel || onCancel,
      handleXClose,
      escOnClose,
      showHeader,
      disabledClose,
      centerAction,
      classNameOK,
      classNameCancel,
    });
  });

  return (
    <Context.Provider value={{
      modalInfo,
      showModal,
      hideModal,
      showConfirmModal,
      showErrorModal,
      showModalAsyncEvent,
      showConfirmModalAsyncEvent,
      showErrorModalAsyncEvent,
    }}>
      {children}
    </Context.Provider>
  );
};

export const useModalPopupContext = () => useContext(Context);
export { Context as ShareModalContext };
export default ModalPopupContext;
