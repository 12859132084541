import React from 'react';
import _ from 'lodash';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';

import { formatMoney, hidePhoneNumber } from '../utils/format';
import { isHomePage } from '../utils/pageUtils';

import config from '../config';
import { routeName } from '../constants';
import useCommonInit from '../hooks/useCommonInit';
import keys from '../i18n/translationKeys';
import { useCommonContext } from '../context/CommonContext';

import PLDiv from './Common/PLDiv';
import { MovingFadeIn } from './Common/AnimateText';
import { XNavLogo } from './Common/XNavLogo';
import LandingBannerLabel from './LandingBannerLabel';
import TruidLinkTab from './TruId/TruidLinkTab';

import { headerBannerData, headerBannerKeys } from '../assets/data/route-config-data';

// we find i18n for header
export const findHeaderKey = (pathname) => headerBannerKeys.find((key) => {
  if (headerBannerData[key].exact) {
    return key === pathname;
  }
  return pathname.startsWith(key);
});

// landing banner common
function LandingBanner({
  t,
  router,
  fulfillmentResults,
  verifyLoanApplicationData,
  loanSubmission,
  customerInfo,
  stepsInputData,
}) {
  const { product, isLANResuming } = useCommonContext();
  const { pathname } = router.location;

  const loanApplicationNumber = _.get(verifyLoanApplicationData, 'loanApplicationNumber') ||
    _.get(loanSubmission, 'loanApplicationNumber');

  const hashedSAID = verifyLoanApplicationData.hashedSAID || stepsInputData.hashedSAID;

  const { banner, keyName, pageName } = headerBannerData[findHeaderKey(pathname)] || {};

  // we init some common
  useCommonInit({
    router,
    stepsInputData,
    loanApplicationNumber,
    hashedSAID,
    isLANResuming,
    pageName,
  });

  // home screen
  if (isHomePage(pathname) || !banner) {
    return null;
  }

  // TRUID Screne
  if (pathname.startsWith(routeName.TRUID)) {
    return (
      <nav className="landing-banner">
        <div className="top-banner-page"><XNavLogo transparent /></div>
      </nav>
    );
  }

  // fill parameters
  const amountDebicheck = fulfillmentResults && fulfillmentResults.finalApprovedAmount;
  const maxAmount = product.maxAmount || config.loanAmount.max;
  const minAmount = product.minAmount || config.loanAmount.min;

  // dynamic all params
  const params = {
    phoneNumber: customerInfo ? hidePhoneNumber(customerInfo.phoneNumber) : _.get(verifyLoanApplicationData, 'phoneNumber'),
    minAmount: `<span>${formatMoney(minAmount)}</span>`,
    maxAmount: `<span>${formatMoney(maxAmount)}</span>`,
    introTruidLink: '',
    debiCheckAmount: `<span>${formatMoney(amountDebicheck)}</span>`,
  };

  const isIntroTruid = pathname.startsWith(routeName.TRUID_INTRO);

  // find i18n key
  const title = t(_.get(keys, `${keyName}.PAGE_TITLE`), params);
  const contentI18n = t(_.get(keys, `${keyName}.PAGE_SUB_HEADING`), params);
  const content = (contentI18n && isIntroTruid) ? <React.Fragment>{contentI18n}<TruidLinkTab />*</React.Fragment> : contentI18n;

  return (
    <nav className="landing-banner">
      <div className="top-banner-page"><XNavLogo transparent /></div>

      <div className="container d-flex align-items-center">
        <MovingFadeIn key={banner}>
          <div className="banner-bg" style={{ backgroundImage: `url(${banner})` }} />
        </MovingFadeIn>

        <div className="banner-header-text d-flex flex-column justify-content-end col-md-12">
          <PLDiv
            mobileContent={<LandingBannerLabel title={title} content={content} isMobile parse={!isIntroTruid} />}
            webContent={<LandingBannerLabel title={title} content={content} parse={!isIntroTruid} />} />
        </div>
      </div>
    </nav>
  );
}

const mapStateToProps = state => ({
  router: state.router,
  customerInfo: state.said.customerInfo,
  fulfillmentResults: state.fulfillmentResults.result,
  stepsInputData: state.stepsInputData,
  verifyLoanApplicationData: state.verifyLoanApplicationData,
  loanSubmission: state.loanSubmission,
});

export default translate()(connect(mapStateToProps, null)(LandingBanner));
