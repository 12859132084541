/* eslint-disable object-curly-newline */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { fetchListValues, saveStepsInput } from '../actions';
import translationKeys from '../i18n/translationKeys';
import { InputCurrency } from '../components/Common/InputCurrency';
import { isEditMode, scrollElementSmooth } from '../utils/commonUtils';
import {
    formatMoney,
    getNumberFromString,
} from '../utils/format';
import { ScreenBusUtils } from '../hooks/useEventBus';

import PLLinkTooltip from '../components/Common/PLLinkTooltip';
import PLButton from '../components/Common/PLButton';
import { WrapAction } from '../components/Common/ActionButtons';
import { FinancialGrossNetContent, FinancialTotalContent } from '../components/Financial/FinancialTooltip';

export class FinancialInformation extends Component {
    constructor(props) {
        super(props);

        this.mapPropsLovValuesToLists();

        this.isEditMode = isEditMode(this.props);
        if (this.isEditMode) {
            const fromStore = this.props.financialInformation && this.props.financialInformation.savedFinalIncomeStatement;
            const mockState = {
                netIncome: fromStore.netIncome.toString(),
                monthlyGrossIncome: fromStore.monthlyGrossIncome.toString(),
                income: fromStore.income.toString(),
                balance: fromStore.balance.toString(),
                deductions: fromStore.deductions.toString(),
                debt: fromStore.debt.toString(),
                isDebtCommitment: fromStore.isDebtCommitment,
                expenses: fromStore.expenses.toString(),
                emptyIncome: false,
                emptyNetIncome: false,
                emptyDebt: false,
                emptyExpenses: false,
            };
            this.state = { ...this.state, ...mockState };
        }
        this.t = this.props.t;
    }

    state = {
        selectedEmploymentStatus: null,
        selectedRace: null,
        selectedMaritalStatus: null,
        selectedMaritalType: null,
        selectedSpouseConsent: null,
        balance: 0,
        errors: {},
        isDebtCommitment: 'no',
        populatedValue: false,
        prepareToPushPage: false,
        emptyIncome: false,
        emptyNetIncome: false,
        emptyDebt: false,
        emptyExpenses: false,
    };

    componentDidMount() {
        if (!this.props.values || !Object.keys(this.props.values).length) {
            this.props.fetchListValues();
        }
    }

    componentDidUpdate(prevProps) {
        if (this.state.prepareToPushPage) {
            ScreenBusUtils.gotoScreenConfirmation();
        } else if (!Object.keys(prevProps.values).length && Object.keys(this.props.values).length) {
            this.mapPropsLovValuesToLists();
            this.forceUpdate();
        }
    }

    componentWillUnmount() {
        this.setState({ prepareToPushPage: false });
    }

    mapPropsLovValuesToLists = () => {
        const { EMPLOYMENT_STATUS, MARITAL_STATUS, MARITAL_TYPE, RACE } = this.props.values;
        this.listEmployment = EMPLOYMENT_STATUS || null;
        this.listMaritalStatus = MARITAL_STATUS || null;
        this.listMaritalType = MARITAL_TYPE || null;
        this.listRace = RACE || null;
    };

    shouldPutToStore = () => {
        const { errors, expenses, income, netIncome, balance, debt } = this.state;
        return (
            !errors.income &&
            !errors.netIncome &&
            !errors.balance &&
            !errors.expenses &&
            income &&
            netIncome &&
            balance &&
            debt &&
            expenses &&
            this.isIncomeStatementCanSave()
        );
    };

    // eslint-disable-next-line consistent-return
    putToStore = () => {
        const {
            selectedEmploymentStatus,
            selectedRace,
            selectedMaritalStatus,
            selectedMaritalType,
            selectedSpouseConsent,
            income,
            netIncome,
            debt,
            expenses,
        } = this.state;

        if (income === '' || income === undefined || income === null) {
            this.setState({
                emptyIncome: true,
            });
        }
        if (netIncome === '' || netIncome === undefined || netIncome === null) {
            this.setState({
                emptyNetIncome: true,
            });
        }
        if (debt === '' || debt === undefined || debt === null) {
            this.setState({
                emptyDebt: true,
            });
        }
        if (expenses === '' || expenses === undefined || expenses === null) {
            this.setState({
                emptyExpenses: true,
            });
        }
        if (!this.shouldPutToStore()) {
            setTimeout(() => this.onScrollElementActive('.has-error input'));
            return false;
        }
        this.props.saveStepsInput({
            financialInformation: {
                selectedEmploymentStatus,
                selectedRace,
                selectedMaritalStatus,
                selectedMaritalType,
                selectedSpouseConsent,
                savedFinalIncomeStatement: {
                    netIncome: getNumberFromString(this.state.netIncome) || 0,
                    monthlyGrossIncome: getNumberFromString(this.state.income) || 0,
                    income: getNumberFromString(this.state.income) || 0,
                    balance: this.state.balance || 0,
                    deductions: this.state.deductions || 0,
                    debt: getNumberFromString(this.state.debt) || 0,
                    isDebtCommitment: this.state.isDebtCommitment, // keep a string, not bool. cz this one can have multi options in future
                    expenses: getNumberFromString(this.state.expenses) || 0,
                },
            },
        });
        this.setState({ prepareToPushPage: true });
    };

    handleInputChange = fieldName => e => {
        this.setState({
            [fieldName]: e.target.value,
            emptyIncome: false,
            emptyNetIncome: false,
            emptyDebt: false,
            emptyExpenses: false,
        }, () => {
            if (fieldName === 'income' || fieldName === 'netIncome') {
                this.setState({
                    errors: {
                        ...this.state.errors,
                        income: false,
                        netIncome: false,
                    },
                });
            }
        });
    };

    calculateBalance = () => {
        let { netIncome, debt, expenses } = this.state;

        netIncome = netIncome || '0';
        debt = debt || '0';
        expenses = expenses || '0';
        return getNumberFromString(netIncome) - getNumberFromString(debt) - getNumberFromString(expenses);
    };

    calculateDeductions = () => {
        let { netIncome, income } = this.state;

        income = income || '0';
        netIncome = netIncome || '0';
        const monthlyGrossIncome = income;

        return getNumberFromString(monthlyGrossIncome) - getNumberFromString(netIncome);
    };

    updateBalance = () => {
        const { errors } = this.state;
        const validationGrossMonthlyIncome = (income, netIncome) => {
            if (getNumberFromString(income) && getNumberFromString(netIncome)) {
                return getNumberFromString(income) >= getNumberFromString(netIncome);
            }
            return true;
        };

        const shouldDisplayNetIncomeError = () => {
            const { income, netIncome } = this.state;
            if (income === '' || income === undefined || income === null) {
                return false;
            } else if (
                !income
                || (income && getNumberFromString(income) <= 0)
                || !validationGrossMonthlyIncome(income, netIncome)
            ) {
                return true;
            }
            return false;
        };

        // const shouldDisplayIncomeError = () => {
        //     const { netIncome } = this.state;
        //     if (netIncome === '' || netIncome === undefined || netIncome === null) {
        //         return false;
        //     } else if (!netIncome || (netIncome && getNumberFromString(netIncome) <= 0)) {
        //         return true;
        //     }
        //     return false;
        // };

        const shouldDisplayExpenseError = () => {
            const { expenses } = this.state;
            if (expenses === '' || expenses === undefined || expenses === null) {
                return false;
            } else if (!expenses || (expenses && getNumberFromString(expenses) <= 0)) {
                return true;
            }
            return false;
        };

        // errors.income = shouldDisplayIncomeError();
        errors.netIncome = shouldDisplayNetIncomeError();
        errors.expenses = shouldDisplayExpenseError();

        // if (errors.income) {
        //     errors.balance = false;
        //     this.setState({ errors, balance: 0 });
        //     return;
        // }
        const balance = this.calculateBalance();
        const deductions = this.calculateDeductions();
        errors.balance = this.state.netIncome && this.state.debt && balance <= 0;

        errors.deductions = this.state.income && this.state.netIncome && deductions < 0;
        this.setState({ balance, deductions, errors });
    };

    isIncomeStatementCanSave = () => {
        const { errors } = this.state;
        return !(errors.balance || errors.income || this.calculateBalance() < 0);
    };

    onDebtOptionChanged = e => {
        this.setState({ isDebtCommitment: e.target.value });
    };

    onClickSave = e => {
        this.putToStore();
        e.preventDefault();
        ScreenBusUtils.gotoScreenConfirmation();
    }

    // eslint-disable-next-line consistent-return
    onClickCancel = () => {
        const {
            selectedEmploymentStatus,
            selectedRace,
            selectedMaritalStatus,
            selectedMaritalType,
            selectedSpouseConsent,
        } = this.state;
        const fromStore = this.props.financialInformation && this.props.financialInformation.savedFinalIncomeStatement;
        if (!this.shouldPutToStore()) return false;
        this.props.saveStepsInput({
            financialInformation: {
                selectedEmploymentStatus,
                selectedRace,
                selectedMaritalStatus,
                selectedMaritalType,
                selectedSpouseConsent,
                savedFinalIncomeStatement: {
                    netIncome: fromStore.netIncome || 0,
                    monthlyGrossIncome: fromStore.monthlyGrossIncome || 0,
                    income: fromStore.income || 0,
                    balance: fromStore.balance || 0,
                    deductions: fromStore.deductions || 0,
                    debt: fromStore.debt || 0,
                    isDebtCommitment: fromStore.isDebtCommitment, // keep a string, not bool. cz this one can have multi options in future
                    expenses: fromStore.expenses || 0,
                },
            },
        });
        ScreenBusUtils.gotoScreenConfirmation();
    }

    onScrollElementActive = (focus) => {
        const element = document.querySelector(`#financial-information ${focus}`);
        scrollElementSmooth(element, 'center');
        setTimeout(() => Boolean(element) && element.focus(), 250);
    }

    handleNext = (name) => {
        let focus = '';
        this.updateBalance();
        if (name === 'input-income' && this.state.income) {
            focus = '.input-net-income';
        } else if (name === 'input-net-income' && this.state.netIncome) {
            focus = '.input-debt';
        } else if (name === 'input-debt' && this.state.debt) {
            focus = '.input-expenses';
        }

        if (focus) {
            setTimeout(() => {
                if (!this.state.errors.netIncome) {
                    this.onScrollElementActive(focus);
                }
            });
        }
    }

    renderIncomeSection = t => (
        <React.Fragment>
            <section>
                <div className="font-weight-medium mb-md-3 mb-2">Enter your income information</div>

                <div className={`form-group ${(this.state.errors.income || this.state.emptyIncome) ? 'has-error' : ''}`}>
                    <label className="input-label">Gross monthly income</label>
                    <InputCurrency
                        initFocused
                        className="input-income"
                        onChange={this.handleInputChange('income')}
                        onBlur={this.updateBalance}
                        value={this.state.income}
                        emptyMessage={(this.state.errors.income || this.state.emptyIncome) && 'Enter an amount.'}
                        handleEnter={this.handleNext}
                    />
                </div>
            </section>

            <section className={`form-group mb-0 ${(this.state.errors.netIncome || this.state.emptyNetIncome) ? 'has-error' : ''}`}>
                <label className="input-label">Net monthly income</label>
                <InputCurrency
                    className="input-net-income"
                    onChange={this.handleInputChange('netIncome')}
                    onBlur={this.updateBalance}
                    value={this.state.netIncome}
                    tipMessage="Enter the exact amount as it’s written on your bank statement."
                    emptyMessage={this.state.emptyNetIncome && 'Enter an amount.'}
                    errorMessage={this.state.errors.netIncome && 'Your net monthly income should be lower than your gross income.'}
                    handleEnter={this.handleNext}
                />
            </section>

            <section className="form-group">
                <PLLinkTooltip
                    tooltipId="pl-gross-net"
                    tooltipContent={<FinancialGrossNetContent />}
                >
                    What’s the difference between Gross and Net income?
                </PLLinkTooltip>
            </section>

            <section>
                <div className="font-weight-medium mb-md-3 mb-2">
                    Enter your expense information
                </div>
                <div>
                    <label className="input-label">Total monthly credit payments</label>
                    <div className={`form-group ${this.state.emptyDebt ? 'has-error' : ''}`}>
                        <InputCurrency
                            className="input-debt"
                            onChange={this.handleInputChange('debt')}
                            onBlur={this.updateBalance}
                            value={this.state.debt}
                            emptyMessage={this.state.emptyDebt && 'Enter an amount.'}
                            handleEnter={this.handleNext}
                        />
                    </div>
                </div>
            </section>

            <section className={`form-group ${(this.state.errors.expenses || this.state.emptyExpenses) ? 'has-error' : ''}`}>
                <label className="input-label">Total monthly living expenses</label>
                <div>
                    <InputCurrency
                        className="input-expenses"
                        onChange={this.handleInputChange('expenses')}
                        onBlur={this.updateBalance}
                        value={this.state.expenses}
                        emptyMessage={(this.state.errors.expenses || this.state.emptyExpenses) && 'Enter an amount.'}
                        handleEnter={this.handleNext}
                    />
                </div>
            </section>

            <section className="form-group">
                <PLLinkTooltip
                    tooltipId="pl-financial-amount"
                    tooltipContent={<FinancialTotalContent />}
                >
                    What amounts should I use here?
                </PLLinkTooltip>
            </section>

            <section className={`form-group mb-0 field-balance ${this.state.errors.balance ? 'has-error' : ''}`}>
                {
                    this.state.netIncome &&
                    this.state.debt &&
                    this.state.expenses &&
                    getNumberFromString(this.state.netIncome) > 0 &&
                    getNumberFromString(this.state.expenses) > 0 &&
                    <React.Fragment>
                        <div>Based on the above information, this is the maximum loan repayment you can afford right now:</div>
                        <div className="font-size-28 font-weight-medium label">{formatMoney(this.state.balance)}</div>
                    </React.Fragment>
                }
                {this.state.errors.balance &&
                    this.state.debt &&
                    getNumberFromString(this.state.netIncome) > 0 &&
                    getNumberFromString(this.state.expenses) > 0 && (
                        <div>
                            {t(translationKeys.financeInformation.ERROR_BALANCE)}
                        </div>
                    )}
            </section>

            <section className="mb-4">
                {this.isEditMode ?
                    <WrapAction className="buttons flex-column-reverse d-flex d-md-block">
                        <PLButton
                            primary={false}
                            onClick={this.onClickCancel}>
                            Cancel
                        </PLButton>
                        <PLButton onClick={this.onClickSave}>
                            Save
                        </PLButton>
                    </WrapAction>
                    :
                    <div className="buttons">
                        <PLButton onClick={() => this.putToStore()}>
                            {t(translationKeys.common.BUTTON_NEXT)}
                        </PLButton>
                    </div>
                }
            </section>
        </React.Fragment>
    );

    render() {
        return (
            <div
                id="financial-information"
                className="d-flex flex-column gap-md-24 gap-16"
            >
                {this.renderIncomeSection(this.t)}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    values: state.listValues && state.listValues.values ? state.listValues.values : {},
    financialInformation: state.stepsInputData && state.stepsInputData.financialInformation,
});

export default translate()(
    connect(
        mapStateToProps,
        {
            fetchListValues,
            saveStepsInput,

        },
    )(FinancialInformation),
);
