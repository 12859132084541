// eslint-disable-next-line no-unused-vars
import useTask from 'react-task-z';

import useEventBus, {
    EventBusName,
    FacialBusUtils,
    canUseEventBus,
    ScreenBusUtils,
} from '../../hooks/useEventBus';
import { DecisioningResult } from '../../constants';
import AppConfig from '../../config';

import { SegmentBusTrackFacials } from '../../segment-bus/SegmentBusTrackFacials';
import { fetchFacialResults } from '../../services/useFacialSDKService';

const useFacialPoolingResults = ({
    stepsInputData,
    stepUpToken,
    loanApplicationNumber,
    verifyLoanApplicationInElibility,
}) => {
    const { facialInfoData = {} } = stepsInputData || {};

    const task = useTask({
        delay: AppConfig.facialTiming.timeDelay,
        maxLoop: AppConfig.facialTiming.timeCounter,
        callback: implementTasks,
    });

    useEventBus(EventBusName.FACTECT_REFRESH_API_FETCHING, () => {
        if (canUseEventBus({
            uniqueId: EventBusName.FACTECT_REFRESH_API_FETCHING,
            seconds: 1,
        })) {
            task.run();
        }
    });

    function implementTasks(loopTime) {
        fetchingResults();
        if (loopTime >= AppConfig.facialTiming.timeCounter) {
            ScreenBusUtils.hideLoadingLazy();
            FacialBusUtils.showTimeout();
            task.stop();
        }
    }

    async function fetchingResults() {
        ScreenBusUtils.showLoading();
        try {
            const response = await fetchFacialResults({
                stepUpToken,
                session: facialInfoData.sessionToken,
                loanApplicationNumber,
            });
            const results = response.data || {};

            if (results.loanApplicationStatus === DecisioningResult.loanApplicationStatus.CONTRACT_SIGNED) {
                FacialBusUtils.showSelfieSuccess();
                ScreenBusUtils.hideLoading();
                task.stop();
                return;
            }

            if (results.facialResult.decisionCode) {
                ScreenBusUtils.hideLoading();
                task.stop();

                // make new pooling get offer reject
                if (results.loanApplicationStatus === DecisioningResult.loanApplicationStatus.OFFER_RETRACTED) {
                    verifyLoanApplicationInElibility({ loanApplicationNumber });
                    SegmentBusTrackFacials.segment2100FacialVerificationOfferRetractedData();
                    return;
                }

                FacialBusUtils.showError(results.facialResult.decisionCode);
                return;
            }
        } catch (ex) {
            ScreenBusUtils.hideLoadingLazy();
            FacialBusUtils.showGeneric();
            task.stop();
        }
    }

    return { task, implementTasks };
};

export default useFacialPoolingResults;
