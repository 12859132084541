import React from 'react';

import Config from '../config';
import { TruIDBusUtils } from '../hooks/useEventBus';

import PLButton from '../components/Common/PLButton';
import LabelTitle from '../components/Common/LabelTitle';
import HelpTip from '../components/Common/HelpTip';
import { FlexColumnCenter } from '../components/Common/FlexCenter';
import TruidLinkTab from '../components/TruId/TruidLinkTab';
import LabelLoanApplication from '../components/Common/LabelLoanApplication';
import IntroSprintHiveSteps from '../components/TruId/IntroSprintHiveSteps';

function IntroSprintHive() {
  return (
    <div id="introtruid">
      <div className="pl-container-highlight px-1">
        <div className="container intro-sprint-hive d-flex flex-column mb-4">
          <LabelLoanApplication />

          <IntroSprintHiveSteps
            title={
              <React.Fragment>
                Email 3-month’s worth of your most recent bank generated PDF statements to:
                <br />
                <a href={`mailto:${Config.emailLoanDoc}`} target="_blank" rel="noopener noreferrer" className="font-weight-500">{Config.emailLoanDoc}</a>
              </React.Fragment>
            }
          />
        </div>
      </div>

      <FlexColumnCenter className="container pt-4 pb-5 text-center">
        <LabelTitle className="mt-sm-4 mx-5 col-12 col-md-10">
          Or you can try using <TruidLinkTab /> to log in to your internet banking, which is the quicker and easier method.
        </LabelTitle>

        <div className="my-4">
          <PLButton onClick={TruIDBusUtils.refreshURLTruIDScreen}>
            Try truID again
          </PLButton>
        </div>

        <HelpTip className="mt-3">We’ll also email you these instructions, so you can continue this process when you’re ready.</HelpTip>
      </FlexColumnCenter>
    </div>
  );
}

export default IntroSprintHive;
