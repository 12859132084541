import React, { useEffect, useState } from 'react';
import { push } from 'react-router-redux';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import _ from 'lodash';

import { routeName } from '../../constants';
import { useCommonContext } from '../../context/CommonContext';
import { useModalPopupContext } from '../../context/ModalPopupContext';

import useIsMounted from '../../hooks/useIsMounted';
import { ScreenBusUtils } from '../../hooks/useEventBus';
import { SegmentBusTrackLanding } from '../../segment-bus/SegmentBusTrackLanding';
import { calulatorLoanPackage } from '../../utils/commonUtils';

import { fetchConfiguration, fetchListValues, resetLoanSubmission, saveStepsInput } from '../../actions';

import PLButton from '../../components/Common/PLButton';
import { FlexRowCenter } from '../../components/Common/FlexCenter';
import HelpTip from '../../components/Common/HelpTip';
import LandingConsent from '../../components/Landing/LandingConsent';

import XLandingSlider from '../x2-components/landing/XLandingSlider';
import XLandingTerms from '../x2-components/landing/XLandingTerms';
import XLandingTermsCalculator from '../x2-components/landing/XLandingTermsCalculator';
import XLandingR1Left from '../x2-components/landing/XLandingR1Left';
import XLandingR2Steps from '../x2-components/landing/XLandingR2Steps';
import XLandingR3Ready from '../x2-components/landing/XLandingR3Ready';
import XLandingR4Question from '../x2-components/landing/XLandingR4Question';

const X2Landing = ({ t, ...props }) => {
  const { product, shareLoanConfig, setShareLoanConfig } = useCommonContext();
  const { showModal, hideModal } = useModalPopupContext();
  const mountedInfoData = useIsMounted(props);

  const [loanConfig, setLoanConfig] = useState({ value: 0, terms: 0 });
  const [loanInfo, setLoanInfo] = useState({});

  useEffect(() => {
    if (product.terms && product.maxAmount) {
      setLoanConfig({
        terms: _.max(product.terms),
        value: product.maxAmount,
      });

      setShareLoanConfig({
        ...shareLoanConfig,
        terms: _.max(product.terms),
        value: product.maxAmount,
      });
    }
  }, [product]);

  useEffect(() => {
    if (loanConfig.terms && loanConfig.value) {
      const { terms, value } = loanConfig;
      setLoanInfo(calulatorLoanPackage(terms, value, product));
    }
  }, [loanConfig]);

  useEffect(() => {
    props.fetchConfiguration();
    props.fetchListValues();

    if (!mountedInfoData.isMounted) {
      return;
    }

    SegmentBusTrackLanding.segment01LandingDisplayData();
    const { isMounted, productId, loanApplicationNumber } = mountedInfoData;
    if (loanApplicationNumber) {
      props.resetLoanSubmission();
      props.push(routeName.OTP_RESUME, { loanApplicationNumber });
    }

    setShareLoanConfig({
      productId,
      isMounted,
    });
  }, [mountedInfoData.isMounted]);

  const handleSliderChange = (val) => {
    setLoanConfig({
      ...loanConfig,
      value: val,
      flgValueHasChange: true,
    });

    if (loanConfig.flgValueHasChange) {
      SegmentBusTrackLanding.segment01TermDelayChangeData();
    }
  };

  const applyNow = () => {
    showModal({
      centerAction: true,
      showXClose: false,
      modalContent: <LandingConsent />,
      handleOK: () => {
        props.saveStepsInput({
          loanInfo: {
            amount: loanConfig.value,
          },
        });
        ScreenBusUtils.gotoScreenStart();
        hideModal();

        setShareLoanConfig({
          ...shareLoanConfig,
          ...loanConfig,
        });
        SegmentBusTrackLanding.segment02ConsentData();
      },
    });
  };
  return (
    <React.Fragment>
      <div className="x-landing-header container d-flex flex-column flex-md-row mt-4">
        <XLandingR1Left product={product} applyNow={applyNow} />

        <div className="header-right col-6">
          <div className="point-one" />
          <div className="point-two" />
          <div className="logo-information" />

          <div className="header-calculator">
            <XLandingSlider
              loanConfig={loanConfig}
              product={product}
              handleSliderChange={handleSliderChange}
            />

            <XLandingTerms
              product={product}
              loanConfig={loanConfig}
              setLoanConfig={setLoanConfig}
            />

            <XLandingTermsCalculator
              loanInfo={loanInfo}
              loanConfig={loanConfig}
            />

            <HelpTip className="mt-3 pb-3">
              <small className="sub-title">This calculation is a non-binding estimate. TymeBank will only be bound by interest rate, cost and loan amount quoted when you apply.</small>
            </HelpTip>

            <FlexRowCenter className="mt-5"><PLButton className="w-100 mt-1" onClick={applyNow}>Check if I’m eligible</PLButton></FlexRowCenter>
          </div>
        </div>
      </div>

      <XLandingR2Steps />

      <XLandingR3Ready applyNow={applyNow} />

      <XLandingR4Question />

    </React.Fragment>
  );
};

const mapDispatchToProps = {
  push,
  fetchConfiguration,
  fetchListValues,
  saveStepsInput,
  resetLoanSubmission,
};

export default translate()(connect(null, mapDispatchToProps)(X2Landing));

