import React from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import joinclass from 'joinclass';
import PLButton from './PLButton';
import { WrapAction } from './ActionButtons';

import { useModalPopupContext } from '../../context/ModalPopupContext';
import informationIcon from '../../assets/images/information.svg';
import errorIcon from '../../assets/images/error.svg';
import closeX from '../../assets/images/close-gray.svg';

/**
 * set modal info from defaultContext() => ModalPopupContext
 */
const ModalPopup = () => {
  // const { modalInfo, showModal, showConfirmModal, showErrorModal, hideModal } = useModalPopupContext()
  const { modalInfo, hideModal } = useModalPopupContext();

  if (!modalInfo.isShow) {
    return <React.Fragment />;
  }

  const hasAction = (modalInfo.showCancel && !modalInfo.disabledClose) || modalInfo.showOK;

  return (
    <Modal className={joinclass('modal-popup', modalInfo.className)} isOpen={modalInfo.isShow}>
      {modalInfo.showXClose && !modalInfo.disabledClose && (
        <a
          onClick={() => {
            if (modalInfo.handleXClose) {
              modalInfo.handleXClose();
              return;
            }
            hideModal();
          }}
          className="modal-popup-close-btn"
          aria-hidden="true"
        >
          <img src={closeX} alt="close" />
        </a>
      )}

      {modalInfo.showHeader &&
        <ModalHeader className="modal-popup-header">
          {
            (modalInfo.isConfirm || modalInfo.isError) &&
            <img className="icon" src={modalInfo.isConfirm ? informationIcon : errorIcon} alt={modalInfo.isConfirm ? 'information' : 'error'} width="48" height="48" />
          }
          <span>{modalInfo.modalTitle}</span>
        </ModalHeader>
      }

      <ModalBody className="modal-popup-body">
        <div className="content modal-body__content">
          {modalInfo.modalContent}
        </div>

        {hasAction &&
          <WrapAction className={
            joinclass('modal-buttons flex-column-reverse d-flex', modalInfo.centerAction && 'buttons flex-md-row justify-content-center')}>
            {modalInfo.showCancel && !modalInfo.disabledClose &&
              <PLButton
                primary={false}
                className={joinclass('modal-button-cancel', modalInfo.classNameCancel)}
                onClick={() => {
                  if (modalInfo.handleCancel) {
                    modalInfo.handleCancel();
                    return;
                  }
                  hideModal();
                }}
              >
                {modalInfo.cancelTitle}
              </PLButton>
            }

            {modalInfo.showOK &&
              <PLButton
                className={joinclass('modal-button-ok', modalInfo.classNameOK)}
                onClick={() => {
                  Boolean(modalInfo.handleOK) && modalInfo.handleOK();
                }}>
                {modalInfo.okTitle}
              </PLButton>
            }
          </WrapAction>
        }
      </ModalBody>
    </Modal>
  );
};

export default ModalPopup;
