import React from 'react';
import joinclass from 'joinclass';
import styled from 'styled-components';

import LabelTitle from './LabelTitle';
import PLDiv from './PLDiv';
import { FlexRowCenter } from './FlexCenter';

import { MovingFadeIn, MovingPopIn, MovingSlideInFromBottom } from './AnimateText';

const WrapItem = styled(FlexRowCenter)`
  position: relative;
  width: 100px;
  height: 100px;
  border-radius: 100%;
  background: #E8E8E8;

  .point {
    position: absolute;
    height: 24px;
    width: 24px;
    border-radius: 100%;
    right: 2px;
    bottom: 2px;
    background: #FFCC00;
    border: 2px solid #FFFFFF;
    color: #231F20;
  }

  @media (max-width: 767px) {
    width: 64px;
    height: 64px;
    .point {
      width: 20px;
      height: 20px;
      font-size: 12px !important;
    }
  }
`;

export function ActionItemDescriptor({ stepInfo }) {
  return (
    <React.Fragment>
      {stepInfo.title && <LabelTitle className="mb-1">{stepInfo.title}</LabelTitle>}
      {stepInfo.mobileContent ?
        <PLDiv
          mobileContent={stepInfo.mobileContent}
          webContent={stepInfo.content} />
        : stepInfo.content && <div className="step-item-description-content">{stepInfo.content}</div>}
    </React.Fragment>
  );
}

export function ActionItem({
  stepInfo, index, disableAnimation, disabledPoint,
}) {
  return (
    <div key={index} className="step-item-info">
      <WrapItem className="mb-2 mt-1 step-item-icon">
        <PLDiv
          mobileContent={stepInfo.iconMobile}
          webContent={stepInfo.icon} />
        {!disabledPoint && <FlexRowCenter className="point">{index + 1}</FlexRowCenter>}
      </WrapItem>

      <div className="step-item-description">
        {disableAnimation ?
          <ActionItemDescriptor stepInfo={stepInfo} /> :

          <MovingFadeIn duration={`${index + 2}500ms`}>
            <ActionItemDescriptor stepInfo={stepInfo} />
          </MovingFadeIn>}
      </div>
    </div>
  );
}

function ActionSteps({
  className,
  listSteps = [],
  disableAnimation,
  vertical = false,
  noLinkLine = false,
  disabledPoint = false,
}) {
  return (
    <div
      className={joinclass(
        'd-flex col-12 action-step-list',
        // vertical mode
        vertical && 'step-list-verical',
        noLinkLine && 'step-list-no-line',
        className,
      )}>
      {listSteps.map((stepInfo, index) => {
        const lastClass = listSteps.length - 1 === index && 'action-step-last';
        // we no use animation
        if (disableAnimation) {
          return (
            <div key={stepInfo.title} className={joinclass('action-step-web action-step-item', lastClass)}>
              <ActionItem stepInfo={stepInfo} index={index} disableAnimation={disableAnimation} disabledPoint={disabledPoint} />
            </div>
          );
        }

        // we use animation
        return (
          <React.Fragment key={stepInfo.title}>
            <PLDiv
              classNameWeb={joinclass('action-step-web action-step-item', lastClass)}
              webContent={
                <MovingPopIn duration={`${(index + 1) * 1500}ms`}>
                  <ActionItem stepInfo={stepInfo} index={index} disableAnimation={disableAnimation} disabledPoint={disabledPoint} />
                </MovingPopIn>
              } />
            <PLDiv
              classNameMobile={joinclass('action-step-mobile action-step-item', lastClass)}
              mobileContent={
                <MovingSlideInFromBottom duration={`${(index + 1) * 750}ms`}>
                  <ActionItem stepInfo={stepInfo} index={index} disableAnimation={disableAnimation} disabledPoint={disabledPoint} />
                </MovingSlideInFromBottom>
              } />
          </React.Fragment>
        );
      })}
    </div>
  );
}

export default ActionSteps;
