import _ from 'lodash';
import { WrapSegmentBusUtils } from '../hooks/useSegmentEventBus';

export const delayChangeTerm = _.debounce(() => {
    WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment01LandingTermChangeData');
}, 500);

export class SegmentBusTrackLanding {
    static segment01LandingDisplayData(params) {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment01LandingDisplayData', params);
    }

    static segment01ABTestingData(params) {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment01ABTestingData', params);
    }

    static segment01LandingTermChangeData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment01LandingTermChangeData');
    }

    static segment01TermDelayChangeData() {
        delayChangeTerm();
    }

    static segment01RefreshPageData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment01RefreshPageData');
    }

    static segment02ConsentData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment02ConsentData');
    }
}
