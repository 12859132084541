import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import joinclass from 'joinclass';
import i18next from 'i18next';
import NumberFormat from 'react-number-format';

import {
    validatePhoneNum,
    formatSAID,
    parsePhoneNumber,
} from '../utils/format';
import translationKeys from '../i18n/translationKeys';

import { validationSaid, saveStepsInput } from '../actions';
import { PHONE_LENGTH } from '../constants';
import { useCommonContext } from '../context/CommonContext';
import { ScreenBusUtils } from '../hooks/useEventBus';

import BackButton from '../components/Common/BackButton';
import PLButton from '../components/Common/PLButton';

const Phone = ({
    stepsInputData,
    ...props
}) => {
    const { product } = useCommonContext();
    // optimize render
    const [formData, setFormData] = useState({
        value: stepsInputData.phoneNumber || '',
        errorsMsg: '',
    });
    const refPhone = React.useRef();

    useEffect(() => {
        refPhone.current.focus();
    }, []);

    function getErrorsMsg(value = '') {
        if (value.trim() === '') {
            return i18next.t(translationKeys.phonePage.PHONE_EMPTY);
        }

        if (!validatePhoneNum(value)) {
            return i18next.t(translationKeys.phonePage.PHONE_INVALID);
        }
        return '';
    }

    function isValidate(value = '') {
        const errorsMsg = getErrorsMsg(value);
        setFormData({
            ...formData,
            errorsMsg,
        });
        return !errorsMsg;
    }

    const handleNext = useCallback(() => {
        if (!isValidate(formData.value)) {
            refPhone.current.focus();
            return;
        }

        props.validationSaid({
            said: stepsInputData.said,
            phone: formData.value,
            productId: product.id,
        });

        props.saveStepsInput({ phoneNumber: formData.value });
    }, [formData.value, stepsInputData]);


    return (
        <React.Fragment>
            <BackButton
                className="pb-3"
                onClick={() => ScreenBusUtils.gotoScreenStart()}
            >
                SAID:  {formatSAID(stepsInputData.said)}
            </BackButton>

            <div className={joinclass('form-group', formData.errorsMsg && 'has-error')}>
                <label>{i18next.t(translationKeys.phonePage.PHONE_LABEL)}</label>

                <NumberFormat
                    format="### ### ####"
                    className="form-control"
                    type="tel"
                    getInputRef={e => { refPhone.current = e; }}
                    value={formData.value}
                    onChange={e => {
                        const newValue = parsePhoneNumber(e.target.value);
                        if (formData.value === newValue) {
                            return;
                        }

                        setFormData({
                            value: newValue,
                            errorsMsg: '',
                        });
                    }}
                    onKeyDown={e => {
                        if (e.key === 'Enter') {
                            handleNext();
                        }
                    }}
                    onBlur={e => {
                        if (parsePhoneNumber(e.target.value).length === PHONE_LENGTH) {
                            isValidate(e.target.value);
                        }
                    }}
                />

                {formData.errorsMsg && <div className="with-errors">{formData.errorsMsg}</div>}
            </div>

            <div className="buttons my-4">
                <PLButton onClick={handleNext}>Next</PLButton>
            </div>
        </React.Fragment>
    );
};

const mapState = (state) => ({
    stepsInputData: state.stepsInputData,
});

const mapDispatchToProps = {
    validationSaid,
    saveStepsInput,
};

export default connect(mapState, mapDispatchToProps)(Phone);
