import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import { translate } from 'react-i18next';
import moment from 'moment';
import ReCAPTCHA from 'react-google-recaptcha';
import { ShareCommonContext } from '../context/CommonContext';
import { LANGUAGE, UpsellingResultStatus } from '../constants';

import PLButton from '../components/Common/PLButton';
import LabelTitle from '../components/Common/LabelTitle';
import { FlexRowBetween } from '../components/Common/FlexCenter';
import InputCheck from '../components/Common/InputCheck';
import ConfirmSalaryTymebank from '../components/Confirmation/ConfirmSalaryTymebank';

import { formatMoney, removeComma } from '../utils/format';
import keys from '../i18n/translationKeys';
import Config from '../config';
import { fetchListValues, postLoanSubmission, saveStepsInput, evaluateEligibilityCheckResult } from '../actions';
import { ScreenBusUtils } from '../hooks/useEventBus';

function Section(props) {
    return (
        <React.Fragment>
            <FlexRowBetween>
                <LabelTitle small>{props.title}</LabelTitle>
                <a className="action no-link" onClick={props.actionEdit} aria-hidden="true">
                    {props.t(keys.confirmationPage.BUTTON_EDIT)}
                </a>
            </FlexRowBetween>
            <div className="section-content">{props.children}</div>
        </React.Fragment>
    );
}

function SectionContentItem(props) {
    if (!props.value) {
        return null;
    }
    return (
        <div className="confirmation-item d-flex wrap-confirmation-item">
            <p className="item-value">{(props.label || '').trim()}</p>
            <p className="item-label">{props.value}</p>
        </div>
    );
}
const getNameByCode = (array, code) => (array ? (array.filter(i => i.code === code)[0] || { name: '' }).name : '');

export class Confirmation extends Component {
    constructor(props) {
        super(props);
        this.t = this.props.t;
    }

    state = {
        customerConsent: false,
        recaptchaToken: null,
        preferLanguage: LANGUAGE.ENGLISH,
        isNotConsent: false,
        isShowConfirm: false,
    };

    componentDidMount() {
        if (!this.props.lov || !Object.keys(this.props.lov).length) {
            this.props.fetchListValues();
        }

        // evaluate eligibility check result
        const { customer } = this.props.eligibilityCheckResults;
        if (!_.get(customer, 'firstName')) {
            const { otp, said } = this.props || {};
            const request = {
                stepUpToken: _.get(otp, 'stepUpToken'),
                said: _.get(said, 'saId'),
                makeDecision: true,
            };
            this.props.evaluateEligibilityCheckResult(request);
        }
    }

    componentDidUpdate() {
        const { data, stepsInputData, decisionResults } = this.props;
        const { qualifiedLessMoney, loanApplicationStatus, upsellStatus } = data || {};
        const { isReceivedSalaryInTyme, isEFProfile } = stepsInputData.userInfo || {};
        const { isResultApproved, isResultDeclined } = decisionResults || {};

        if (isResultDeclined) {
            ScreenBusUtils.gotoScreenDeclinedOffers(this.props.loanSubmission.loanApplicationNumber, true);
            return;
        }

        // redirect after get final decision result and make sure that has loanApplicationStatus
        if (isResultApproved && loanApplicationStatus) {
            // ignore offer limit
            if (isReceivedSalaryInTyme || isEFProfile) {
                ScreenBusUtils.redirectScreenAfterResumeByLoanStatus({
                    loanApplicationStatus,
                    usBus: true,
                    isEFProfile: true,
                    upsellStatus,
                });
                return;
            }

            // upselling
            if (UpsellingResultStatus.YES[upsellStatus]) {
                const offerList = _.get(data, 'offerList') || [];
                if (offerList.length > 0) {
                    this.props.saveStepsInput({
                        upsellValues: {
                            maxAmount: Number(removeComma(offerList[0].finalApprovedAmount)),
                            minAmount: Number(removeComma(data.expectedAmount)),
                        },
                    });
                }
                ScreenBusUtils.gotoScreenUpsellOffers();
                return;
            }

            // lower offer
            if (qualifiedLessMoney) {
                ScreenBusUtils.gotoScreenLimitOffers();
                return;
            }

            ScreenBusUtils.gotoScreenOffers();
        }
    }

    renderLoanInfo = () => {
        const { loanInfo } = this.props.stepsInputData;
        if (!this.props.lov) return null;

        const selectedPurpose = loanInfo.selectedPurpose ?
            loanInfo.selectedPurpose.name : '';

        const amount = formatMoney(loanInfo.amount) || '';

        return (
            <div className="row m-0 mt-2 wrap-section">
                <SectionContentItem className="col-sm-6" label={this.t(keys.confirmationPage.LOAN_PURPOSE_TITLE)} value={selectedPurpose} />
                <SectionContentItem className="col-sm-6" label={this.t(keys.confirmationPage.LOAN_AMOUNT_TITLE)} value={amount} />
            </div>
        );
    };

    renderAdditionalAboutUser = () => {
        const { userInfo } = this.props.stepsInputData;
        const { customer } = this.props.eligibilityCheckResults;
        if (!this.props.lov || !customer || !customer.firstName) {
            return null;
        }

        const {
            emailValue,
            selectedEmploymentStatus,
            company,
            workingMonth,
            workingYear,
            selectedPeriodEmployment,
            selectedRace,
        } = userInfo;

        const {
            firstName,
            lastName,
        } = this.props.eligibilityCheckResults.customer;

        return (
            <Fragment>
                <div className="row m-0 mt-2 wrap-section">
                    <SectionContentItem className="col-sm-6" label="First name" value={firstName} />
                    <SectionContentItem className="col-sm-6" label="Surname" value={lastName} />
                </div>
                <div className="row m-0 mt-2 wrap-section">
                    <SectionContentItem className="col-sm-6" label="Email" value={emailValue} />
                    <SectionContentItem
                        className="col-sm-6"
                        label={this.t(keys.confirmationPage.EMPLOYMENT_STATUS)}
                        value={getNameByCode(this.props.lov.EMPLOYMENT_STATUS, selectedEmploymentStatus)}
                    />
                </div>
                <div className="row m-0 mt-2 wrap-section">
                    <SectionContentItem className="col-sm-6" label={this.t(keys.confirmationPage.WORK_FOR)} value={company} />
                    {company && (
                        <SectionContentItem
                            className="col-sm-6"
                            label={this.t(keys.confirmationPage.WORK_START)}
                            value={moment()
                                .month(workingMonth - 1)
                                .year(workingYear)
                                .format('MMMM YYYY')}
                        />
                    )}
                </div>
                <div className="row m-0 mt-2 wrap-section">
                    <SectionContentItem
                        className="col-sm-6"
                        label="Previous employment period"
                        value={getNameByCode(this.props.lov.PREVIOUS_EMPLOYMENT_DURATION, selectedPeriodEmployment)}
                    />
                    <SectionContentItem className="col-sm-6" label="Race" value={getNameByCode(this.props.lov.RACE, selectedRace)} />
                </div>
            </Fragment>
        );
    };

    renderFinance = () => {
        const { financialInformation } = this.props.stepsInputData;
        if (!this.props.lov) return null;

        const income = formatMoney(financialInformation.savedFinalIncomeStatement.income);
        const debt = formatMoney(financialInformation.savedFinalIncomeStatement.debt);
        const expenses = formatMoney(financialInformation.savedFinalIncomeStatement.expenses);
        const netIncome = formatMoney(financialInformation.savedFinalIncomeStatement.netIncome);
        const balance = formatMoney(financialInformation.savedFinalIncomeStatement.balance);

        return (
            <Fragment>
                <div className="row m-0 mt-2 wrap-section">
                    <SectionContentItem className="col-sm-6" label={this.t(keys.confirmationPage.INCOME)} value={income} />
                    <SectionContentItem className="col-sm-6" label="Monthly net income" value={netIncome} />
                </div>
                <div className="row m-0 mt-2 wrap-section">
                    <SectionContentItem className="col-sm-6" label={this.t(keys.confirmationPage.DEBT)} value={debt} />
                    <SectionContentItem className="col-sm-6" label={this.t(keys.confirmationPage.LIVING_EXPENSES)} value={expenses} />
                </div>
                <div className="row m-0 mt-2 wrap-section">
                    <SectionContentItem className="col-sm-6" label="Affordable repayment" value={balance} />
                </div>
            </Fragment>
        );
    };

    handleRecaptchaResponse = resp => {
        if (resp) {
            this.setState({
                recaptchaToken: resp,
            });
        } else {
            this.setState({
                recaptchaToken: null,
            });
        }
    };

    // eslint-disable-next-line consistent-return
    handleLoanSubmission = (isSelectedTymeAffordability) => {
        if (!this.state.customerConsent) {
            this.setState({
                isNotConsent: true,
            });
        }

        if (!(this.state.customerConsent && this.state.recaptchaToken)) {
            return null;
        }

        const { saId, id } = this.props.said;
        const { stepUpToken } = this.props.otp;
        const {
            loanInfo,
            financialInformation,
            phoneNumber,
            isReceivedSalaryInTyme,
        } = this.props.stepsInputData;

        const {
            emailValue,
            selectedEmploymentStatus,
            company,
            workingMonth,
            workingYear,
            selectedPeriodEmployment,
            selectedRace,
            acceptTymeMarketingConsent,
        } = this.props.stepsInputData.userInfo;

        let formattedWorkingMonth;
        if (workingMonth < 10) {
            formattedWorkingMonth = `0${workingMonth}`;
        } else {
            formattedWorkingMonth = workingMonth;
        }

        const plProductId = this.context.product.id;
        const information = {
            customerInfo: {
                said: saId,
                phoneNumber,
                emailAddress: emailValue,
                employmentStatus: selectedEmploymentStatus,
                race: selectedRace,
                customerId: id,
                grossAnnualIncome: financialInformation.savedFinalIncomeStatement.monthlyGrossIncome * 12,
                deduction: financialInformation.savedFinalIncomeStatement.deductions,
                debtCommitments: financialInformation.savedFinalIncomeStatement.debt,
                livingExpenses: financialInformation.savedFinalIncomeStatement.expenses,
                netMonthlyIncome: financialInformation.savedFinalIncomeStatement.netIncome,
                companyName: company,
                previousEmploymentDuration: selectedPeriodEmployment,
                startedWorkingDate: company ? `${formattedWorkingMonth}-${workingYear}` : null,
                preferLanguage: this.state.preferLanguage,
                acceptTymeMarketingConsent,
            },
            loanApplicationInfo: {
                loanPurpose: loanInfo.selectedPurpose.code,
                expectedLoanAmount: loanInfo.amount,
                payExtraForExpressService: false,
                payInitiationFeeUpfront: loanInfo.payInitiationFeeUpfront,
                customerConsent: this.state.customerConsent,
                plProductId,
                isSelectedTymeAffordability,
            },
            stepUpToken,
        };
        const info = {
            captchaToken: this.state.recaptchaToken,
            information,
            isReceivedSalaryInTyme,
            isLoanSubmit: true,
            isSelectedTymeAffordability,
        };
        this.props.postLoanSubmission(info);
    };

    render() {
        const { t } = this.props;
        if (!this.props.lov || !this.props.stepsInputData) {
            return null;
        }

        const {
            isReceivedSalaryInTyme,
        } = this.props.stepsInputData.userInfo || {};

        const handleCustomerConsent = () => {
            this.setState({
                customerConsent: !this.state.customerConsent,
                isNotConsent: false,
            });
        };

        return (
            <div id="confirmation-page">
                <Section
                    title="Loan Details"
                    actionEdit={() => {
                        ScreenBusUtils.gotoScreenLoanAmountEdit();
                    }}
                    t={t}>
                    {this.renderLoanInfo()}
                </Section>
                <hr className="my-md-4 my-3" />
                <Section
                    title="Personal Details"
                    actionEdit={() => {
                        ScreenBusUtils.gotoScreenUserEdit();
                    }}
                    t={t}>
                    {this.renderAdditionalAboutUser()}
                </Section>
                <hr className="my-md-4 my-3" />
                <Section
                    title="Affordability"
                    actionEdit={() => {
                        ScreenBusUtils.gotoScreenFinancialInfomation(true);
                    }}
                    t={t}>
                    {this.renderFinance()}
                </Section>

                <hr className="my-md-4 my-3" />

                <div className="mb-3">
                    I would like a copy of this document in:
                </div>
                <div className="form-check">
                    <InputCheck
                        label="English"
                        name="select-language"
                        value={LANGUAGE.ENGLISH}
                        checked={this.state.preferLanguage === LANGUAGE.ENGLISH}
                        handleChange={() => this.setState({ preferLanguage: LANGUAGE.ENGLISH })}
                    />
                </div>
                <div className="form-check">
                    <InputCheck
                        label="isiZulu"
                        name="select-language"
                        value={LANGUAGE.ZULU}
                        checked={this.state.preferLanguage === LANGUAGE.ZULU}
                        handleChange={() => this.setState({ preferLanguage: LANGUAGE.ZULU })}
                    />
                </div>

                <hr className="my-md-4 my-3" />

                <div className="form-check">
                    <InputCheck
                        type="checkbox"
                        label={this.t(keys.confirmationPage.AGREE_PERMISSION)}
                        value={this.state.customerConsent}
                        handleChange={handleCustomerConsent}
                    />
                </div>
                {this.state.isNotConsent && <div className="with-errors d-block my-3">Please accept the above terms to continue.</div>}

                <hr className="my-4" />

                <ReCAPTCHA sitekey={Config.GOOGLE_RECAPTCHA_SITEKEY} onChange={this.handleRecaptchaResponse} />

                <div className="buttons my-4">
                    <PLButton
                        isAsync
                        onClick={() => {
                            if (isReceivedSalaryInTyme) {
                                this.setState({ isShowConfirm: true });
                                return;
                            }
                            this.handleLoanSubmission();
                        }}
                    >
                        Submit
                    </PLButton>
                </div>

                <ConfirmSalaryTymebank
                    isOpen={isReceivedSalaryInTyme && this.state.isShowConfirm}
                    handleNext={(isSelectedTymeAffordability) => {
                        this.handleLoanSubmission(isSelectedTymeAffordability);
                        this.setState({ isShowConfirm: false });
                    }}
                />
            </div>
        );
    }
}

Confirmation.contextType = ShareCommonContext;

const mapStateToProps = state => ({
    stepsInputData: state.stepsInputData,
    verifyLoanApplicationData: state.verifyLoanApplicationData,
    lov: state.listValues.values || null,
    said: state.said.customer,
    otp: state.otp,
    loanSubmission: state.loanSubmission,
    decisionResults: state.decisionResults,
    eligibilityCheckResults: state.eligibilityCheckResults,
    data: state.offers,
});

const mapDispatchToProps = {
    // push,
    fetchListValues,
    postLoanSubmission,
    evaluateEligibilityCheckResult,
    saveStepsInput,
};

export default translate()(
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(Confirmation),
);
