import React from 'react';
import XWrapMoney from '../Common/XWrapMoney';

function PlanDetailsRepayment({
    label,
    children,
}) {
    return (
        <React.Fragment>
            <div className="font-size-14 font-size-md-16 money-text">
                {label}
            </div>
            <XWrapMoney className="font-size-36 font-size-md-40 line-height-1">
                {children}
            </XWrapMoney>
        </React.Fragment>
    );
}

export default PlanDetailsRepayment;

