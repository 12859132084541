import { useEffect, useState } from 'react';
import _ from 'lodash';

import useEventBus, { EventBusName, ScreenBusUtils } from '../useEventBus';

// listen refresh Truid Data
function useRefetchTruIDAfterUpdateMissing({
    offersState,
    fetchAffordabilityResults,
    fetchSprintHiveAffordabilityResults,
    loanApplicationNumber,
}) {
    const [flagScreen, setFlagScreen] = useState(false);
    const { isApprovedTruid, qualifiedLessMoney, isIntroChecking } = offersState;

    useEventBus(EventBusName.FETCH_TRUID_MISSING_AFFORDABILITY, missingStatus => {
        fetchAffordabilityResults({ loanApplicationNumber, missingStatus });
        setFlagScreen(true);
        ScreenBusUtils.hideModalCommon();
    });

    useEventBus(EventBusName.FETCH_SPRINT_HIVE_AFFORDABILITY, () => {
        fetchSprintHiveAffordabilityResults({ loanApplicationNumber });
        setFlagScreen(true);
        ScreenBusUtils.hideModalCommon();
    });

    useEffect(() => {
        if (isApprovedTruid) {
            moveNextScreen();
        }
    }, [isApprovedTruid]);

    useEffect(() => {
        if (flagScreen) {
            setFlagScreen(false);
            if (isApprovedTruid) {
                moveNextScreen();
                return;
            }

            // from intro truID
            if (isIntroChecking) {
                const url = _.get(offersState, 'inviteUrl');
                if (!url) {
                    ScreenBusUtils.showErrorCode();
                    return;
                }
                ScreenBusUtils.gotoScreenTruID();
            }
        }
    }, [offersState]);

    function moveNextScreen() {
        if (qualifiedLessMoney) {
            ScreenBusUtils.gotoScreenLowerOffersOfDE();
            return;
        }
        ScreenBusUtils.gotoScreenPackage();
    }
}

export default useRefetchTruIDAfterUpdateMissing;
