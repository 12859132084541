import React, { useEffect, useState } from 'react';
import joinclass from 'joinclass';
import { translate } from 'react-i18next';
import useTask from 'react-task-z';

import keys from '../../i18n/translationKeys';

const MAX_TIME = 31;

const TimeCounterOtp = ({ t, maxLoop = MAX_TIME, handleResendOtp }) => {
    const [seconds, setSeconds] = useState(maxLoop);
    const isActived = seconds > 0;

    const task = useTask({
        delay: 1000,
        maxLoop,
        callback: () => {
            setSeconds(prevSeconds => prevSeconds - 1);
        },
    });

    useEffect(() => {
        task.run();
    }, []);

    const handleClick = () => {
        if (!isActived) {
            handleResendOtp();
            setSeconds(maxLoop);
            task.run();
        }
    };

    return (
        <React.Fragment>
            <h2 className="font-size-14 font-size-md-16 mt-5">{t(keys.otpPage.DID_NOT_RECEIVE)}</h2>
            <div className="mt-md-3 mt-2 mb-4">
                <a
                    className={joinclass('mt-2 no-link', isActived && 'disabled underline')}
                    onClick={handleClick}
                    aria-hidden="true"
                >
                    {isActived ?
                        <React.Fragment>You can resend a new OTP in <span>{seconds}</span> second{seconds !== 1 ? 's' : ''}</React.Fragment> :
                        <React.Fragment>Resend OTP</React.Fragment>
                    }
                </a>
            </div>
        </React.Fragment>
    );
};

export default translate()(TimeCounterOtp);
