import React from 'react';
import joinclass from 'joinclass';

import { listMonthNames } from '../../constants';
import WrapSelect from '../Common/WrapSelect';

const InputCompany = ({
  yearGap,
  company,
  getInputRef,
  workingMonth,
  workingYear,
  setFormData,
  errorMessage,
}) => {
  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth();
  const startYear = year - yearGap;
  const listRangeMonths = `${year}` !== `${workingYear}` ? listMonthNames : listMonthNames.slice(0, month + 1);
  const listYears = Array.from({ length: yearGap + 1 }, (_, i) => startYear + i).reverse();

  return (
    <React.Fragment>
      <div className="font-weight-bold mt-4 mt-md-5 mb-3">
        Enter your current employment details
      </div>

      <div className="d-flex flex-column d-flex flex-md-row gap-24 gap-md-16">
        <div className={joinclass('col-md-5 col-12 p-0', errorMessage && 'has-error')}>
          <label>Company name</label>
          <input
            ref={getInputRef}
            className="form-control"
            value={company}
            onChange={e => setFormData(e.target.value, 'company')}
          />
          {errorMessage && <div className="with-errors">{errorMessage}</div>}
        </div>

        <div className="col-md-5 col-12 p-0 d-flex flex-row d-flex justify-content-start justify-content-md-start gap-24 gap-md-16">
          <div className="col-md-5 p-0 company-date-time">
            <label>Starting month</label>
            <WrapSelect
              fullHeight
              value={listRangeMonths[workingMonth - 1]}
              listData={listRangeMonths}
              handleSelection={value => setFormData(listMonthNames.findIndex(i => i === value) + 1, 'workingMonth')}
            />
          </div>

          <div className="col-md-3 p-0 company-date-time">
            <label>Starting year</label>
            <WrapSelect
              right
              value={workingYear}
              listData={listYears}
              handleSelection={value =>
                setFormData(value, 'workingYear', value === year)
              }
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default InputCompany;
