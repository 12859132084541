import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import useEventBus, { EventBusName } from '../hooks/useEventBus';
import { MovingFadeIn, MovingPopIn } from '../components/Common/AnimateText';
import { FlexColumnCenter } from '../components/Common/FlexCenter';

import move from '../assets/images/move.png';

const OverlayLoading = (props) => {
    const [showQuick, setShowQuick] = useState(false);
    const [loadingMessage, setLoadingMessage] = useState('');
    const { overlayLoading } = props.ui;

    useEventBus(EventBusName.SHOW_LOADING_QUICK, state => setShowQuick(Boolean(state)));
    useEventBus(EventBusName.SHOW_LOADING_MESSAGE, message => {
        setLoadingMessage(message);
    });

    useEffect(() => {
        !overlayLoading && setLoadingMessage('');
    }, [overlayLoading]);

    if (!(overlayLoading || showQuick)) {
        return <React.Fragment />;
    }

    const getCorrectZIndex = () => {
        const shownModal = document.querySelector('.modal.show');
        return shownModal ? window.getComputedStyle(shownModal).getPropertyValue('z-index') + 1 : 24;
    };

    return (
        <div className="over-loading-wrap" style={{ zIndex: getCorrectZIndex() }}>
            <MovingFadeIn>
                <FlexColumnCenter className="spinner-wrapper font-size-20">
                    <span
                        className="spinner mb-3"
                        style={{ backgroundImage: `url(${move})` }}
                    />
                    {loadingMessage && <MovingPopIn duration="2s">{loadingMessage}</MovingPopIn>}
                </FlexColumnCenter>
            </MovingFadeIn>
        </div>
    );
};

const mapStateToProps = state => ({
    ui: state.ui,
});

export default connect(
    mapStateToProps,
    {},
)(OverlayLoading);
