import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';

import { fetchListValues, saveStepsInput } from '../actions';
import { isEditMode } from '../utils/commonUtils';
import { ScreenBusUtils } from '../hooks/useEventBus';

import ExpectedAmount from '../components/LoanInformation/ExpectedAmount';

export class LoanInfo extends Component {
    constructor(props) {
        super(props);
        const { amount = '' } = this.props.loanInfo;
        this.state = { amountValue: amount };

        this.isEditMode = isEditMode(this.props);
    }

    componentDidMount() {
        if (!this.props.lov || !Object.keys(this.props.lov).length || !this.props.lov.values) {
            this.props.fetchListValues();
        }
    }

    handleNext = (amount) => {
        if (!amount) {
            return;
        }

        this.props.saveStepsInput({
            loanInfo: {
                ...this.props.loanInfo,
                amount,
            },
        });
        ScreenBusUtils.gotoScreenLoanPurpose(this.isEditMode);
    };

    render() {
        if (this.props.lov.isLoading) {
            return null;
        }
        return (
            <ExpectedAmount
                isEditMode={this.isEditMode}
                value={this.state.amountValue}
                handleLoanAmount={this.handleNext}
            />
        );
    }
}

const mapStateToProps = (state) => ({
    lov: state.listValue || {},
    loanInfo: _.get(state, 'stepsInputData.loanInfo') || {},
});

const mapDispatchToProps = {
    fetchListValues,
    saveStepsInput,
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
)(LoanInfo);
