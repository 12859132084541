import { ErrorCode } from '../constants';
import { SegmentBusTrackFacials } from './SegmentBusTrackFacials';

export const segmentTrackCode = {
    // offer decline by SAID vs PHONE: FACIAL
    [ErrorCode.FACIAL_OFFER_RETRACTED]:
        () => SegmentBusTrackFacials.segment2100FacialVerificationSaIDErrorNoHanisPhotoData(),
};

export class SegmentBusTrackCode {
    static tracking(code, params) {
        const t = segmentTrackCode[code];
        if (t) {
            t(params);
        }
    }
}
