import { of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, switchMap } from 'rxjs/operators';

import apiService from '../services/apiService';
import ErrorUtils from '../utils/errorUtils';

import { POST_LOAN_SUBMISSION } from '../store/actionTypes';
import { postLoanSubmissionSuccess, fetchDecisionResults } from '../actions';

export function postLoanSubmissionEpic(action$) {
    return action$.pipe(
        ofType(POST_LOAN_SUBMISSION),
        switchMap(({ payload }) => {
            const {
                captchaToken,
                information,
                provider,
                isReceivedSalaryInTyme,
                isSelectedTymeAffordability,
            } = payload;

            return apiService
                .postLoanSubmission(captchaToken, information, provider)
                .pipe(
                    switchMap(res => of(
                        postLoanSubmissionSuccess(res),
                        fetchDecisionResults({
                            loanApplicationNumber: res.loanApplicationNumber,
                            isReceivedSalaryInTyme,
                            isSelectedTymeAffordability,
                            isLoanSubmit: true,
                        }),
                    )),
                    catchError(error => ErrorUtils.getApiAction(error)),
                );
        }));
}

export default combineEpics(postLoanSubmissionEpic);
