import { WrapSegmentBusUtils } from '../hooks/useSegmentEventBus';

export class SegmentBusTrackFinal {
    static segment2200LoanSuccessScreenData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment2200LoanSuccessScreenData');
    }

    static segment2200LoanFundedDisplayedData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment2200LoanFundedDisplayedData');
    }
}
