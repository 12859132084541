import { useState } from 'react';
import useEventBus, { EventBusName } from './useEventBus';

export const isOverlayLoading = (ui = {}) => {
    const [showQuick, setShowQuick] = useState(false);
    const isLoading = ui.overlayLoading || showQuick;

    useEventBus(EventBusName.SHOW_LOADING_QUICK, state => setShowQuick(Boolean(state)));
    return isLoading;
}

export const isScreenBackgroundLoading = (ui = {}) => {
    const [backgroundLoading, setBackgroundLoading] = useState(false);

    useEventBus(EventBusName.SHOW_LOADING_IN_BACKGROUND, state => setBackgroundLoading(Boolean(state)));
    return backgroundLoading || ui.overlayLoading;
}
