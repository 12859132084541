import React from 'react';

export const TwitterIcon = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.05469 15.9825C12.1968 15.9825 15.5547 12.6032 15.5547 8.43459C15.5547 4.26602 12.1968 0.886719 8.05469 0.886719C3.91255 0.886719 0.554688 4.26602 0.554688 8.43459C0.554688 12.6032 3.91255 15.9825 8.05469 15.9825Z" fill="#57BEEC" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.95369 6.46652C7.95369 6.63368 7.97376 6.79597 8.01087 6.9514C5.41825 6.93322 3.79987 4.81171 3.79987 4.81171C3.79987 4.81171 2.88492 6.30258 4.44495 7.57504C4.10475 7.54978 3.93392 7.50752 3.52494 7.30643C3.52494 7.93007 3.84997 9.03042 5.16005 9.35986C5.08 9.39048 4.655 9.49193 4.25996 9.40079C4.25996 9.58322 4.85517 10.8153 6.16019 10.8153C5.95528 11.0031 4.89026 11.8339 3.17969 11.6818C6.19668 13.7817 11.929 12.2768 12.0108 6.57363C12.0125 6.53806 12.0135 6.50248 12.0135 6.46652C12.0135 6.46085 12.0131 6.45487 12.0131 6.44897C12.1383 6.36829 12.8615 5.72411 13.0294 5.37947C12.4813 5.6229 12.0562 5.6887 11.8667 5.69759C12.3513 5.38467 12.6405 4.99328 12.7662 4.5479C12.5113 4.72538 11.8631 5.02153 11.4593 5.05349H11.4588C11.0888 4.65635 10.5651 4.4082 9.98361 4.4082C8.8625 4.4082 7.95369 5.32965 7.95369 6.46652Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.95369 6.46652C7.95369 6.63368 7.97376 6.79597 8.01087 6.9514C5.41825 6.93322 3.79987 4.81171 3.79987 4.81171C3.79987 4.81171 2.88492 6.30258 4.44495 7.57504C4.10475 7.54978 3.93392 7.50752 3.52494 7.30643C3.52494 7.93007 3.84997 9.03042 5.16005 9.35986C5.08 9.39048 4.655 9.49193 4.25996 9.40079C4.25996 9.58322 4.85517 10.8153 6.16019 10.8153C5.95528 11.0031 4.89026 11.8339 3.17969 11.6818C6.19668 13.7817 11.929 12.2768 12.0108 6.57363C12.0125 6.53806 12.0135 6.50248 12.0135 6.46652C12.0135 6.46085 12.0131 6.45487 12.0131 6.44897C12.1383 6.36829 12.8615 5.72411 13.0294 5.37947C12.4813 5.6229 12.0562 5.6887 11.8667 5.69759C12.3513 5.38467 12.6405 4.99328 12.7662 4.5479C12.5113 4.72538 11.8631 5.02153 11.4593 5.05349H11.4588C11.0888 4.65635 10.5651 4.4082 9.98361 4.4082C8.8625 4.4082 7.95369 5.32965 7.95369 6.46652Z" fill="white" />
  </svg>
);

export const LinkedInIcon = () => (
  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.74219 15.9825C12.8843 15.9825 16.2422 12.6032 16.2422 8.43459C16.2422 4.26602 12.8843 0.886719 8.74219 0.886719C4.60005 0.886719 1.24219 4.26602 1.24219 8.43459C1.24219 12.6032 4.60005 15.9825 8.74219 15.9825Z" fill="#57BEEC" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.4956 6.33509C6.04977 6.33509 6.49902 5.90724 6.49902 5.37946C6.49902 4.85168 6.04977 4.42383 5.4956 4.42383C4.94143 4.42383 4.49219 4.85168 4.49219 5.37946C4.49219 5.90724 4.94143 6.33509 5.4956 6.33509Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.57422 7.05078H6.39861V12.5685H4.57422V7.05078Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.42773 7.05065H9.25212V7.86001C9.25212 7.86001 9.6815 6.94727 10.8237 6.94727C13.0279 6.94727 12.9875 9.18628 12.9875 9.18628V12.5684H11.1406V9.43758C11.1406 9.43758 11.1577 8.38236 10.2076 8.38236C9.41623 8.38236 9.25212 9.02972 9.25212 9.18628C9.25212 10.7449 9.25212 12.5684 9.25212 12.5684H7.42773V7.05065Z" fill="white" />
  </svg>
);

