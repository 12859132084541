import React from 'react';

const PaymentsIcon = () => (
  <svg width="46" height="58" viewBox="0 0 46 58" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.88281 57.0971H45.0093V5.79102H5.88281V57.0971Z" fill="#FFCC00" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.88281 57.0971H45.0093V5.79102H5.88281V57.0971Z" stroke="#4F4E4D" />
    <path d="M0.992188 52.2104H40.1187V0.904297H0.992188V52.2104Z" fill="#FEFEFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M0.992188 52.2103H40.1187V0.904297H0.992188V52.2103Z" stroke="#4F4E4D" />
    <path d="M20.5547 15.5623H35.2271" stroke="#4F4E4D" />
    <path d="M25.4453 20.449H35.2269" stroke="#4F4E4D" />
    <path fillRule="evenodd" clipRule="evenodd" d="M16.8174 13.2531C16.8275 10.0513 14.0992 9.33398 12.8699 9.33398H5.44141V23.3821H8.43313V17.846H11.6464C13.6505 17.8739 13.7771 19.7303 13.888 21.3569C13.9441 22.1794 13.9962 22.9431 14.285 23.3821H17.2428C16.777 22.6887 16.7249 21.6955 16.6715 20.6792C16.5855 19.0403 16.4963 17.3412 14.6639 16.7429C16.1309 16.1097 16.8116 15.0601 16.8174 13.2531ZM8.43503 15.7434L8.44502 11.7715L11.6965 11.7857C11.6965 11.7857 13.811 11.6401 13.811 13.7406C13.811 15.7983 11.6865 15.7577 11.6865 15.7577L8.43503 15.7434Z" fill="#231F20" />
  </svg>
);

export const PaymentsMobileIcon = () => (
  <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.16406 30.5001H27.3584V4.01953H7.16406V30.5001Z" fill="#FFCC00" />
    <path fillRule="evenodd" clipRule="evenodd" d="M7.16406 30.5001H27.3584V4.01953H7.16406V30.5001Z" stroke="#4F4E4D" strokeWidth="0.516129" />
    <path d="M4.64062 27.9786H24.835V1.49805H4.64062V27.9786Z" fill="#FEFEFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.64062 27.9786H24.835V1.49805H4.64062V27.9786Z" stroke="#4F4E4D" strokeWidth="0.516129" />
    <path d="M14.7383 9.0642H22.3112" stroke="#4F4E4D" strokeWidth="0.516129" />
    <path d="M17.2617 11.5857H22.3103" stroke="#4F4E4D" strokeWidth="0.516129" />
    <path fillRule="evenodd" clipRule="evenodd" d="M12.809 7.87045C12.8142 6.2179 11.406 5.84766 10.7716 5.84766H6.9375V13.0983H8.48162V10.2409H10.1401C11.1745 10.2553 11.2398 11.2135 11.2971 12.053C11.326 12.4775 11.3529 12.8717 11.5019 13.0983H13.0286C12.7881 12.7404 12.7612 12.2278 12.7337 11.7033C12.6893 10.8574 12.6432 9.98043 11.6975 9.6716C12.4547 9.34482 12.806 8.80309 12.809 7.87045ZM8.48185 9.15576L8.48702 7.10572L10.1652 7.11309C10.1652 7.11309 11.2566 7.03791 11.2566 8.12206C11.2566 9.1841 10.16 9.16313 10.16 9.16313L8.48185 9.15576Z" fill="#231F20" />
  </svg>
);

export default PaymentsIcon;

export const PaymentsCheckIcon = () => (
  <svg width="39" height="40" viewBox="0 0 39 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.1875 31.6527H28.8715V4.46289H8.1875V31.6527Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M8.1875 31.6527H28.8715V4.46289H8.1875V31.6527Z" stroke="#4F4E4D" />
    <path d="M5.60156 29.0629H26.2855V1.87305H5.60156V29.0629Z" fill="#FEFEFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M5.60156 29.0629H26.2855V1.87305H5.60156V29.0629Z" stroke="#4F4E4D" />
    <path d="M8.1875 9.64129H21.115" stroke="#4F4E4D" />
    <path d="M22.4082 9.64129H23.7009" stroke="#4F4E4D" />
    <path d="M22.4082 12.2311H23.7009" stroke="#4F4E4D" />
    <path d="M8.1875 12.2311H21.115" stroke="#4F4E4D" />
    <path d="M8.1875 25.1784H13.3585" stroke="#4F4E4D" />
    <path d="M25.6393 38.1249C29.9231 38.1249 33.3958 34.6469 33.3958 30.3564C33.3958 26.066 29.9231 22.5879 25.6393 22.5879C21.3555 22.5879 17.8828 26.066 17.8828 30.3564C17.8828 34.6469 21.3555 38.1249 25.6393 38.1249Z" fill="#60D154" stroke="#4F4C4D" />
    <path d="M22.084 31.4077L24.0855 33.4123L28.6323 27.1191" stroke="white" />
  </svg>
);

export const PaymentsSuccessIcon = () => (
  <svg width="65" height="64" viewBox="0 0 65 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32.5 64C50.1731 64 64.5 49.6731 64.5 32C64.5 14.3269 50.1731 0 32.5 0C14.8269 0 0.5 14.3269 0.5 32C0.5 49.6731 14.8269 64 32.5 64Z" fill="#E8E8E8" />
    <rect x="14.3867" y="15.3086" width="31.8847" height="23.0657" rx="7.2" fill="white" stroke="#4F4D4C" />
    <path d="M14.3867 18.041V41.5371C14.3867 42.4317 15.1119 43.1569 16.0065 43.1569H46.0157C46.9103 43.1569 47.6355 42.4317 47.6355 41.5371V19.6608C47.6355 18.7662 46.9103 18.041 46.0157 18.041H14.3867Z" fill="white" stroke="#4F4D4C" />
    <rect x="37.832" y="27.1836" width="9.54836" height="6.83379" fill="white" stroke="#4F4D4C" />
    <path d="M40.5598 27.1836V34.0174" stroke="#4F4D4C" />
    <path d="M43.5555 49.4997C47.9635 49.4997 51.5368 45.9264 51.5368 41.5184C51.5368 37.1105 47.9635 33.5371 43.5555 33.5371C39.1476 33.5371 35.5742 37.1105 35.5742 41.5184C35.5742 45.9264 39.1476 49.4997 43.5555 49.4997Z" fill="#60D154" stroke="#3F4449" />
    <path d="M39.8965 42.5993L41.9561 44.6589L46.6346 38.1934" stroke="white" />
  </svg>
);
