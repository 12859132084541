// import * as Yup from 'yup'; => dosent matching with this project / please upgrade it
import _ from 'lodash';
import { EMAIL_REGEX } from '../constants';

// optimized later
export class ValidatorUtils {
    static isRequired = (value) => _.isNil(value) || _.isEmpty(value);
    static isMatchingEmail = (value) => EMAIL_REGEX.test(value);

    static validateEmpty = ({
        value,
        emptyMessage = 'Enter a value.',
    }) => {
        if (ValidatorUtils.isRequired(value)) {
            return emptyMessage;
        }
        return '';
    }

    static validateOptionEmpty = ({
        value,
        emptyMessage = 'Select an option from the list.',
    }) => ValidatorUtils.validateEmpty({
        value,
        emptyMessage,
    })

    static validateEmail = ({
        value,
        emailEmptyMessage = 'Enter an email address.',
        emailInvalidMessage = 'Enter a valid email address.',
    }) => {
        if (ValidatorUtils.isRequired(value)) {
            return emailEmptyMessage;
        }
        if (!ValidatorUtils.isMatchingEmail(value)) {
            return emailInvalidMessage;
        }
        return '';
    }
}

// optimized later
// export const yupValidateUserInfo = ({
//     emailEmptyMessage = 'Enter an email address.',
//     emailInvalidMessage = 'Enter a valid email address.',
//     raceEmptyMessage = 'Select an option from the list.',
// }) => Yup.object().shape({
//     selectedRace: Yup.string().required(raceEmptyMessage),
//     emailValue: Yup.string().required(emailEmptyMessage).matches(EMAIL_REGEX, emailInvalidMessage),
// });
