import React from 'react';

const CloseIcon = ({
  stroke = '#9E9E9E',
}) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32">
    <g fill="none" fillRule="evenodd">
      <path d="M32 0H0v32h32V0z" />
      <path stroke={stroke} strokeLinecap="square" strokeWidth="2" d="M10 22l12-12M22 22L10 10" />
    </g>
  </svg>
);

export default CloseIcon;
