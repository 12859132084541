import React from 'react';

import FacebookIcon from '../images/footer/facebook_icon.png';
import { LinkedInIcon, TwitterIcon } from '../images/elements/SocialIcon';

export const socialInfoDatas = [
  {
    url: 'https://www.facebook.com/tymebankza/',
    icon: <img src={FacebookIcon} alt="" />,
    label: 'Facebook',
  },
  {
    url: 'https://twitter.com/tymebankza/',
    icon: <TwitterIcon />,
    label: 'Twitter',
  },
  {
    url: 'https://www.linkedin.com/company/tyme/',
    icon: <LinkedInIcon />,
    label: 'LinkedIn',
  },
];

export const tymePolicyDatas = [
  {
    url: 'https://www.tymebank.co.za/legal/paia-manual/',
    label: 'PAIA manual',
  },
  {
    url: 'https://www.tymebank.co.za/legal/terms-conditions/',
    label: 'Website terms & conditions',
  },
  {
    url: 'https://www.tymebank.co.za/legal/privacy-policy/',
    label: 'Privacy policy',
  },
];
