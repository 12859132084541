import React from 'react';

const TickIcon = () => (
  <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="12" cy="12.2168" r="10" fill="#FFCC00" stroke="#FFCC00" strokeWidth="2" />
    <path d="M16.5 8.7168L11.1667 15.7168L8.5 12.535" stroke="#1A1A1A" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
  </svg>
);

export default TickIcon;
