import React, { Component } from 'react';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import _ from 'lodash';

import translationKeys from '../i18n/translationKeys';

import { fetchListValues, saveStepsInput } from '../actions';
import { formatMoney } from '../utils/format';
import { isEditMode } from '../utils/commonUtils';
import { ValidatorUtils } from '../utils/yupUtils';
import { ScreenBusUtils } from '../hooks/useEventBus';

import ButtonListSelectionTick from '../components/Common/ButtonListSelectionTick';
import BackButton from '../components/Common/BackButton';
import PLButton from '../components/Common/PLButton';
import { WrapAction } from '../components/Common/ActionButtons';

export class LoanPurpose extends Component {
    constructor(props) {
        super(props);

        const { selectedPurpose, amount = '' } = this.props.loanInfo;
        this.state = {
            amountValue: amount,
            selectionCode: selectedPurpose || {},
            errorMessage: '',
        };

        this.isEditMode = isEditMode(this.props);
    }

    componentDidMount() {
        if (!this.props.lov || !Object.keys(this.props.lov).length || !this.props.lov.values) {
            this.handleBack();
        }
    }

    handleValidation = (forward) => {
        const { selectionCode } = this.state;

        const errorMessage = ValidatorUtils.validateOptionEmpty({ value: selectionCode.code });
        if (errorMessage) {
            this.setState({
                errorMessage,
            });
            return;
        }

        this.props.saveStepsInput({
            loanInfo: {
                ...this.props.loanInfo,
                selectedPurpose: selectionCode,
                loanPurposeFastTrack: false,
                payInitiationFeeUpfront: false,
            },
        });
        forward();
    };

    handleBack = () => {
        this.isEditMode ? ScreenBusUtils.gotoScreenLoanAmountEdit() : ScreenBusUtils.gotoScreenLoanAmount(true);
    }

    handleNext = () => {
        this.handleValidation(ScreenBusUtils.gotoScreenUser);
    }

    handleUpdate = () => {
        this.handleValidation(ScreenBusUtils.gotoScreenConfirmation);
    }

    render() {
        return (
            <React.Fragment>
                <BackButton className="pb-3" onClick={this.handleBack}>
                    Loan amount: {formatMoney(this.state.amountValue)}
                </BackButton>

                <div className="form-group">
                    <ButtonListSelectionTick
                        label={this.props.t(translationKeys.loanPurposePage.LOAN_PURPOSE_OPTIONS)}
                        selectedValue={this.state.selectionCode.code}
                        listData={_.get(this.props.lov.values, 'LOAN_PURPOSE') || []}
                        btnGroup="reasons-borrow"
                        errorMessage={this.state.errorMessage}
                        handleChangeSelection={(code, name) => {
                            this.setState({
                                selectionCode: {
                                    code,
                                    name,
                                },
                                errorMessage: '',
                            });
                        }}
                        handleNext={this.isEditMode ? this.handleUpdate : this.handleNext}
                    />
                </div>

                <WrapAction className="buttons my-4 flex-column-reverse d-flex d-md-block">
                    {this.isEditMode &&
                        <PLButton
                            primary={false}
                            onClick={() => ScreenBusUtils.gotoScreenConfirmation()}>
                            Cancel
                        </PLButton>
                    }

                    <PLButton onClick={this.isEditMode ? this.handleUpdate : this.handleNext}>
                        {this.isEditMode ? 'Save' : 'Next'}
                    </PLButton>
                </WrapAction>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state) => ({
    loanInfo: _.get(state, 'stepsInputData.loanInfo') || {},
    lov: state.listValues || {},
});

export default translate()(
    connect(
        mapStateToProps,
        {
            fetchListValues,
            saveStepsInput,
        },
    )(LoanPurpose),
);
