import React from 'react';
import WrapSelect from '../Common/WrapSelect';

const TruidSelectSalaryDate = ({
    formData = {},
    setFormData,
}) => (
    <div className="form-group">
        <label>I receive my salary on</label>

        <WrapSelect
            value={formData.salaryDate}
            handleSelection={(value) => setFormData({
                ...formData,
                salaryDate: value,
            })}
            right
            listData={[
                ...['01', '02', '03', '04', '05', '06', '07', '08', '09'],
                ...Array.from(Array(32).keys()).slice(10),
            ]}
        />
    </div>
);

export default TruidSelectSalaryDate;
