import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';

import { fetchFulfillmentSuccess } from '../actions';
import { FETCH_FULFILLMENT_RESULTS } from '../store/actionTypes';

import apiService from '../services/apiService';
import ErrorUtils from '../utils/errorUtils';

export function getFulfillmentEpic(action$) {
    return action$.pipe(
        ofType(FETCH_FULFILLMENT_RESULTS),
        switchMap(({ payload }) => {
            const {
                stepUpToken, loanReferenceNumber,
            } = payload;
            return apiService
                .getFulfillment(stepUpToken, loanReferenceNumber)
                .pipe(
                    map(res => fetchFulfillmentSuccess(res)),
                    catchError(error => ErrorUtils.getApiAction(error)),
                );
        }));
}

export default combineEpics(getFulfillmentEpic);
