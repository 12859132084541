import React from 'react';
import Slider from 'rc-slider';
import joinclass from 'joinclass';

const PLSlider = ({
  className,
  min = 1,
  max = 100,
  value = 0,
  step = 1,
  disabled = false,
  onChange = () => { },
}) => (
  <Slider
    className={joinclass('pl-slider', disabled && 'opacity-dot-4', className)}
    min={min}
    max={max}
    step={step}
    value={value}
    disabled={disabled}
    onChange={v => !disabled && onChange(v)}
  />
);

export default PLSlider;
