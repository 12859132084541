import React from 'react';
import { DecisioningResult } from '../../constants';

import { XWrapMoneyLargeSkeleton, XWrapLabelSmallSkeleton } from '../Common/LoadingSkeletons';
import { LabelLoanApplication } from '../Common/LabelLoanApplication';
import XWrapMoney from '../Common/XWrapMoney';
import HelpTip from '../Common/HelpTip';
import SelectionOfferItem from './SelectionOfferItem';
import { PLTooltipInfomationIcon } from '../Common/PLTooltip';

const OfferViewChoices = ({
    mainFlow,
    offers,
    amount,
    dateExpired,
    isTymeBankSalary,
    loanApplicationStatus,
    loanApplicationNumber,
}) => {
    const isApproved = loanApplicationStatus === DecisioningResult.loanApplicationStatus.APPROVED;
    return (
        <React.Fragment>
            <LabelLoanApplication refNumber={loanApplicationNumber} />

            <h2 className="font-size-16 font-size-md-20 font-weight-medium mt-4">
                <span className="d-inline-flex align-items-center">
                    Your loan has been approved for:
                    {mainFlow &&
                        <PLTooltipInfomationIcon id="offer-tooltip">
                            This amount might change after we analyse your affordability
                        </PLTooltipInfomationIcon>
                    }
                </span>
            </h2>

            <XWrapMoneyLargeSkeleton>
                <XWrapMoney className="font-size-36 font-size-md-40">{amount}</XWrapMoney>
            </XWrapMoneyLargeSkeleton>

            <p className="font-size-14 font-size-md-20 font-weight-medium mt-5 mb-4">
                {
                    isApproved
                        ? 'You’ll be able to choose from the following repayment plans:' :
                        'We’ll verify your affordability in the next step. After that, you’ll be able to choose from the following repayment plans:'
                }
            </p>

            <div className="choices row justify-content-center mb-0 mb-md-2">
                {offers.map((offer) => (
                    <SelectionOfferItem
                        key={offer.tenor}
                        offer={offer}
                        isApproved={isApproved}
                    />
                ))}
            </div>

            {
                !(isApproved && isTymeBankSalary) &&
                <HelpTip className="mt-0 mt-md-4 mb-4" classNameContent="help-tip-content">
                    <span className="mr-1">This conditional approval is valid until</span>
                    <XWrapLabelSmallSkeleton>
                        <span className="font-weight-500">{dateExpired}.</span>
                    </XWrapLabelSmallSkeleton>
                </HelpTip>
            }
        </React.Fragment>
    );
};

export default OfferViewChoices;
