import React from 'react';
import styled from 'styled-components';
import joinclass from 'joinclass';

import HelpIcon from '../../assets/images/elements/HelpIcon';
import TickSuccessIcon from '../../assets/images/elements/TickSuccessIcon';

export const WrapHelp = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
  font-size: 16px;

  &.small {
    font-size: 14px;
  }

  .tip-icon {
    margin-top: -1px;
  }

  .tip-content div {
    display: inline;
  }

  @media (max-width: 767px) {
    align-items: start;
    &.center {
      align-items: center;
    }
    font-size: 14px;

    .small {
      font-size: 14px !important;
    }
  }
`;

function HelpTip({
  label,
  children,
  className,
  classNameContent,
  small,
  tickIcon,
  customizeIcon,
  center,
}) {
  const defaultIcon = !tickIcon ? <HelpIcon /> : <TickSuccessIcon />;
  return (
    <WrapHelp className={joinclass(className, small && 'small', center && 'center')}>
      <div className="tip-icon">{customizeIcon || defaultIcon}</div>
      <div className={joinclass('d-inline tip-content', classNameContent)}> {label || children}</div>
    </WrapHelp>
  );
}

export default HelpTip;
