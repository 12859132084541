import { FaceTecSDK } from 'react-facetec-sdk/build/core/FaceTecSDK.js/FaceTecSDK';

import { buildPreSignedBody } from './FacialEncryption';
import { FacialBusUtils, ScreenBusUtils } from '../../hooks/useEventBus';
import { SegmentBusTrackFacials } from '../../segment-bus/SegmentBusTrackFacials';

export class LivenessCheckProcessor {
    latestNetworkRequest = new XMLHttpRequest();
    latestSessionResult;
    isError;
    publicEncryptionKey;
    preSignedURL;
    onComplete;
    selfieResult;
    faceScanResultCallback;

    constructor(preSignedURL, publicEncryptionKey, onComplete, sessionToken = '') {
        this.isError = false;
        this.onComplete = onComplete;
        this.latestSessionResult = null;
        this.preSignedURL = preSignedURL;
        this.publicEncryptionKey = publicEncryptionKey;
        this.selfieResult = null;

        new FaceTecSDK.FaceTecSession(this, sessionToken);
    }

    async processSessionResultWhileFaceTecSDKWaits(sessionResult, faceScanResultCallback) {
        // Save the current sessionResult
        this.latestSessionResult = sessionResult;
        this.faceScanResultCallback = faceScanResultCallback;

        if (sessionResult.status !== FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully) {
            this.latestNetworkRequest.abort();
            faceScanResultCallback.cancel();

            // handle error
            if (
                [
                    FaceTecSDK.FaceTecSessionStatus.Timeout,
                    FaceTecSDK.FaceTecSessionStatus.UnknownInternalError,
                ].includes(sessionResult.status)
            ) {
                FacialBusUtils.showTimeout();
                // SegmentBusTrackFacials.trackingFacetecSession(sessionResult.status);
                return;
            }

            // handle error
            if (sessionResult.status === FaceTecSDK.FaceTecSessionStatus.ContextSwitch) {
                FacialBusUtils.showSDKError(sessionResult.status);
                return;
            }

            // cancel handle
            if (
                [
                    FaceTecSDK.FaceTecSessionStatus.UserCancelled,
                    FaceTecSDK.FaceTecSessionStatus.ProgrammaticallyCancelled,
                    FaceTecSDK.FaceTecSessionStatus.OrientationChangeDuringSession,
                    FaceTecSDK.FaceTecSessionStatus.LandscapeModeNotAllowed,
                    FaceTecSDK.FaceTecSessionStatus.UserCancelledFromNewUserGuidance,
                    FaceTecSDK.FaceTecSessionStatus.UserCancelledFromRetryGuidance,
                    FaceTecSDK.FaceTecSessionStatus.UserCancelledWhenAttemptingToGetCameraPermissions,
                    FaceTecSDK.FaceTecSessionStatus.LockedOut,
                    FaceTecSDK.FaceTecSessionStatus.UserCancelledViaClickableReadyScreenSubtext,
                    FaceTecSDK.FaceTecSessionStatus.UserCancelledFullScreenMode,
                ].includes(sessionResult.status)
            ) {
                ScreenBusUtils.notifyConfirmScreenLeave();
                // more reason
                if (sessionResult.status !== FaceTecSDK.FaceTecSessionStatus.UserCancelled) {
                    SegmentBusTrackFacials.trackingFacetecSession(sessionResult.status);
                }
                return;
            }

            if (
                [
                    FaceTecSDK.FaceTecSessionStatus.CameraNotEnabled,
                    FaceTecSDK.FaceTecSessionStatus.CameraNotRunning,
                ].includes(sessionResult.status)
            ) {
                FacialBusUtils.showBlockCamera();
                // SegmentBusTrackFacials.trackingFacetecSession(sessionResult.status);
                return;
            }

            FacialBusUtils.showGeneric();
            SegmentBusTrackFacials.trackingFacetecSession(sessionResult.status);
            return;
        }

        // we cannot detect faces
        if (!sessionResult.faceScan) {
            this.latestNetworkRequest.abort();
            faceScanResultCallback.cancel();
            FacialBusUtils.showGeneric(() => FacialBusUtils.refreshFacialSession(true));
            // SegmentBusTrackFacials.trackingFacetecSession(sessionResult.status);
            return;
        }

        const parameters = await buildPreSignedBody(sessionResult, this.publicEncryptionKey);
        this.latestNetworkRequest = new XMLHttpRequest();
        this.latestNetworkRequest.open('PUT', this.preSignedURL);
        this.latestNetworkRequest.setRequestHeader('Content-Type', 'application/json');
        this.latestNetworkRequest.setRequestHeader('Access-Control-Allow-Origin', '*');
        this.latestNetworkRequest.setRequestHeader('x-amz-acl', 'bucket-owner-full-control');

        this.latestNetworkRequest.crossDomain = true;
        this.isError = false;

        this.latestNetworkRequest.onreadystatechange = () => {
            SegmentBusTrackFacials.segment2100FacialVerificationCaptureSelfieData();
            if (this.latestNetworkRequest.readyState === XMLHttpRequest.DONE) {
                try {
                    const responseJSON = JSON.parse(this.latestNetworkRequest.responseText);
                    // eslint-disable-next-line prefer-destructuring
                    const scanResultBlob = responseJSON.scanResultBlob;
                    if (responseJSON.wasProcessed) {
                        FaceTecSDK.FaceTecCustomization.setOverrideResultScreenSuccessMessage(
                            'Liveness\nConfirmed',
                        );

                        faceScanResultCallback.proceedToNextStep(scanResultBlob);
                    } else {
                        faceScanResultCallback.cancel();
                    }
                } catch (ex) {
                    faceScanResultCallback.cancel();
                }
            }
        };

        this.latestNetworkRequest.onerror = () => {
            this.isError = true;
            faceScanResultCallback.cancel();
        };

        this.latestNetworkRequest.upload.onprogress = (event) => {
            const progress = event.loaded / event.total;
            faceScanResultCallback.uploadProgress(progress);
        };

        const jsonStringToUpload = JSON.stringify(parameters);
        this.latestNetworkRequest.send(jsonStringToUpload);

        setTimeout(() => {
            if (this.latestNetworkRequest.readyState === XMLHttpRequest.DONE) {
                return;
            }
            faceScanResultCallback.uploadMessageOverride('Still Uploading...');
        }, 6000);
    }

    onFaceTecSDKCompletelyDone = async () => {
        const success = (this.latestSessionResult || {}).status ===
            FaceTecSDK.FaceTecSessionStatus.SessionCompletedSuccessfully;
        this.onComplete(this.latestSessionResult, success && !this.isError, this.latestNetworkRequest.status);

        if (this.isError) {
            FacialBusUtils.showErrorUpload(() => FacialBusUtils.refreshFacialSession(true));
        }
    };

    clearStop = () => {
        this.latestNetworkRequest && this.latestNetworkRequest.abort();
        this.faceScanResultCallback && this.faceScanResultCallback.cancel();
        this.faceScanResultCallback = undefined;
    }
}
