import React, { useState } from 'react';
import joinclass from 'joinclass';

import {
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
} from 'reactstrap';
import useEventListener from '../../hooks/useEventListener';

function WrapSelect({
    value,
    right,
    listData = [],
    classNameItemMenu,
    fullHeight = false,
    handleSelection = () => { },
}) {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen(prevState => !prevState);

    useEventListener('scroll', () => setDropdownOpen(false));

    return (
        <Dropdown isOpen={dropdownOpen} toggle={toggle}>
            <DropdownToggle className={joinclass('form-control', right && 'justify-content-end')} caret><span>{value}</span></DropdownToggle>
            <DropdownMenu className={joinclass('pl-shadow', fullHeight && 'dropdown-full-height')}>
                {listData.map((item) => (
                    <DropdownItem
                        key={item}
                        className={joinclass('pl-dropdown-item', value === item ? 'selected' : null, classNameItemMenu)}
                        onClick={() => handleSelection(item)}
                    >
                        <span>{item}</span>
                    </DropdownItem>
                ))}
            </DropdownMenu>
        </Dropdown>
    );
}
export default WrapSelect;
