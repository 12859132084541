import React from 'react';

import Analytics from 'analytics';
import _ from 'lodash';
import segmentPlugin from '@analytics/segment';

import Config from '../config';
import { useCommonContext } from '../context/CommonContext';

import useEventBus from './useEventBus';
import { SEGMENT_EVENT, WrapSegmentBusUtils, useSegmentEventBus } from './useSegmentEventBus';

import { createSegmentProductData, createSegmentEventDatas } from '../assets/segment/segment-base-build-data';
import { mappingConfigSegmentNames } from '../assets/segment/segment-base-template-data';

/* initialize analytics and load plugins */
export const plAnalytics = Analytics({
    debug: true,
    app: 'personal-loan',
    plugins: [
        segmentPlugin({
            writeKey: Config.writeSegment,
        }),
    ],
});

plAnalytics.ready(() => {
    setTimeout(() => WrapSegmentBusUtils.segmentReady(), 100);
});

window.Analytics = plAnalytics;

const useSegmentAnalytics = ({
    loanApplicationNumber,
}) => {
    useSegmentEventBus({ plAnalytics, loanApplicationNumber });
};

export default useSegmentAnalytics;

export const useIsReadySegmentAnalytics = () => {
    const [segmentReady, setSegmentReady] = React.useState();

    useEventBus(SEGMENT_EVENT.SEGMENT_READY, () => {
        setSegmentReady(true);
    });

    return segmentReady;
};

export const useSegmentTrackingEvents = ({ stepsInputData, loanApplicationNumber, hashedSAID }) => {
    const { product } = useCommonContext();
    const { trackingTerm = {} } = stepsInputData;
    const session = _.get(stepsInputData, 'facialInfoData.sessionToken');

    const trackingEvent = (eventName, params = {}, resovle = () => { }) => {
        const products = createSegmentProductData({
            name: product.name,
            id: product.id,
            terms: trackingTerm.terms,
            loanAmount: trackingTerm.amount,
        });

        const templatesss = mappingConfigSegmentNames[eventName];
        if (!templatesss) {
            return;
        }

        const { properties } = templatesss;
        const segmentEventData = createSegmentEventDatas({
            event: templatesss.event,
            ui_screen_path: templatesss.properties.ui_screen_path,
            ui_screen_state: templatesss.properties.ui_screen_state,
            dev_session_id: session || '', // FACIAL
            dev_screen_class_name: document.title,
            flow_conversion: properties.flow_conversion || '',
            loan_application_number: loanApplicationNumber || '',
            hash_SAID: hashedSAID || '',
            ...products,
            ...params,
        });
        WrapSegmentBusUtils.sendSegmentEvent(segmentEventData);
        resovle();
    };

    useEventBus(SEGMENT_EVENT.SEND_EVENT_TRACKING_FROM_CLIENT,
        ({ eventName, params, resovle }) => trackingEvent(eventName, params, resovle));

    return trackingEvent;
};
