import { RESET_LOAN_SUBMISSION, SAVE_STEPS_INPUT } from '../store/actionTypes';

const initialState = {
    loanInfo: {
        selectedPurpose: null,
        amount: null,
    },
    financialInformation: {
        selectedEmploymentStatus: null,
        selectedRace: null,
        selectedMaritalStatus: null,
        selectedMaritalType: null,
        selectedSpouseConsent: null,
        savedFinalIncomeStatement: {
            income: 0,
            deduction: 0,
            debt: 0,
            isDebtCommitment: null,
            expenses: 0,
        },
    },
    additionalInfo: {
        selectedCompanyWorkingFor: null,
        selectedCompanyWorkingMonth: null,
        selectedCompanyWorkingYear: null,
        selectedPayFrequency: null,
        selectedPayNextDate: null,
        name: null,
        cellphone: null,

    },
};

export default (state = initialState, action) => {
    switch (action.type) {
        case SAVE_STEPS_INPUT:
            return {
                ...state,
                ...action.payload,
            };
        case RESET_LOAN_SUBMISSION: {
            return {
                ...initialState,
            };
        }
        default:
            return state;
    }
};
