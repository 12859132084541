import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { connect } from 'react-redux';
import joinclass from 'joinclass';

import { isScreenBackgroundLoading } from '../../hooks/useIsLoading';

export const XWrapMoneyLarge = ({
    ui, dark, loading, children,
}) => {
    const isLoading = isScreenBackgroundLoading(ui);
    return (isLoading || loading) ? <Skeleton className={joinclass('mt-1 mb-2', dark && 'dark-mode')} width={216} height={48} /> : children;
};

export const XWrapMoneyMedium = ({
    ui, dark, loading, children,
}) => {
    const isLoading = isScreenBackgroundLoading(ui);
    return (isLoading || loading) ? <Skeleton className={joinclass('mt-1 mb-2', dark && 'dark-mode')} width={120} height={24} /> : children;
};

export const XWrapMoneySmall = ({
    ui, dark, loading, children,
}) => {
    const isLoading = isScreenBackgroundLoading(ui);
    return (isLoading || loading) ? <Skeleton className={joinclass('mt-1 mb-2', dark && 'dark-mode')} width={120} height={20} /> : children;
};

export const XLabelLarge = ({
    ui, dark, loading, children,
}) => {
    const isLoading = isScreenBackgroundLoading(ui);
    return (isLoading || loading) ? <Skeleton className={joinclass('mt-1 mb-2', dark && 'dark-mode')} width={156} height={32} /> : children;
};

export const XLabelMedium = ({
    ui, dark, loading, children,
}) => {
    const isLoading = isScreenBackgroundLoading(ui);
    return (isLoading || loading) ? <Skeleton className={joinclass('mt-1 mb-2', dark && 'dark-mode')} width={156} height={24} /> : children;
};

export const XLabelSmall = ({
    ui, dark, loading, children,
}) => {
    const isLoading = isScreenBackgroundLoading(ui);
    return (isLoading || loading) ? <Skeleton className={joinclass('mt-1 mb-2', dark && 'dark-mode')} width={88} height={20} /> : children;
};

const mapStateToProps = state => ({
    ui: state.ui,
});

const XWrapMoneyLargeSkeleton = connect(mapStateToProps, {})(XWrapMoneyLarge);
const XWrapMoneyMediumSkeleton = connect(mapStateToProps, {})(XWrapMoneyMedium);

const XWrapLabelLargeSkeleton = connect(mapStateToProps, {})(XLabelLarge);
const XWrapLabelMediumSkeleton = connect(mapStateToProps, {})(XLabelMedium);
const XWrapLabelSmallSkeleton = connect(mapStateToProps, {})(XLabelSmall);

export {
    XWrapMoneyLargeSkeleton,
    XWrapMoneyMediumSkeleton,
    XWrapLabelLargeSkeleton,
    XWrapLabelMediumSkeleton,
    XWrapLabelSmallSkeleton,
};
