import React, { useEffect } from 'react';
import joinclass from 'joinclass';
import NumberFormat from 'react-number-format';

export const InputCurrency = ({
    className,
    value,
    initFocused,
    onChange,
    onBlur,
    tipMessage,
    emptyMessage,
    errorMessage,
    handleEnter = () => { },
}) => {
    const refInput = React.useRef();

    useEffect(() => {
        initFocused && refInput.current.focus();
    }, []);

    const MessageGuilde = () => {
        if (emptyMessage || errorMessage) {
            return <div className="with-errors">{emptyMessage || errorMessage}</div>;
        }
        if (tipMessage) {
            return <div className="tip-guilde">{tipMessage}</div>;
        }
        return null;
    };

    return (
        <React.Fragment>
            <div className="input-group-amount">
                <span className="input-group-amount__currency">
                    <i>R</i>
                </span>
                <NumberFormat
                    fixedDecimalScale
                    decimalScale={2}
                    allowNegative={false}
                    thousandSeparator=" "
                    className={joinclass('form-control', className)}
                    placeholder="0"
                    maxLength="18"
                    type="tel"
                    value={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    onKeyDown={(event) => {
                        if (event.key === 'Enter') {
                            handleEnter(className);
                        }
                    }}
                    getInputRef={e => { refInput.current = e; }}
                />
            </div>
            <MessageGuilde />
        </React.Fragment>
    );
};
