import React from 'react';
import styled from 'styled-components';
import PLTooltip from './PLTooltip';

export const WrapLinkTooltip = styled.span`
  color: #4782CA;
  font-size: 16px;
  font-weight: 400;
  text-decoration: underline;
  cursor: pointer;

  @media (max-width: 767px) {
    font-size: 14px;
  }
`;

function PLLinkTooltip({
  tooltipId, tooltipContent, children, className,
}) {
  return (
    <React.Fragment>
      <WrapLinkTooltip id={tooltipId} className={className}>{children}</WrapLinkTooltip>
      <PLTooltip target={tooltipId}>
        {tooltipContent}
      </PLTooltip>
    </React.Fragment>
  );
}

export default PLLinkTooltip;
