import React from 'react';

const ProofOfResidenceIcon = () => (
  <svg width="70" height="70" viewBox="0 0 70 70" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.1211 67.6281H60.5583V8.04688H15.1211V67.6281Z" fill="#FECC05" stroke="#231F20" />
    <path d="M9.44141 61.9543H54.8787V2.37305H9.44141V61.9543Z" fill="white" stroke="#231F20" />
    <path d="M15.1211 19.3949H43.5194" stroke="#231F20" />
    <path d="M46.3594 19.3949H49.1992" stroke="#231F20" />
    <path d="M46.3594 25.0707H49.1992" stroke="#231F20" />
    <path d="M15.1211 25.0707H43.5194" stroke="#231F20" />
    <path d="M15.1211 53.4418H26.4804" stroke="#231F20" />
  </svg>
);

export default ProofOfResidenceIcon;

export const ResidenceTickIcon = () => (
  <svg width="63" height="62" viewBox="0 0 63 62" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M13.4016 49.6444H46.4959V6.14062H13.4016V49.6444Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M13.4016 49.6444H46.4959V6.14062H13.4016V49.6444Z" stroke="#4F4E4D" />
    <path d="M9.26477 45.5018H42.3591V1.99805H9.26477V45.5018Z" fill="#FEFEFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M9.26477 45.5018H42.3591V1.99805H9.26477V45.5018Z" stroke="#4F4E4D" />
    <path d="M13.4016 14.4272H34.0855" stroke="#4F4E4D" />
    <path d="M36.1539 14.4272H38.2223" stroke="#4F4E4D" />
    <path d="M36.1539 18.5718H38.2223" stroke="#4F4E4D" />
    <path d="M13.4016 18.5718H34.0855" stroke="#4F4E4D" />
    <path d="M13.4016 39.2866H21.6751" stroke="#4F4E4D" />
    <path d="M41.3249 60.0038C48.179 60.0038 53.7353 54.4389 53.7353 47.5742C53.7353 40.7095 48.179 35.1445 41.3249 35.1445C34.4709 35.1445 28.9146 40.7095 28.9146 47.5742C28.9146 54.4389 34.4709 60.0038 41.3249 60.0038Z" fill="#60D154" stroke="#4F4C4D" />
    <path d="M35.6368 49.2562L38.8393 52.4636L46.1141 42.3945" stroke="white" />
  </svg>
);

export const ResidenceTickMobileIcon = () => (
  <svg width="32" height="33" viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.95068 25.8222H23.4978V4.07031H6.95068V25.8222Z" fill="white" />
    <path fillRule="evenodd" clipRule="evenodd" d="M6.95068 25.8222H23.4978V4.07031H6.95068V25.8222Z" stroke="#4F4E4D" strokeWidth="0.5" />
    <path d="M4.88232 23.7499H21.4295V1.99805H4.88232V23.7499Z" fill="#FEFEFE" />
    <path fillRule="evenodd" clipRule="evenodd" d="M4.88232 23.7499H21.4295V1.99805H4.88232V23.7499Z" stroke="#4F4E4D" strokeWidth="0.5" />
    <path d="M6.95068 8.21264H17.2927" stroke="#4F4E4D" strokeWidth="0.5" />
    <path d="M18.3269 8.21264H19.3611" stroke="#4F4E4D" strokeWidth="0.5" />
    <path d="M18.3269 10.2849H19.3611" stroke="#4F4E4D" strokeWidth="0.5" />
    <path d="M6.95068 10.2849H17.2927" stroke="#4F4E4D" strokeWidth="0.5" />
    <path d="M6.95068 20.6423H11.0875" stroke="#4F4E4D" strokeWidth="0.5" />
    <path d="M20.9125 31C24.3395 31 27.1176 28.2175 27.1176 24.7851C27.1176 21.3528 24.3395 18.5703 20.9125 18.5703C17.4854 18.5703 14.7073 21.3528 14.7073 24.7851C14.7073 28.2175 17.4854 31 20.9125 31Z" fill="#60D154" stroke="#4F4C4D" strokeWidth="0.5" />
    <path d="M18.0684 25.6261L19.6696 27.2298L23.307 22.1953" stroke="white" strokeWidth="0.5" />
  </svg>
);
