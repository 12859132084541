import { of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';
import apiService from '../services/apiService';
import ErrorUtils from '../utils/errorUtils';

import { FETCH_CONFIGURATION, FETCH_LIST_VALUES } from '../store/actionTypes';
import {
    fetchConfigurationSuccess,
    fetchConfigurationFailed,
    fetchListValuesSuccess,
} from '../actions';

export function fetchConfigurationEpic(action$) {
    return action$.pipe(
        ofType(FETCH_CONFIGURATION),
        switchMap(() => apiService
            .getConfigurationList()
            .pipe(
                map(res => fetchConfigurationSuccess(res)),
                catchError(error => of(fetchConfigurationFailed({}), ErrorUtils.getFuncApiAction(error))),
            ),
        ),
    );
}

export function fetchListValuesEpic(action$) {
    return action$.pipe(
        ofType(FETCH_LIST_VALUES),
        switchMap(() => apiService
            .getListValues()
            .pipe(
                map(res => fetchListValuesSuccess(res)),
                catchError(error => ErrorUtils.getApiAction(error)),
            ),
        ));
}

export default combineEpics(fetchConfigurationEpic, fetchListValuesEpic);
