import React from 'react';
import { connect } from 'react-redux';
import { ScreenBusUtils } from '../hooks/useEventBus';

import LabelLoanApplication from '../components/Common/LabelLoanApplication';
import PLButton from '../components/Common/PLButton';

function ResumeLoan() {
    return (
        <React.Fragment>
            <LabelLoanApplication />

            <h2 className="font-size-16 font-size-md-20 font-weight-medium mt-4">
                Unfortunately, your Personal Loan application has expired and is no longer valid.
            </h2>
            <p className="mt-1">Not to worry though, you can start a new application now. Tap the button below to get started.</p>

            <div className="buttons my-4">
                <PLButton
                    onClick={() => ScreenBusUtils.goHome()}
                >
                    Apply now
                </PLButton>
            </div>
            {/* </div> */}
        </React.Fragment>
    );
}

const mapStateToProps = () => ({});

export default
    connect(
        mapStateToProps,
    )(ResumeLoan);
