import React from 'react';
import joinclass from 'joinclass';

import styled from 'styled-components';

export const WrapFlexDiv = styled.div`
  height: ${props => `${props.height || 'auto'}`};
  width: ${props => `${props.width || 'auto'}`};
`;

export function FlexColumnCenter({
  className, height, width, ...props
}) {
  return (
    <WrapFlexDiv className={joinclass('d-flex justify-content-center flex-column align-items-center', className)} width={width} height={height} {...props}>{props.children}</WrapFlexDiv>
  );
}

export function FlexRowCenter({
  className, height, width, ...props
}) {
  return (
    <WrapFlexDiv className={joinclass('d-flex justify-content-center align-items-center', className)} width={width} height={height} {...props}>{props.children}</WrapFlexDiv>
  );
}

export function FlexRowBetween({
  className, height, width, ...props
}) {
  return (
    <WrapFlexDiv className={joinclass('d-flex justify-content-between align-items-center', className)} width={width} height={height} {...props}>{props.children}</WrapFlexDiv>
  );
}
