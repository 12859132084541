import { of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';

import apiService from '../services/apiService';
import { handleErrorApi } from '../actions';
import {
    SEND_LOAN_OFFERS_SELECTION,
    SEND_LOAN_OFFERS_SELECTION_FAILED,
    SEND_LOAN_OFFERS_SELECTION_SUCCESS,
} from '../store/actionTypes';
import { ErrorCode } from '../constants';

export function offersEpic(action$) {
    return action$.pipe(
        ofType(SEND_LOAN_OFFERS_SELECTION),
        switchMap(({ payload }) => {
            const { loanApplicationNumber, customerSelection, selectedOfferOptionId } = payload;
            return apiService
                .selectLoanOffers(loanApplicationNumber, customerSelection, selectedOfferOptionId)
                .pipe(
                    map(res => ({ type: SEND_LOAN_OFFERS_SELECTION_SUCCESS, payload: res })),
                    catchError(err => of(
                        { type: SEND_LOAN_OFFERS_SELECTION_FAILED, payload: err.response },
                        handleErrorApi([{ errorCode: ErrorCode.GENERIC_ERROR }]),
                    )),
                );
        }));
}

export default combineEpics(offersEpic);
