import React from 'react';
import PLButton from '../../../components/Common/PLButton';
import { MovingFadeIn } from '../../../components/Common/AnimateText';
import HelpTip from '../../../components/Common/HelpTip';
import PLDiv from '../../../components/Common/PLDiv';
import XWrapMoney from '../../../components/Common/XWrapMoney';

import { XNavLogo } from '../../../components/Common/XNavLogo';
import { x2LandingReasons } from '../../../assets/x2-data/x2-landing-data';

function XLandingR1Left({
    product, applyNow,
}) {
    return (
        <div className="header-left col-6">
            <div className="w-100 mb-3"><XNavLogo /></div>
            <div className="header-left-title position-relative d-flex flex-column gap-8 pt-2">
                <h1 className="font-size-24 font-size-md-36">
                    Get a personal loan <br /><span className="within">within minutes</span>
                </h1>
                <h5 className="font-size-18 font-size-md-32 font-weight-400">Up to <XWrapMoney>{product.maxAmount}</XWrapMoney>, all yours.*</h5>
                <div className="col-6 p-0 mt-2"><PLButton onClick={applyNow}>Check if I’m eligible</PLButton></div>

                <div className="point-one" />
                <div className="point-two" />
                <div className="logo-information" />
            </div>

            <div className="d-flex flex-row justify-content-end justify-content-md-start">
                <div className="solid-line mt-md-5 mb-2 col-4 col-md-2" />
            </div>

            <div className="pt-3 pb-5">
                <h5 className="mb-3 mb-md-4">Why choose us?</h5>
                <div className="d-flex flex-column gap-8 gap-md-12 pb-3 pb-md-4">
                    {x2LandingReasons.map((item, index) => (
                        <MovingFadeIn key={item.title} duration={`${(index + 1) * 1200}ms`}>
                            <HelpTip
                                className="gap-16"
                                classNameContent="d-flex align-items-center"
                                label={item.title}
                                customizeIcon={<PLDiv mobileContent={item.iconMobile} webContent={item.icon} />}
                                center
                            />
                        </MovingFadeIn>
                    ))}
                </div>

                <small className="w-100 d-block font-size-14 text-left opacity-dot-6">
                    * Subject to a credit assessment
                </small>
            </div>
        </div>
    );
}

export default XLandingR1Left;
