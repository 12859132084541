import { of } from 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, switchMap } from 'rxjs/operators';

import apiService from '../services/apiService';
import { ScreenBusUtils } from '../hooks/useEventBus';
import { VERIFY_LOAN_APPLICATION } from '../store/actionTypes';
import { verifyLoanApplicationSuccess } from '../actions';
import { profileEFType } from '../constants';

import { handleDeclineSpecialCode } from '../utils/commonUtils';
import { ErrorUtils } from '../utils/errorUtils';
import { saveProfileType } from './otpEpics';

// we need to migrate framework
export const verifyLoanAppNumberOtp = ({
    loanApplicationNumber,
    // isSpecialCode,
    profileType,
    loanApplicationStatus,
    declinceCode,
    stateStore,
}) => apiService
    .verifyLoanApplication(loanApplicationNumber)
    .pipe(
        catchError(error => {
            const { response } = error;
            ScreenBusUtils.hideLoading();

            if (!response || error.status !== 403) {
                ScreenBusUtils.clearDeclineStatus();
                return ErrorUtils.getApiAction(error);
            }

            // response from g-profile
            return of(
                verifyLoanApplicationSuccess({
                    requestToken: response.requestToken,
                    phoneNumber: response.phoneNumber,
                    loanApplicationStatus: response.loanApplicationStatus || loanApplicationStatus,
                    loanApplicationNumber,
                    isEFProfile: profileEFType.includes(profileType),
                    declinceCode,
                }),

                saveProfileType({
                    ...response,
                    loanApplicationNumber,
                    profileType,
                }, stateStore),
            );
        }),
    );

export function verifyLoanApplicationEpic(action$, store$) {
    return action$.pipe(
        ofType(VERIFY_LOAN_APPLICATION),
        switchMap(({ payload }) => {
            const { loanApplicationNumber, isFetchStatus } = payload;
            ScreenBusUtils.showLoading();

            if (!isFetchStatus) {
                return verifyLoanAppNumberOtp({
                    loanApplicationNumber,
                    stateStore: store$,
                });
            }

            return apiService.getAffordabilityResults(loanApplicationNumber)
                .pipe(
                    switchMap(res => {
                        const declineReasonDetails = res.declineReasonDetails || {};

                        let funcHandle;
                        if (declineReasonDetails.code) {
                            funcHandle = handleDeclineSpecialCode({
                                declinceCode: declineReasonDetails.code,
                                // handleGProfileType: () => ScreenBusUtils.showModalTruIDGProfileType(declineReasonDetails),
                                handleMissing: () => ScreenBusUtils.saveDeclineStatus(declineReasonDetails.code),
                            });

                            if (funcHandle) {
                                funcHandle();
                            } else {
                                ScreenBusUtils.saveDeclineStatus(declineReasonDetails.code, declineReasonDetails);
                            }
                        }

                        return verifyLoanAppNumberOtp({
                            loanApplicationNumber,
                            isSpecialCode: funcHandle,
                            profileType: res.profileType,
                            loanApplicationStatus: res.loanApplicationStatus,
                            declinceCode: declineReasonDetails.code,
                            stateStore: store$,
                        });
                    }),
                    catchError(error => ErrorUtils.getApiAction(error)),
                );
        }));
}

export default combineEpics(verifyLoanApplicationEpic);
