import { wrapAppBankTfgTymeIcon, wrapAppBankTymeIcon } from '../../assets/images/elements/WrapSvgIcon';

export const offerKey = {
    monthRepay: 'monthlyInstallmentAmount',
    totalInterest: 'interestAndFeeAmount',
    totalRepay: 'totalRepaymentAmount',
};

export const packageKey = {
    monthRepay: 'monthlyRepaymentAmount',
    totalInterest: 'totalInterestAndFeeAmount',
    totalRepay: 'totalRepaymentAmount',
};

export const planPackage = {
    finalApprovedAmount:
    {
        title: 'Loan amount',
        key: 'finalApprovedAmount',
        color: '#FDC600',
    },
    totalInsuranceFeeAmount:
    {
        title: 'Mandatory credit life insurance premium*',
        key: 'totalInsuranceFeeAmount',
        color: '#DD6565',
    },
    initiationFee:
    {
        title: 'Initiation fee',
        key: 'initiationFee',
        color: '#D6DBEB',
    },
    interestAmount:
    {
        title: 'Total Interest',
        key: 'interestAmount',
        color: '#374095',
        subKey: 'subKeyInterestRate',
    },
    totalServiceFeeAmount:
    {
        title: 'Total service fee',
        key: 'totalServiceFeeAmount',
        color: '#3BA76A',
    },
};

export const offerTemplate = [
    {
        id: 70,
        tenor: 12,
        finalApprovedAmount: '20,000.00',
        monthlyInstallmentAmount: '1,984.20',
        totalRepaymentAmount: '23,810.60',
        establishmentFeeAmount: '1,000.00',
        loanServiceFeeAmount: '.00',
        interestAmount: '2,810.60',
        selected: false,
    },
    {
        id: 71,
        tenor: 18,
        finalApprovedAmount: '20,000.00',
        monthlyInstallmentAmount: '1,399.40',
        totalRepaymentAmount: '25,189.00',
        establishmentFeeAmount: '1,000.00',
        loanServiceFeeAmount: '.00',
        interestAmount: '4,189.10',
        selected: false,
    },
    {
        id: 72,
        tenor: 19,
        finalApprovedAmount: '20,000.00',
        monthlyInstallmentAmount: '1,399.40',
        totalRepaymentAmount: '25,189.00',
        establishmentFeeAmount: '1,000.00',
        loanServiceFeeAmount: '.00',
        interestAmount: '4,189.10',
        selected: false,
    },
];

export const offerStepAppTymeBank = [
    {
        title: 'Log in to the TymeBank app',
        icon: wrapAppBankTymeIcon.viewLarge(),
        iconMobile: wrapAppBankTymeIcon.viewMobile(),
    },
    {
        title: 'Tap on the personal loan section to continue',
        icon: wrapAppBankTfgTymeIcon.viewLarge(),
        iconMobile: wrapAppBankTfgTymeIcon.viewMobile(),
    },
];
