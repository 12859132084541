import React, { useCallback, useEffect, useState } from 'react';
import { translate } from 'react-i18next';

import { ValidatorUtils } from '../../utils/yupUtils';
import { scrollElementSmooth } from '../../utils/commonUtils';
import { ScreenBusUtils } from '../../hooks/useEventBus';

import PLButton from '../Common/PLButton';
import InputCheck from '../Common/InputCheck';
import { WrapAction } from '../Common/ActionButtons';
import ButtonListSelectionTick from '../Common/ButtonListSelectionTick';
import InputEmail from './InputEmail';

const UserInfoTabOne = ({
    listRace,
    acceptTymeMarketingConsent,
    email,
    raceCode,
    raceName,
    isEditMode,
    handleNextSection = () => { },
}) => {
    const [formData, setFormData] = useState({
        acceptTymeMarketingConsent,
        emailValue: email || '',
        selectedRace: raceCode || '',
        selectedRaceName: raceName || '',
    });
    const [errors, setErrors] = useState({});

    const refsInputEmail = React.useRef();

    useEffect(() => {
        Boolean(refsInputEmail.current) && refsInputEmail.current.focus();
    }, [refsInputEmail.current]);

    const handleChangeEmail = (emailValue) => {
        setFormData({
            ...formData,
            emailValue,
        });
        setErrors({
            ...errors,
            emailValue: '',
        });
    };

    const handleNextSelection = useCallback(() => {
        let errorMessage = ValidatorUtils.validateEmail({
            value: formData.emailValue,
        });
        if (errorMessage) {
            setErrors({
                emailValue: errorMessage,
            });
            scrollElementSmooth(refsInputEmail.current, 'center');
            refsInputEmail.current.focus();
            return;
        }

        errorMessage = ValidatorUtils.validateOptionEmpty({
            value: formData.selectedRace,
        });
        if (errorMessage) {
            setErrors({
                selectedRace: errorMessage,
            });
            return;
        }

        handleNextSection(formData);
    }, [formData]);

    return (
        <React.Fragment>
            <InputEmail
                emailValue={formData.emailValue}
                errorMessage={errors.emailValue}
                handleChange={handleChangeEmail}
                handleEnter={handleNextSelection}
                getInputRefs={e => { refsInputEmail.current = e; }}
            />

            <div className="form-check">
                <InputCheck
                    type="checkbox"
                    label="I would like to hear about TymeBank’s special offers and updates"
                    checked={formData.acceptTymeMarketingConsent}
                    handleChange={() => setFormData({ ...formData, acceptTymeMarketingConsent: !formData.acceptTymeMarketingConsent })}
                />
            </div>

            <ButtonListSelectionTick
                label="Select your race*"
                flexClassName="justify-content-start"
                selectedValue={formData.selectedRace}
                listData={listRace}
                btnGroup="user-race"
                errorMessage={errors.selectedRace}
                handleChangeSelection={(selectedRace, selectedRaceName) => {
                    setFormData({
                        ...formData,
                        selectedRace,
                        selectedRaceName,
                    });
                    setErrors({
                        ...errors,
                        selectedRace: '',
                    });
                }}
                handleNext={handleNextSelection}
            />

            <p className="my-3">
                *This is for governmental statistical purposes only. Your race has <span className="font-weight-bold">no bearing on your application.</span>
            </p>

            <WrapAction className="buttons my-4 flex-column-reverse d-flex d-md-block">
                {isEditMode &&
                    <PLButton
                        primary={false}
                        onClick={() => ScreenBusUtils.gotoScreenConfirmation()}>
                        Cancel
                    </PLButton>
                }

                <PLButton onClick={handleNextSelection}>
                    Next
                </PLButton>
            </WrapAction>
        </React.Fragment>
    );
};

export default translate()(UserInfoTabOne);
