import { useEffect } from 'react';
import GTMModule from 'react-gtm-module';

import { GA } from '../utils/gaUtils';
import { isSegmentPage } from '../utils/pageUtils';

import config from '../config';
import useEventBus, { EventBusName } from './useEventBus';
import { useCommonContext } from '../context/CommonContext';

const useGAPageTracking = (router, options = {}) => {
  const { shareLoanConfig } = useCommonContext();
  const { pathname } = router.location;

  useEffect(() => {
    if (shareLoanConfig.isMounted) {
      // from url segment product, we need find exact product after
      if (isSegmentPage(pathname)) {
        return;
      }

      if (!GA.isGaExist()) {
        setTimeout(() => senGA(pathname), 1250);
        return;
      }

      senGA(pathname);
    }
  }, [shareLoanConfig.isMounted, router.location]);

  useEventBus(EventBusName.GA_SEND_PAGE, (page) => {
    senGA(page);
  });

  function senGA(page) {
    setTagManagerArgs(page);
    GA.sendPageView(page);
  }

  function setTagManagerArgs(page) {
    const tagManagerArgs = {
      gtmId: config.GaDefaultConfig.trackingId,
      dataLayer: {
        page,
        ...options,
      },
      dataLayerName: 'PageDataLayer',
    };
    GTMModule.dataLayer(tagManagerArgs);
  }
};

export default useGAPageTracking;
