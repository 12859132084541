import React from 'react';
import joinclass from 'joinclass';

import { routeName } from '../constants';
import { FlexColumnCenter } from './Common/FlexCenter';
import { MovingFadeIn, MovingPopIn } from './Common/AnimateText';

const Tab = [
  'Identity',
  'Loan Amount',
  'Personal Details',
  'Affordability',
  'Summary',
];

const stepScreen = {
  [routeName.START]: '0',
  [routeName.PHONE]: '0',
  [routeName.OTP]: '0',
  [routeName.OTP_RESUME]: '0',
  [routeName.LOAN_AMOUT]: 1,
  [routeName.LOAN_PURPOSE]: 1,
  [routeName.USER_INFO]: 2,
  [routeName.FINANCIAL_INFO]: 3,
  [routeName.ADDITIONAL_INFO]: 3,
  [routeName.CONFIRMATION]: 4,
};

const listSteps = Object.keys(stepScreen);

export function AnimateTapSteps({
  children,
  isCompleted,
  isActived,
  index,
  pathname,
}) {
  if (isCompleted) {
    return children;
  }

  if (isActived) {
    return <MovingPopIn duration="1.5s">{children}</MovingPopIn>;
  }

  return (
    <MovingFadeIn
      key={pathname}
      duration={`${(index + 1) * 500}ms`}
    >
      {children}
    </MovingFadeIn>
  );
}

const TapSteps = ({ pathname }) => {
  const key = listSteps.find(k => pathname.startsWith(k));
  const stepIndex = stepScreen[key] || -1;

  if (stepIndex === -1) {
    return null;
  }

  return (
    <div className="list-tab-steps container">
      <div className="tab-step d-flex flex-row">
        {Tab.map((item, index) => (
          <FlexColumnCenter
            key={item}
            className={
              joinclass(
                'tab-item pt-2 pb-2 d-xs-none',
                // eslint-disable-next-line eqeqeq
                stepIndex == index && 'tab-active',
                stepIndex > index && 'tab-completed',
              )
            }
          >
            <AnimateTapSteps
              // eslint-disable-next-line eqeqeq
              isActived={stepIndex == index}
              isCompleted={stepIndex > index}
              index={index}
              pathname={pathname}
            >
              {item}
            </AnimateTapSteps>

            <div className="tab-border" />
          </FlexColumnCenter>
        ))}
      </div>
    </div>

  );
};

export default TapSteps;
