export const FETCH_CONFIGURATION = 'FETCH_CONFIGURATION';
export const FETCH_CONFIGURATION_SUCCESS = 'FETCH_CONFIGURATION_SUCCESS';
export const FETCH_CONFIGURATION_FAILED = 'FETCH_CONFIGURATION_FAILED';

export const HANDLE_ERROR_API = 'HANDLE_ERROR_API';
export const HIDE_MODAL_HANDLE_ERROR_API = 'HIDE_MODAL_HANDLE_ERROR_API';

export const VALIDATION_SAID = 'VALIDATION_SAID';
export const VALIDATION_SAID_SUCCESS = 'VALIDATION_SAID_SUCCESS';
export const VALIDATION_SAID_FAILED = 'VALIDATION_SAID_FAILED';

export const VALIDATE_OTP = 'VALIDATE_OTP';
export const VALIDATE_OTP_SUCCESS = 'VALIDATE_OTP_SUCCESS';
export const VALIDATE_OTP_FAILED = 'VALIDATE_OTP_FAILED';

export const SHOW_OVERLAY_LOADING = 'SHOW_OVERLAY_LOADING';
export const HIDE_OVERLAY_LOADING = 'HIDE_OVERLAY_LOADING';

export const GENERATE_OTP = 'GENERATE_OTP';
export const GENERATE_OTP_SUCCESS = 'GENERATE_OTP_SUCCESS';
export const GENERATE_OTP_FAILED = 'GENERATE_OTP_FAILED';

export const VERIFY_OTP = 'VERIFY_OTP';
export const VERIFY_OTP_SUCCESS = 'VERIFY_OTP_SUCCESS';
export const VERIFY_OTP_FAILED = 'VERIFY_OTP_FAILED';

export const RESEND_OTP = 'RESEND_OTP';
export const RESEND_OTP_SUCCESS = 'RESEND_OTP_SUCCESS';
export const RESEND_OTP_FAILED = 'RESEND_OTP_FAILED';

export const FETCH_LIST_PRODUCTS = 'FETCH_LIST_PRODUCTS';
export const FETCH_LIST_PRODUCTS_SUCCESS = 'FETCH_LIST_PRODUCTS_SUCCESS';
export const FETCH_LIST_PRODUCTS_FAILED = 'FETCH_LIST_PRODUCTS_FAILED';

export const FETCH_LIST_VALUES = 'FETCH_LIST_VALUES';
export const FETCH_LIST_VALUES_SUCCESS = 'FETCH_LIST_VALUES_SUCCESS';
export const FETCH_LIST_VALUES_FAILED = 'FETCH_LIST_VALUES_FAILED';

export const SAVE_STEPS_INPUT = 'SAVE_STEPS_INPUT';

export const PRELOAD_MEDIA_PROCESS = 'PRELOAD_MEDIA_PROCESS';

export const POST_LOAN_SUBMISSION = 'POST_LOAN_SUBMISSION';
export const POST_LOAN_SUBMISSION_SUCCESS = 'POST_LOAN_SUBMISSION_SUCCESS';
export const POST_LOAN_SUBMISSION_FAILED = 'POST_LOAN_SUBMISSION_FAILED';
export const RESET_LOAN_SUBMISSION = 'RESET_LOAN_SUBMISSION';

export const SHOW_CONFIRM_OFFER_MODAL = 'SHOW_CONFIRM_OFFER_MODAL';
export const HIDE_CONFIRM_OFFER_MODAL = 'HIDE_CONFIRM_OFFER_MODAL';
export const FETCH_DECISION_RESULTS = 'FETCH_DECISION_RESULTS';
export const FETCH_DECISION_UPSELL_RESULTS = 'FETCH_DECISION_UPSELL_RESULTS';

export const DECISION_RESULTS_IN_PROGRESS = 'DECISION_RESULTS_IN_PROGRESS';
export const DECISION_RESULTS_ELIGIBLE = 'DECISION_RESULTS_ELIGIBLE';
export const DECISION_RESULTS_INELIGIBLE = 'DECISION_RESULTS_INELIGIBLE';

export const UPDATE_OFFER_LIST = 'UPDATE_OFFER_LIST';

export const SEND_LOAN_OFFERS_SELECTION = 'SEND_LOAN_OFFERS_SELECTION';
export const SEND_LOAN_OFFERS_SELECTION_SUCCESS = 'SEND_LOAN_OFFERS_SELECTION_SUCCESS';
export const SEND_LOAN_OFFERS_SELECTION_FAILED = 'SEND_LOAN_OFFERS_SELECTION_FAILED';

// export const TOAST_LOAD = 'TOAST_LOAD';

export const SET_MODE_IFRAME_KIOSK = 'SET_MODE_IFRAME_KIOSK';

export const GENERATE_PRESIGNED_URL = 'GENERATE_PRESIGNED_URL';
export const GENERATE_PRESIGNED_URL_SUCCESS = 'GENERATE_PRESIGNED_URL_SUCCESS';
export const GENERATE_PRESIGNED_URL_FAILED = 'GENERATE_PRESIGNED_URL_FAILED';

export const ASSIGN_LOAN_DOCUMENTS = 'ASSIGN_LOAN_DOCUMENTS';
export const ASSIGN_LOAN_DOCUMENTS_SUCCESS = 'ASSIGN_LOAN_DOCUMENTS_SUCCESS';
export const ASSIGN_LOAN_DOCUMENTS_FAILED = 'ASSIGN_LOAN_DOCUMENTS_FAILED';

export const FETCH_AFFORDABILITY_RESULTS = 'FETCH_AFFORDABILITY_RESULTS';
export const AFFORDABILITY_RESULTS_IN_PROGRESS = 'AFFORDABILITY_RESULTS_IN_PROGRESS';
export const AFFORDABILITY_RESULTS_ELIGIBLE = 'AFFORDABILITY_RESULTS_ELIGIBLE';
export const AFFORDABILITY_RESULTS_INELIGIBLE = 'AFFORDABILITY_RESULTS_INELIGIBLE';

export const FETCH_SPRINT_HIVE_AFFORDABILITY_RESULTS = 'FETCH_SPRINT_HIVE_AFFORDABILITY_RESULTS';

export const FETCH_LOAN_DETAIL = 'FETCH_LOAN_DETAIL';
export const FETCH_LOAN_DETAIL_SUCCESS = 'FETCH_LOAN_DETAIL_SUCCESS';
export const FETCH_LOAN_DETAIL_FAILED = 'FETCH_LOAN_DETAIL_FAILED';

export const PUT_SELECTED_OFFER = 'PUT_SELECTED_OFFER';
export const PUT_SELECTED_OFFER_SUCCESS = 'PUT_SELECTED_OFFER_SUCCESS';
export const PUT_SELECTED_OFFER_FAILED = 'PUT_SELECTED_OFFER_FAILED';

export const FETCH_CONTRACT_INFO = 'FETCH_CONTRACT_INFO';
export const FETCH_CONTRACT_INFO_SUCCESS = 'FETCH_CONTRACT_INFO_SUCCESS';
export const FETCH_CONTRACT_INFO_FAILED = 'FETCH_CONTRACT_INFO_FAILED';

export const EVALUATE_ELIBILITY_CHECK_RESULT = 'EVALUATE_ELIBILITY_CHECK_RESULT';
export const EVALUATE_ELIBILITY_CHECK_RESULT_WITH_RETRY = 'EVALUATE_ELIBILITY_CHECK_RESULT_WITH_RETRY';
export const EVALUATE_ELIBILITY_CHECK_IN_PROGRESS = 'EVALUATE_ELIBILITY_CHECK_IN_PROGRESS';
// after ELIBILITY we need verify loans
export const VERIFY_LOAN_APPLICATION_IN_ELIBILITY = 'VERIFY_LOAN_APPLICATION_IN_ELIBILITY';

export const SAVE_CUSTOMER_INFO = 'SAVE_CUSTOMER_INFO';
export const SAVE_STEP_UP_TOKEN = 'SAVE_STEP_UP_TOKEN';

export const SIGN_CONTRACT_LOAN = 'SIGN_CONTRACT_LOAN';
export const SIGN_CONTRACT_LOAN_SUCCESS = 'SIGN_CONTRACT_LOAN_SUCCESS';
export const SIGN_CONTRACT_LOAN_FIRST_TIME_SUCCESS = 'SIGN_CONTRACT_LOAN_FIRST_TIME_SUCCESS';
export const SIGN_CONTRACT_LOAN_FAILED = 'SIGN_CONTRACT_LOAN_FAILED';

export const FETCH_FULFILLMENT_RESULTS = 'FETCH_FULFILLMENT_RESULTS';
export const FULFILLMENT_RESULTS_IN_PROGRESS = 'FULFILLMENT_RESULTS_IN_PROGRESS';
export const FULFILLMENT_RESULTS_SUCCESS = 'FULFILLMENT_RESULTS_SUCCESS';
export const FULFILLMENT_RESULTS_FAILED = 'FULFILLMENT_RESULTS_FAILED';

export const VERIFY_LOAN_APPLICATION = 'VERIFY_LOAN_APPLICATION';
export const VERIFY_LOAN_APPLICATION_SUCCESS = 'VERIFY_LOAN_APPLICATION_SUCCESS';
export const VERIFY_LOAN_APPLICATION_FAILED = 'VERIFY_LOAN_APPLICATION_FAILED';

export const FETCH_USER_TYPE = 'FETCH_USER_TYPE';
export const FETCH_USER_TYPE_SUCCESS = 'FETCH_USER_TYPE_SUCCESS';
export const FETCH_USER_TYPE_FAILED = 'FETCH_USER_TYPE_FAILED';
