/** Landing is ready! */
import { useEffect, useState } from 'react';
import { PL_PAGE } from '../constants';
import { useCommonContext } from '../context/CommonContext';
import { isHomePage, isSegmentPage } from '../utils/pageUtils';

import { useIsReadySegmentAnalytics } from './useSegmentAnalytics';

const useIsMounted = (props) => {
    // use share common
    const [config, setConfig] = useState({});
    const { shareLoanConfig } = useCommonContext();

    const isCanSegment = useIsReadySegmentAnalytics();

    useEffect(() => {
        const { search, pathname } = props.location;
        if (shareLoanConfig.isMounted || (!isCanSegment && !window.Analytics)) {
            return;
        }

        const loanApplicationNumber = new URLSearchParams(search).get('loanApplication');
        let { productId } = props.match.params || {};

        if (isHomePage(pathname) && loanApplicationNumber) {
            sessionStorage.clear();
            setConfig({
                loanApplicationNumber,
                isMounted: true,
            });
            return;
        }

        const navigation = window.performance.getEntriesByType('navigation') || [];
        const { type } = navigation[0] || {};
        const page = sessionStorage.getItem(PL_PAGE);
        if (isHomePage(pathname) && type === 'reload' && page) {
            productId = page;
        }

        setConfig({
            productId: (isSegmentPage(pathname) || page) ? productId : undefined,
            isMounted: true,
        });
    }, [isCanSegment]);

    return {
        ...config,
    };
};

export default useIsMounted;
