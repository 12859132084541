/* eslint-disable default-case */
/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
// eslint-disable-next-line no-unused-vars
import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { $emit } from 'event-bus-e2z';
import { EventBusName, ScreenBusUtils } from '../../hooks/useEventBus';
import { ErrorCode } from '../../constants';
import { TRUID_ADDRESS_MISSING, TRUID_SALARY_DATE_MISSING } from '../../truidConstants';

import { useSearchAddressByPlaceId, useUpdateAddressInformation, useUpdateSalaryDate } from '../../services/useTruidService';

export const useParseAddressDetails = (placeId, description) => {
  const [infoForm, setInfoForm] = useState();

  const {
    data, loading, refetchParam, cancelRequest,
  } = useSearchAddressByPlaceId({
    placeId,
  });

  useEffect(() => {
    if (placeId) {
      cancelRequest();
      refetchParam({
        newParams: {
          place_id: placeId,
        },
        mergeParams: true,
      });
    }
  }, [placeId]);

  useEffect(() => {
    if (data && !loading) {
      const addressComponents = _.get(data, 'result.address_components', []);
      let address = '';
      let route = '';
      let postcode = '';
      let city = '';
      // let country = "";
      let region = '';
      let sublocalityLevel = '';

      for (const component of addressComponents) {
        const { long_name, types } = component;
        switch (types[0]) {
          // address
          case 'street_number': {
            address = `${long_name} ${address}`;
            break;
          }
          case 'route': {
            address += long_name;
            route = long_name;
            break;
          }

          // maybe suburb
          case 'sublocality_level_1': {
            sublocalityLevel = `${long_name}`.trim();
            break;
          }
          case 'sublocality_level_2': {
            sublocalityLevel = !sublocalityLevel ? long_name : sublocalityLevel;
            break;
          }

          // city
          case 'locality': {
            city = long_name;
            break;
          }
          case 'postal_code': {
            postcode = `${long_name}${postcode}`;
            break;
          }
          case 'administrative_area_level_1': {
            region = long_name;
            break;
          }
        }
      }

      // split route
      const infoRoad = ((description.split(route) || [])[0] || '').trim();
      const hasAddress = Boolean(address.trim());

      setInfoForm({
        ...infoForm,
        address: `${infoRoad} ${address.trim() || sublocalityLevel}`.trim(),
        suburb: hasAddress ? sublocalityLevel : _.get(infoForm, 'suburb'),
        city: city || region,
        zipCode: postcode,

        // clear info
        placeId: '',
        description: '',
        active: true,
      });
    }
  }, [data, loading]);

  return { infoForm };
};

export const useSaveAddress = (formData, loanApplicationNumber, stepUpToken) => {
  const {
    data, onPost: postChange, loading: isFetch, errors,
  } = useUpdateAddressInformation(
    {
      data: {
        ...formData,
        loanApplicationNumber,
      },
      stepUpToken,
    },
  );

  // handler error
  useEffect(() => {
    if (isFetch) {
      return;
    }

    // show error
    if (errors) {
      $emit(EventBusName.NOTIFY_ERROR, {
        errors: [{ errorCode: ErrorCode.ADDRESS_ERROR_CODE }],
        onlyCloseModal: true,
      });
      return;
    }

    if (data) {
      ScreenBusUtils.fetchAffordabilityAfterUpdateMissing(TRUID_ADDRESS_MISSING);
      ScreenBusUtils.hideModalCommon();
      ScreenBusUtils.clearDeclineStatus();
    }
  }, [data, isFetch, errors]);

  return { postChange, isFetch };
};

export const useSaveSalaryDate = (formData, loanApplicationNumber, stepUpToken) => {
  const {
    data, onPost: postChange, loading: isFetch, errors,
  } = useUpdateSalaryDate(
    {
      data: {
        ...formData,
        loanApplicationNumber,
      },
      stepUpToken,
    },
  );

  // handler error
  useEffect(() => {
    if (isFetch) {
      return;
    }

    // show error
    if (errors) {
      $emit(EventBusName.NOTIFY_ERROR, {
        errors: [{ errorCode: ErrorCode.ADDRESS_ERROR_CODE }],
        onlyCloseModal: true,
      });
      return;
    }

    if (data) {
      ScreenBusUtils.fetchAffordabilityAfterUpdateMissing(TRUID_SALARY_DATE_MISSING);
      ScreenBusUtils.hideModalCommon();
      ScreenBusUtils.clearDeclineStatus();
    }
  }, [data, isFetch, errors]);

  return { postChange, isFetch };
};
