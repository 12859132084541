import { WrapSegmentBusUtils } from '../hooks/useSegmentEventBus';

export class SegmentBusTrackDecisionEngine {
    static segment1300DecisionConditionallyApprovedData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment1300DecisionConditionallyApprovedData');
    }

    static segment1300DecisionPartiallyConditionallyApprovedData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment1300DecisionPartiallyConditionallyApprovedData');
    }
}
