import { handleActions } from 'redux-actions';
import {
    AFFORDABILITY_RESULTS_IN_PROGRESS,
    AFFORDABILITY_RESULTS_ELIGIBLE,
    FETCH_AFFORDABILITY_RESULTS,
    AFFORDABILITY_RESULTS_INELIGIBLE,
    FETCH_SPRINT_HIVE_AFFORDABILITY_RESULTS,
} from '../store/actionTypes';

const initialState = {
    isFetching: null,
    isResultInProgress: null,
    isResultApproved: null,
    isResultDeclined: null,
};

export const actions = {
    [FETCH_AFFORDABILITY_RESULTS]: () => ({
        ...initialState,
        isFetching: true,
        retryCount: 0,
    }),
    [FETCH_SPRINT_HIVE_AFFORDABILITY_RESULTS]: () => ({
        ...initialState,
        isFetching: true,
        retryCount: 0,
    }),
    [AFFORDABILITY_RESULTS_IN_PROGRESS]: (state) => ({
        ...initialState,
        isResultInProgress: true,
        isFetching: false,
        retryCount: state.retryCount + 1,
    }),
    [AFFORDABILITY_RESULTS_ELIGIBLE]: (state, { payload = {} }) => ({
        ...initialState,
        isResultDeclined: false,
        isResultApproved: payload.isResultApproved !== undefined ? payload.isResultApproved : true,
        isFetching: false,
    }),
    [AFFORDABILITY_RESULTS_INELIGIBLE]: (state, { payload }) => ({
        ...initialState,
        isResultDeclined: true,
        isFetching: false,
        result: payload,
    }),
};

export default handleActions(actions, initialState);
