// 1. decision-conditionally-approved
export const segment1300DecisionConditionallyApprovedData = {
    type: 'track',
    event: 'lndPln decision process scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/decision-process/path-happy/decision-conditionally-approved/',
        ui_screen_state: 'functional',
        flow_conversion: 'no',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};

// 2. decision-partially-conditionally-approved
export const segment1300DecisionPartiallyConditionallyApprovedData = {
    type: 'track',
    event: 'lndPln decision process scrn viewed',
    properties: {
        ui_screen_path: '/027-personal-loan/decision-process/path-happy/decision-partially-conditionally-approved/',
        ui_screen_state: 'functional',
        flow_conversion: 'no',
        // dev_screen_class_name: 'string',
        dev_screen_class_description: 'string',
    },
};
