import React from 'react';
import LandingMonthItem from '../../../components/Landing/LandingMonthItem';
import XLandingLabel from './XLandingLabel';

const XLandingTerms = ({
    product,
    loanConfig,
    setLoanConfig,
}) => (
    <React.Fragment>
        <XLandingLabel>Pay it back over how long? (Repayment term)</XLandingLabel>
        <div className="d-flex flex-row header-list-month mt-3 gap-16 justify-content-around">
            {(product.terms || []).map((item) => (
                <LandingMonthItem
                    key={item}
                    value={item}
                    loanConfig={loanConfig}
                    setLoanConfig={setLoanConfig}
                />
            ))}
        </div>
    </React.Fragment>
);

export default XLandingTerms;
