import styled from 'styled-components';

export const WrapAction = styled.div`
    .btn-common {
        margin-right: 24px !important;
    }

    &.justify-content-center {
        .btn-common {
            margin-right: auto !important;
        }
    }

    .btn-common:last-child {
        margin-right: auto !important;
    }

    @media (max-width: 767px) {
        width: 100%;
        .btn-common {
            margin-right: 0px !important;
        }
    }
`;
