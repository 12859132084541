import { WrapSegmentBusUtils } from '../hooks/useSegmentEventBus';

export class SegmentBusTrackPackage {
    static segment1700LoanPackageSelectionDisplayedData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment1700LoanPackageSelectionDisplayedData');
    }

    static segment1700LoanPackageNotSelectedData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment1700LoanPackageNotSelectedData');
    }

    static segment1700LoanPackageDetailsData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment1700LoanPackageDetailsData');
    }

    static segment1700LoanPackageConfirmData() {
        WrapSegmentBusUtils.sendSegmentEventTrackingFromClient('segment1700LoanPackageConfirmData');
    }
}
