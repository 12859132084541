import React from 'react';

export const LocationSmallIcon = () => (
  <svg width="64" height="64" viewBox="0 0 64 64" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M32 64C49.6731 64 64 49.6731 64 32C64 14.3269 49.6731 0 32 0C14.3269 0 0 14.3269 0 32C0 49.6731 14.3269 64 32 64Z" fill="#E8E8E8" />
    <path d="M32 50.7512C40.7507 39.348 45.1261 31.2272 45.1261 26.3889C45.1261 19.1314 39.2493 13.248 32 13.248C24.7507 13.248 18.8739 19.1314 18.8739 26.3889C18.8739 31.2272 23.2493 39.348 32 50.7512Z" fill="#FFCC00" stroke="#4F4C4D" />
    <path d="M31.9705 31.4377C35.4567 31.4377 38.2828 28.6084 38.2828 25.1183C38.2828 21.6281 35.4567 18.7988 31.9705 18.7988C28.4843 18.7988 25.6582 21.6281 25.6582 25.1183C25.6582 28.6084 28.4843 31.4377 31.9705 31.4377Z" fill="white" stroke="#4F4C4D" />
  </svg>
);

const LocationIcon = () => (
  <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="50" cy="50" r="50" fill="#E3E3E3" />
    <path d="M50 83.7517C65.7513 63.226 73.627 48.6086 73.627 39.8996C73.627 26.8361 63.0488 16.2461 50 16.2461C36.9512 16.2461 26.373 26.8361 26.373 39.8996C26.373 48.6086 34.2487 63.226 50 83.7517Z" fill="#FFC425" stroke="#4F4C4D" strokeWidth="3" />
    <path d="M49.9471 48.9882C56.2223 48.9882 61.3093 43.8955 61.3093 37.6132C61.3093 31.331 56.2223 26.2383 49.9471 26.2383C43.672 26.2383 38.585 31.331 38.585 37.6132C38.585 43.8955 43.672 48.9882 49.9471 48.9882Z" fill="#E3E3E3" stroke="#4F4C4D" strokeWidth="3" />
  </svg>
);
export default LocationIcon;
