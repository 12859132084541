import React from 'react';
import MovingText from 'react-animate-z';

const defaultParams = {
  // type: "slideInFromBottom",
  // duration: "1000ms",
  delay: '0s',
  direction: 'normal',
  timing: 'ease-in',
  iteration: '1',
  fillMode: 'none',
};

const buildParams = (type, duration) => ({
  ...defaultParams,
  type,
  duration,
});

export function FixMoveText({
  children,
  type,
  duration = '1s',
  className,
  ...props
}) {
  return (
    <MovingText {...buildParams(type, duration)} {...props} className={className}>
      {children}
    </MovingText>
  );
}

export function MovingEffect3D({
  children, duration = '1s', className, ...props
}) {
  return (
    <FixMoveText type="effect3D" duration={duration} {...props} className={className}>
      {children}
    </FixMoveText>
  );
}

export function MovingFadeIn({
  children, duration = '1s', className, ...props
}) {
  return (
    <FixMoveText type="fadeIn" duration={duration} {...props} className={className}>
      {children}
    </FixMoveText>
  );
}

// optimze if use more.
export function MovingSlideInFromBottom({
  children, duration = '1s', className, ...props
}) {
  return (
    <FixMoveText type="slideInFromBottom" duration={duration} {...props} className={className}>
      {children}
    </FixMoveText>
  );
}

export function MovingSlideInFromTop({
  children, duration = '1s', className, ...props
}) {
  return (
    <FixMoveText type="slideInFromTop" duration={duration} {...props} className={className}>
      {children}
    </FixMoveText>
  );
}

export function MovingZoomIn({
  children, duration = '1s', className, ...props
}) {
  return (
    <FixMoveText type="zoomIn" duration={duration} {...props} className={className}>
      {children}
    </FixMoveText>
  );
}

export function MovingFadeInFromBottom({
  children, duration = '1s', className, ...props
}) {
  return (
    <FixMoveText type="fadeInFromBottom" duration={duration} {...props} className={className}>
      {children}
    </FixMoveText>
  );
}

export function MovingFadeInFromLeft({
  children, duration = '1s', className, ...props
}) {
  return (
    <FixMoveText type="fadeInFromLeft" duration={duration} {...props} className={className}>
      {children}
    </FixMoveText>
  );
}

// slide
export function MovingSlideInFromLeft({
  children, duration = '1s', className, ...props
}) {
  return (
    <FixMoveText type="slideInFromLeft" duration={duration} {...props} className={className}>
      {children}
    </FixMoveText>
  );
}

export function MovingPopIn({
  children, duration = '1s', className, ...props
}) {
  return (
    <FixMoveText type="popIn" duration={duration} {...props} className={className}>
      {children}
    </FixMoveText>
  );
}

export function MovingFlash({
  children, duration = '1s', className, ...props
}) {
  return (
    <FixMoveText type="flash" duration={duration} {...props} className={className}>
      {children}
    </FixMoveText>
  );
}

export function MovingSqueezeMix({
  children, duration = '1s', className, ...props
}) {
  return (
    <FixMoveText type="squeezeMix" duration={duration} {...props} className={className}>
      {children}
    </FixMoveText>
  );
}

export function MovingFlipFromBottom({
  children, duration = '1s', className, ...props
}) {
  return (
    <FixMoveText type="flipFromBottom" duration={duration} {...props} className={className}>
      {children}
    </FixMoveText>
  );
}

