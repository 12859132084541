import React, { useState } from 'react';
import { translate } from 'react-i18next';
import joinclass from 'joinclass';

import keys from '../../i18n/translationKeys';
import config from '../../config';
import { formatMoney, removeSpace } from '../../utils/format';
import { ScreenBusUtils } from '../../hooks/useEventBus';
import { useCommonContext } from '../../context/CommonContext';

import { WrapAction } from '../Common/ActionButtons';
import PLButton from '../Common/PLButton';
import { InputCurrency } from '../Common/InputCurrency';

const ExpectedAmount = ({ t, ...props }) => {
    // optimize render
    const [formData, setFormData] = useState({
        number: props.value || '',
        emptyMessage: '',
        overMessage: '',
    });
    const refContainer = React.useRef();
    const { product } = useCommonContext();
    const maxAmount = product.maxAmount || config.loanAmount.max;
    const minAmount = product.minAmount || config.loanAmount.min;
    const hasError = formData.emptyMessage || formData.overMessage;

    const paramMessage = {
        minAmount: formatMoney(minAmount),
        maxAmount: formatMoney(maxAmount),
    };

    const inRangeAmount = () => {
        const amount = Number(removeSpace(formData.number));
        return minAmount <= amount && amount <= maxAmount;
    };

    const isValidateRange = () => {
        if (formData.number !== '' && !inRangeAmount()) {
            setFormData({
                ...formData,
                emptyMessage: '',
                overMessage: t(keys.loanInfoPage.LOAN_OVER_RANGE, paramMessage),
            });
            return false;
        }
        return true;
    };

    const handleAmountChange = e => {
        const value = removeSpace(e.target.value);
        if (value === formData.number) {
            return;
        }
        setFormData({ number: value });
    };

    const handleNext = () => {
        const element = refContainer.current.querySelector('.form-control');

        // quick forcus
        if (hasError && element) {
            Boolean(element) && element.focus();
            return;
        }

        if (formData.number === '') {
            setFormData({
                ...formData,
                emptyMessage: t(keys.loanInfoPage.LOAN_EMPTY),
                overMessage: '',
            });
            Boolean(element) && element.focus();
            return;
        }

        if (!isValidateRange()) {
            Boolean(element) && element.focus();
            return;
        }

        props.handleLoanAmount(formData.number);
    };

    return (
        <React.Fragment>
            <div ref={refContainer} className={joinclass('form-group', hasError && 'has-error')}>
                <label>{t(keys.loanInfoPage.LOAN_LABEL)}</label>

                <InputCurrency
                    initFocused
                    value={formData.number}
                    onChange={handleAmountChange}
                    onBlur={isValidateRange}
                    handleEnter={handleNext}
                    tipMessage={t(keys.loanInfoPage.LOAN_TIP, paramMessage)}
                    emptyMessage={formData.emptyMessage}
                    errorMessage={formData.overMessage}
                />
            </div>

            <WrapAction className="buttons my-4 flex-column-reverse d-flex d-md-block">
                {props.isEditMode &&
                    <PLButton
                        primary={false}
                        onClick={() => ScreenBusUtils.gotoScreenConfirmation()}>
                        Cancel
                    </PLButton>
                }

                <PLButton onClick={handleNext}>
                    Next
                </PLButton>
            </WrapAction>
        </React.Fragment>
    );
};

export default translate()(ExpectedAmount);
