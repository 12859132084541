import { useGet, usePost } from './useService';

import config from '../config';
import { INITIATION_FEE } from '../constants';
import HttpClient from './HttpClient';

export const endpointGetFacialSDK = `${config.host}/lfs/v1.0.0/personal-loan-facial/sdk-license`;
export const endpointPostFacialInitial = `${config.host}/lfs/v1.0.0/personal-loan-facial/session`;
export const endpointPostFacialResult = `${config.host}/ext-lcs/v1.0.0/lending-completion/external/facial/retrieve-result`;

export const useGetFacialSDK = ({
    stepUpToken,
    said,
    phoneNumber,
}) => useGet({
    url: endpointGetFacialSDK,
    headers: {
        'Step-Up-Token': stepUpToken,
        SaId: said,
        mssidn: phoneNumber,
    },
});

export const useInitialFacialInfo = ({
    stepUpToken,
    said,
    phoneNumber,
    session,
    loanApplicationNumber,
    noLockScreen,
}) => {
    const postching = usePost({
        noLockScreen,
        url: endpointPostFacialInitial,
        headers: {
            'Step-Up-Token': stepUpToken,
            SaId: said,
            mssidn: phoneNumber,
        },
        bodyData: {
            session,
            loan_application_number: loanApplicationNumber,
        },
    });

    return {
        ...postching,
        rePost: sessionToken =>
            postching.onPostData({
                newData: {
                    session: sessionToken,
                },
            }),
    };
};

export const fetchFacialResults = ({
    stepUpToken,
    session,
    loanApplicationNumber,
}) => HttpClient
    .axiosPost({
        endPoint: endpointPostFacialResult,
        headers: {
            'Step-Up-Token': stepUpToken,
        },
        data: {
            session,
            initiationFeePaymentMethod: INITIATION_FEE.ADD,
            isSelectInsurance: true,
            loanApplicationNumber,
        },
    });

