import React from 'react';
import joinclass from 'joinclass';

function LabelTitle({
  children, className, small, ...props
}) {
  return (
    <div className={joinclass('label-title', small && 'small', className)} {...props}>{children}</div>
  );
}

export default LabelTitle;
