import { handleActions } from 'redux-actions';

import {
    EVALUATE_ELIBILITY_CHECK_IN_PROGRESS,
    EVALUATE_ELIBILITY_CHECK_RESULT,
    SAVE_CUSTOMER_INFO,
} from '../store/actionTypes';

const initialState = {
    retryCount: 0,
    stepUpToken: null,
    customer: {
        firstName: null,
        lastName: null,
    },
};

export const actions = {
    [EVALUATE_ELIBILITY_CHECK_RESULT]: () => ({
        ...initialState,
    }),
    [EVALUATE_ELIBILITY_CHECK_IN_PROGRESS]: (state) => ({
        ...initialState,
        retryCount: state.retryCount + 1,
    }),
    [SAVE_CUSTOMER_INFO]: (state, { payload }) => ({
        ...initialState,
        customer: {
            firstName: payload.customerFirstName,
            lastName: payload.customerLastName,
        },
    }),
};

export default handleActions(actions, initialState);
