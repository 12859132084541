import React from 'react';
import person from '../images/x2-icon/step-customer-icon.svg';
import payment from '../images/x2-icon/step-payments-icon.svg';
import notePen from '../images/x2-icon/step-sign-contract-icon.svg';
import payOut from '../images/x2-icon/step-payout-icon.svg';

import flash from '../images/x2-icon/reason-flag-icon.svg';
import disbursment from '../images/x2-icon/reason-disburse-icon.svg';
import money from '../images/x2-icon/reason-money-icon.svg';

// convert to function and use i18n if need
export const x2LandingStepData = [
    {
        icon: <img src={person} width={48} height={48} alt=" " />,
        title: 'Check your eligibility',
        content: 'We’ll ask some quick questions to see if you’re eligible for the loan.',
    },
    {
        icon: <img src={payment} width={48} height={48} alt=" " />,
        title: 'Share 3 months’ bank statements',
        content: 'We’ll then digitally verify your affordability on the spot—with no waiting.',
    },
    {
        icon: <img src={notePen} width={48} height={48} alt=" " />,
        title: 'Sign contract online',
        content: 'There’s no paperwork. Sign the contract online, and we’ll email you everything.',
    },
    {
        icon: <img src={payOut} width={48} height={48} alt=" " />,
        title: 'Get your cash',
        content: 'Once the loan is approved, we’ll pay the funds instantly into your account.',
    },
];

export const x2LandingReasons = [
    {
        icon: <img src={flash} width={40} height={40} alt=" " />,
        iconMobile: <img src={flash} width={24} height={24} alt=" " />,
        title: 'Apply online, with no paperwork',
    },
    {
        icon: <img src={disbursment} width={40} height={40} alt=" " />,
        iconMobile: <img src={disbursment} width={24} height={24} alt=" " />,
        title: 'Instant disbursement',
    },
    {
        icon: <img src={money} width={40} height={40} alt=" " />,
        iconMobile: <img src={money} width={24} height={24} alt=" " />,
        title: 'One of the cheapest personal loans in SA',
    },
];

export const x2LandingMonthCalculator = [
    {
        title: 'Your monthly repayments',
        classNameMoney: 'month-special-value font-size-18 font-size-md-24',
        key: 'monthRepay',
        tooltipId: 'monthRepay',
        tooltipText: 'Estimate includes service fees, interest and insurance premium charged per month',
    },
    {
        title: 'Total interest, fee and insurance',
        smallTitle: '(Over the repayment term)',
        key: 'totalInterestAndFee',
        tooltipId: 'totalInterestAndFee',
    },
    {
        title: 'Total cost of the loan',
        key: 'totalRepay',
        tooltipId: 'totalRepay',
        tooltipText: 'Including the total interest, fees and insurance over the repayment term',
    },
];

export const x2LandingInterestFee = {
    interestRate: {
        title: 'Example interest rate',
        isPercent: true,
        key: 'interestRate',
        subLabel: '(per annum)',
    },
    initiationFee:
    {
        title: 'Once-off initiation fee',
        key: 'initiationFee',
    },
    insurance:
    {
        title: 'TFG Money Personal Loan Protection*',
        key: 'insurance',
        subLabel: () => { },
    },
    serviceFee:
    {
        title: 'Service fee',
        key: 'serviceFee',
        subLabel: '(per month)',
    },
};

export const x2OfferInterestFee = {
    interestRate:
    {
        title: 'Annual interest rate',
        isPercent: true,
        key: 'interestRate',
        subKey: 'subKeyInterestRate',
    },
    establishmentFeeAmount:
    {
        title: 'Initiation fee',
        key: 'establishmentFeeAmount',
    },
    insuranceFeeAmount:
    {
        title: 'Mandatory credit life insurance premium*',
        key: 'insuranceFeeAmount',
    },
    loanServiceFeeAmount:
    {
        title: 'Monthly service fee',
        key: 'loanServiceFeeAmount',
    },
};
