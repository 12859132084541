/* eslint-disable no-mixed-operators */
import React from 'react';
import { connect } from 'react-redux';

import { ScreenBusUtils } from '../hooks/useEventBus';

import { XWrapMoneyLargeSkeleton } from '../components/Common/LoadingSkeletons';
import XWrapMoney from '../components/Common/XWrapMoney';
import LabelLoanApplication from '../components/Common/LabelLoanApplication';
import PLButton from '../components/Common/PLButton';

import iconMoneyLimit from '../assets/images/iconMoneyLimit.svg';

const LowerOffers = ({ data }) => {
    const amount = (data.offerList[0] && data.offerList[0].finalApprovedAmount);
    return (
        <div id="offers">
            <LabelLoanApplication />

            <img
                className="mt-2"
                src={iconMoneyLimit}
                alt=""
                width="64"
            />

            <h2 className="font-size-16 font-size-md-20 font-weight-medium mt-4">
                Now that your affordability has been verified, we’re excited to let you know we’ll be lending you:
            </h2>
            <XWrapMoneyLargeSkeleton>
                <XWrapMoney className="font-size-36 font-size-md-40">{amount}</XWrapMoney>
            </XWrapMoneyLargeSkeleton>

            <p className="mt-5">
                Only one more step to go after this.
            </p>

            <div className="buttons my-4">
                <PLButton onClick={() => ScreenBusUtils.gotoScreenPackage()}>
                    Next
                </PLButton>
            </div>
        </div>
    );
};

const mapStateToProps = state => ({
    data: state.offers || { offerList: [] },
});

export default connect(
    mapStateToProps,
)(LowerOffers);
