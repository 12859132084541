export default {
  common: {
    BUTTON_NEXT: 'Next',
    BUTTON_CANCEL: 'Cancel',
    BUTTON_OK: 'Ok',
    BUTTON_REVIEW: 'Review application',
    BUTTON_BACK: 'Back',
    MONTHS:
      '{"months" : ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"]}',
    FREQUENCY: '{"frequency": ["Weekly", "Fortnightly", "Monthly"]}',
    SELECT_OPTION: 'Please choose an option',
  },
  handleErrors: {
    HEADER_MESSAGE_CANNOT_PROCEED: 'We cannot proceed with your application',
    HEADER_MESSAGE_SOMETHING_WRONG: 'Something went wrong',
    CONTENT_MESSAGE_NO_INVITATION: 'We love that you’re interested in applying for a Personal Loan with us. Unfortunately, applications are only by invitation at the moment so we’ll let you know if it becomes available for you in the future.',
    CONTENT_MESSAGE_TECHNICAL_DIFFICULTY: 'Sorry, we have encountered some issues while processing your request. Please try again later.',
    CONTENT_MESSAGE_CONTACT_CENTRE: 'For further assistance, please call our Customer Contact Centre on 0860 999 119.',
    CONTENT_MESSAGE_UNDER18: "We're sorry, but application for personal loan is only open to individuals who are 18 years old and above.",
    EXPRESS_BUTTON: 'Express interest',
    CHANGE_PHONE_NUMBER_BUTTON: 'Re-enter cellphone number',

    OTP_LIMIT_HEADER: 'OTP limit reached',
    OTP_LIMIT_TOP: 'You have reached the maximum number of OTP attempts in a session.',
    OTP_LIMIT_BOTTOM: 'Please start again.',

    OTP_CANNOT_VERIFY_HEADER: 'We couldn’t verify your cellphone number',
    OTP_CANNOT_VERIFY_TOP: 'We need to verify your cellphone number to continue your loan application. For your security, you’ll have to start your application again and enter the correct OTP.',
    OTP_EXPIRED_HEADER: 'We are not able to verify you',
    OTP_EXPIRED_TOP: 'Unfortunately, we are unable to proceed with your application until we verify your cellphone number.',
    OTP_EXPIRED_BOTTOM: 'OTP is expired',
    CONNECTIVITY_ERROR_BOTTOM: "Due to customer's internet connectivity issue",

    HEADER_EXISTING_TYMEBANK_PROFILE: 'You can continue your application on the app',
    EXISTING_TYMEBANK_PROFILE: 'Since you’re already a TymeBank customer, we’ve made it easy to continue your application on the app.',
    EXISTING_TYMEBANK_PROFILE_BOTTOM: 'Download the TymeBank app now if you haven’t already.',

    HEADER_AGE_MUST_BE_18: 'You must be 18 or older to apply for a Personal Loan',
    AGE_MUST_BE_18: "We love that you're interested in applying {{productName}} Personal Loan already but according to regulations, you'll have to wait utils you're 18 years old. For more info, contact us at {{contactCenter}}.",
    SAFPS_FAILED: 'Your ID number is listed with the South African Fraud Prevention Service (SAFPS). Visit www.safps.org.za or call the SAFPS directly on 011 867 2234 for further assistance.',
    ASSISTANCE_MESSAGE: 'For more information, contact us at {{contactCenter}} and provide the below error code.',
    IDN_BLOCK: "Before you can apply for {{productName}} Personal Loan with TymeBank, you'll need to update your personal information at the Department of Home Affairs.",
    // cellphone
    HEADER_CELLPHONE_EXIST: 'That cellphone number is already in use',
    CELLPHONE_EXIST: 'Unfortunately, that cellphone number is already registered with TymeBank. Check that you entered the correct phone number and try again.',
    CELLPHONE_EXIST_SUPPORT: 'Contact us at {{contactCenter}} if you need assistance.',

    LOAN_IN_PROGRESS: 'Please check your emails and follow the link we provided to continue your existing Personal Loan application from where you left off.',
    HEADER_HAS_PROBLEM: 'There seems to be a problem',
    HAS_PROBLEM: 'You can try again and if the issue persists, call Customer Support on {{contactCenter}}.',
    HEADER_APPLY_REACH_LIMIT: 'Maximum attempt reached',
    APPLY_REACH_LIMIT: 'You have reached the maximum attempt allowed for an application. Please try again in 10 minutes.',
    EXPIRED_INVITATION_HEADER: 'Your invitation has expired',
    EXPIRED_INVITATION_CONTENT: 'Sorry, the invitation sent to you has expired. To request a new invitation or any further assistance, please call our Customer Contact Center on 0860 999 119.',

    TRUID_ADDRESS_CONTENT: 'Data update failed, please try to submit again.',
  },
  landingPage: {
    APPLY_LOAN_HEADER: 'A Personal Loan on your terms',
    WHAT_IS_PERSONAL_LOAN: 'Quick and easy',
    LOAN_HEADER_SUB: 'Apply online in just a few minutes and get an instant answer. Let’s boost your budget today!',
    PERSONAL_LOAN_DESCRIPTION:
      'You know who you’re going to be and a TymeBank Personal Loan can help you get there. Once approved, the money will be added to your EveryDay account immediately and then you repay us by monthly debit order on the day you choose.',
    COST_AND_FEES: 'Cost and fees',
    INTEREST_RATE: 'Interest rate',
    ESTABLISHMENT_FEE: 'Initiation fee',
    LOAN_SERVICE_FEE: 'Loan service fee',
    BEFORE_STARTED: 'What you will need',
    PLEASE_HAVE_DOCUMENT: 'Please have these documents ready:',
    BANK_STATEMENT_OR_PAYSLIP: 'Your bank statements for the last 3 months',
    MARRIAGE_CERTIFICATE: 'Marriage certificate and spouse’s consent (if applicable)',
    BUTTON_START_NOW: 'Start now',
    BUTTON_BACK: "Help & FAQ's",
  },

  saidPage: {
    PAGE_TITLE: 'Let’s start with your ID number',
    PAGE_SUB_HEADING: 'We’ll use your 13-digit ID number to verify your identity',

    SAID_LABEL: 'Enter your South African ID number',
    SAID_EMPTY: 'Enter a South African ID number.',
    SAID_INVALID: 'Enter a valid South African ID number.',
    SAID_INVALID_18_AGE: 'You need to be over 18 years to apply for a loan.',
    SAID_POLICY: 'By entering your ID number and continuing, you agree to our',
  },
  phonePage: {
    PAGE_TITLE: 'Next, we need your cellphone number',
    PAGE_SUB_HEADING: 'We’ll use it to send you updates about your loan',

    PHONE_LABEL: 'Enter your South African cellphone number',
    PHONE_EMPTY: 'Enter a South African cellphone number.',
    PHONE_INVALID: 'Enter a valid South African cellphone number.',
  },
  otpPage: {
    PAGE_TITLE: 'Enter the OTP we just sent you',
    PAGE_SUB_HEADING: 'We sent an OTP (One-time PIN) to {{phoneNumber}}',

    ENTER_OTP: 'Enter the OTP we just sent you',
    DID_NOT_RECEIVE: 'Didn’t get the OTP?',
    RESEND_IN_SECOND: 'You can resend a new OTP in {{seconds}} second',
    RESEND: 'Resend OTP',
  },
  loanInfoPage: {
    PAGE_TITLE: 'How much do you want to borrow?',
    PAGE_SUB_HEADING: 'You can apply for a loan of between {{minAmount}} and {{maxAmount}}',

    LOAN_LABEL: 'Enter your desired loan amount:',
    LOAN_TIP: 'Enter an amount between {{minAmount}} to {{maxAmount}}.',
    LOAN_EMPTY: 'Enter a loan amount.',
    LOAN_OVER_RANGE: 'Amount must be between {{minAmount}} and {{maxAmount}}.',
  },
  loanPurposePage: {
    PAGE_TITLE: 'What will your loan funds be used for?',
    PAGE_SUB_HEADING: 'Let’s get you one step closer to achieving your goals',
    LOAN_PURPOSE_OPTIONS: 'Select an option from the list below:',
  },
  // revamp ux
  userInfoPage: {
    PAGE_TITLE: 'Tell us about yourself',
    PAGE_SUB_HEADING: 'We’ll keep your personal info secure at all times',
  },

  // financial page
  financeInformationPage: {
    PAGE_TITLE: 'Let’s check your affordability',
    PAGE_SUB_HEADING: 'We’ll just need your income and expense information',
  },

  financeInformation: {
    PAGE_TITLE: 'Tell us about your finances',
    PAGE_SUB_HEADING: 'Tell us about your finances',
    WHAT_IS_TOTAL: 'What is your gross monthly income?',
    THIS_INCLUDES_ALL: 'This is all sources of income before deductions',
    DEDUCTION: 'Monthly deductions',
    INCLUDES_ALL_STATUTORY:
      'Includes all statutory and non-statutory deductions, such as TAX, UIF, Pension, Medical Aids etc',
    DEBT_COMMITMENTS: 'Monthly debt commitments',
    SUCH_AS_REPAYMENTS: 'Such as repayments on credit card, personal loan, mortgage etc.',
    ARE_YOU_RECEIVE:
      'Are you receiving debt counselling, do you have a pending debt review application or are you insolvent?',
    LIVING_EXPENSES: 'Monthly living expenses',
    SUCH_AS_FOOD: 'Such as cellphone bills, insurance, entertainment, food, petrols, school fees etc.',
    YOUR_AVAILABLE_BALANCE: 'Your available balance left per month should be',
    MARITAL_TYPE_TITLE: 'What is the marital type?',
    SPOUSE_CONSENT_TITLE: 'Has your spouse given consent to entering a credit agreement with TymeBank?',
    ERROR_INCOME: 'Please enter an amount, which is greater than zero (0)',
    ERROR_BALANCE: 'Unfortunately, we cannot proceed with your application at this time because the above amount is too low. If you’ve entered the incorrect information for your affordability details, you can edit them now.',
    HOW_LONG_DID_YOU_WORK: 'How long did you work at your previous company?',
  },

  // additional-page
  additionalInfoPage: {
    PAGE_TITLE: 'Step 3 of 3',
    PAGE_SUB_HEADING: 'Tell us more about yourself',
  },
  additionalInfo: {
    PAGE_TITLE: 'Step 3 of 3',
    PAGE_SUB_HEADING: 'Tell us more about yourself',

    FREQUENCY_TITLE: 'How often do you get paid?',
    NEXTPAYDATE_TITLE: 'When is your next pay day',
    EMERGENCY_TITLE: 'Who should we contact if we can’t reach you?',
    EMERGENCY_INFO: 'We are not asking for a guarantor, he/she does not have obligation to pay your debt.',
    EMERGENCY_NAME: 'Name',
    EMERGENCY_CELLPHONE: 'Cellphone number',
    EMERGENCY_CELLPHONE_ERROR: 'Please enter a valid cellphone number.',
  },

  confirmationPage: {
    PAGE_TITLE: 'Review your application',
    PAGE_SUB_HEADING: 'If any of this information is incorrect, you can go back and update it before submitting your application.',

    BUTTON_EDIT: 'Edit',
    WHAT_YOU_LIKE_TO_BORROW: 'How much would you like to borrow?',
    ABOUT_YOUR_SELF: 'About yourself',
    ABOUT_FINANCE: 'About your finances',
    ABOUT_MORE: 'More about yourself',
    AGREE_PERMISSION:
      'I agree that TymeBank may undertake a credit bureau enquiry on me and that third parties may give TymeBank information about me including financial information and information related to my spending and buying behaviour.',
    WILLING_PAY_EXTRA: 'If there’s an option, I am willing to pay extra for a quicker loan application.',
    THIRD_PARTY_PERMISSION:
      'I give third parties permission to give TymeBank information about me including my personal profile, buying and spending behaviour and financial and credit profile. This includes other Banks, Credit Providers and Retailers like Pick n Pay.',

    LOAN_PURPOSE_TITLE: 'This loan is for:',
    LOAN_AMOUNT_TITLE: 'Loan amount',
    EMAIL_TITLE: 'Email address',

    EMPLOYMENT_STATUS: 'Employment status',
    RACE: 'Race',
    MARITAL_STATUS: 'Marital status',
    MARITAL_TYPE: 'What is the marital type?',
    SPOUSE_CONSENT: 'Has your spouse given consent to enter a credit agreement with TymeBank?',

    INCOME: 'Monthly gross income',
    DEDUCTION: 'Monthly deductions',
    DEBT: 'Debt commitments',
    IS_DEBT_COMMITMENT:
      'Are you receiving debt counselling, do you have a pending debt review application or are you insolvent?',
    LIVING_EXPENSES: 'Living expenses',

    WORK_FOR: 'Current company name',
    WORK_START: 'Start date',
    HOW_PAID: 'How often do you get paid?',
    DAY_PAID: 'Which day do you get paid?',
    CONTACT: 'Who should we contact if we can’t reach you?',
  },

  offersPage: {
    PAGE_TITLE: 'Your loan application is conditionally approved',
    PAGE_SUB_HEADING: '',
  },

  limitOffersPage: {
    PAGE_TITLE: 'Congrats! Your loan is conditionally approved',
    PAGE_SUB_HEADING: 'You’re one step closer to reaching your goal...',
  },

  lowerOffersPage: {
    PAGE_TITLE: 'Congrats! Your loan is fully approved',
    PAGE_SUB_HEADING: 'You’re one step closer to reaching your goal.',
  },

  introTruIDPage: {
    PAGE_TITLE: 'Now, we’ll confirm your affordability',
    PAGE_SUB_HEADING: '',
  },

  introSprintHivePage: {
    PAGE_TITLE: 'Email us your bank statements',
    PAGE_SUB_HEADING: 'To confirm your affordability.',
  },

  truidPage: {
    PAGE_TITLE: 'Now, we’ll confirm your affordability',
    PAGE_SUB_HEADING: 'We should be able to give you an instant result',
  },

  packagePage: {
    PAGE_TITLE: 'Congrats! Your loan has been fully approved',
    PAGE_SUB_HEADING: 'Let’s finalise some of your repayment plan details',
  },

  agreementPage: {
    PAGE_TITLE: 'Pre-Agreement Statement and Quotation',
    PAGE_SUB_HEADING: 'Check your email for a copy of the agreement',
  },

  introSelfiePage: {
    PAGE_TITLE: 'Sign the agreement with a selfie',
    PAGE_SUB_HEADING: 'To securely confirm your acceptance of the loan agreement, we will ask you to take a selfie.',
  },

  selfiePage: {
    PAGE_TITLE: 'Now let\'s verify by taking selfie',
    PAGE_SUB_HEADING: 'To make things easier and more secure, we just need to quickly check your face using your camera.',
  },

  debicheckPage: {
    PAGE_TITLE: 'We’re ready to disburse your {{debiCheckAmount}}!', // key not working
    PAGE_SUB_HEADING: 'Confirm your debit order so we can get those funds into your account',
  },

  fundedPage: {
    PAGE_TITLE: 'Your funds have been disbursed',
    PAGE_SUB_HEADING: 'Nothing left to do here!',
  },

  offersDeclinedPage: {
    PAGE_TITLE: 'We’re sorry',
    PAGE_SUB_HEADING: 'Your application wasn’t approved this time around',

    REF_NUMBER_LABEL: 'Reference number',
    ANY_QUESTION_LABEL: 'If you have any questions, please call our Customer Contact Centre on',
    WHAT_CAN_DO_LABEL: 'What can you do?',
    LEARN_MORE_TEXT:
      'While we can’t give you a loan right now, you can still open a free Everyday account on the TymeBank app. When you’ve settled your existing loan, it’ll be easier to apply for a TymeBank TFG Money Personal Loan because you’ll already be a TymeBank customer!',
    VISIT: 'Visit',
    TODAY: 'today!',
    OFFERS_DESCRIPTION:
      'Your application has been provisionally approved. Applications that receive final approvals will be offered at different plans with different costs as shown by the example below. Final communication of the outcome of your application will be made by email. If successful you can make your plan selection at a Kiosk when you finalise your application.',
    LEVEL_USER_DESCRIPTION:
      'Loans will only be paid to accounts with a "Getting to know you" or "Good Friends" profile level.',
    LOAN_AMOUNT: 'Loan amount',
    WHAT_NEXT: 'What’s next',
    NEXT: 'Next',
    SEND_STATEMENTS: 'Send us your bank statements',
    SEND_STATEMENTS_DESCRIPTION: "Please send us your last 3 months' bank statements to loan@tymebank.co.za.",
    WAITTING: 'Wait for our go ahead',
    WAITTING_DESCRIPTION: 'If everything is in order we will notify you via email and SMS.',
    GO_TO_KIOSK: 'Open an EveryDay account',
    GO_TO_KIOSK_DESCRIPTION: 'Join TymeBank and your loan will be paid into your EveryDay account.',
  },

  resumePage: {
    PAGE_TITLE: 'We’re sorry, your application has expired',
    PAGE_SUB_HEADING: 'You’ll need to start a new Personal Loan application',
  },

  uploadPage: {
    PAGE_TITLE: 'Upload your bank statements',
    PAGE_SUB_HEADING: 'You can upload up to a maximum of 6 PDF files',
  },
  finalPage: {
    PAGE_TITLE_SUCCESS: 'Nearly done',
    PAGE_SUB_HEADING_SUCCESS: 'Please wait for our go-ahead.',
    PAGE_TITLE_FAILED: 'Nearly done',
    PAGE_SUB_HEADING_FAILED: 'Before we can give you the thumbs-up, there are documents we need from you.',
  },
  fullyApprovedPage: {
    PAGE_TITLE: 'Congrats, your loan is fully approved!',
    PAGE_SUB_HEADING: 'Just a few steps left to finalise your loan repayment plan',
  },
  methodsPage: {
    PAGE_TITLE: 'We need your bank statements',
    PAGE_SUB_HEADING: 'Before we can give you the thumbs-up, there are documents we need from you.',
    DESCRIPTION_TIP:
      "<0>Tip:</0> Don't have your bank statements yet? You can also login to your internet banking and get your bank statements. Click <1>here</1> to find out more.",
  },
  guidePage: {
    PAGE_TITLE: 'How to get your statements from your bank',
    PAGE_SUB_HEADING:
      'This should be your statements for the last 3 months that include salary payment deposits from your named employer',
    BANK_USING: 'Which bank are you using?',
    BANK_LABEL: 'Bank name',
    HOW_TO_TEXT: '<0>How to get your bank statement from {{name}}:</0>',
  },
  inputEmail: {
    FORM_TITLE: 'What is your email address?',
    FORM_LABEL_EMAIL: 'Email address',
    FORM_LABEL_EMAIL_AGAIN: 'Re-enter email address',
    FORM_ERROR: 'Enter a valid email address.',
    FORM_RETRY_ERROR: "Email addresses don't match",
  },

  employmentStatus: {
    ARE_YOU_TITLE: 'Are you currently',
  },
  raceSelection: {
    ARE_YOU_TITLE: 'Are you',
  },
  currentCompany: {
    COMPANY_FORM_TITLE: 'Enter your current employment details',
    COMPANY_FORM_LABEL: 'Company name',
    WORKING_TIME_LABEL: 'When did you start working here?',
  },
};
