import React, { Component } from 'react';
import joinclass from 'joinclass';
import { v4 } from 'uuid';

import InputCheck from './InputCheck';
import { CheckIcon } from '../../assets/images/elements/CheckIcon';

export class ButtonSelectionTick extends Component {
    constructor(props) {
        super(props);
        this.refInput = React.createRef();
    }

    componentDidMount() {
        if (this.props.isSelected && this.refInput.current) {
            this.refInput.current.focus();
        }
    }

    render() {
        const {
            btnIcon,
            btnGroup = v4(),
            label,
            value,
            isSelected,
            children,
            onClick,
            handleNext,
            ...rest
        } = this.props;

        return (
            <div className="btn-wrapper">
                <button
                    className={joinclass('btn btn-with-icon d-flex align-items-center gap-8', isSelected && 'active')}
                    onClick={e => {
                        if (this.refInput.current) {
                            this.refInput.current.focus();
                        }
                        onClick(e);
                    }}
                    {...rest}
                >
                    {btnIcon && <i className="icon" style={{ backgroundImage: `url(${btnIcon})` }} />}
                    <span>{label || children}</span>
                    {isSelected ? <i className="icon-check"><CheckIcon /></i> : ''}
                </button>

                <InputCheck
                    getInputRef={e => { this.refInput.current = e; }}
                    hidden
                    name={btnGroup}
                    value={value}
                    checked={isSelected}
                    handleChange={() => onClick(value)}
                    handleEnterSpace={handleNext}
                />
            </div>
        );
    }
}

export default ButtonSelectionTick;
