import React, { useRef } from 'react';
import { useElementShowOnce } from '../../../hooks/useOnScreen';
import { durationTimes } from '../../../utils/commonUtils';

import { FlexColumnCenter } from '../../../components/Common/FlexCenter';
import { MovingFadeIn, MovingPopIn } from '../../../components/Common/AnimateText';

import { x2LandingStepData } from '../../../assets/x2-data/x2-landing-data';

function XLandingR2Steps() {
    const refsSteps = useRef(null);
    const isStepsOnScreen = useElementShowOnce(refsSteps);

    return (
        <FlexColumnCenter className="x-landing-step">
            <div className="container">
                <h1 className="w-100 text-center mb-4 mb-md-5">
                    <b className="font-weight">Four</b> easy steps
                </h1>

                <div ref={refsSteps} className="d-flex flex-column flex-md-row list-step col-12 gap-32 gap-md-16">
                    {isStepsOnScreen && x2LandingStepData.map((item, index) => (
                        <React.Fragment key={item.title}>
                            <MovingFadeIn
                                duration={durationTimes[index]}
                                className="step-item pl-shadow position-relative"
                            >
                                <MovingPopIn duration="2s" className="x-step-point-index">{index + 1}</MovingPopIn>

                                <div className="d-flex flex-column gap-8">
                                    <div className="x-landing-step-icon d-flex flex-row">
                                        <div className="x-step-point">{item.icon}</div>
                                    </div>
                                    <div className="d-flex flex-column text-left text-md-center">
                                        <h5 className="mb-1 mb-md-2">{item.title}</h5>
                                        <p className="sub-guilde">{item.content}</p>
                                    </div>
                                </div>
                            </MovingFadeIn>
                        </React.Fragment>
                    ))}
                </div>
            </div>
        </FlexColumnCenter>
    );
}

export default XLandingR2Steps;
