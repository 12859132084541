import { handleActions } from 'redux-actions';
import {
    FETCH_LIST_PRODUCTS,
    FETCH_LIST_PRODUCTS_SUCCESS,
    FETCH_LIST_PRODUCTS_FAILED,
} from '../store/actionTypes';

const initialState = {
    isLoading: null,
    products: null,
};

export const actions = {
    [FETCH_LIST_PRODUCTS]: (state = initialState) => ({
        ...state,
    }),
    [FETCH_LIST_PRODUCTS_SUCCESS]: (state, { payload }) => ({
        ...state,
        products: payload,
    }),

    [FETCH_LIST_PRODUCTS_FAILED]: (state = initialState, action) => ({
        ...state,
        error: action.payload,
        // do we have any errors here?
    }),
};

export default handleActions(actions, initialState);
