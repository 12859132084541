import React from 'react';
import joinclass from 'joinclass';

import XWrapMoney from '../Common/XWrapMoney';
import { XWrapLabelSmallSkeleton } from '../Common/LoadingSkeletons';

const MinMaxUpsell = ({
  label,
  className,
  children,
}) => {
  if (children) {
    return (
      <div className={joinclass('col-5 p-0 px-1', className)}>
        {label}: <XWrapMoney>{children}</XWrapMoney>
      </div>
    );
  }

  return (
    <XWrapLabelSmallSkeleton>
      <div className={joinclass('col-5 p-0 px-1', className)}>
        {label}: <XWrapMoney>{children}</XWrapMoney>
      </div>
    </XWrapLabelSmallSkeleton>
  );
};

export default MinMaxUpsell;
