import React from 'react';
import joinclass from 'joinclass';

function PLDiv({
  mobileContent,
  webContent,
  classNameMobile,
  classNameWeb,
  className,
  children,
  ...props
}) {
  return (
    <React.Fragment>
      {mobileContent && <div {...props} className={joinclass('d-sm-block d-md-none', classNameMobile, className)}>{mobileContent}</div>}
      {webContent && <div {...props} className={joinclass('d-none d-sm-none d-md-block', classNameWeb, className)}>{webContent}</div>}
      {children}
    </React.Fragment>
  );
}

export default PLDiv;
