import 'rxjs';
import { combineEpics, ofType } from 'redux-observable';
import { catchError, map, switchMap } from 'rxjs/operators';

import { FETCH_CONTRACT_INFO } from '../store/actionTypes';
import ErrorUtils from '../utils/errorUtils';
import { fetchContractInfoSuccess } from '../actions';
import apiService from '../services/apiService';

export function loanContractInfoEpic(action$) {
    return action$.pipe(
        ofType(FETCH_CONTRACT_INFO),
        switchMap(({ payload }) => {
            const { stepUpToken, loanApplicationNumber } = payload;
            return apiService
                .getLoanContractInfo(stepUpToken, loanApplicationNumber)
                .pipe(
                    map(res => fetchContractInfoSuccess(res)),
                    catchError(error => ErrorUtils.getApiAction(error)),
                );
        }));
}

export default combineEpics(loanContractInfoEpic);
