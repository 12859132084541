import React from 'react';

// AnimateText.js
const HOCAnimation = (AnimationText, { children, ...data }) => class extends React.Component {
    render() {
        return (
            <AnimationText {...data}>{children}</AnimationText>
        );
    }
};

export default HOCAnimation;
