import NodeRSA from 'node-rsa';
import { randomBytes } from 'crypto';

import { Config } from '../data/facetec-config-data';

const SECRET_KEY_LENGTH = 16;
const randomSecureNumberArray = () => randomBytes(SECRET_KEY_LENGTH);
/**
 * Base64ToBytes : decodes a Base64-encoded string to array of int numbers.
 * @param {ArrayBuffer} base64
 * @returns {IntNumber[]}
 */
export const decode = (base64) => {
    if (!base64) return [];

    // ("atob" should be read as "ASCII to binary")
    const binString = atob(base64);
    return Int8Array.from(binString, (m) => m.codePointAt(0));
};

/**
 * BytesToBase64 : Creates a Base64-encoded ASCII string from ArrayBuffer
 * @param {ArrayBuffer} arrayBuffer
 * @returns {String}
 */
const encode = (arrayBuffer) => {
    if (!arrayBuffer) return '';

    const bytes = new Uint8Array(arrayBuffer);
    const binaryString = bytes.reduce((data, byte) => data + String.fromCharCode(byte), '');
    return btoa(binaryString);
};

/*
Import an AES secret key from an ArrayBuffer containing the raw bytes.
Takes an ArrayBuffer string containing the bytes, and returns a Promise
that will resolve to a CryptoKey representing the secret key.
*/
const importSecretKey = async (rawKey) => {
    const key = await window.crypto.subtle.importKey('raw', rawKey, 'AES-GCM', true, [
        'encrypt',
        'decrypt',
    ]);
    return key;
};
export const encrypt = async (contentData, secretKey, iv) => {
    const cryptoKey = await importSecretKey(secretKey);
    const encryptData = await crypto.subtle.encrypt(
        { name: 'AES-GCM', iv },
        cryptoKey,
        contentData,
    );
    return encryptData;
};

export const getAuditTrailImageBase64Encrypted = async (auditTrail, secretKey, iv) => {
    const auditTrailBytes = decode(auditTrail);
    const enscryptAuditTrail = await encrypt(auditTrailBytes, secretKey, iv);
    const auditTrailImageBase64Encrypted = encode(enscryptAuditTrail);
    return auditTrailImageBase64Encrypted;
};

export const getEncryptedSecret = (secretKey, iv, publicEncryptionKey) => {
    // eslint-disable-next-line no-use-before-define
    const fullPublicEncryptionKey = formatPublicKey(publicEncryptionKey);
    const nodeRSA = new NodeRSA(fullPublicEncryptionKey, 'public', {
        encryptionScheme: {
            scheme: 'pkcs1',
        },
    });

    const base64EncryptedSecretKey = nodeRSA.encrypt(secretKey, 'base64');
    const base64EncryptedIV = nodeRSA.encrypt(iv, 'base64');
    return `${base64EncryptedSecretKey}:${base64EncryptedIV}`;
};

export const buildPreSignedBody = async (sessionResult, publicEncryptionKey) => {
    const secretKey = randomSecureNumberArray();
    const iv = randomSecureNumberArray();
    const auditTrailImage = await getAuditTrailImageBase64Encrypted(sessionResult.auditTrail[0], secretKey, iv);
    const secret = getEncryptedSecret(secretKey, iv, publicEncryptionKey);

    const payload = {
        faceMap: sessionResult.faceScan, // done
        sessionId: sessionResult.sessionId, // done
        auditTrailImage,
        secret,
    };
    return payload;
};

export const formatPublicKey = encryptionPublicKey => {
    if (!encryptionPublicKey) {
        return Config.PublicFaceScanEncryptionKey;
    }

    if (encryptionPublicKey.includes('-----BEGIN PUBLIC KEY')) {
        return encryptionPublicKey;
    }

    return `-----BEGIN PUBLIC KEY-----\n${atob(encryptionPublicKey).replaceAll('\\n', '\n')}\n-----END PUBLIC KEY-----`;
};
