import { handleActions } from 'redux-actions';
import {
    VERIFY_OTP_SUCCESS,
    VERIFY_OTP_FAILED,
    SAVE_STEP_UP_TOKEN,
} from '../store/actionTypes';

const initialState = {
    stepUpToken: null,
};

export const actions = {
    [VERIFY_OTP_SUCCESS]: (state = initialState) => ({
        ...state,
        error: null,
    }),
    [VERIFY_OTP_FAILED]: (state = initialState, action) => ({
        ...state,
        error: action.payload,
    }),
    [SAVE_STEP_UP_TOKEN]: (state = initialState, action) => ({
        ...state,
        stepUpToken: action.payload,
    }),
};

export default handleActions(actions, initialState);
