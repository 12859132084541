import HttpClient from './HttpClient';
import config from '../config';
import { API_HEADER_INPUTS } from '../constants';
import { getProvider } from '../utils/commonUtils';

const API_HOST = config.host;
const API_HOST_AND_CONTEXT = API_HOST + config.apiService.contextPath;
const API_HOST_AND_LDS_CONTEXT = API_HOST + config.apiService.ldsContextPath;
const API_HOST_AND_EXT_LCS_CONTEXT = API_HOST + config.apiService.extLcsContextPath;

export default class ApiService {
    static getConfigurationList = () =>
        HttpClient.get(`${API_HOST_AND_CONTEXT}/settings/configurations`, {
            'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE,
            Channel: config.channel,
        });

    static validationSaid = payload =>
        HttpClient.postAsJson(
            `${API_HOST_AND_CONTEXT}/validation-and-capture/said`,
            {
                'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE,
                Channel: config.channel,
                setValue: 'invalidInvitation',
            },
            {
                saId: payload.said,
                phoneNumber: payload.phone,
                plProductId: payload.productId,
            },
        );

    static getErrorCases = () =>
        HttpClient.get(`${API_HOST_AND_CONTEXT}/errorsSimulator`, {
            'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE,
            setValue: 'invalidInvitation',
        });

    static fakeTimeout = () =>
        HttpClient.get('https://spring-wood-8022.getsandbox.com/hello', {
            'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE,
        });

    static verifyOtp = (requestToken, otpCode) =>
        HttpClient.postAsJson(
            `${API_HOST}/otp/v1.0.0/otp/verification/request-token`,
            { 'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE },
            { requestToken, otpCode },
        );

    static generateOtp = requestToken =>
        HttpClient.postAsJson(
            `${API_HOST}/otp/v1.0.0/otp`,
            { 'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE },
            { requestToken },
        );

    static getListValues = () =>
        HttpClient.get(`${API_HOST_AND_CONTEXT}/settings/lovs`, {
            'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE,
            Channel: config.channel,
        });

    static getDecisionResults = (loanApplicationNumber, allowedUpSell) => {
        const type = allowedUpSell ? '&type=UPSELL' : '';
        return HttpClient.get(
            `${API_HOST_AND_LDS_CONTEXT}/decisions?loanApplicationNumber=${loanApplicationNumber}${type}`,
            {
                'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE,
                Channel: config.channel,
            },
        );
    }

    static postLoanSubmission = (captchaToken, information) =>
        HttpClient.postAsJson(
            `${API_HOST_AND_CONTEXT}/loan-application`,
            {
                'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE,
                Channel: config.channel,
                Provider: getProvider(),
                RecaptchaToken: captchaToken,
            },
            information,
        );

    static selectLoanOffers = (loanApplicationNumber, customerSelection, selectedOfferOptionId) =>
        HttpClient.putAsJson(
            `${API_HOST_AND_LDS_CONTEXT}/offers`,
            {
                'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE,
                Channel: config.channel,
            },
            selectedOfferOptionId
                ? {
                    loanApplicationNumber,
                    customerSelection,
                    id: selectedOfferOptionId,
                }
                : {
                    loanApplicationNumber,
                    customerSelection,
                },
        );

    static generatePresignedURL = (loanApplicationNumber, stepUpToken, numberOfFiles) =>
        HttpClient.get(
            `${API_HOST_AND_LDS_CONTEXT}/loan-documents/${loanApplicationNumber}/generate-pre-signed-url?numberOfFiles=${numberOfFiles}`,
            {
                'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE,
                Channel: config.channel,
                'Step-Up-Token': stepUpToken,
            },
        );

    static assignLoanDocument = (loanApplicationNumber, fileNames, stepUpToken) =>
        HttpClient.putAsJson(
            `${API_HOST_AND_LDS_CONTEXT}/loan-documents/${loanApplicationNumber}/assign-to-loan`,
            {
                'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE,
                Channel: config.channel,
                'Step-Up-Token': stepUpToken,
            }, {
            fileNames,
        },
        );

    static getProductList = () =>
        HttpClient.get(
            `${API_HOST_AND_CONTEXT}/pl-products`,
            {
                'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE,
                Channel: config.channel,
            },
        );

    static getAffordabilityResults = loanApplicationNumber => HttpClient.get(
        `${API_HOST_AND_LDS_CONTEXT}/decisions?loanApplicationNumber=${loanApplicationNumber}`,
        {
            'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE,
            Channel: config.channel,
        },
    );

    static getLoanDetail = (stepUpToken, loanApplicationNumber, initiationFeePaymentMethod) =>
        HttpClient.get(
            `${API_HOST_AND_EXT_LCS_CONTEXT}/external/loan-offer?initiationFeePaymentMethod=${initiationFeePaymentMethod}&loanApplicationNumber=${loanApplicationNumber}`,
            {
                'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE,
                Channel: config.channel,
                'Step-Up-Token': stepUpToken,
            },
        );

    static putSelectedOffer = (stepUpToken, loanApplicationNumber, selectedOfferOptionId) =>
        HttpClient.putAsJson(
            `${API_HOST_AND_EXT_LCS_CONTEXT}/external/loan-offer`,
            {
                'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE,
                Channel: config.channel,
                'Step-Up-Token': stepUpToken,
            },
            {
                loanApplicationNumber,
                selectedOfferOptionId,
            },
        );

    static getLoanContractInfo = (stepUpToken, loanApplicationNumber) =>
        HttpClient.get(
            `${API_HOST_AND_EXT_LCS_CONTEXT}/external/loan-contract-info?loanApplicationNumber=${loanApplicationNumber}`,
            {
                'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE,
                Channel: config.channel,
                'Step-Up-Token': stepUpToken,
            },
        );

    static signContract = (stepUpToken, loanApplicationNumber, initiationFeePaymentMethod, isSelectInsurance) =>
        HttpClient.postAsJson(
            `${API_HOST_AND_EXT_LCS_CONTEXT}/external/signed-fulfillment`,
            {
                'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE,
                Channel: config.channel,
                'Step-Up-Token': stepUpToken,
            },
            {
                loanApplicationNumber,
                initiationFeePaymentMethod,
                isSelectInsurance,
            },
        );

    static getFulfillment = (stepUpToken, loanReferenceNumber) =>
        HttpClient.get(
            `${API_HOST_AND_EXT_LCS_CONTEXT}/external/loan-fulfillment-result?loanReferenceNumber=${loanReferenceNumber}`,
            {
                'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE,
                Channel: config.channel,
                'Step-Up-Token': stepUpToken,
            },
        );

    static evaluateEligibilityCheckResult = (stepUpToken, said, makeDecision) => HttpClient.postAsJson(
        `${API_HOST_AND_CONTEXT}/eligibility-check`,
        {
            'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE,
            Channel: config.channel,
            'Step-Up-Token': stepUpToken,
            Provider: getProvider(),
        },
        {
            saId: said,
            makeDecision,
        },
    )

    // get PL Product config
    static getProduct = () => HttpClient.axiosGet({ endPoint: `${API_HOST_AND_CONTEXT}/pl-products` })

    static getConsents = (productId, loanApplicationNumber) => HttpClient.axiosGet({
        endPoint: `${API_HOST_AND_CONTEXT}/${productId}/consents`,
        params: {
            loanApplicationNumber,
        },
    })

    static verifyLoanApplication = (loanApplicationNumber) =>
        HttpClient.postAsJson(
            `${API_HOST_AND_LDS_CONTEXT}/loan-applications/verification`,
            {
                'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE,
                Channel: config.channel,
                Document_On_Link: true,
            },
            {
                loanApplicationNumber,
            },
        );

    static getUserTypeInfo(stepUpToken, said) {
        return HttpClient.get(
            `${API_HOST_AND_CONTEXT}/customer-info`,
            {
                'Content-Type': API_HEADER_INPUTS.CONTENT_TYPE,
                Channel: config.channel,
                'Step-Up-Token': stepUpToken,
                said,
            },
        );
    }
}
