import React from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import moment from 'moment';

import {
  fetchAffordabilityResults,
  fetchDecisionResults,
  fetchSprintHiveAffordabilityResults,
  verifyLoanApplication,
} from '../actions';

import { truidResumeStatus } from '../truidConstants';
import { ErrorCode } from '../constants';

import useEventBus, { EventBusName, ScreenBusUtils } from '../hooks/useEventBus';
import useRefreshURLTruID from '../hooks/truID/useRefreshURLTruID';
import useRefetchTruIDAfterUpdateMissing from '../hooks/truID/useRefetchTruIDAfterUpdateMissing';
import useHandleEventTruID from '../hooks/truID/useHandleEventTruID';

import { useModalPopupContext } from '../context/ModalPopupContext';
import TruidAddressUpdate from '../components/TruId/TruidAddressUpdate';
import TruidFormEditSalaryDate from '../components/TruId/TruidFormEditSalaryDate';

const TruIdContext = ({ loanInfo, userInfo, ...props }) => {
  const {
    showModal,
    showConfirmModal,
    showErrorModal,
    hideModal,
  } = useModalPopupContext();

  const incomeVerificationExpired = _.get(loanInfo, 'info.incomeVerificationExpired') || _.get(loanInfo, 'incomeVerificationExpired');
  const dateExpired = <span className="d-inline-block">{moment(new Date(incomeVerificationExpired)).format('DD/MM/YYYY')}</span>;

  useHandleEventTruID();
  useRefreshURLTruID(props);
  useRefetchTruIDAfterUpdateMissing(props);

  useEventBus(EventBusName.TRUID_ADDRESS_MISSING, addressData => showEditTruIDAddress(addressData));
  useEventBus(EventBusName.TRUID_SALARY_DATE_MISSING, () => showEditTruIDSalaryDate());

  /** HANDLE STATUS WHEN USE RESUME TRUID */
  useEventBus(EventBusName.TRUID_NOTIFY_RESUME_URL, (
    {
      code,
      title,
      message,
      loanApplicationStatus,
      loanApplicationNumber,
    },
  ) => {
    if (!code || loanApplicationStatus) {
      return;
    }

    const buttonOK = _.get(truidResumeStatus[code], 'buttonOK', 'Got it');
    const buttonCancel = _.get(truidResumeStatus[code], 'buttonCancel');

    const showCancel = Boolean(buttonCancel);
    showConfirmModal({
      modalTitle: title,
      modalContent: message,
      showCancel,
      okTitle: buttonOK,
      escOnClose: false,
      cancelTitle: buttonCancel,
      handleOK: () => {
        hideModal();

        if (!showCancel) {
          ScreenBusUtils.goHome();
        }

        // retry otp with loan APp
        if (loanApplicationNumber) {
          ScreenBusUtils.clearDeclineStatus();
          props.verifyLoanApplication({ loanApplicationNumber, isFetchStatus: false });
        }

        return undefined;
      },
      handleCancel: () => ScreenBusUtils.goHome(),
      handleXClose: () => ScreenBusUtils.goHome(),
    });

    ScreenBusUtils.clearDeclineStatus();
  });

  // listen for TRUID_NOTIFY_G_PROFILE_INCOME_TYPE
  useEventBus(EventBusName.TRUID_NOTIFY_G_PROFILE_INCOME_TYPE, ({
    code,
    title,
    message,
    isInTruID,
  }) => {
    showErrorModal({
      modalTitle: title,
      modalContent: (
        <React.Fragment>
          <div className="mb-md-4 mb-3">
            {message}
          </div>
          <div>If you need assistance, call us on 0860 999 119.</div>
          <div className="with-guilde">Error code: {ErrorCode.G_PROFILE_INCOME_TYPE}</div>
        </React.Fragment>
      ),
      showCancel: false,
      okTitle: _.get(truidResumeStatus[code], 'buttonOK', 'Got it'),
      escOnClose: false,
      handleOK: () => {
        hideModal();
        if (isInTruID) {
          ScreenBusUtils.gotoScreenIntroTruID();
        }
      },
      handleCancel: () => ScreenBusUtils.goHome(),
      handleXClose: () => ScreenBusUtils.goHome(),
    });

    ScreenBusUtils.clearDeclineStatus();
  });

  /** USER EDIT TRUID DATA */
  function showEditTruIDAddress(addressData) {
    showModal({
      modalContent: <TruidAddressUpdate addressData={addressData} />,
      centerAction: true,
      disabledClose: true,
      showOK: false,
    });
  }

  function showEditTruIDSalaryDate() {
    showModal({
      modalContent: <TruidFormEditSalaryDate
        dateExpired={dateExpired}
        handleKeepOk={() => showEditTruIDSalaryDate()}
      />,
      centerAction: true,
      disabledClose: true,
      showOK: false,
    });
  }

  return (
    <React.Fragment />
  );
};

const mapStateToProps = state => ({
  loanInfo: state.offers,
  offersState: state.offers,
  loanApplicationNumber: _.get(state.loanSubmission, 'loanApplicationNumber') ||
    _.get(state.verifyLoanApplicationData, 'loanApplicationNumber'),
  userInfo: _.get(state, 'stepsInputData.userInfo') || {},
});

export default connect(
  mapStateToProps,
  {
    fetchAffordabilityResults,
    fetchDecisionResults,
    fetchSprintHiveAffordabilityResults,
    verifyLoanApplication,
  },
)(TruIdContext);
